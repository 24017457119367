import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { CommonService } from '../../providers';
import { Subscription } from 'rxjs';
import { StorageService } from '../../services/storage.service';

@Component({
    selector: 'app-maintenance-page',
    templateUrl: './maintenance.html',
    styleUrls: ['./maintenance.scss']
  })
  export class MaintenancePage implements OnInit, OnDestroy {
    subscriptions = new Subscription();
    constructor(
        public router: Router,
        public route: ActivatedRoute,
        private storageService: StorageService,
        private commonService: CommonService
      ) { }

    async ngOnInit() {
        const isApiOk = await this.healthCheck();
        this.route.queryParams.subscribe(params => {
            if (isApiOk) {
                if (params['from']) {
                    this.router.navigate([params['from']]);
                }
                else {
                    this.router.navigate(['/404']);
                }
            }
        })
    }

    ngOnDestroy(): void {}

    async healthCheck() {
        try {
          const response = await this.commonService.healthCheck().toPromise();
          if (response.status === "error") {
            return false
          }
        } catch (err) {
          return false
        }
        return true
      }
}
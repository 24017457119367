import { Injectable } from '@angular/core';
import {
  APIError,
  APIErrorExtend,
  APIResponse,
} from '../models/type.definition';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  existingKey = ['email'];
  keyTrad = ['Adresse email'];

  constructor() {}

  getError(err: APIError) {
    switch (err.status) {
      case 400:
        return this.checkErrorByMessage(err) || this.badRequestError();
      case 401:
        return this.authError(err);
      case 404:
        return this.notFoundError(err);
      case 409:
        return this.conflictError(err);
      case 413:
        return this.sizeExceeded();
      case 415:
        return this.fileTypeError();
      case 500:
        return this.serverError();
      default:
        return this.defaultError();
    }
  }

  private checkErrorByMessage(err: APIError): void | string {
    let error: APIErrorExtend = err.error as APIErrorExtend;
    if (error.codeError) {
      switch (error.codeError) {
        case 1:
          return `Le quota est inférieur à la quantité (${(err.error as APIErrorExtend).data}) d'addon déjà vendu`;
        case 2:
          return `Le mot de passe est incorrect`
      }
    } else {
      switch ((err.error as { msg: string }).msg) {
        case "You can't delete because ticket are related to this formfield":
          return 'Impossible de supprimer ce champ car il est déjà enregistré sur certains tickets';
        case "You can't delete because ticketsType are related to this typeCategory":
          return 'Impossible de supprimer cette catégorie car au moins un ticket y est rattaché';
      }
    }
  }

  private badRequestError = () => 'Veuillez remplir tous les champs requis';

  private sizeExceeded = () => 'Taille maximale de fichier dépassée';

  private fileTypeError = () => 'Format non pris en charge';

  private serverError = () => 'Erreur Interne du Serveur';

  private authError = (err: APIError) => {
    const msg = (err.error as { msg: string }).msg;
    if (msg) {
      switch (msg) {
        case 'Incorrect account.':
          return 'Identifiants invalides';
      }
    } else {
      return 'Identifiants invalides';
    }
  };

  private defaultError = () => "Une erreur inconnue s'est produite";

  private notFoundError = (err: APIError) => {
    const msg = (err.error as { msg: string }).msg;
    if (msg) {
      switch (msg) {
        case 'This account is not recognized':
          return "Aucun compte n'existe avec cette adresse";
        case 'This code is incorrect':
          return 'Ce code est incorrect';
      }
    } else {
      return err.message;
    }
  };

  private conflictError(err: APIError) {
    const msg = (err.error as APIResponse).msg || err.message;
    if (msg.includes('is already registered')) {
      const key = msg.split(' is already registered')[0].split(' ')[1];
      for (let i = 0; i < this.existingKey.length; i++) {
        if (key === this.existingKey[i]) {
          return `${this.keyTrad[i]} indisponible`;
        }
      }
    } else if (msg.includes('already into this organisation')) {
      return 'Cet utilisateur est déjà membre de cette organisation';
    }
  }
}

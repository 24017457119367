import { Component} from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import User from 'src/app/models/user.model';
import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { UserService } from 'src/app/providers';
import { UpdateService } from 'src/app/services/update.service';
import { FormComponent } from 'src/app/elements/form-component';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent extends FormComponent<User> {
  protected dataName = "Profil";
  public loadingState$: Subject<void> = new Subject<void>();
  public mainForm: FormGroup;
  public data: User;
  public passwordError: boolean;
  public currentPasswordRequired: boolean;
  constructor(
    protected formProvider: UserService,
    private updateService: UpdateService,
  ) {
    super();
   }

  async onInit(): Promise<void> {
    this.data = await new Promise(res => this.subscribeTo(this.userService.me$, me => {
      if (me) {
        res(me)
      }
    }));
    this.getImage();
    this.loaded = true;
    this.passwordError = false;
    this.currentPasswordRequired = false;
    this.createImagesChangeState(['avatarId']);
  }

  private async getImage(): Promise<void> {
    const id = this.data.avatarId;
    if (id) {
      this.loadingImage.push("avatarId");
      this.data.avatar = await this.mediaService.getStream(id).toPromise();
      this.loadingImage.splice(this.loadingImage.indexOf("avatarId"));
    }
  }

  initForm(): void {

    this.mainForm = this.formBuilder.group({
      avatar: new FormControl(''), /** @todo Gestion des images */
      lastName: new FormControl(this.data.lastName, Validators.required),
      firstName: new FormControl(this.data.firstName, Validators.required),
      email: new FormControl(this.data.email, [Validators.required, Validators.email]),
      currentPassword: new FormControl(),
      newPassword: new FormControl(),
      newPassword2: new FormControl(),
      phone: new FormControl(this.data.phone),
    })

    this.mainForm.valueChanges.pipe(distinctUntilChanged()).subscribe(val => {
      if (val.email != this.data.email || val.newPassword || val.newPassword2) {
        this.mainForm.controls['currentPassword'].setValidators([Validators.required]);
        this.currentPasswordRequired = true
      }
      else {
        this.mainForm.controls['currentPassword'].clearValidators();
        this.currentPasswordRequired = false
      }
      if (val.newPassword || val.newPassword2) {
        this.mainForm.setValidators([this.checkPassword]);
      }
      this.mainForm.controls['currentPassword'].updateValueAndValidity({emitEvent : false});
    })
  }

  checkPassword: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    /** Au moins un (?=.) nombre (\d) peu importe la position */
    /** Au moins un caractère spécial (\W) peu importe la position */
    /** Au moins une lettre (\w) peu importe la position */
    let pwd1 = group.get('newPassword').value;
    let pwd2 = group.get('newPassword2').value
    const passwordOk = /(?=.*\d)(?=.*\W)(?=.*\w)[\d\W\w]{8}/.test(pwd1);
    const passwordsMatches = pwd1 === pwd2;
    return (passwordOk && passwordsMatches) ? null : { notSame: true }
  }

  async submitForm(): Promise<void> {
    this.getFileToCreate(this.mainForm.value.avatar, 'avatarId', this.data.avatarId);
    const formData = this.mainForm.value;
    delete formData.newPassword2

    if (!formData.newPassword) {
      delete formData.newPassword
      delete formData.password
    }
    else {
      formData.password = formData.newPassword
      delete formData.newPassword
    }
    if (this.filesToCreate[0]) {
      await this.createOrDeleteMedia(formData, 0, "userId");
      formData.avatarId = this.filesToCreate[0].file ? this.filesToCreate[0]._id : null;
    }

    this.userService.updateMe(formData).subscribe( () => {
      this.loadingState$.next();
      delete formData.avatar;
      if (!formData.avatarId) {
        delete this.data.avatar;
      }
      this.data = this.updateService.object(this.data, formData);
      this.filesToCreate = [];
      this.createImagesChangeState(['avatarId']);
      this.userService.me$.next(this.data);
      this.notificationService.newNotification({
        message: 'Profil mis à jour avec succès',
        state: 'success'
      })
    },
      (err: any) => {
        this.error = this.errorHandler.getError(err)
        this.notificationService.newNotification({ message: this.error, state: 'error' });
        this.loadingState$.next();
      }
    )
  }

  goBack() {
    this.router.navigate(['tableau-de-bord']);
  }


  public changeImage(key: string, keyId: string, file: File): void {
    const image = this.data.avatarId;
    if (this.imageChanged[keyId].load || !image) {
      this.imageChanged[keyId].changed = true;
    }
    else {
      this.imageChanged[keyId].load = true;
    }
    this.setValue(key, file);
  }

}

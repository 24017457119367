import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { RouteType } from 'src/app/models/type.definition';
import { Subscription } from 'rxjs';
import { EventService, OrganisationService } from 'src/app/providers';
import { Router } from '@angular/router';
import { RoutesService } from 'src/app/services/routes.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input() isAdmin: boolean;
  private _subscription = new Subscription();
  public mainRoutes: RouteType[];
  public eventRoutes: RouteType[];
  public selectedEvent: any;
  public allEvents: any[];
  public displaySessions: boolean;
  public displaySeatingPlan: boolean;
  public displaySecurity: boolean;
  public displayProduct: boolean;
  public adminInOrganization = false;

  constructor(
    private eventService: EventService,
    private routesService: RoutesService,
    private organisationService: OrganisationService,
    private storageService: StorageService,
    private router: Router,
  ) { }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  async ngOnInit() {
    this.mainRoutes = this.routesService.mainRoutes;
    this.eventRoutes = this.routesService.eventRoutes;
    this._subscription.add(this.eventService.selected$.subscribe(data => {
      this.selectedEvent = data;
      this.displaySessions = this.selectedEvent?.dates?.type === 'sessions';

      // // Check if display seating plan menu
      if (this.selectedEvent) {
        if (this.selectedEvent.bookType === 'nft') {
          this.displaySecurity = true;
          this.displayProduct = false;
        } else {
          this.displaySecurity = false;
          this.displayProduct = true;
        }
        if (this.selectedEvent.useRoomPlacement) {
          if (!this.displaySessions) {
            this.displaySeatingPlan = true;
          } else {
            this.displaySeatingPlan = false;
          }
        } else {
          this.displaySeatingPlan = false;
        }
      }
    }));

    this._subscription.add(this.organisationService.selected$.subscribe(() => {
      this._subscription.add(this.eventService.getList({ select: ['name', 'dates'], perPage: 500 }).subscribe(result => {
        this.allEvents = result?.data
      }));
    }));
    this._subscription.add(this.organisationService.adminConnected$.subscribe(value => {
      this.adminInOrganization = value;
    }));
  }

  changeEvent(id: string) {
    const event = this.allEvents.filter(_ => _.id === id)[0];
    this.eventService.selectEvent(event);
    this.router.navigate([`evenements/${id}/general`]);
  }

  clickOnRoute(route: string, parent: string = null): string {
    return parent ? `${parent}/${route}` : route;
  }

  goBack() {
    this.router.navigate([this.isAdmin && !this.adminInOrganization ? 'admin' : 'evenements'])
  }

  goBackToAdminDashboard() {
    this.storageService.removeItem('adminInOrganization');
    this.storageService.removeItem('organisationId');
    this.organisationService.adminConnected$.next(false);
    this.adminInOrganization = false;
    this.router.navigateByUrl('organizations');
  }
}

import { Component } from '@angular/core';
import { moreAnimation } from 'src/app/animations/animations';
import { ListFromEventComponent } from 'src/app/elements/list-component';
import { IForm } from 'src/app/models/form.model';
import { ITicketType } from 'src/app/models/ticket.model';
import { TableOptions } from 'src/app/models/type.definition';
import { FormFieldService, FormService, TicketTypeService } from 'src/app/providers';
import { GetEvent } from '../getEvent';

@Component({
  selector: 'app-event-forms',
  templateUrl: './event-forms.component.html',
  styleUrls: ['./event-forms.component.scss'],
  providers: [GetEvent],
  animations: [
    moreAnimation
  ]
})
export class EventFormsComponent extends ListFromEventComponent<IForm> {
  private _ticketTypes: ITicketType[];
  protected dataName = 'Formulaire';

  public tableOptions: TableOptions[] = [
    {
      title: "Nom",
      class: "details",
      subClass: "name",
      subClass2: "amount",
      subClass3: "category",
      sort: true,
      key: 'name',
      subKey: 'amount',
      subKey2: 'category',
      sortMethod: (order) => this.sort("name", order),
    },
    {
      title: "Questions",
      isArray: true,
      class: "questions columns",
      sort: false,
      key: 'questions',
    },
    this.DefaultToggle
  ];

  constructor(protected provider: FormService,
    protected formFieldService: FormFieldService,
    protected ticketTypesService: TicketTypeService,
    protected getEvent: GetEvent) {
    super();
  }

  public async beforeInit(): Promise<void> {
    this._ticketTypes = await this.getListOfDataOfEvent(this.ticketTypesService);
  }

  protected setGetParams(): void {
    //this.getParams.select = ['name', 'ticketTypes'];
  }

  protected async formatElement(element: IForm): Promise<IForm> {
    const fields = await this.formFieldService.getList({ filter: { formId: element._id } }).toPromise();
    element.fields = fields?.data || [];
    element.questions = element.fields.map(_ => _.label);
    element.amount = `${element.questions.length} question(s)`;
    const categories = this._ticketTypes.filter(_ => element.ticketTypes.ids.includes(_._id)).map(_ => _.name);
    element.category = categories.length ? categories.join(', ') : 'Tous';
    return element;
  }

}

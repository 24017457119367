import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IEvent } from 'src/app/models/event.model';
import { GetEvent } from '../../getEvent';
import { GetParams, TableOptions } from 'src/app/models/type.definition';

@Component({
  selector: 'app-event-access-control-operator',
  templateUrl: './event-access-control-operator.component.html',
  styleUrls: ['./event-access-control-operator.component.scss'],
  providers: [GetEvent]
})
export class EventAccessControlOperatorComponent implements OnInit {
  public currentUrl: string;
  public event: IEvent;
  public tableOptions: any[] = [{
    title: "Nom",
    class: "last_name",
    sort: true,
    key: 'name',
    //sortMethod: order => this.sort('name', order),
  },
  {
    title: "Prénom",
    class: "first_name",
    sort: true,
    key: 'firstName',
    //sortMethod: order => this.sort('participants', order),
  },
  {
    title: "Téléphone",
    class: "phone",
    subClass: "doselect",
    key: 'phone',
  },
  {
    title: "E-mail",
    class: "email",
    subClass: "doselect",
    key: 'email',
  },
  {
    title: "Contrôles d'accès",
    class: "controls",
    key: 'controls',
  },
  {
    class: "more",
    toggle: [
      {
        title: 'Éditer',
        //method: this.editSession
      },
      {
        title: 'Archiver',
        //method: this.editSession
      },
      {
        title: 'Supprimer',
        //method: this.deleteSession,
        class: 'red'
      }
    ]
  }];
  public operators = [
    {
      name: 'STEPHAN',
      firstName: 'Augustin',
      phone: '06.70.15.35.28',
      email: 'augustin@advency.fr',
      controls: '2 contrôles d’accès',
    },
    {
      name: 'STEPHAN',
      firstName: 'Augustin',
      phone: '06.70.15.35.28',
      email: 'augustin@advency.fr',
      controls: '2 contrôles d’accès',
    },
    {
      name: 'STEPHAN',
      firstName: 'Augustin',
      phone: '06.70.15.35.28',
      email: 'augustin@advency.fr',
      controls: '2 contrôles d’accès',
    },
    {
      name: 'STEPHAN',
      firstName: 'Augustin',
      phone: '06.70.15.35.28',
      email: 'augustin@advency.fr',
      controls: '2 contrôles d’accès',
    },
    {
      name: 'STEPHAN',
      firstName: 'Augustin',
      phone: '06.70.15.35.28',
      email: 'augustin@advency.fr',
      controls: '2 contrôles d’accès',
    },
    {
      name: 'STEPHAN',
      firstName: 'Augustin',
      phone: '06.70.15.35.28',
      email: 'augustin@advency.fr',
      controls: '2 contrôles d’accès',
    },
  ];

  constructor(
    private router: Router,
    private getEvent: GetEvent
  ) { }

  async ngOnInit(): Promise<void> {
    this.currentUrl = this.router.url;
    this.event = await this.getEvent.get();
  }

  public createOperator(): void {
    this.router.navigate([`${this.currentUrl}/creer`]);
  }

}

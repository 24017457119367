<section id="event_general" class="container loading_container" [ngClass]="{ 'loaded' : displayPage }">
	<span class="loader_global"></span>
	<div *ngIf="displayPage" class="loading_content">
		<div id="page_header">
			<div id="page_header_left">
				<h1>
					{{event?.name}}
					<span class="status {{event?.status}}">{{event?.translateStatus}}</span>
				</h1>
				<span *ngIf="event?.dates.startDate" class="date">
					{{event?.dates.startDate | date:'dd MMMM yyyy'}}
				</span>
			</div>
			<div *ngIf="event?.status == 'draft' && eventPublishableStatus?.publishable" id="page_header_right">
				<app-button (clickEvent)="prePublish()" [loadingOff]="loadingState$" class="button" text="Publier"
					[style]="'plain'"></app-button>
			</div>
		</div>
		<div *ngIf="event?.status == 'draft'  && eventPublishableStatus?.publishable" class="event_message">
			<i>
				<svg class="down" role="img" title="check" class="check-icon">
					<use href="assets/images/sprite.svg#icon-check"></use>
				</svg>
			</i>
			Votre événement est fin prêt à démarrer. Appuyez sur Publier et invitez les personnes à participer à votre
			événement !
		</div>
		<div *ngIf="eventPublishableStatus && !eventPublishableStatus.allConfigured" id="event_customize">
			<div class="event_message" *ngIf="!eventPublishableStatus.publishable">
				<i>
					<svg class="down" role="img" title="check" class="check-icon">
						<use href="assets/images/sprite.svg#icon-check"></use>
					</svg>
				</i>
				Votre événement {{event?.name}} a été créé. Suivez les 4 étapes pour le personnaliser !
			</div>
			<ul>
				<li *ngIf="!eventPublishableStatus.rateCreated">
					<span class="title">Créer des tarifs</span>
					<p>Créez différents types de tarifs, gratuit ou payant, et gérez votre billetterie.</p>
					<app-button class="button" text="Créer des tarifs" (clickEvent)="goTo('billetterie/ticket')"
						[style]="'border blue'"></app-button>
				</li>
				<li *ngIf="!eventPublishableStatus.ticketCustomized">
					<span class="title">Personnaliser le e-billet</span>
					<p>Personnalisez les billets que les participants recevront.</p>
					<app-button class="button" text="Personnaliser" [style]="'border blue'"
						(clickEvent)="goTo('billetterie/e-tickets')"></app-button>
				</li>
				<li *ngIf="!eventPublishableStatus.quotaDefined">
					<span class="title">Définir le stock</span>
					<p>Définissez le stock de places disponible pour votre événement!</p>
					<app-button class="button" text="Créer" [style]="'border blue'"
						(clickEvent)="goTo('billetterie/parametres')">
					</app-button>
				</li>
				<li *ngIf="!eventPublishableStatus.eventCustomized">
					<span class="title">Customiser</span>
					<p>Customisez votre page événement pour faire ressortir l’identité de votre événement !</p>
					<app-button class="button" text="Customiser" [style]="'border blue'"
						(clickEvent)="goTo('mini-sites')">
					</app-button>
				</li>
			</ul>
		</div>
		<ul id="event_numbers">
			<li>
				<svg class="down" role="img" title="money" class="money-icon">
					<use href="assets/images/sprite.svg#icon-money"></use>
				</svg>
				<span class="label">Total recette (net)</span>
				<span class="value">{{(event.statistics?.costPrice || 0) / 100 | currency: "EUR"}}</span>
			</li>
			<li>
				<svg class="down" role="img" title="money" class="money-icon">
					<use href="assets/images/sprite.svg#icon-money"></use>
				</svg>
				<span class="label">Total recette (brut)</span>
				<span class="value">{{(event.statistics?.sellingPrice || 0) / 100 | currency: "EUR"}}</span>
			</li>
			<li>
				<svg class="down" role="img" title="ticket" class="ticket-icon">
					<use href="assets/images/sprite.svg#icon-ticket"></use>
				</svg>
				<span class="label">Total commandes</span>
				<span class="value">{{event.statistics?.orders}}</span>
			</li>
			<li>
				<svg class="down" role="img" title="user" class="user-icon">
					<use href="assets/images/sprite.svg#icon-user"></use>
				</svg>
				<span class="label">Total participants</span>
				<span class="value">{{event.statistics?.participants}}
					<span *ngIf="event.statistics?.invitations" class="tiny_info"> + {{event.statistics?.invitations}} invitation(s)</span>
				</span>
			</li>
		</ul>
		<div id="event_widgets">
			<div class="graph_header">
			<span class="graph_title">
				<svg class="down" role="img" title="money" class="money-icon">
					<use href="assets/images/sprite.svg#icon-money"></use>
				</svg>
				Répartition des ventes par widget
			</span>
			<app-date-picker (dateSelection)="setSelectedDate($event)"
							 [firstDate]="firstDate" [firstSelection]="dateSelected"></app-date-picker>
			</div>

			<div class="inner table-container">
				<table class="ob-table">
					<thead class="ob-table__head">
						<tr class="ob-table__row">
							<th class="ob-table__cell cell__name">Widget</th>
							<th class="ob-table__cell">Commandes</th>
							<th class="ob-table__cell">Participants</th>
							<th class="ob-table__cell">Recette (net)</th>
							<th class="ob-table__cell">Recette (brut)</th>
						</tr>
					</thead>
					<tbody *ngIf="stats && !loadingWidgets" class="ob-table__body">
						<tr class="ob-table__row" *ngFor="let widget of stats.tableWidgets; let i = index">
							<td class="ob-table__cell cell__name">{{ widget.name }}</td>
							<td class="ob-table__cell">{{ widget.amountOrders }}</td>
							<td class="ob-table__cell">{{ widget.amountTickets }}</td>
							<td class="ob-table__cell">{{ widget.totalCostPrice | currency: "EUR" }} </td>
							<td class="ob-table__cell">{{ widget.totalSellingPrice | currency: "EUR" }}</td>
						</tr>
					</tbody>
				</table>
				<div *ngIf="!stats || loadingWidgets" class="chart_loader"></div>
			</div>
		</div>
		<div id="event_stats">
			<div id="event_stats_left">
				<div id="event_income">
					<div class="graph_header">
						<span class="graph_title">
							<svg class="down" role="img" title="money" class="money-icon">
								<use href="assets/images/sprite.svg#icon-money"></use>
							</svg>
							Recettes nettes
						</span>

					</div>
					<div class="inner">
						<canvas id="income_chart"></canvas>
						<div *ngIf="!lineIncomeChart" class="chart_loader"></div>
					</div>
				</div>
				<div id="event_orders">
					<div class="graph_header">
						<span class="graph_title">
							<svg class="down" role="img" title="ticket" class="ticket-icon">
								<use href="assets/images/sprite.svg#icon-ticket"></use>
							</svg>
							Commandes
						</span>
					</div>
					<div class="inner">
						<canvas id="orders_chart"></canvas>
						<div *ngIf="!lineOrdersChart" class="chart_loader"></div>
					</div>
				</div>

			</div>
			<div id="event_stats_right">
				<div id="event_distribution">
					<span class="graph_title">
						<svg class="down" role="img" title="ticket" class="ticket-icon">
							<use href="assets/images/sprite.svg#icon-ticket"></use>
						</svg>
						Répartition des recettes / ticket
					</span>
					<div class="inner">
						<canvas id="pie_chart" *ngIf="!stats || stats.distribution.length"></canvas>
						<ul id="pie_legend" *ngIf="stats && stats.distribution.length">
							<ng-container *ngFor="let ticket of stats.distribution; let i = index">
								<h3 class="category_label"
									*ngIf="!i || ticket.ticketTypeCategory !== stats.distribution[i - 1].ticketTypeCategory">
									{{ticket.ticketTypeCategory || 'Sans catégorie'}}
								</h3>
								<li>
									<span class="color"
										[style]="{'background': backgroundColors[i%backgroundColors.length]}"></span>
									<span class="label">{{ticket.name}}</span>
								</li>
							</ng-container>
						</ul>
						<div class="no_ticket" *ngIf="stats && !stats.distribution.length">
							<ng-container *ngIf="!hasTicket">
								<span class="label">Il n’y aucun ticket disponible. Vous avez besoin d’aide ?</span>
								<app-button class="button" text="Créer les tarifs" [style]="'border blue'"
									(clickEvent)="goTo('billetterie/ticket')"></app-button>
							</ng-container>
							<ng-container *ngIf="hasTicket">
								<span class="label">Aucun ticket n'a été vendu pour le moment</span>
							</ng-container>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</section>

<app-popup *ngIf="displayPublishPopup" title="Publication de votre événement" message="Vous allez publier un événement de type NFT. Cet événement sera déployé dans une blockchain. Suite à cette publication, le nom de l'événement ne pourra plus être modifié et les types de tickets qui ont déjà été vendus ne pourront plus être supprimés." popupOpened="true" buttonText="Publier" [data]="publish" (backEvent)="cancelPublish()" (clickEvent)="publish(_event)"></app-popup>

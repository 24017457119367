<section id="form_page" class="container loading_container" [ngClass]="{ 'loaded' : mainForm }">
    <div class="loading_content" *ngIf="data">
        <div id="page_header">
            <div id="page_header_left">
                <h1>E-ticket</h1>
            </div>
        </div>
        <div id="form_wrapper" class="section_split" *ngIf="data.bookType == 'nft'">
            <form *ngIf="mainForm" [formGroup]="mainForm" class="split_half">
                <h3 class="smaller_margin">Personnalisez votre ticket: {{ticketType.name}}</h3>
                <p class="description_medium form_item">
                    Personnalisez ce ticket en lui ajoutant une image ou une vidéo et un texte descriptif qui lui sera propre.
                </p>
                <!-- <p *ngIf="isDefaultCustom" class="description_medium form_item">
                    L'apparence que vous visualisez actuellement est celle par défaut de tous les tickets. En modifiant celle ci, vous ne modifierez que l'apparence de ce type de ticket. Pour modifier l'apparence par défaut de tous les tickets, rendez vous dans l'onglet E-tickets.
                </p> -->
                <div class="form_item">
                    <app-file-input *ngIf="loaded"
                        disabledText="Visuel non modifiable car votre événement est en ligne"
                        [loading]="loadingImage.includes('imageId')" class="form_item"
                        [imageWidth]="720"
                        [cropperStaticWidth]="720"
                        [cropperStaticHeight]="640"
                        text="Ajouter une image / vidéo à votre ticket"
                        [addFormat]="['mp4']"
                        [limitSizeMo]="6"
                        [preFile]="data && !loadingImage.includes('imageId') && ticketType.ticketVisual.image"
                        (imageChange)="changeImage($event)" [maintainAspectRatio]="false" [cropperStaticHeight]="640" [cropperStaticWidth]="720">
                    </app-file-input>
                </div>
                <div (keydown.enter)="$event.stopPropagation()" class="form_item">
                    <label for="text">Description personnalisée</label>
                    <app-wysiwyg [previousValue]="previousText" (changeText)="setDescriptionValue($event)"></app-wysiwyg>
                </div>
                <div class="form_actions right">
                    <app-button (clickEvent)="cancel()" text="Annuler" [style]="'border blue'" [loadingOff]="loadingState$">
                    </app-button>
                    <app-button [loadingOff]="loadingState$" [style]="'plain'" type="submit" [disabled]="mainForm.invalid"
                        (clickEvent)="submitForm()" text="Valider">
                    </app-button>
                    <app-popup *ngIf="error != null" title="Une erreur est survenue" message="{{error}}" popupOpened="true"
                        (backEvent)="error = null"></app-popup>
                </div>
            </form>
            <div class="split_half">
                <h3>Prévisualisation du ticket</h3>
                <app-ticket-preview id="unique" *ngIf="dataPreview" [event]="dataPreview" [ticketType]="ticketType"></app-ticket-preview>
            </div>

        </div>
    </div>
</section>
<section id="form_page" class="container loading_container" [ngClass]="{ 'loaded' : mainForm }">
    <div class="loading_content">
        <div id="page_header">
            <div id="page_header_left">
                <h1>Badge</h1>
            </div>
        </div>

        <form *ngIf="mainForm" [formGroup]="mainForm">
            <div id="filters_wrap">
                <h3 class="title">Type de ticket</h3>
                <p class="description_medium form_item">
                    Sélectionnez le type de ticket auquel s'appliquera ce visuel de badge.
                </p>
                <div *ngIf="ticketTypes.length" class="input_wrap select">
                    <select formControlName="ticketTypeId" id="filter_status" (change)="changeTicketTypeId($event.target.value)">
                        <option value="" [attr.selected]="selectedTicketTypeId == null ? 'true' : null">
                            - Par défaut -
                        </option>
                        <optgroup label="Tarifs">
                            <option *ngFor="let ticketType of ticketTypes" [value]="ticketType.id" [attr.selected]="selectedTicketTypeId === ticketType.id ? 'true' : null">{{ getTicketTypeLabel(ticketType) }}</option>
                        </optgroup>
                    </select>
                    <svg role="img" title="chevron" class="icon-chevron">
                        <use href="assets/images/sprite.svg#icon-chevron"></use>
                    </svg>
                </div>
            </div>

            <div id="form_wrapper" class="section_split">
                <div class="split_half">
                    <h3 class="smaller_margin">Personnalisez votre badge</h3>

                    <div *ngIf="selectedTicketTypeId" class="form_item full smallest_margin">
                        <input id="override" type="checkbox" class="toggle" formControlName="overriden">
                        <label for="override">
                        Surcharger les informations par défaut
                        <i class="check">
                            <svg role="img" title="check" class="check-icon">
                            <use href="assets/images/sprite.svg#icon-check"></use>
                            </svg>
                        </i>
                        </label>
                    </div>

                    <div *ngIf="!selectedTicketTypeId || (selectedTicketTypeId && mainForm.get('overriden').value)">

                        <p class="description_medium form_item" *ngIf="data.bookType !== 'nft'">
                            Personnalisez ce badge en lui ajoutant une image et un texte descriptif qui lui sera propre.
                        </p>
                        <p class="description_medium form_item" *ngIf="data.bookType == 'nft'">
                            Personnalisez ce badge en lui ajoutant une image ou une vidéo et un texte descriptif qui lui sera propre.
                        </p>
                        <div class="form_item">
                            <p class="error" *ngIf="errorLoadingImage">
                                Un problème est survenu lors du chargement de votre image. Veuillez en ajouter une à nouveau.
                            </p>
                            <app-file-input *ngIf="loaded && data.bookType !== 'nft'"
                                disabledText="Visuel non modifiable car votre événement est en ligne"
                                [loading]="loadingImage.includes('imageId')" class="form_item"
                                [imageWidth]="250"
                                [text]="textAddMedia"
                                [preFile]="data && !loadingImage.includes('imageId') && currentImage"
                                (imageChange)="changeImage($event)" [maintainAspectRatio]="false" [cropperStaticHeight]="125" [cropperStaticWidth]="250">
                            </app-file-input>
                            <app-file-input *ngIf="loaded && data.bookType === 'nft'"
                                disabledText="Visuel non modifiable car votre événement est en ligne"
                                [loading]="loadingImage.includes('imageId')" class="form_item"
                                [imageWidth]="720"
                                [cropperStaticWidth]="720"
                                [cropperStaticHeight]="640"
                                [addFormat]="['mp4']"
                                [text]="textAddMedia"
                                [preFile]="data && !loadingImage.includes('imageId') && currentImage"
                                (imageChange)="changeImage($event)" [maintainAspectRatio]="false" [cropperStaticHeight]="640" [cropperStaticWidth]="720">
                            </app-file-input>

                        </div>

                        <div class="form_item" *ngIf="customFormFields && customFormFields.length">
                            <label for="category">Champ de formulaire participant</label>
                            <div class="input_wrap select">
                                <select (change)="selectFormField($event.target.value)" id="category" formControlName="formFieldId">
                                    <option [attr.selected]="!currentFormField" [value]="null">- Sélectionner un champ -</option>
                                    <option *ngFor="let formField of customFormFields" value="{{formField._id}}" [attr.selected]="currentFormField == formField._id ? true : null">
                                        {{ formField.form_name }} - {{ formField.label }}
                                    </option>
                                </select>
                                <svg role="img" title="chevron" class="icon-chevron">
                                    <use href="assets/images/sprite.svg#icon-chevron"></use>
                                </svg>
                            </div>
                            <span class="description">
                                Le champ doit faire parti d'un formulaire accessible à tous les tarifs.
                            </span>
                        </div>

                        <div class="form_item">
                            <label for="footer">Footer</label>
                            <input id="footer" (input)="setFooterTextValue($event.target.value)" type="text" placeholder="Participant.e" formControlName="footerText">
                        </div>

                        <div (keydown.enter)="$event.stopPropagation()" class="form_item">
                            <label for="text">Description personnalisée</label>
                            <app-wysiwyg [previousValue]="previousText" (changeText)="setDescriptionValue($event)"></app-wysiwyg>
                        </div>

                    </div>

                    <div class="form_actions right">
                        <app-button (clickEvent)="cancel()" text="Annuler" [style]="'border blue'" [loadingOff]="loadingState$">
                        </app-button>
                        <app-button [loadingOff]="loadingState$" [style]="'plain'" type="submit" [disabled]="mainForm.invalid"
                            (clickEvent)="submitForm()" text="Valider">
                        </app-button>
                        <app-popup *ngIf="error != null" title="Une erreur est survenue" message="{{error}}" popupOpened="true"
                            (backEvent)="error = null"></app-popup>
                    </div>
                </div>
                <div class="split_half">
                    <h3>Prévisualisation du badge</h3>
                    <app-badge-preview *ngIf="dataPreview" [event]="dataPreview"></app-badge-preview>
                </div>

            </div>
        </form>

    </div>
</section>
<div class="loading_container" [ngClass]="{ 'loaded' : data }">
    <div class="loading_content">
        <div id="page_header" class="container">
            <div id="page_header_left">
                <h1>Invitations</h1>
            </div>
            <div *ngIf="event.status !== 'draft'" id="page_header_right">
                <app-button (click)="createInvitation()" class="button" text="Créer une invitation" [style]="'plain'">
                </app-button>
            </div>
        </div>
        <section>
            <app-main-table *ngIf="data?.length" idSection="participants_content" idTable="table_participants"
                tableType="participants" [columns]="tableOptions" [datum]="data" [ref]="this"></app-main-table>
            <app-pager *ngIf="totalPages" [totalPages]="totalPages" [page]="page" (changePage)="changePage($event)">
            </app-pager>
        </section>

        <section id="block_first" *ngIf="data && !data.length" class="container">
            <ng-container *ngIf="event.status !== 'draft'; else draft">
                <p class="description grey">Vous n’avez pas encore créé d’invitation pour cet événement. Souhaitez-vous
                    en transmettre ?</p>
                <figure>
                    <img src="assets/images/visuel_invitation.svg" />
                </figure>
                <app-button (click)="createInvitation()" class="button_create no_margin" text="Créer une invitation"
                    [style]="'plain full'"></app-button>
            </ng-container>
            <ng-template #draft>
                <p class="description grey">Vous devez publier votre événement avant de pouvoir créer des invitations.
                </p>
                <figure>
                    <img src="assets/images/visuel_invitation.svg" />
                </figure>
            </ng-template>
        </section>

        <app-delete-popup *ngIf="ticketToDelete"
            [customText]="'Désirez-vous supprimer ' + getParticipantName(ticketToDelete) + ' des participants de l\événement ?'"
            type='participant' (clickEvent)="deleteOk()" (close)="ticketToDelete = null"></app-delete-popup>
    </div>
</div>
<div class="full_loader" *ngIf="fullLoader">
	<div></div>
</div>
import { HttpClient } from '@angular/common/http';
import { QueryParams } from '../etc/queryParams';
import { environment } from 'src/environments/environment';
import { injector } from '../etc/injector';
import { GetParams } from '../models/type.definition';

export abstract class Requester<T = {}> {
  protected abstract url: string;
  protected httpClient: HttpClient;
  protected queryTranslator: QueryParams<T>;

  constructor() {
    this.httpClient = injector.get(HttpClient);
    this.queryTranslator = injector.get(QueryParams);
  }

  protected getUrl(end?: string, body?: GetParams<T>): string {
    return `${environment.apiUrl}/v1/${this.url}${end ? `/${end}` : ''}${body ? this.queryTranslator.translate(body) : ''}`;
  }
}

import { Component } from '@angular/core';
import { FormFromEventComponent } from 'src/app/elements/form-component';
import { GetEvent } from '../../../getEvent';
import { EventSessionService, TaxService, TicketTypeService } from 'src/app/providers';
import { ISession } from 'src/app/models/session.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ITax } from 'src/app/models/tax.model';
import { ITicketType } from 'src/app/models/ticket.model';

@Component({
  selector: 'app-event-ticketing-session-create',
  templateUrl: './event-ticketing-session-create.component.html',
  styleUrls: ['./event-ticketing-session-create.component.scss'],
  providers: [GetEvent]
})
export class EventTicketingSessionCreateComponent extends FormFromEventComponent<ISession> {
  protected dataName = 'Session';
  protected taxes: ITax[];
  public ticketTypes: ITicketType[];
  public checkedTicketTypes: string[] = [];
  public dropDown: boolean = false;
  public ticketTypeApplied: boolean;

  constructor(protected formProvider: EventSessionService,
    protected getEvent: GetEvent,
    protected taxService: TaxService,
    protected ticketTypeService: TicketTypeService) {
    super();
  }

  async onInit(): Promise<void> {
    await this.getDataToEdit('sessionId', { template: 'full' });

    this.taxes = await this.getListOf(this.taxService, 'data');

    this.ticketTypes = await this.getListFromEventOf(this.ticketTypeService, {
      select: ['name', 'ticketTypeCategoryId'],
      populate: ['ticketTypeCategoryId'],
      perPage: 500
    });
    if (this.data) {
      this.ticketTypeApplied = this.data.isTicketTypeRestricted;
      this.checkedTicketTypes = this.data.ticketTypeIds.map(_ => _._id);
    }
  }

  protected initForm(): void {
    const quota = (this.data?.stocks.quota || this.data?.stocks.quota === 0) ? this.data.stocks.quota : null;
    this.mainForm = this.formBuilder.group({
      date: new FormControl(this.data?.date, Validators.required),
      stocks: new FormGroup({
        quota: new FormControl(quota),
      }),
      isTicketTypeRestricted: new FormControl(this.data?.isTicketTypeRestricted || false),
      ticketTypeIds: new FormControl(this.data?.ticketTypeIds.map(_ => _._id) || []),
      taxId: new FormControl(this.data?.taxId._id, Validators.required)
    });
  }

  public submitForm(): void {
    const formData = {
      ...this.mainForm.value,
      name: 'Session',
      eventId: this.event._id
    }
    formData.ticketTypeIds = this.checkedTicketTypes;
    this.createOrUpdate(formData, () => this.goBack());
  }

  public checkTicket(id: string, value: boolean): void {
    const inArray = this.checkedTicketTypes.includes(id);
    if (value && !inArray) {
      this.checkedTicketTypes.push(id);
    }
    else if (!value && inArray) {
      this.checkedTicketTypes = this.checkedTicketTypes.filter(_ => _ !== id);
    }
  }

  public ticketChecked(id: string): boolean {
    return this.checkedTicketTypes.includes(id) || null;
  }

  public ticketRestricted(): boolean {
    return this.mainForm.value.ticketTypes.restrict;
  }

  public getTicketsName(): string {
    const names = this.ticketTypes.filter(_ => this.checkedTicketTypes.includes(_._id));
    if (!names.length) {
      return 'Tous';
    }
    else {
      const fullString = names.map(_ => _.name).join(', ');
      return fullString.length < 80 ? fullString : `${fullString.substring(0, 77)} ...`
    }
  }

}

<div class="popup-overlay" (click)="back()">
	<div class="popup" [@popupAnimation] (click)="$event.stopPropagation()">
		<button class="button_close" (click)="back()">
			<svg role="img" title="close" class="close-icon">
				<use href="assets/images/sprite.svg#icon-close"></use>
			</svg>
		</button>
		<div class="popup-content">
			<h2>{{title}}</h2>
			<p *ngIf="message" class="message">{{message}}</p>
			<div *ngIf="specialContent" [class]="specialContentClass">{{specialContent}}</div>
			<form class="panel_form" *ngIf="input || inputs || selects">
				<div *ngIf="input" class="form_fields form_cols">
					<div class="form_item quarter">
						<input [type]="input" (keyup)="inputValue = $event.target.value">
					</div>
				</div>
				<ng-container *ngIf="inputs">
					<div *ngFor="let group of inputs; let i = index">
						<h3 *ngIf="formCat[i]">{{formCat[i]}}</h3>
						<div class="form_fields form_cols center">
							<div *ngFor="let currentInput of group; let j = index"
								class="form_item {{group.length == 1 ? 'half' : 'quarter'}}"
								[ngClass]="{'has_suffix' : suffixes && suffixes[i][j]}">
								<label for="{{'input_' + j}}">{{labels[i][j]}}</label>
								<input [disabled]="inputDisabled[i][j]" id="{{'input_' + j}}" [type]="currentInput" [value]="inputValues[i][j]"
									(keyup)="inputValues[i][j] = $event.target.value">
								<span *ngIf="suffixes && suffixes[i][j]" class="suffix">{{suffixes[i][j]}}</span>
							</div>
						</div>
					</div>
				</ng-container>
				<ng-container *ngIf="selects">
					<div *ngFor="let select of selects; let i = index">
						<div class="form_fields form_cols center">
							<!-- Todo : Automatiser le label si plus select et intégrer -->
							<!-- <label for="{{'select_' + i}}">{{select[i].label}}</label> -->
							<select style="margin-bottom: 50px; width: 50%;" (change)="inputValue = $event.target.value">
								<option value="null">Sélectionner un workspace</option>
								<option *ngFor="let option of select[i].options" value="{{ option.id }}">{{ option.name}}</option>
							</select>
						</div>
					</div>
				</ng-container>
			</form>
			<app-button [disabled]="input && !inputValue" [text]="buttonText" (clickEvent)="onClick()"
				[style]="'plain'">
			</app-button>
		</div>
	</div>
</div>
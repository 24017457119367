import { Component, OnInit } from '@angular/core';
import { OrganisationService, CurrencyService } from 'src/app/providers';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { GrowthService } from 'src/app/services/growth.service';
import { Subject, Subscription } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-ribiban-settings',
  templateUrl: './ribiban-settings.component.html',
  styleUrls: ['./ribiban-settings.component.scss']
})
export class RibibanSettingsComponent implements OnInit {
  private _subscription = new Subscription();
  private _bankAccount: any;
  public loadingState$: Subject<void> = new Subject<void>();
  public currencies: any;
  public organisation: any;
  public orgaForm: FormGroup
  public countries: any;
  public dataLoading = { value: false }

  constructor(
    private formBuilder: FormBuilder,
    private organisationService: OrganisationService,
    private growthService: GrowthService,
    private currencyService: CurrencyService,
    private notificationService: NotificationService) { }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ngOnInit(): void {
    this._subscription.add(this.organisationService.selected$.subscribe(data => {
      this.handleData(data);
    }));
    this._subscription.add(this.currencyService.getList().subscribe( (result: any) => {
      this.currencies = result?.data
    }))
  }

  async handleData(data: any) {
    if (data) {
      if (!this.dataLoading.value) {
        this.dataLoading.value = true;
        const keys = ['bankAccount']
        const nextStep = await this.growthService.checkCurrent(data, keys, this.dataLoading, this.organisationService, 'selected$')
        if (nextStep) {
          this.organisation = data;
          this._bankAccount = data.bankAccount;
          this.initForm();
        }
      }
    }
  }

  initForm(): void {
    this.orgaForm = this.formBuilder.group({
      domiciliation: new FormControl(this._bankAccount.domiciliation || null, Validators.required),
      IBAN: new FormControl(this._bankAccount.IBAN || null, Validators.required),
      BICSWIFT: new FormControl(this._bankAccount.BICSWIFT || null, Validators.required),
      owner: new FormControl(this._bankAccount.owner || null, Validators.required),
      currencyId: new FormControl(this._bankAccount.currencyId || null)
    })
  }

  updateOrganisation(): void {
    const formData = {
      bankAccount: this.orgaForm.value
    }
    this.organisationService.update(this.organisation.id, formData).subscribe(res => {
      this.loadingState$.next();
      this.updateObject(formData)
      this.notificationService.newNotification({
        message: 'Coordonnées bancaires mises à jour avec succès',
        state: 'success'
      })
    })
  }

  updateObject(formData) {
    for (const key in formData) {
      this.organisation[key] = formData[key];
    }
    this.organisationService.selected$.next(this.organisation)
  }

}

import {
  Component,
  EventEmitter,
  Output,
  ViewChild,
  Input,
} from '@angular/core';
// import places from "places.js";

@Component({
  selector: 'app-places',
  templateUrl: './places.component.html',
  styleUrls: ['./places.component.scss'],
})
export class PlacesComponent {
  @Output() onChange? = new EventEmitter();
  @ViewChild('input') input;
  @Input() type: string; // type is available as a prop
  @Input() label: string;

  options = {
    componentRestrictions: {
      country: ['FR'],
    },
  };

  getPostalcodeAddress(address) {
    for (let component of address.address_components) {
      let indexOf = component.types.indexOf('postal_code');
      if (indexOf !== -1) {
        return component.long_name;
      }
    }
  }

  getCountryAddress(address) {
    for (let component of address.address_components) {
      let indexOf = component.types.indexOf('country');
      if (indexOf !== -1) {
        return component.long_name;
      }
    }
  }

  AddressChange(address: any) {
    let place = {
      name: address.name,
      postcode: this.getPostalcodeAddress(address),
      city: address.vicinity,
      country: this.getCountryAddress(address),
    };
    this.onChange.emit(place);
  }

  inputChange(e) {
    if (e.target.value === '') {
      this.onChange.emit(null);
    }
  }

  clickOnInput(e) {
    setTimeout(() => this.inputChange(e));
  }
}

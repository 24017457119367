import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/providers';
import { StorageService } from 'src/app/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private storageService: StorageService,
    private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const isAuth = this.authService.isAuth$.value

    if (!isAuth) {
      return false
    }
    else if (!this.authService.isAdmin) {
        this.router.navigate(['tableau-de-bord']);
        return false
    }
    this.storageService.removeItem('organisationId');
    this.storageService.removeItem('adminInOrganization');
    this.storageService.setItem('token', this.authService.adminSimpleToken)
    return true;
  }
}

<section id="form_page" class="container loading_container" [ngClass]="{ 'loaded' : mainForm }">
  <div class="loading_content">
    <div id="page_header">
      <div id="page_header_left">
        <h1>Mini-site</h1>
      </div>
      <div *ngIf="data" id="page_header_right">
        <app-button (clickEvent)="preview()" text="Prévisualiser" [style]="'border blue'"></app-button>
      </div>
    </div>
    <div id="form_wrapper">
      <h2>Votre mini-site</h2>
      <form *ngIf="mainForm" [formGroup]="mainForm">
        <h3>Paramètres Généraux</h3>
        <div class="form_item">
          <label for="name">Nom du Mini-site</label>
          <input id="name" type="text" formControlName="name">
        </div>

        <div (keydown.enter)="$event.stopPropagation()" class="form_item">
          <label for="description">Description</label>
          <app-wysiwyg [previousValue]="data && data.description" (changeText)="setValue('description', $event)">
          </app-wysiwyg>
        </div>

        <div (keydown.enter)="$event.stopPropagation()" class="form_item">
          <label for="description">Comment y accéder ?</label>
          <app-wysiwyg [previousValue]="data && data.gettingThere" (changeText)="setValue('gettingThere', $event)">
          </app-wysiwyg>
        </div>

        <div (keydown.enter)="$event.stopPropagation()" class="form_item">
          <label for="description">À propos</label>
          <app-wysiwyg [previousValue]="data && data.about" (changeText)="setValue('about', $event)"></app-wysiwyg>
        </div>

        <div class="form_item">
          <label for="widgets">Widget</label>
          <div *ngIf="widgets.length" class="input_wrap select">
            <select id="widget" formControlName="eventWidgetId">
              <option *ngFor="let widget of widgets" value="{{widget._id}}">
                {{widget.name}}</option>
            </select>
            <svg role="img" title="chevron" class="icon-chevron">
              <use href="assets/images/sprite.svg#icon-chevron"></use>
            </svg>
          </div>
          <div *ngIf="!widgets.length">
            <select disabled>
              <option>Aucun widget créé</option>
            </select>
          </div>
        </div>

        <div class="form_item tags">
          <label for="tags">Tags</label>
          <ul>
            <li *ngFor="let tag of tags" (click)="deleteTag(tag)">
              {{tag}}
              <svg role="img" title="close" class="icon-close">
                <use href="assets/images/sprite.svg#icon-close"></use>
              </svg>
            </li>
          </ul>
          <input #tagInput placeholder="Écrivez un tag puis appuyer sur entrée pour le valider" type="text" (keydown.enter)="addTag(tagInput)" (keydown.enter)="$event.preventDefault()">
        </div>

        <div formGroupName="customStyles">
          <h3>Styles personnalisés</h3>

          <app-file-input *ngIf="loaded" [loading]="loadingImage.includes('presentationImage')" class="form_item"
            text="Ajoutez l'image de présentation de votre événement"
            [preFile]="data && !loadingImage.includes('presentationImage') && data.customStyles.presentationImage"
            (imageChange)="changeImage('presentationImage', [$event])" [cropperStaticHeight]="370"
            [cropperStaticWidth]="370"></app-file-input>

          <h3>Photo de couverture</h3>
          <div class="form_cols">
            <div class="form_item">
              <input #chooseUnsplashInput id="choose_unsplash" type="checkbox" class="toggle"
                [checked]="chooseUnsplash"
                (change)="changeChooseUnsplash(chooseUnsplashInput.checked)">
              <label for="choose_unsplash">
                Choisir la photo de couverture depuis Unsplash
                <i class="check">
                  <svg role="img" title="check" class="check-icon">
                    <use href="assets/images/sprite.svg#icon-check"></use>
                  </svg>
                </i>
              </label>
            </div>
          </div>
          <div *ngIf="chooseUnsplash" class="form_item unsplash_input" (click)="$event.stopPropagation()">
            <input #unsplashInput type="text" (keyup)="searchUnsplash(unsplashInput.value)"
              (click)="unsplashInput.value && searchUnsplash()" placeholder="Tapez ce que vous souhaiter voir apparaître"
              (keypress)="typing = true" />
            <div *ngIf="unsplashList" class="unsplash_list">
              <ul *ngIf="unsplashList.length">
                <li *ngFor="let image of unsplashList">
                  <img *ngIf="image.base64" loading="lazy" (click)="selectUnsplashImage(image)" [src]="image.base64" [alt]="unsplashInput + '_' + image.author">
                  <div *ngIf="!image.base64" class="image_loader"></div>
                </li>

              </ul>
              <p *ngIf="!unsplashList.length">Aucune image trouvée</p>
            </div>
          </div>
          <app-file-input *ngIf="loaded && !chooseUnsplash" [loading]="loadingImage.includes('covers')" class="form_item"
            text="Ajoutez l'image de couverture de votre événement"
            [preFile]="unsplashSelected || (data && !loadingImage.includes('covers') && data.customStyles.covers)"
            [imageWidth]="540"  (imageChange)="changeImage('covers', [$event])"
            [cropperStaticHeight]="180" [cropperStaticWidth]="540"></app-file-input>

          <h3>Couleurs du site</h3>
          <div formGroupName="colors" class="form_cols">
            <div class="form_item quarter color">
              <label for="description">Arrière plan</label>
              <input [(colorPicker)]="colors.background" [style.background]="colors.background" name="color"
                (colorPickerChange)="changeColor('background', $event)" autocomplete="off" />
            </div>
            <div class="form_item quarter color">
              <label for="description">Titres</label>
              <input [(colorPicker)]="colors.title" [style.background]="colors.title" name="color"
                (colorPickerChange)="changeColor('title', $event)" autocomplete="off" />
            </div>
            <div class="form_item quarter color">
              <label for="description">Textes</label>
              <input [(colorPicker)]="colors.text" [style.background]="colors.text" name="color"
                (colorPickerChange)="changeColor('text', $event)" autocomplete="off" />
            </div>
            <div class="form_item quarter color">
              <label for="description">Liens</label>
              <input [(colorPicker)]="colors.link" [style.background]="colors.link" name="color"
                (colorPickerChange)="changeColor('link', $event)" autocomplete="off" />
            </div>
          </div>
        </div>

        <h3>Vos réseaux sociaux</h3>
        <div formGroupName="socials" class="form_cols">
          <div class="form_item half">
            <label for="facebook">Facebook</label>
            <input id="facebook" type="text" formControlName="facebook">
          </div>
          <div class="form_item half">
            <label for="instagram">Instagram</label>
            <input id="instagram" type="text" formControlName="instagram">
          </div>
          <div class="form_item half">
            <label for="linkedin">Linkedin</label>
            <input id="linkedin" type="text" formControlName="linkedin">
          </div>
          <div class="form_item half">
            <label for="twitter">Twitter</label>
            <input id="twitter" type="text" formControlName="twitter">
          </div>
          <div class="form_item half">
            <label for="tiktok">TikTok</label>
            <input id="tiktok" type="text" formControlName="tiktok">
          </div>
        </div>
        <div class="form_item">
          <label for="website">Site web</label>
          <input id="website" type="text" formControlName="website">
        </div>

        <div class="form_item">
          <label for="instagram">Instagram</label>
          <div class="insta_container">
            <p class="description_medium"> Affichez vos photos Instagram sur la page de votre établissement
            Donnez aux clients un avant-goût de votre établissement en affichant des photos issues de votre compte Instagram. <br/>
            En mettant en ligne des photos, vous (1) déclarez et garantissez que vous disposez des droits d'utilisation correspondants
             et que cela ne porte pas atteinte aux droits de tiers ; (2) concédez à OandB le droit d'exploiter ces photos, y compris le droit
              de les modifier sans votre autorisation ; et (3) dégagez OandB de toute responsaibilité concernant leur utilisation. Nous nous réservons le droit de vous déconnecter à tout moment. </p>
            <app-button *ngIf="!isInstagramConnected" [style]="'plain'" [disabled]="mainForm.invalid"
              (clickEvent)="submitFormInsta()" text="Se connecter">
            </app-button>
            <app-button *ngIf="isInstagramConnected" [style]="'plain'" [disabled]="mainForm.invalid"
              (clickEvent)="deconnectInsta()" text="Se déconnecter">
            </app-button>
          </div>
          <!-- <a href="https://api.instagram.com/oauth/authorize?client_id=4476134115837758&redirect_uri=https://244e-77-157-175-106.ngrok.io/&scope=user_profile,user_media&response_type=code">Associer votre compte instagram</a> -->
        </div>
        <!--
        <h3>Connexion Instagram</h3>
        <div formGroupName="instagramAuth">
          <div class="form_item">
            <label for="instagramAppSecret">Clé secrète de l'application Instagram</label>
            <input id="instagramAppSecret" type="text" formControlName="instagramAppSecret">
          </div>
          <div class="form_item">
            <label for="instagramAppId">Identifiant de l'application Instagram</label>
            <input id="instagramAppId" type="text" formControlName="instagramAppId">
          </div>
          <div class="form_item">
            <label for="instagramRedirectUri">Url de redirection</label>
            <input id="instagramRedirectUri" type="text" formControlName="instagramRedirectUri">
          </div>
          <div class="form_item">
            <label for="instagramToken">Token</label>
            <input id="instagramToken" type="text" formControlName="instagramToken">
          </div>
        </div>
        -->

        <div class="form_actions right">
          <app-button *ngIf="data" (clickEvent)="preview()" text="Prévisualiser" [style]="'border blue'"></app-button>
          <app-button [loadingOff]="loadingState$" [style]="'plain'" [disabled]="mainForm.invalid"
            (clickEvent)="submitForm()" text="Enregistrer" type="submit">
          </app-button>
          <app-popup *ngIf="error != null" title="Une erreur est survenue" message="{{error}}" popupOpened="true"
            (backEvent)="error = null"></app-popup>
        </div>
      </form>
    </div>
  </div>
</section>
<div *ngIf="data">
	<div id="page_header" class="container">
		<h1>Commande N°{{data.orderNumber}}</h1>
		<div id="page_header_right" (click)="$event.stopPropagation()">
			<app-button (clickEvent)="actionToggleOpen = true" [loadingFunction]="false" class="button_create"
				text="Actions" [style]="'plain'" [icon]="'chevron'"></app-button>
			<nav class="drop drop_more" *ngIf="actionToggleOpen" [@moreAnimation]>
				<ul>
					<ng-container *ngFor="let link of actionToggle.links">
						<li *ngIf="!link.if || link.if()" (click)="link.method(actionToggle.ref)"
							(click)="$event.stopPropagation()" class="{{link.class}}">{{link.title}}</li>
					</ng-container>
				</ul>
			</nav>
		</div>
	</div>

	<div id="order_content" class="container">
		<div class="inner">
			<p class="h2">Informations sur la commande N°{{data.orderNumber}}</p>
			<ul class="order_details">
				<li>
					<span class="label">N° de commande</span>
					<span class="value doselect">{{data.orderNumber}}</span>
				</li>
				<li>
					<span class="label">Date</span>
					<span class="value doselect">{{data.date}}</span>
				</li>
				<li>
					<span class="label">Moyen de paiement</span>
					<span class="value doselect">{{data.paymentMethod}}</span>
				</li>
				<li>
					<span class="label">Montant</span>
					<span class="value doselect">{{data.totalPrice.sellingPrice | currency: "EUR"}}</span>
				</li>
				<li *ngFor="let adjustment of data.totalPrice.adjustments">
					<span class="label">
						{{ adjustment.label }}
					</span>
					<span class="value doselect">
						{{ (adjustment.amount / 100 ) | currency: "EUR"}}
					</span>
				</li>
				<li *ngIf="data.promoCode">
					<span class="label">Code Promo</span>
					<span class="value doselect">{{data.promoCode.name}}</span>
				</li>
				<!-- @todo Commenté pour la v1 -->
				<!-- <li>
					<span class="label">Paiement échelonné</span>
					<span class="value doselect">Non</span>
				</li> -->
				<li>
					<span class="label">Statut</span>
					<span class="value doselect">{{data.translatedStatus}}</span>
				</li>
				<li>
					<span class="label">Nom</span>
					<span class="value doselect" *ngIf="!editName">{{data.invoiceData.firstName}}
						{{data.invoiceData.lastName}}</span>
					<div class="aligned_input" *ngIf="editName">
						<input placeholder="Prénom" (change)="newFirstName = $event.target.value" id="firstname"
							type="text" value="{{data.invoiceData.firstName}}" />
						<input placeholder="Nom" (change)="newLastName = $event.target.value" id="lastname" type="text"
							value="{{data.invoiceData.lastName}}" />
					</div>
					<span class="edit" *ngIf="!editName">
						<app-button (clickEvent)="editName = true" text="Modifier" [style]="'link'"></app-button>
					</span>
					<span class="save" *ngIf="editName">
						<app-button (clickEvent)="saveNewName()" text="Valider" [style]="'plain full'">
						</app-button>
					</span>
				</li>
				<li>
					<span class="label">E-mail</span>
					<span class="value doselect" *ngIf="!editEmail">{{data.invoiceData.email}}</span>
					<input placeholder="Email" (change)="newEmail = $event.target.value" type="email" *ngIf="editEmail"
						value="{{data.invoiceData.email}}" />
					<span class="edit" *ngIf="!editEmail">
						<app-button (clickEvent)="editEmail = true" text="Modifier" [style]="'link'"></app-button>
					</span>
					<span class="save" *ngIf="editEmail">
						<app-button (clickEvent)="saveNewEmail()" text="Valider" [style]="'plain full'">
						</app-button>
					</span>
				</li>
				<li>
					<span class="label">Adresse</span>
					<span class="value doselect" *ngIf="!editAddress">{{data.invoiceData.address}}</span>
					<input placeholder="Adresse" (change)="newAddress = $event.target.value" type="text"
						*ngIf="editAddress" value="{{data.invoiceData.address}}" />
					<span class="edit" *ngIf="!editAddress">
						<app-button (clickEvent)="editAddress = true" text="Modifier" [style]="'link'"></app-button>
					</span>
					<span class="save" *ngIf="editAddress">
						<app-button (clickEvent)="saveNewAddress()" text="Valider" [style]="'plain full'">
						</app-button>
					</span>
				</li>
				<li>
					<span class="label">Ville</span>
					<span class="value doselect" *ngIf="!editCity">{{data.invoiceData.zipCode}}
						{{data.invoiceData.city}}</span>
					<div class="aligned_input" *ngIf="editCity">
						<input placeholder="Code Postal" (change)="newZipCode = $event.target.value" type="text"
							value="{{data.invoiceData.zipCode}}" />
						<input placeholder="Ville" (change)="newCity = $event.target.value" type="text"
							value="{{data.invoiceData.city}}" />
					</div>
					<span class="edit" *ngIf="!editCity">
						<app-button (clickEvent)="editCity = true" text="Modifier" [style]="'link'"></app-button>
					</span>
					<span class="save" *ngIf="editCity">
						<app-button (clickEvent)="saveNewCity()" text="Valider" [style]="'plain full'"></app-button>
					</span>
				</li>
				<li *ngIf="!data.isProduct">
					<span class="label">Tickets</span>
					<span class="value doselect">{{ticketNumber}}</span>
					<span class="see_tickets">
						<app-button (clickEvent)="goToTicketPage()" text="Voir les tickets" [style]="'link'"></app-button>
					</span>
				</li>
				<li *ngIf="data.isProduct">
					<span class="label">Produits</span>
					<span class="value doselect">{{products}}</span>
				</li>
			</ul>
		</div>
	</div>
</div>



<app-popup *ngIf="refundPopupOpen" (backEvent)="refundPopupOpen = false"
	[title]="'Souhaitez-vous rembourser la commande N°' + data.orderNumber"
	message="Le virement de remboursement sera envoyé au participant sur le compte en banque qu'il a utilisé pour effectuer la commande"
	[buttonText]="'Rembourser '+ ( data.totalPrice.sellingPrice | currency: 'EUR' ) "
	(clickEvent)="askRefundOk()"></app-popup>

<app-delete-popup *ngIf="cancelPopupOpen" [name]="data.orderNumber" deleteWord="annuler la commande"
	[isFemale]="true" buttonText="Confirmer l'annulation" type="commande" (clickEvent)="cancelOrderOk()"
	(close)="cancelPopupOpen = false"></app-delete-popup>

<div class="full_loader" *ngIf="fullLoader">
	<div></div>
</div>
<div id="page_header" class="container">
  <div id="page_header_left">
    <h1>Sessions</h1>
  </div>
  <div id="page_header_right">
    <app-button (clickEvent)="createSession()" [loadingFunction]="false" class="button" text="Créer une date"
      [style]="'plain'"></app-button>
  </div>
</div>

<ng-container *ngIf="sessions">
  <section *ngIf="sessions.length">
    <app-main-table [ref]="this"
    idSection="sessions_content" idTable="table_sessions" tableType="sessions"
    [columns]="tableOptions" [datum]="sessions"></app-main-table>
  </section>

  <section *ngIf="!sessions.length" id="block_first" class="container">
    <p class="description grey">Vous n’avez pas encore créé les différentes sessions et leurs dates pour cet événement</p>
    <figure>
      <img src="assets/images/visuel_sessions.svg" />
    </figure>
    <app-button (click)="createSession()" class="button_create no_margin" text="Créer une date" [style]="'plain full'">
    </app-button>
  </section>
</ng-container>

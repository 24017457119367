import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  constructor() { }

  object(object1, object2) {
    return this.update(object1, object2);
  }

  form(formData, originalData) {
    return this.update(formData, originalData, this.isNullOrUndefined, formData)
  }

  private update(object1, object2, lastCondition = this.default, arg = true) {
    for (const key in object2) {
      if ((object2[key] !== null && typeof object2[key] === 'object') && !Array.isArray(object2[key]) && object2[key] !== null) {
        if (object2[key] instanceof Date) {
          if (object1[key] !== object2[key]) {
            object1[key] = new Date(object2[key]);
          }
        }
        else {
          if (!object1[key]) {
            object1[key] = {}
          }
          object1[key] = this.update(object1[key], object2[key], lastCondition, object1[key]);
        }
      }
      else if (lastCondition(arg, key)) {
          object1[key] = object2[key];
      }
    }

    return object1
  }

  default = (value, key) => value;

  isNullOrUndefined = (value, key) => {
    return value[key] === null || value[key] === undefined
  };
  
}

<ng-container *ngIf="data">

    <div id="page_header" class="container">
        <div id="page_header_left">
            <h1>Contrôle d’accès <span *ngIf="event.eventCode">(Code de l'événement: <span class="doselect">{{event.eventCode}}</span>)</span></h1>
        </div>
        <div id="page_header_right">
            <app-button (click)="createList()" class="button" text="Créer une liste" [style]="'plain'"></app-button>
        </div>
    </div>

    <section *ngIf="data.length">
        <app-main-table [ref]="this" idSection="controls_content" idTable="table_controls" tableType="controls"
            [columns]="tableOptions" [datum]="data"></app-main-table>

        <app-pager *ngIf="totalPages" [totalPages]="totalPages" [page]="page" (changePage)="changePage($event)"></app-pager>
    </section>

    <section *ngIf="!data.length" id="block_first" class="container">
        
        <p class="description grey">Vous n’avez pas encore créé de liste de contrôle</p>
        <figure>
            <img src="assets/images/visuel_controle_acces.svg" />
        </figure>
        <app-button (click)="createList()" class="button_create no_margin" text="Créer une liste"
            [style]="'plain full'"></app-button>
    </section>
</ng-container>



<app-delete-popup *ngIf="elementToDelete" [name]="elementToDelete.name" type='contrôle' 
(clickEvent)="deleteOk()" (close)="elementToDelete = null"></app-delete-popup>
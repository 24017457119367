import { Component, OnDestroy, AfterViewInit } from '@angular/core';
import { EventService } from 'src/app/providers';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { GetEvent } from '../getEvent';
import { IEvent, IEventPublishableStatus, translateStatus } from 'src/app/models/event.model';
import { GetParams } from 'src/app/models/type.definition';
import * as ChartsJS from '../../../../assets/libraries/chart.bundle.min';
import { IEventStats } from 'src/app/models/stats.model';
import { NotificationService } from 'src/app/services/notification.service';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'app-event-general',
  templateUrl: './event-general.component.html',
  styleUrls: ['./event-general.component.scss'],
  providers: [GetEvent]
})
export class EventGeneralComponent implements AfterViewInit, OnDestroy {

  private _subscription = new Subscription();
  public event: IEvent;
  public backgroundColors = ['#41bbe7', '#ff003c', '#68cbb5', '#22216d', '#f000ff'];
  public hasTicket: boolean;
  public loadingWidgets: boolean = true
  public loadingState$ = new Subject<void>();
  public stats: IEventStats;
  public eventPublishableStatus: IEventPublishableStatus;
  public dateSelected = [null, null]

  public dateSelector: string[];

  /* Charts variables */
  // Pie chart
  pieChartTooltips: any = [];
  optionsPie: any = [];
  typePie: any = '';
  pieChartElem: any = '';
  pieChartData: any = [];
  pieChart: any = '';

  // Line chart
  lineChartTooltips: any = [];
  optionsLine: any = [];
  typeLine: any = '';
  lineIncomeChartElem: any = '';
  lineIncomeChartData: any = [];
  lineIncomeChart: any = null;
  lineOrdersChartElem: any = '';
  lineOrdersChartData: any = [];
  lineOrdersChart: any = null;

  displayPage = false;
  public firstDate: Date;

  displayPublishPopup = false;

  constructor(
    private eventService: EventService,
    private getEvent: GetEvent,
    private dateService: DateService,
    private notificationService: NotificationService,
    private router: Router) { }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  async ngAfterViewInit() {
    const getOptions: GetParams<IEvent> = {
      select: ['status', 'createdAt', 'ticketTypeCategories', 'statistics']
    }
    this._subscription.add(this.eventService.selected$.subscribe(async (data: IEvent) => {
      this.displayPage = false;
      this.event = await this.getEvent.get(getOptions);
      if (data) {

        this.hasTicket = !!this.event.ticketTypeCategories?.map(_ => _.ticketTypes).filter(_ => _.length).length;
        this.dateSelector = [];
        this.firstDate = new Date(this.event.createdAt);
        this.dateService.setToBeginningOfDay(this.firstDate);
        this.initSelectedDate();
        this.eventPublishableStatus = await this.eventService.canBePublished(this.event._id).toPromise();
        this.eventPublishableStatus.publishable = this.eventPublishableStatus.rateCreated;
        this.eventPublishableStatus.allConfigured = ![
          this.eventPublishableStatus.eventCustomized,
          this.eventPublishableStatus.eventWidgetCreated,
          this.eventPublishableStatus.rateCreated,
          this.eventPublishableStatus.ticketCustomized,
          this.eventPublishableStatus.quotaDefined
        ].filter(_ => !_).length;
        this.displayPage = true;
        await this.getStats();
        setTimeout(() => this.initCharts());
      }
    }));
  }

  goTo(path: string): void {
    this.router.navigateByUrl(`/evenements/${this.event._id}/${path}`);
  }

  makeChart(chartElement, type, data, options) {
    if (chartElement) {
      return new ChartsJS(chartElement, {
        type: type,
        data: data,
        options: options
      });
    }
  }

  initPieCharts(): void {
    if (this.stats.distribution.length) {
      this.pieChartTooltips = {
        callbacks: {
          beforeLabel: function (tooltipItem, data) {
            return data.labels[tooltipItem.index][1] || '';
          },
          label: function (tooltipItem, data) {
            const value = " " + data['datasets'][tooltipItem.datasetIndex]['data'][tooltipItem['index']].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ',') + "€";
            const name = data.labels[tooltipItem.index][0];
            return `${name}: ${value}`;
          }
        },
        backgroundColor: '#000',
        titleFontSize: 0,
        titleFontColor: '#0066ff',
        bodyFontColor: '#fff',
        bodyFontSize: 12,
        displayColors: true
      }
      this.optionsPie = {
        legend: {
          display: false,
        },
        aspectRatio: 1,
        tooltips: this.pieChartTooltips
      }
      this.typePie = 'pie';
      // PIE CHART
      this.pieChartElem = document.getElementById("pie_chart") as HTMLCanvasElement;
      this.pieChartData = {
        datasets: [{
          data: this.stats.distribution.map(_ => _.totalPrice.floated),
          backgroundColor: this.backgroundColors,
          borderWidth: 0
        }],
        labels: this.stats.distribution.map(_ => {
          const labels = [_.name];
          _.ticketTypeCategory && labels.push(_.ticketTypeCategory);
          return labels
        })
      }

      console.log(this.stats.distribution, this.pieChartData.datasets);

      if (this.pieChart) {
        this.pieChart.destroy();
      }
      this.pieChart = this.makeChart(this.pieChartElem, this.typePie, this.pieChartData, this.optionsPie);
    }
  }

  initLineCharts(): void {
    this.setLineChartsOptions();
    this.setLineIncomesChart();
    this.setLineOrderChart();
  }

  setLineIncomesChart(): void {
    console.log("Charts", this.stats.chartPrice.data);

    this.lineIncomeChartElem = document.getElementById("income_chart") as HTMLCanvasElement;

    this.lineIncomeChartData = {
      labels: this.stats.chartPrice.labels.map(_ => _.toUpperCase().split('-')),
      datasets: [{
        data: this.stats.chartPrice.data,
        borderColor: '#131620',
        fill: false,
        tension: 0,
      }]
    }
    if (this.lineIncomeChart) {
      this.lineIncomeChart.destroy();
    }
    const options = {
      ...this.optionsLine,
      plugins: {
        title: {
          display: false,
          text: 'incomes'
        }
      }
    }
    this.lineIncomeChart = this.makeChart(this.lineIncomeChartElem, this.typeLine, this.lineIncomeChartData, options);
  }

  setLineOrderChart(): void {
    this.lineOrdersChartElem = document.getElementById("orders_chart") as HTMLCanvasElement;
    this.lineOrdersChartData = {
      labels: this.stats.chartOrder.labels.map(_ => _.toUpperCase().split('-')),
      datasets: [{
        data: this.stats.chartOrder.data,
        borderColor: '#131620',
        fill: false,
        tension: 0,
      }]
    }
    const options = {
      ...this.optionsLine,
      plugins: {
        title: {
          display: false,
          text: 'orders'
        }
      }
    }
    this.lineOrdersChart = this.makeChart(this.lineOrdersChartElem, this.typeLine, this.lineOrdersChartData, options);
  }

  setLineChartsOptions(): void {
    this.lineChartTooltips = {
      callbacks: {
        label: function (tooltipItem, data) {
          if (this._chart.options.plugins.title.text == 'incomes') {
            return " " + (data['datasets'][tooltipItem.datasetIndex]['data'][tooltipItem['index']] / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ',') + "€";
          }
          return "" + data['datasets'][tooltipItem.datasetIndex]['data'][tooltipItem['index']];
        }
      },
      backgroundColor: '#EBF3FF',
      titleFontSize: 0,
      titleSpacing: 0,
      titleMarginBottom: 0,
      bodyFontColor: '#131620',
      bodyFontSize: 14,
      cornerRadius: 0,
      padding: 12,
      displayColors: false
    }

    this.optionsLine = {
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          //barThickness: 25,
          gridLines: {
            display: false
          },
          ticks: {
            fontSize: 12,
            fontColor: '#7f8286',
            fontFamily: 'sofia-pro',
            maxTicksLimit: 10,
            maxRotation: 0,
          }
        }],
        yAxes: [{
          ticks: {
            beginAtZero: true,
            fontSize: 12,
            fontColor: '#7f8286',
            fontFamily: 'sofia-pro'
          },
          gridLines: {
            //display:false
          }
        }]
      },
      tooltips: this.lineChartTooltips
    }
    this.typeLine = 'line';
  }

  initCharts() {
    this.initPieCharts();
    this.initLineCharts();
  }

  prePublish() {
    if (this.event.bookType === 'nft') {
      this.displayPublishPopup = true;
    } else {
      this.publish();
    }
  }

  cancelPublish() {
    this.displayPublishPopup = false;
    this.loadingState$.next();
  }

  publish(): void {
    this.eventService.update(this.event._id, { status: 'published' }).subscribe(res => {
      this.event.status = 'published';
      this.event.translateStatus = translateStatus(this.event.status)
      this.loadingState$.next();
      this.displayPublishPopup = false;
      this.notificationService.newNotification({ message: "Événement publié avec succès", state: "success" })
    }, err => {
      this.displayPublishPopup = false;
      this.loadingState$.next();
    })
  }

  public async getStats(): Promise<void> {
    // @TODO: Retirer l'appel en double.
    await this.getEventStats();
    this.setTicketStats();
  }

  async setSelectedDate(dates: [Date, Date]) {
    this.dateSelected[0] = this.dateService.getInternationalString(dates[0]);
    this.dateSelected[1] = this.dateService.getInternationalString(dates[1]);

    this.lineOrdersChart?.destroy();
    this.lineOrdersChart = null;
    this.lineIncomeChart?.destroy();
    this.lineIncomeChart = null;
    this.loadingWidgets = true
    await this.getEventStats();
    this.loadingWidgets = false
    this.initLineCharts();
  }

  private async getEventStats(): Promise<void> {
    this.stats = await this.eventService.getEventStats(this.event._id, this.dateSelected).toPromise();
  }

  setTicketStats(): void {
    if (this.stats.distribution.length) {
      this.stats.distribution = this.stats.distribution
        .filter(distribution => (distribution.totalPrice.floated > 0))
    }
    this.stats.distribution.sort((a, b) => a.ticketTypeCategory?.localeCompare(b.ticketTypeCategory));
  }

  private async initSelectedDate(): Promise<void> {
    const now = new Date();
    const weekAgo = new Date(now.getTime() - (7 * 24 * 60 * 60 * 1000))
    const createdEvent = new Date(this.event.createdAt)

    const firstDateFork: [Date, Date] = [weekAgo.getTime() >= this.firstDate.getTime() ? weekAgo : this.firstDate, now];
    this.dateSelected[0] = this.dateService.getInternationalString(firstDateFork[0]);
    this.dateSelected[1] = this.dateService.getInternationalString(firstDateFork[1]);
    this.loadingWidgets = true
    await this.getEventStats();
    this.initLineCharts();
    this.loadingWidgets = false
  }

}
import { EditableEventData } from './editable-data'
import { IEvent } from './event.model';
import { IOrder } from './order.model';
import { IOrganisation } from './organisation.model';

export interface IRefund extends EditableEventData {
    status?: 'todo' | 'done' | 'canceled';
    orderId?: IOrder;
    organisationId?: IOrganisation;
    eventId?: IEvent;
    organisationName?: string;
    eventName?: string;
    name?: string;
    amount?: string;
    translatedStatus?: 'Non traitée' | 'Remboursée' | 'Non remboursée'
}

export function translateRefundStatus(status: 'todo' | 'done' | 'canceled'): 'Non traitée' | 'Remboursée' | 'Non remboursée' {
    switch (status) {
        case 'todo': return 'Non traitée';
        case 'done': return 'Remboursée';
        case 'canceled': return 'Non remboursée';
    }
}
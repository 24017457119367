import { Component, OnInit } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { AuthService, OrganisationService } from 'src/app/providers';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { Location } from '@angular/common';
import { StorageService } from 'src/app/services/storage.service';
import { AuthComponent } from '../auth-component';

@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.scss']
})
export class LogInComponent extends AuthComponent {

  public authForm: FormGroup;
  public showPwd: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private location: Location,
    protected route: ActivatedRoute
  ) {
    super()
  }

  ngOnInit(): void {
    this.route.params.subscribe(async (params: Params) => {
      this.route.queryParams.subscribe(async (queryParams) => {
        this.url = params.url || 'tableau-de-bord';
        this.queryParams = queryParams;
      });
    });
    if (this.storageService.getItem('token')) {
      this._subscription.add(this.authService.renewToken().subscribe(
        (result: any) => {
          if (result.token) {
            this.authService.setIfAdmin(result.isSuperAdmin);
            this.authService.saveToken(result.token);
            this.loadOrganisationsIfNeeded();
            this.router.navigate([this.url], { queryParams: this.queryParams });
          }
          else {
            this.initLoginPage();
          }
        },
        err => {
          if (err) {
            this.storageService.clear();
            this.initLoginPage();
          }
        }));
    }
    else {
      this.initLoginPage()
    }
  }

  initLoginPage() {
    this.location.go('/connexion');
    this.initForm();
  }

  initForm(): void {
    this.authForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required)
    });
  }

  logIn(): void {
    const formData = this.authForm.value;
    super.logIn(formData);
  }

  signUp(): void {
    this.router.navigate(['inscription']);
  }

  closeError(): void {
    this.error = null;
  }

  loadOrganisationsIfNeeded() {
    if (this.storageService.getItem('organisationId')) {
      this.organisationService.setList();
      this.organisationService.getCurrent();
    }
  }
}

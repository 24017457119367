import { RouteType } from 'src/app/models/type.definition';

export const MAINROUTES: RouteType[] = [
  {
    route: 'admin',
    title: 'Tableau de Bord',
    icon: 'board',
    active: false,
    displaySubmenu: false,
    forAdmin: true,
    submenu: []
  },
  {
    route: 'data',
    title: 'Data',
    icon: 'user',
    active: false,
    displaySubmenu: true,
    forAdmin: true,
    submenu: [
      {
        route: 'organizations',
        title: 'Organisations',
        icon: 'user',
        active: false,
        displaySubmenu: false,
        forAdmin: true,
        submenu: []
      },
      {
        route: 'events',
        title: 'Billetteries',
        icon: 'board',
        active: false,
        displaySubmenu: false,
        forAdmin: true,
        submenu: []
      },
      {
        route: 'orders',
        title: 'Commandes',
        icon: 'board',
        active: false,
        displaySubmenu: false,
        forAdmin: true,
        submenu: []
      },
      {
        route: 'tickets',
        title: 'Participants',
        icon: 'board',
        active: false,
        displaySubmenu: false,
        forAdmin: true,
        submenu: []
      },
    ]
  },
  {
    route: 'demandes-de-remboursements',
    title: 'Remboursements',
    icon: 'money',
    active: false,
    displaySubmenu: false,
    forAdmin: true,
    submenu: []
  },
  {
    route: 'reddition',
    title: 'Reddition de comptes',
    icon: 'gear',
    active: false,
    displaySubmenu: false,
    forAdmin: true,
    submenu: []
  },
  {
    route: 'seating-plan',
    title: 'Plan de salle',
    icon: 'gear',
    active: false,
    displaySubmenu: false,
    forAdmin: true,
    submenu: []
  },
  {
    route: 'tableau-de-bord',
    title: 'Tableau de Bord',
    icon: 'board',
    active: false,
    displaySubmenu: false,
    submenu: []
  },
  {
    route: 'evenements',
    title: 'Événements',
    icon: 'calendar',
    active: false,
    displaySubmenu: false,
    submenu: [
      {
        route: 'creer-un-evenement',
        title: 'Créer un événement',
        icon: '',
        active: false,
        displaySubmenu: false,
        submenu: []
      },
    ]
  },
  {
    route: 'equipe',
    title: 'Équipe',
    icon: 'user',
    active: false,
    displaySubmenu: false,
    submenu: [
      {
        route: 'ajouter-un-membre',
        title: 'Ajouter un membre',
        icon: '',
        active: false,
        displaySubmenu: false,
        submenu: []
      },]
  },
  {
    route: 'parametres',
    title: 'Paramètres',
    icon: 'gear',
    active: false,
    displaySubmenu: true,
    submenu: [
      {
        route: 'general',
        title: 'Général',
        icon: '',
        active: false,
        displaySubmenu: false,
        submenu: []
      },
      // {
      //   route: 'rib-iban',
      //   title: 'RIB/IBAN',
      //   icon: '',
      //   active: false,
      //   displaySubmenu: false,
      //   submenu: []
      // },
      {
        route: 'seating-plan',
        title: 'Plan de salle',
        icon: '',
        active: false,
        displaySubmenu: false,
        submenu: []
      },
      {
        route: 'payment-account',
        title: 'Compte de paiement',
        icon: '',
        active: false,
        displaySubmenu: false,
        submenu: []
      },
      {
        route: 'comptabilite',
        title: 'Comptabilité',
        icon: '',
        active: false,
        displaySubmenu: false,
        submenu: []
      }
    ]
  },
];

import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { IEvent } from 'src/app/models/event.model';
import { IForm, IFormField } from 'src/app/models/form.model';
import { GetEvent } from '../../getEvent';
import { FormComponent } from 'src/app/elements/form-component';
import { EventService, FormFieldService, FormService, TicketTypeService } from 'src/app/providers';
import { WysiwygComponent } from 'src/app/elements/wysiwyg/wysiwyg.component';
import { FileInputComponent } from 'src/app/elements/file-input/file-input.component';
import { GetParams } from 'src/app/models/type.definition';
import { ITicketType } from 'src/app/models/ticket.model';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-event-ticketing-badge',
  templateUrl: './event-ticketing-badge.component.html',
  styleUrls: ['./event-ticketing-badge.component.scss'],
  providers: [GetEvent]
})
export class EventTicketingBadgeComponent extends FormComponent<IEvent> {
  @ViewChild(WysiwygComponent) wysiwyg: WysiwygComponent;
  @ViewChild(FileInputComponent) fileInput: FileInputComponent;
  public dataName = 'Badge';
  public error: string;
  public ticketTypes: ITicketType[];
  public currentFormField: string = null;
  public loadingState$: Subject<void> = new Subject<void>();
  public previousImage: Blob;
  public currentImage: Blob
  public previousText: string;
  public dataPreview: any;
  public selectedTicketTypeId: string = null;
  public errorLoadingImage: boolean = false
  public textAddMedia = "Ajouter une image à votre ticket";
  //public customFormService: FormService;
  public customForms;
  public customFormFields: IFormField[] = [];
  public selectedTicketType: ITicketType
  public defaultText: string;
  public defaultFooterText: string;
  public defaultFormFieldId: string;
  constructor(
    private getEvent: GetEvent,
    protected formProvider: EventService,
    protected customFormService: FormService,
    protected customFormFieldService: FormFieldService,
    protected rateService: TicketTypeService,
    private notification: NotificationService

  ) {
    super();
  }

  async afterInit() {
    const getParams: GetParams<IForm> = {
      filter: { eventId: this.data._id },
      populate: ['fields'],
    };
    this.customForms = await this.customFormService.getList(getParams).toPromise()
    const customForms: IForm[] = this.customForms?.data || []
    for (const form of customForms) {
      if (!form.isTicketTypeRestricted) {
        this.customFormFields = form.fields.map(field => {
          field['form_name'] = form.name
          return field
        })
      }
    }
  }

  async onInit() {
    this.data = await this.getEvent.get({ select: ['ticketing', 'place', 'status'] });
    await this.loadTicketTypes()
    this.dataPreview = this.data;
    this.dataPreview.ticketing.badgeVisual.formField = null
    this.defaultText = this.data.ticketing?.badgeVisual?.text || '';
    this.defaultFooterText = this.data.ticketing?.badgeVisual?.footerText || '';
    this.defaultFormFieldId = this.data.ticketing?.badgeVisual?.formFieldId?._id || '';
    this.previousText = this.data.ticketing.badgeVisual?.text || '';
    this.errorLoadingImage = false
    await this.getImage();
    this.loaded = true;
    this.createImagesChangeState(['imageId']);
    if (this.data.bookType === 'nft') {
      this.textAddMedia = "Ajouter une image / vidéo à votre ticket"
    }
    await this.afterInit();
    this.selectFormField(this.data.ticketing.badgeVisual?.formFieldId)
  }

  selectFormField(value) {
    this.currentFormField = value

    if (value === 'null' || !value) {
      this.dataPreview.ticketing.badgeVisual.formField = null
      this.currentFormField = null
      if (this.mainForm) {
        this.mainForm.patchValue({ formFieldId: null });
      }
    }
    else {
      this.dataPreview.ticketing.badgeVisual.formField = this.customFormFields
        .filter(el => el.id == value)
        .map(el => el.label)
    }
  }

  async changeTicketTypeId(ticketTypeId) {

    this.selectedTicketTypeId = ticketTypeId
    const selected = this.ticketTypes.find(ticketType => ticketType._id == ticketTypeId)
    let description = this.defaultText
    let footerText = this.defaultFooterText
    let formFieldId = this.defaultFormFieldId
    if (selected) {
      this.selectedTicketType = selected

      const isOverriden = (this.selectedTicketType?.badgeVisual?.overriden) ? true : false
      this.mainForm.patchValue({ overriden: isOverriden });
      if (isOverriden) {
        description = selected?.badgeVisual?.text || ''
        footerText = selected?.badgeVisual?.footerText || ''
        formFieldId = selected?.badgeVisual?.formFieldId || ''
      }
    }
    else {
      this.selectedTicketType = null
      this.selectedTicketTypeId = null

    }
    this.getImage()
    this.setDescriptionValue(description)
    this.setFooterTextValue(footerText)
    this.selectFormField(formFieldId)
    if (this.wysiwyg) {
      this.wysiwyg.reset(description)
    }
  }

  async loadTicketTypes() {
    this.ticketTypes = await this.getList(
      this.rateService.getList({
        filter: {
          eventId: this.data._id,
        },
        select: ['name', 'price', 'ticketVisual', 'badgeVisual', 'ticketTypeCategoryId'],
        populate: ['ticketTypeCategoryId']
      })
    );
  }

  async getList<T>(subject: Observable<{ data: T[] }>): Promise<T[]> {
    return (await subject.toPromise())?.data || [];
  }

  private async getImage(): Promise<void> {

    let id = this.data.ticketing.badgeVisual.imageId?._id || null;
    if (this.selectedTicketType) {
      if (this.selectedTicketType?.badgeVisual?.overriden) {
        id = this.selectedTicketType.badgeVisual.imageId._id
      }
    }
    if (this.mainForm) {
      this.mainForm.patchValue({ imageId: id });
    }

    if (id) {
      this.loadingImage.push("imageId");
      try {
        this.previousImage = await this.mediaService.getStream(id).toPromise();
        this.currentImage = this.previousImage
        this.dataPreview.ticketing.badgeVisual.badgeImage = this.previousImage;
        this.loadingImage.splice(this.loadingImage.indexOf("imageId"));
      } catch (error) {
          this.errorLoadingImage = true
          console.log('Probleme');
      } finally {
        this.loadingImage.splice(this.loadingImage.indexOf("imageId"));
      }
    }
    else {
      this.previousImage = null
      this.currentImage = this.previousImage
      this.dataPreview.ticketing.badgeVisual.image = this.previousImage;
    }
    if (this.fileInput) {
      await this.fileInput.reset();
    }
  }

  initForm() {
    this.mainForm = this.formBuilder.group({
      badgeImage: new FormControl(null),
      imageId: new FormControl(this.data.ticketing.badgeVisual.imageId),
      text: new FormControl(this.previousText),
      formFieldId: new FormControl(this.data.ticketing.badgeVisual.formFieldId?._id || null),
      footerText: new FormControl(this.data.ticketing.badgeVisual.footerText),
      ticketTypeId: new FormControl(""),
      overriden: new FormControl(false),
    });
  }

  setDescriptionValue(value: string) {
    this.mainForm.patchValue({ text: value });
    this.dataPreview.ticketing.badgeVisual.text = value;
  }

  setFooterTextValue(value: string) {
    this.mainForm.patchValue({ footerText: value });
    this.dataPreview.ticketing.badgeVisual.footerText = value;
  }

  async submitTicketType() {

    const badgeVisual = this.mainForm.value

    const formData: ITicketType = {
      eventId: this.data,
      badgeVisual
    }

    this.getFileToCreate(this.mainForm.value.badgeImage, 'imageId', this.selectedTicketType?.badgeVisual?.imageId._id, 'badgeVisual.imageId');

    if (this.filesToCreate[0]) {
      await this.createOrDeleteMedia(formData, 0, 'ticketTypeId', false, {typeId: this.selectedTicketType._id});
    }

    if (!formData.badgeVisual.overriden) {
      formData.badgeVisual.text = ''
      if (formData.badgeVisual?.imageId) {
        await this.deleteMedia(formData.badgeVisual.imageId._id);
        formData.badgeVisual.imageId = null
      }
    }

    this.rateService.update(this.selectedTicketType._id, formData).subscribe(
      async (result) => {
        this.selectedTicketType.badgeVisual = formData.badgeVisual
        this.notification.newNotification({
          message: `E-Ticket édité avec succès`,
          state: 'success',
        });
      },
      (err) => {
        this.notification.newNotification({
          message: `Un problème est survenu`,
          state: 'error',
        });
      }
    );

    this.loadingState$.next();
  }

  async submitForm() {

    if (this.selectedTicketType) {
      await this.submitTicketType()
    }
    else {
      const formData: IEvent = {
        ticketing: {
          ...this.data.ticketing,
          badgeVisual: this.mainForm.value
        }
      };
      if (formData.ticketing.badgeVisual.formFieldId?._id == 'null') {
        formData.ticketing.badgeVisual.formFieldId = null
      }
      this.getFileToCreate(this.mainForm.value.badgeImage, 'imageId', this.data.ticketing.badgeVisual.imageId?._id, 'ticketing.badgeVisual.imageId');

      if (this.filesToCreate[0]) {
        await this.createOrDeleteMedia(formData, 0, 'eventId');
      }
      this.createOrUpdate(formData, res => {
        this.data.ticketing.badgeVisual = formData.ticketing.badgeVisual;
        this.previousText = this.data.ticketing.badgeVisual.text;
      });
    }
  }

  getTicketTypeLabel(ticketType: ITicketType) {
    if (ticketType?.ticketTypeCategoryId) {
      return `${ticketType.ticketTypeCategoryId.name} > ${ticketType.name}`
    }
    return ticketType.name
  }

  public changeImage(file: File): void {

    const image = (this.selectedTicketType) ? this.selectedTicketType?.badgeVisual?.imageId : this.data.ticketing?.badgeVisual?.imageId;

    if (this.imageChanged.imageId.load || !image) {
      this.imageChanged.imageId.changed = true;
    }
    else {
      this.imageChanged.imageId.load = true;
    }
    this.setValue('badgeImage', file);
    if (!file) {
      this.setValue('imageId', null);
      this.errorLoadingImage = false
    }
    this.errorLoadingImage = false
    this.dataPreview.ticketing.badgeVisual.badgeImage = file;
  }

  public async cancel(): Promise<void> {
    this.mainForm.patchValue({ text: this.previousText, image: this.previousImage });
    this.dataPreview.ticketing.badgeVisual.image = this.previousImage;
    this.dataPreview.ticketing.badgeVisual.text = this.previousText;

    this.wysiwyg.reset();
    this.fileInput.reset();
    this.loadingState$.next();
  }
}

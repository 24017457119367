import { EditableEventData } from './editable-data';
import { ISession } from './session.model';
import { ITicketType } from './ticket.model';

export interface IImportOrder extends EditableEventData {
	name?: string;
	date?: string;
	translatedStatus?: string;
	sessionId?: ISession | null;
	status?: ImportStatus;
	fileKey?: string;
	provider?: ImportProvider;
	total?: number;
	processed?: number;
	success?: number;
	errors?: number;
	ticketTypes?: {
		source: string;
		total: number;
		destinationType: DestinationType;
		ticketTypeId?: ITicketType | null;
	}[]
	logs?: string[];
}
export type DestinationType = "NEW" | "TICKET_TYPE";
export type ImportProvider = "FNAC" | "TICKETMASTER" | "CUSTOM";
export type ImportStatus = "NOT_STARTED" | "IN_PROGRESS" | "COMPLETED" | "CANCELED" | "FAILED";

export function translateStatus(value: ImportStatus): string {
	switch (value) {
	  case 'NOT_STARTED': return 'Non démarré';
	  case 'IN_PROGRESS': return 'En cours...';
	  case 'COMPLETED': return 'Terminé';
	  case 'CANCELED': return 'Annulé';
	  case "FAILED" : return "Echoué";
	}
  }
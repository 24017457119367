import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Storage } from '../models/type.definition';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  mainKey = "currentUser"
  organisationId$: Subject<string> = new Subject();

  constructor() { }

  init(token: string): Storage {
    const stringStorage: string = localStorage.getItem("currentUser");
    if(stringStorage){
      const storage: Storage = JSON.parse(stringStorage);
      this.setItem("token", token);
      if(storage.organisationId) {
        this.organisationId$.next(storage.organisationId);
      }
      return storage
    }
    else {
      localStorage.setItem("currentUser", JSON.stringify({ token }));
    }
  }

  getItem<K extends keyof Storage>(key: K): Storage[K] {
    const stringStorage: string = localStorage.getItem("currentUser");
    if(stringStorage){
      const storage: Storage = JSON.parse(stringStorage);
      return storage[key];
    }
  }

  setItem<K extends keyof Storage>(key: K, data: Storage[K]): void {
    let storage: Storage = JSON.parse(localStorage.getItem(this.mainKey));
    storage[key] = data;
    localStorage.setItem('currentUser', JSON.stringify(storage));
    if(key === 'organisationId'){
      this.organisationId$.next(storage[key]);
    }
  }

  removeItem<K extends keyof Storage>(key: K): void {
    let storage: Storage = JSON.parse(localStorage.getItem(this.mainKey));
    delete storage[key];
    localStorage.setItem('currentUser', JSON.stringify(storage));
    if(key === 'organisationId'){
      this.organisationId$.next(null);
    }
  }

  clear(){
    localStorage.clear();
  }
}



import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ListComponent } from 'src/app/elements/list-component';
import { IEvent, translateStatus } from 'src/app/models/event.model';
import { IOrganisation, IPaymentGateway } from 'src/app/models/organisation.model';
import { GetParams, TableOptions } from 'src/app/models/type.definition';
import { EventService, OrganisationService } from 'src/app/providers';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'app-adminEvents',
  templateUrl: './adminEvents.component.html',
  styleUrls: ['./adminEvents.component.scss']
})
export class AdminEventsComponent extends ListComponent<IEvent>  {
  dataName = "Billetterie";
  public filtersForm: FormGroup;
  public noEmail = false;
  public moreFilters: boolean = false;
  public selectedStatus: string = 'published';
  public selectedAccounting: string = null;
  public organisationToEdit: IOrganisation;
  public stripeGateway: IPaymentGateway = null
  public elementToDelete: IEvent;
  public firstDate: Date;
  public dateSelected = [null, null];

  tableOptions: TableOptions[] = [
    {
      title: "Organisation",
      class: "orgaName",
      sort: true,
      key: 'organisationName',
      sortMethod: order => this.sort("name", order),
    },
    {
      title: "Nom",
      class: "name",
      sort: true,
      key: 'name',
      sortMethod: order => this.sort("name", order),
    },
    {
      title: "Date de l'événement",
      class: "date",
      sort: true,
      key: 'date',
      sortMethod: order => this.sort("dates.startDate", order)
    },
    {
      title: "Reddition envoyée",
      class: "accounting",
      sort: true,
      key: 'isAccountingSentTranslated',
      classKey: 'accounting',
      sortMethod: order => this.sort("isAccountingSent", order)
    },
    {
      title: "Statut billetterie",
      class: "status",
      sort: true,
      key: 'translateStatus',
      classKey: 'status',
      sortMethod: order => this.sort("status", order)
    },
    {
      class: "more",
      toggle: [
        {
          title: 'Voir la billetterie',
          method: this.selectEvent
        },
        {
          title: 'Administrer l\'organisation',
          method: this.editOrganisation
        },
        {
          title: 'Contacter l\'organisateur',
          method: this.mailTo
        },
        {
          title: 'Supprimer l\'événement',
          class: "red",
          if: (data: IEvent) => data.status == 'draft',
          method: (data) => this.elementToDelete = data
        }
      ]
    }
  ];

  constructor(
    protected provider: EventService,
    protected dateService: DateService,
    protected organisationService: OrganisationService) {
    super()
  }

  protected async afterInit(): Promise<void> {
    this.initForm();
  }

  protected changePage(page: number) {
    this.page = page;
    this.getData();
  }

  initForm(): void {
    this.filtersForm = this.formBuilder.group({
      search: new FormControl(),
      startDate: new FormControl(),
      endDate: new FormControl(),
      status: new FormControl('published'),
    });
  }

  public setSearch(search: string): void {
    this.filtersForm.patchValue({ search })
  }

  public setSelectedDate(dates: [Date, Date]) {
    console.log(dates);
  }

  setGetParams(): void {
    this.getParams.filter.status = this.selectedStatus;
    this.getParams.filter.isAccountingSent = this.selectedAccounting;
    this.getParams.select = ['organisationId', 'name', 'dates', 'status', 'isAccountingSent'];
    this.getParams.populate = ['organisationId.name', 'organisationId.commission'];
    super.setGetParams();
  }

  formatElement(event: IEvent): IEvent {
    event.organisationName = event.organisationId?.name;
    event.translateStatus = translateStatus(event.status);
    event.isAccountingSentTranslated = (event.isAccountingSent) ? 'Oui' : 'Non';
    if (event.dates.type === 'sessions') event.date = 'Session';
    else event.date = this.dateService.formatDate(event.dates.startDate);
    if (event.organisation) {
      event.organisation.commission.flat *= 100;
    }
    return event;
  }

  selectEvent(event: IEvent, ref = this) {
    ref.provider.selectEvent(event);
    ref.router.navigate(['evenements/' + event._id + '/general'])
  }

  mailTo(event: IEvent, ref = this) {
    const email = event.organisation?.contactInfo.email;
    if (email) {
      window.location.href = `mailto:${email}`;
    }
    else {
      ref.noEmail = true;
    }
  }

  async editOrganisation(event: IEvent, ref = this): Promise<void> {
    const getParams: GetParams<IOrganisation> = {
      select: ['commission', 'name', 'paymentGateways'],
      populate: ['commission']
    }
    const organisation = await ref.organisationService.getById(event.organisationId._id, getParams).toPromise();
    ref.organisationToEdit = organisation;
    ref.organisationToEdit._id = event.organisationId._id;
    ref.stripeGateway = organisation.paymentGateways.find(gateway => gateway.type === "stripe")
  }

  editOrganisationOk(values: [[string], [number, number]]): void {
    const updatedObject: { commission?: { flat: number, percentage: number }, paymentGateways?: any[] } = {};
    updatedObject.commission = { flat: values[1][1] * 100, percentage: values[1][0] };


    if (values[0][0]) {
      const gateways = this.organisationToEdit.paymentGateways
      let stripegateway = gateways.find(gateway => gateway.type === "stripe")
      const gatewayData = {
        accountId: values[0][0],
        chargesEnabled: true,
        detailsSubmitted: true,
        metadata: null
      }
      if (stripegateway) {
        stripegateway = { ...stripegateway, ...gatewayData }
      }
      else {
        gateways.push({ type: "stripe", ...gatewayData })
      }
      updatedObject.paymentGateways = gateways
    }

    this.subscribeTo(this.organisationService.update(this.organisationToEdit._id, updatedObject), res => {
      this.data.filter(_ => _.organisationId._id == this.organisationToEdit._id).forEach(_ => {
        _.organisationId.commission = {
          flat: updatedObject.commission.flat / 100,
          percentage: updatedObject.commission.percentage
        }
      })
      this.organisationToEdit = null;
      this.stripeGateway = null;
      this.notificationService.newNotification({ message: "Organisation mise à jour avec succès", state: "success" });
    })

  }

  public filter(): void {
    this.page = 1;
    this.addFilterOnNextRequest = true;
    this.setGetParams()
    this.getData();
  }

  deleteOk() {
    this.deleteData(this.elementToDelete, this, () => this.elementToDelete = null);
  }

}

import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { AuthService, CommonService, EventService, OrganisationService, UserService } from './providers';
import { Subscription } from 'rxjs';
import { StorageService } from './services/storage.service';
import { ClickOutService } from './services/click-out.service';
import { NavigationEnd, Router } from '@angular/router';
import { SocketService } from './services/socket.service';
import { filter } from 'rxjs/operators';
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private _subscription = new Subscription();
  public isAuth: boolean;
  public isAdmin: boolean;
  public showSidebar: boolean = false;
  public organisationId: string;
  public eventSubscription: Subscription;
  public adminInOrganization: boolean;

  constructor(
    private commonService: CommonService,
    private authService: AuthService,
    private storageService: StorageService,
    private clickOutService: ClickOutService,
    private organisationService: OrganisationService,
    private eventService: EventService,
    private userService: UserService,
    private router: Router,
    private socket: SocketService
  ) {
  }

  healthCheck() {


    this._subscription.add(this.commonService.healthCheck().subscribe(async _ => {
      if (_.status === 'error') {
        const currentRoute = this.router.url.split('?')[0];
        if (currentRoute !== '/maintenance') {
          this.router.navigate(['maintenance'], { queryParams: { from: this.router.url } });
        }
        return
      }

    }, async (err) => {
      const currentRoute = this.router.url.split('?')[0];
      if (currentRoute !== '/maintenance') {
        this.router.navigate(['maintenance'], { queryParams: { from: this.router.url } });
      }
      return
    }))

  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.healthCheck();
    this.setUpAnalytics();
    this._subscription.add(this.organisationService.adminConnected$.subscribe(value => {
      this.adminInOrganization = value;
    }))
    this._subscription.add(this.storageService.organisationId$.subscribe((value: string) => {
      this.organisationId = value;
    }))
    this._subscription.add(this.authService.isAuth$.subscribe(async (value: boolean) => {
      this.isAuth = value;
      this.isAdmin = this.authService.isAdmin;
      // if (this.isAdmin) {
      //   const organisationId = this.storageService.getItem("organisationId");
      //   this.adminInOrganization = !!this.storageService.getItem('adminInOrganization')
      //   if (organisationId && this.adminInOrganization) {
      //     this.organisationService.getById(organisationId).toPromise()
      //       .then(() => this.organisationService.adminConnected$.next(true))
      //       .catch(() => this.storageService.removeItem('organisationId'))
      //   }
      // }
      this.eventSubscription = this.setEventSubscription();
      if (!value) {
        this.organisationId = null;
      }
    }));
  }

  /**
   * Admin Token Manager - If user is admin, subscribe to `eventService.selected$`
   * When a new event is selected, renew the token with the organisationId of this event
   * When no event is selected, replace the organisation token with a simple token
   * @returns
   */
  private setEventSubscription(): Subscription | null {
    if (this.isAdmin) {
      this.authService.adminSimpleToken = this.storageService.getItem('token');
      return this.eventService.selected$.subscribe(async event => {
        if (event && !this.adminInOrganization) {
          this.storageService.setItem('token', this.authService.adminSimpleToken);
          const organisationToken = await this.authService.authOrganisation(event.organisationId._id).toPromise();
          organisationToken.organisationId = event.organisationId._id;
          this.organisationService.storeToken(organisationToken)
        }
        else if(!this.adminInOrganization){
          this.clearOrganisationToken();
        }
      })
    }
    else {
      this.eventSubscription?.unsubscribe();
      return null
    }
  }

  /**
   * If exists, remove `organisationId` from the localStorage and replace the organisation token with the simple token
   */
  private clearOrganisationToken(): void {
    if (this.storageService.getItem('organisationId')) {
      this.storageService.removeItem('organisationId');
      // this.storageService.removeItem('adminInOrganization');
      this.storageService.setItem('token', this.authService.adminSimpleToken);
    }
  }

  toggleSidebar(): void {
    this.showSidebar = !this.showSidebar;
  }

  clickEvent() {
    this.clickOutService.closeAll();
  }

  logOut(): void {
    this.authService.logOut();
    this.userService.me$.next(null);
    this.organisationService.clear();
    this.router.navigate(['']);
  }

  /** Every time the window is closed or refresh and the user is admin, call the `clearOrganisationToken` method */
  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(): void {
    if (this.isAdmin) {
      // this.storageService.setItem('token', this.authService.adminSimpleToken);
      this.clearOrganisationToken();
    }
  }

  setUpAnalytics() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        gtag('config', 'G-WBRSV7LS2D',
          {
            page_path: event.urlAfterRedirects
          }
        );
      });
  }

}

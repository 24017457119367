import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormFromEventComponent } from 'src/app/elements/form-component';
import { IEvent } from 'src/app/models/event.model';
import { ITicketType } from 'src/app/models/ticket.model';
import { GetParams } from 'src/app/models/type.definition';
import { AuthService, EventSessionService, ImportOrderService, TicketTypeService } from 'src/app/providers';
import { ISession } from 'src/app/models/session.model';
import { GetEvent } from '../../../getEvent';
import { IImportOrder } from 'src/app/models/import-order';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-event-orders-import-resume',
  templateUrl: './event-orders-import-resume.component.html',
  styleUrls: ['./event-orders-import-resume.component.scss'],
  providers: [GetEvent]
})
export class EventOrdersImportResumeComponent extends FormFromEventComponent<IImportOrder> implements OnInit {

  public ticketTypesOptions: ITicketType[] = [];
  public event: IEvent;
  public dataName = 'Invitation'
  public displayEmail: boolean = false;
  public isAdmin: boolean;
  public ticketTypes: {name: string, tickets: ITicketType[]}[];
  public eventSessions: null | ISession[] = null;
  public sendEmail = false;
  public addPlacement = false
  public stock: number;
  public qtyOk: boolean = true;
  public spreadsheetOk: boolean = false;
  public table = null;
  public currentFile: File | null = null
  protected getEventParams: GetParams<IEvent> = {select: ['status', 'ticketing']};

  constructor(
    protected formProvider: ImportOrderService,
    protected getEvent: GetEvent,
    protected ticketTypeService: TicketTypeService,
    protected eventSessionService: EventSessionService,
    protected route: ActivatedRoute,
    private authService: AuthService,
    private notification: NotificationService,
  ) {
    super()
  }

  async ngOnInit(): Promise<void> {
    this.isAdmin = this.authService.isAdmin;
    await super.ngOnInit();
  }

  get tickets(): FormArray {
    return this.mainForm.get('ticketTypes') as FormArray;
  }

  async onInit(): Promise<void> {
    await this.getDataToEdit('importId');
    const result = await this.ticketTypeService.getList({
      filter: { eventId: this.event._id, importProvider: this.data.provider },
      limit: 500
    }).toPromise();
    if (result) {
      this.ticketTypesOptions = result.data;
    }
  }

  initForm(): void {

    this.mainForm = this.formBuilder.group({
      ticketTypes: new FormArray([]),
    });

    this.data.ticketTypes.forEach(ticketType => {
      const ticketTypeForm = this.formBuilder.group({
        source: new FormControl(ticketType.source),
        destination: new FormControl(null),
      });
      this.tickets.push(ticketTypeForm)
    })

    console.log("==V =>", this.mainForm.value);

  }

  public setEmailContentValue(emailContent: string): void {
    this.mainForm.patchValue({ emailContent })
  }

  async submitForm(): Promise<void> {
    const form = this.mainForm.value;
    this.formProvider.start(this.id, form).subscribe(result => {
      this.loadingState$.next();
      if (result.status === 'error') {
        this.notification.newNotification({
          message: result.message,
          state: 'error'
        });
        return;
      }
      this.notification.newNotification({
        message: `Import créé avec succès`,
        state: 'success'
      });
      this.goBack();
    }, err => {
      this.loadingState$.next();
      this.notification.newNotification({
        message: err.error.message,
        state: 'error'
      });
    })
  }

  public changeFile(file: File): void {
    this.currentFile = file
    this.mainForm.patchValue({ file });
  }

  goBack(): void {
    this.router.navigate([`evenements/${this.event._id}/commandes/import`]);
  }

  toggleEmail(): void {
    this.displayEmail = !this.displayEmail;
  }

}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/providers';
import { StorageService } from 'src/app/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
    private storageService: StorageService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const isAuth = this.authService.isAuth$.value;
    const organisationId = this.storageService.getItem("organisationId");
    const routeIsOrga = route.routeConfig.path.includes('organisations');

    if (!isAuth) {
      this.router.navigate(['redirect', state.url.split('?')[0]], { queryParams: route.queryParams });
      return false
    }
    else if (!routeIsOrga && !organisationId) {
      if (!this.authService.isAdmin) {
        this.router.navigate(['organisations']);
        return false
      }
    }

    return true;
  }
}

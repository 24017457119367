import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { IEvent } from 'src/app/models/event.model';
import { ITicketType, ITicketTypeCategory } from 'src/app/models/ticket.model';
import { ITax } from 'src/app/models/tax.model';
import { GetParams, FormOf } from 'src/app/models/type.definition';
import {
  AuthService,
  EventService,
  OrganisationService,
  TaxService,
  TicketTypeCategoryService,
  TicketTypeService,
} from 'src/app/providers';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UpdateService } from 'src/app/services/update.service';
import { GetEvent } from '../../../getEvent';
import { IOrganisation } from 'src/app/models/organisation.model';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-event-ticketing-create-rate',
  templateUrl: './event-ticketing-create-rate.component.html',
  styleUrls: ['./event-ticketing-create-rate.component.scss'],
  providers: [GetEvent],
})
export class EventTicketingCreateRateComponent implements OnInit {
  private _subscription = new Subscription();
  public event: IEvent;
  public flatCommission: number;
  public error: string;
  public limitStock: boolean;
  public freeTicket = false;
  public stripeInvalid: boolean;
  public organisation: IOrganisation;
  public loadingState$: Subject<void> = new Subject<void>();
  public rateForm: FormGroup;
  public currentUrl: string;
  public priceOk: boolean;
  public price: { user?: number; commission?: number } = {};
  public taxes: ITax[];
  public ticketType: ITicketType;
  public ticketTypeCategories: ITicketTypeCategory[];
  public isAdmin: boolean;
  public defaultTax: string;
  public isNft: boolean;

  constructor(
    private errorHandler: ErrorHandlerService,
    private getEvent: GetEvent,
    private update: UpdateService,
    private authService: AuthService,
    private eventService: EventService,
    private formBuilder: FormBuilder,
    private rateService: TicketTypeService,
    private categoryService: TicketTypeCategoryService,
    private storageService: StorageService,
    private taxService: TaxService,
    private notification: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private organisationService: OrganisationService
  ) {}

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  async ngOnInit(): Promise<void> {
    this.currentUrl = this.router.url;
    this.isAdmin = this.authService.isAdmin;
    this.event = await this.getEvent.get({
      select: [
        'commission',
        'commissionIncreased',
        'ticketing',
        'useRoomPlacement',
      ],
    });
    this.defaultTax = this.event.ticketing.taxId._id;
    this.flatCommission = this.event.commission.flat / 100;
    const id = this.route.snapshot.paramMap.get('ticketId');
    if (id) {
      const getParams: GetParams<ITicketType> = {};
      this.ticketType = await this.rateService
        .getById(id, getParams)
        .toPromise();
      if (this.ticketType) {
        this.ticketType.price =
          this.ticketType.price < 0 ? 0 : this.ticketType.price / 100;
      }
      this.freeTicket = this.ticketType?.price == 0;
      this.changePrice(this.ticketType?.price + '');
      this.limitStock = !!this.ticketType?.stocks.quota;
    }
    const body = {
      filter: {
        eventId: this.event._id,
      },
    };
    if (this.event && this.event.bookType == "nft") {
      this.isNft = true;
    } else {
      this.isNft = false;
    }
    this.organisation = this.organisationService.selected$.value;
    if (!this.organisation && this.isAdmin) {
      const id = this.storageService.getItem('organisationId');
      this.organisation = await this.organisationService
        .getById(id)
        .toPromise();
    }

    this.stripeInvalid = true

    if (this.organisation.paymentGateways && this.organisation.paymentGateways.length) {
      const hasOneValidStripeAccount = this.organisation.paymentGateways.some(gateway => {
        return gateway.chargesEnabled && gateway.detailsSubmitted && gateway.type === "stripe"
      })
      this.stripeInvalid = !hasOneValidStripeAccount;
    }

    this.ticketTypeCategories = await this.getList(
      this.categoryService.getList(body)
    );
    if (this.ticketTypeCategories.length) {
      this.ticketTypeCategories.unshift({ name: 'Aucune', _id: null });
    }
    this.taxes = await this.getList(this.taxService.getList());
    this.initForm();
  }

  async getList<T>(subject: Observable<{ data: T[] }>): Promise<T[]> {
    return (await subject.toPromise())?.data || [];
  }

  initForm() {
    const start = this.ticketType?.scheduled?.startDate;
    const end = this.ticketType?.scheduled?.endDate;
    const controls: FormOf<ITicketType> = {
      name: new FormControl(this.getValue('name'), Validators.required),
      price: new FormControl(this.getValue('price')),
      taxId: new FormControl({
        value: this.getEntityValue('taxId', this.defaultTax),
        disabled: !!this.ticketType,
      }),
      scheduled: new FormGroup({
        startDate: new FormControl(start ? new Date(start) : new Date()),
        endDate: new FormControl(end ? new Date(end) : null),
      }),
      ticketTypeCategoryId: new FormControl(
        this.getEntityValue('ticketTypeCategoryId', this.ticketTypeCategories[0]?._id)
      ),
      tickets: new FormGroup({
        minimumSelectable: new FormControl(
          this.getValue('tickets')?.minimumSelectable
        ),
        maximumSelectable: new FormControl(
          this.getValue('tickets')?.maximumSelectable
        ),
      }),

      security: new FormGroup({
        isResalable: new FormControl(this.ticketType ? this.getValue("security")?.isResalable : true),
        priceHasLimit: new FormControl(this.getValue("security")?.priceHasLimit || false),
        maxPricePercent: new FormControl(this.getValue("security")?.maxPricePercent),
        minPricePercent: new FormControl(this.getValue("security")?.minPricePercent),
      }),
      stocks: new FormGroup({
        quota: new FormControl(this.getValue('stocks')?.quota),
      }),
      quota: new FormControl(this.getValue("quota")),
      isVisible: new FormControl(this.getValue('isVisible', true)),
      showCountDown: new FormControl(this.getValue('showCountDown', false)),
      showRemainingPlace: new FormControl(this.getValue('showRemainingPlace', false)),
      inTicketOffice: new FormControl(this.getValue('inTicketOffice', false)),
      description: new FormControl(this.getValue('description')),
      // addons: new FormArray([])
    };
    this.rateForm = this.formBuilder.group(controls);
    // if (this.ticketType?.addons?.length) {
    //   this.addAddons()
    // }
  }

  // createAddon(addon: IAddon = null) {
  //   return this.formBuilder.group({
  //     name: new FormControl(addon?.name || '', Validators.required),
  //     price: new FormControl(addon?.price || null, Validators.required),
  //     taxId: new FormControl(addon?.taxId || null, Validators.required)
  //   })
  // }

  // addAddons() {
  //   for (const addon of this.ticketType.addons) {
  //     this.addAddon(addon);
  //   }
  // }

  // addAddon(addon = null) {
  //   const addons = this.rateForm.get('addons') as FormArray;
  //   addons.push(this.createAddon(addon));
  // }

  // removeAddon(i: number) {
  //   const addons = this.rateForm.get('addons') as FormArray;
  //   addons.removeAt(i);
  // }

  getValue<K extends keyof ITicketType>(
    key: K,
    defaultValue: ITicketType[K] = null
  ): ITicketType[K] {
    return this.checkIfSet(key) ? this.ticketType[key] : defaultValue;
  }

  getEntityValue<K extends keyof ITicketType>(
    key: K,
    defaultValue: null | string = null
  ): string {
    return this.checkIfSet(key) ? this.ticketType[key]["_id"] : defaultValue;
  }

  checkIfSet(key: keyof ITicketType): boolean {
    return this.ticketType && ![null, undefined].includes(this.ticketType[key]);
  }

  createRate() {
    const formData: ITicketType = {
      eventId: this.event._id,
      ...this.rateForm.value,
    };

    if ((formData.ticketTypeCategoryId as any) === "null") {
      formData.ticketTypeCategoryId = null
    }

    if (this.freeTicket) {
      formData.price = 0;
      delete formData.taxId;
    } else {
      formData.price = Math.round(formData.price * 100);
    }
    if (this.ticketType) {
      this.rateService.update(this.ticketType._id, formData).subscribe(
        (result) => {
          this.onSuccess('éditée');
        },
        (err) => this.onError(err)
      );
    } else {
      this.rateService.create(formData).subscribe(
        (result) => {
          this.onSuccess('créé');
        },
        (err) => this.onError(err)
      );
    }
  }

  getDefaultTax(): string {
    return this.taxes[0]?._id;
  }

  getIndexes(): [number, number] | void {
    for (let i = 0; i < (this.event.ticketTypeCategories || []).length; i++) {
      if (this.event.ticketTypeCategories[i].ticketTypes)
        for (
          let j = 0;
          j < (this.event.ticketTypeCategories[i].ticketTypes || []).length;
          j++
        ) {
          if (
            this.event.ticketTypeCategories[i].ticketTypes[j]._id ==
            this.ticketType._id
          ) {
            return [i, j];
          }
        }
    }
  }

  changeDates(dates: { startDate: Date; endDate: Date }): void {
    this.rateForm.patchValue({ scheduled: dates });
  }

  changeDescriptionValue(description: string): void {
    this.rateForm.patchValue({ description });
  }

  onSuccess(type: 'créé' | 'éditée'): void {
    delete this.event.ticketTypeCategories;
    this.loadingState$.next();
    this.notification.newNotification({
      message: `Ticket ${type} avec succès`,
      state: 'success',
    });
    this.eventService.selectEvent(this.event);
    this.goBack();
  }

  onError(err: any): void {
    this.error = this.errorHandler.getError(err);
    this.loadingState$.next();
  }

  goBack(): void {
    this.router.navigate([`evenements/${this.event._id}/billetterie/ticket/`]);
  }

  changeFreeTicket(): void {
    this.freeTicket = !this.freeTicket;
    this.changePrice(this.rateForm.value.price);
  }

  setPrice(price: number): void {
    this.price.commission = (this.event.commission.percentage * price) / 100;
    this.price.user =
      price -
      (this.event.commissionIncreased
        ? 0
        : this.price.commission + this.flatCommission);
  }

  changePrice(value: string): void {
    const price = parseFloat(value);
    this.setPrice(price);
    this.priceOk = !isNaN(price) || this.freeTicket;
    if (this.stripeInvalid && this.priceOk && !this.freeTicket && price) {
      this.priceOk = false;
    }
    // Can't sell nft ticket at 0 €
    if (this.event.bookType == 'nft' && price < 5) {
      this.priceOk = false;
    }
  }
}

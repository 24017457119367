import { injector } from '../../etc/injector';
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Observable, Subject, Subscription } from 'rxjs';
import { NotificationService } from '../../services/notification.service';
import { GrowthService } from 'src/app/services/growth.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { APICreateResponse, APIError, APIListResponse, APIResponse, DateInterval, DefaultGetParams, FormOf, GetParams, ImageChangeState, ImagesChangeState } from 'src/app/models/type.definition';
import { Input, OnDestroy, OnInit } from '@angular/core';
import { Provider } from 'src/app/providers/provider';
import { EmptyAPIList } from 'src/app/etc/const';
import { HTTPTools } from 'src/app/etc/http-tools';
import { AbstractComponent } from 'src/app/etc/abstract-component';
import { first } from 'rxjs/operators';
import { EditableData, EditableEventData } from 'src/app/models/editable-data';
import { ActivatedRoute, Router } from '@angular/router';
import { GetEvent } from 'src/app/pages/events/getEvent';
import { IEvent } from 'src/app/models/event.model';
import { FilesHandler } from 'src/app/services/file-handler.service';
import { MediaService, OrganisationService, UserService } from 'src/app/providers';
import { FilesToCreate, FileToCreate, IMedia, MediaType } from 'src/app/models/media.model';
import User from 'src/app/models/user.model';


const DefaultImageChangedState: ImageChangeState = {
  load: false,
  changed: false
}

/**
 * @abstract Component that provide properties and methods for the reactive form logic for an `EditableData`
 */
@AbstractComponent()
export abstract class FormComponent<T extends EditableData> implements OnInit, OnDestroy {
  protected abstract dataName: string;
  protected abstract formProvider: Provider<T>;
  protected filesHandler: FilesHandler;
  protected subscription = new Subscription();
  protected dates?: DateInterval;
  @Input() protected id?: string;
  protected waitUser = false;
  public imageChanged: ImagesChangeState
  protected user: User;
  protected filesToCreate: FilesToCreate = [];
  public loadingImage: string[] = [];

  /** List of dependencies to inject */
  protected formBuilder: FormBuilder;
  protected notificationService: NotificationService;
  protected growthService: GrowthService;
  protected errorHandler: ErrorHandlerService;
  protected router: Router;
  protected activatedRoutes: ActivatedRoute;
  protected mediaService: MediaService;
  protected userService: UserService;


  public data?: T;
  public loadingState$ = new Subject<void>();
  public displayToolTipError: boolean;
  public loaded: boolean = false;
  public mainForm: FormGroup;
  public error: string;

  constructor() {
    this.formBuilder = injector.get(FormBuilder);
    this.notificationService = injector.get(NotificationService);
    this.growthService = injector.get(GrowthService);
    this.errorHandler = injector.get(ErrorHandlerService);
    this.activatedRoutes = injector.get(ActivatedRoute);
    this.filesHandler = injector.get(FilesHandler);
    this.mediaService = injector.get(MediaService);
    this.userService = injector.get(UserService);
    this.router = injector.get(Router);
  }

  /**
   * Unsubscribe to every Observable
   */
  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Execute the `onInit` of the child component and init the form
   * `onInit` may be asynchronous, so is `ngOnInit`
   */
  public async ngOnInit(): Promise<void> {
    if (this.waitUser) {
      this.user = await new Promise(res => this.subscribeTo(this.userService.me$, user => {
        if (user) {
          res(user);
        }
      }));
    }
    await this.onInit();
    this.initForm();
  }

  /**
   * @abstract Called in the `ngOnInit` method.
   * Must be implemented in the child component
   */
  protected abstract onInit(): void | Promise<void>;

  /**
   * @abstract Create the reactive form and set it to the property `mainForm`
   * Must be implemented in the child component
   */
  protected abstract initForm(): void;

  /**
   * @abstract Must be called when the `mainForm` is submitted.
   * The method must called the method `createOrUpdate`
   * Must be implemented in the child component
   */
  public abstract submitForm(): void;

  /**
   * Reset the form, using the callback queue to refresh the template
   */
  protected resetForm(): void {
    this.mainForm = null;
    setTimeout(() => this.initForm());
  }

  /**
   * Create a `FormGroup` based on a object of type `FormOf<Type>`.
   *
   * Default type is `T`, but it changes when the recursive building is used
   *
   * @param form A form containing only key of type `F`
   */
  protected createFormGroup<F = T>(form: FormOf<F>): FormGroup {
    for (const key in form) {
      if (form[key] !== null && typeof form[key] === 'object' && !Array.isArray(form[key])) {
        if (!(form[key] instanceof FormControl) && !(form[key] instanceof FormArray)) {
          form[key] = this.createFormGroup({ ...form[key] } as FormOf<F[keyof F]>);
        }
      }
      else if (Array.isArray(form[key])) {
        form[key] = new FormControl(form[key]);
      }
      else {
        form[key] = new FormControl(form[key]);
      }
    }
    return this.formBuilder.group(form);
  }

  /**
   * Set the data `T` to edit if `getId` return an id
   *
   * @param idKey The key of the query params containing the id
   * @param getParams
   * @returns The data `T`
   */
  protected async getDataToEdit(idKey?: string, getParams?: GetParams<T>): Promise<void> {
    this.id = this.getId(idKey);
    if (this.id) {
      await this.getDataToEditWithId(this.id, getParams);
    }
  }

  /**
   * Set the data `T` to edit with the id
   *
   * @param id
   * @param getParams
   * @returns The data `T`
   */
  protected async getDataToEditWithId(id: string, getParams?: GetParams<T>): Promise<void> {
    this.data = await this.formProvider.getById(id, getParams).toPromise();
  }


  /**
   * Get the id in the params of current route if it exists
   * @returns
   */
  private getId(idKey?: string): string | undefined {
    return this.id || this.activatedRoutes.snapshot.children[0].params[idKey];
  }

  /**
   * Call the method `getList` of a `Provider` and return the response of the API
   * @param provider The reference to the `Provider`
   */
  protected async getListOf<D>(provider: Provider<D>): Promise<APIListResponse<D>>;
  /**
   * Call the method `getList` of a `Provider` and return the wanted key of the response of the API
   * @param provider The reference to the `Provider`
   * @param key The key of the `APIListResponse` object returning by the API
   */
  protected async getListOf<D, K extends keyof APIListResponse<D>>(provider: Provider<D>, key: K): Promise<APIListResponse<D>[K]>;

  /**
 * Call the method `getList` of a `Provider` and return the wanted key of the response of the API
 * @param provider The reference to the `Provider`
 * @param key The key of the `APIListResponse` object returning by the API
 * @param getParams The options of the request
 */
  protected async getListOf<D, K extends keyof APIListResponse<D>>(provider: Provider<D>, key: K, getParams: GetParams<D>): Promise<APIListResponse<D>[K]>;
  protected async getListOf<D, K extends keyof APIListResponse<D>>(
    provider: Provider<D>,
    key?: K,
    getParams: GetParams<D> = {}
  ): Promise<APIListResponse<D>[K] | APIListResponse<D>> {
    const list = (await provider.getList(getParams).toPromise()) || EmptyAPIList;
    return key ? list[key] : list;
  }

  /**
   * Set the value of the `FormControl` with the key `key` inside `mainForm`
   * @param key Key of the `FormControl`
   * @param value The value to set
   */
  public setValue(key: string | string[], value: any): void {
    if (Array.isArray(key)) {
      this.getNestedForm(key).patchValue({ [key[key.length - 1]]: value });
    }
    else {
      this.mainForm.patchValue({ [key]: value });
    }
  }

  /**
   * Navigate through the nested `FormGroup` in `mainForm` corresponding to the keys and return the last one
   *
   * @param keys
   * @returns
   */
  protected getNestedForm(keys: string[]): FormGroup {
    let formGroup = this.mainForm;
    for (let i = 0; i < keys.length; i++) {
      if (i + 1 == keys.length) {
        break;
      }
      else {
        formGroup = formGroup.get(keys[i]) as FormGroup;
      }
    }
    return formGroup;
  }

  /**
   * Call the `create` or `update` method of the provider, subscribe to it and call the callback depending on the result
   *
   * @param form
   * @param successCallback
   * @param errorCallback
   */
  protected async createOrUpdate(
    form: T | FormData,
    successCallback?: (response: APIResponse) => void | Promise<void>,
    errorCallback?: (err?: APIError) => void | Promise<void>,
    back: boolean = true,
    passSuccess = false
  ): Promise<void> {
    let observable = this.data ? this.update(form) : this.create(form);
    this.subscribeToRequest(observable, successCallback, errorCallback, back, passSuccess);
  }

  /**
   * Return the observable for the `update` method of the provider
   * @param form The form to send with the request
   * @returns
   */
  protected update(form: T | FormData): Observable<APIResponse> {
    return this.formProvider.update(this.data._id, form);
  }

  /**
   * Return the observable for the `create` method of the provider
   * @param form The form to send with the request
   * @returns
   */
  protected create(form: T | FormData): Observable<APIResponse> {
    return this.formProvider.create(form);
  }

  /**
   * Subscribe to the request made by the observable
   *
   * @param observable
   * @param successCallback
   * @param errorCallback
   * @param back
   */
  protected subscribeToRequest(
    observable: Observable<APIResponse>,
    successCallback?: (response: APIResponse) => void | Promise<void>,
    errorCallback?: (err?: APIError) => void | Promise<void>,
    back: boolean = true,
    passSuccess = false
  ): void {
    this.subscribeTo(observable, async (response: APIResponse) => {
      successCallback && (await successCallback(response));
      if (!passSuccess) {
        const word = this.data ? 'édité' : 'crée';
        this.notificationService.newNotification({ message: `${this.dataName} ${word} avec succès`, state: 'success' });
        this.loadingState$.next();
      }
      back && this.goBack();
    }, err => { this._onError(err); errorCallback && errorCallback(err) });
  }


  /**
   * Subscribe to an observable and executes the callbacks
   *
   * @param observable The observable to subscribe to
   * @param callback Called each time the observable emit a value
   * @param onError Called if an error is catch
   * @param ephemeral [default = false] If true, the observable will be marked with the pipe `first`, else it will be added to the `Subscription`
   */
  public subscribeTo<T>(
    observable: Observable<T>,
    callback: (value: T) => void | Promise<void>,
    onError?: (error?: APIError) => void,
    ephemeral: boolean = false): void {
    if (ephemeral) {
      observable.pipe(first()).subscribe(callback, onError);
    }
    else {
      this.subscription.add(observable.subscribe(callback, onError || this._onError));
    }
  }

  /**
   * Default error handler. Get the message from the `APIError` and display it with `NotificationService.newNotification`
   * @param err
   */
  private _onError = (err: APIError) => {
    this.notificationService.newNotification({ message: this.errorHandler.getError(err), state: 'error' });
    this.loadingState$.next();
  }

  /**
 * Method to call when the emitter `newDate` emit from a `DateIntervalComponent`
 * Update the current `mainForm` and store the new date
 *
 * @param date The date in string format
 * @param key The key of the date
 */
  public changeDate(date: Date, key: string) {
    this.mainForm.patchValue({ [key]: date });
  }

  /**
   * Method to call when the emitter `newDates` emit from a `DateIntervalComponent`
   * Update the current `mainForm` and store the new dates
   *
   * @param dates The dates in string format
   * @param key The key of the object containing the dates
   * @param begin [default = false] If `true`, the key `startDate` will be renamed `beginDate`
   */
  public changeDates(dates: DateInterval, key: string | string[], begin: boolean = false) {
    if (begin) {
      dates.beginDate = dates.startDate;
    }
    let form: FormGroup;
    if (Array.isArray(key)) {
      form = this.getNestedForm(key)
    }
    else {
      form = this.mainForm.get(key) as FormGroup;
    }
    this.dates = dates;
    form.patchValue(dates);
  }

  /**
   * Return a `FileToCreate` object with the content of the file, its destination key and its id if it exists
   * @param file
   * @param destination
   * @param id
   * @returns
   */
  protected getFileToCreate(file: File, destination: string, id?: string, fullDestination?: string): void {
    if (file && (!this.imageChanged || this.imageChanged[destination].changed)) {
      this.filesToCreate.push({
        file,
        _id: id,
        destination: fullDestination || destination
      })
    }
    else if (this.imageChanged && this.imageChanged[destination].changed) {
      this.filesToCreate.push({
        file: null,
        _id: id,
        destination
      })
      this.mainForm.value[destination] = null;
    }
    console.log('mainForm => ', this.mainForm.value[destination]);
  }

  protected createImagesChangeState(keys: string[]): void {
    this.imageChanged = {};
    for (const key of keys) {
      this.imageChanged[key] = { ...DefaultImageChangedState };
    }
  }

  /**
   * Create the media before creating or updating a document
   * If something went wrong, reset all the media previously created
   * @param form
   * @param type
   * @param keysToCreate
   * @param successCallback
   * @param errorCallback
   * @param back
   */
  protected async createMediaAndUpdate(
    form: any,
    type: MediaType,
    keysToCreate: (keyof T)[] = [],
    successCallback?: (response: APIResponse) => void | Promise<void>,
    errorCallback?: (err?: APIError) => void | Promise<void>,
    back: boolean = true,
    passSuccess = false
  ): Promise<void> {
    let created = false;
    if (this.filesToCreate.length) {
      if (!this.data) {
        const id: string = await new Promise((res) => {
          const data = {} as T;
          for (const key in keysToCreate) {
            data[key] = form[key];
          }
          this.subscribeTo(this.create(form), (response: APICreateResponse) => res(response._id));
        });
        created = true;
        this.data = { ...form, id };
      }
      for (let i in this.filesToCreate) {
        await this.createOrDeleteMedia(form, parseInt(i), type, created);
      }
      this.subscribeToRequest(this.update(form), async res => {
        successCallback && (await successCallback(res));
      }, async err => {
        await this.resetMedia(this.filesToCreate.length - 1);
        errorCallback && (await errorCallback(err));
      }, back, passSuccess);
    }
    else {
      console.log("====>>", form);
      this.createOrUpdate(form, successCallback, errorCallback, back);
    }
  }

  /**
   * If file exist, create it to replace the current media of the document
   * If not, delete the current media existing in the document
   *
   * @param form
   * @param files
   * @param i
   * @param type
   */
  protected async createOrDeleteMedia(form: any, i: number, type: MediaType, created = false, metadata = {}): Promise<void> {
    const files = this.filesToCreate;

    if (files[i].file) {
      files[i]._id = await this.createMedia(files[i].file, type, files[i]._id, metadata);


      if (!files[i]._id) {
        await this.resetMedia(i - 1);
        if (created) {
          await this.formProvider.delete(this.data._id).toPromise();
        }
        throw Error('Erreur lors de l\'upload');
      }
      else {
        this.addToForm(form, files[i]);
      }
    }
    else {
      if (files[i]._id) {
        await this.deleteMedia(files[i]._id)
      }
    }
  }

  private addToForm(form: any, file: FileToCreate): void {
    this.getNestedAttribut(form, file.destination.split('.'), file._id, file.array);
  }

  private getNestedAttribut(form: any, keys: string[], value: any, array: boolean) {
    let attribut = form;
    for (let i = 0; i < keys.length - 1; i++) {
      attribut = attribut[keys[i]];
    }
    if (array) {
      attribut[keys[keys.length - 1]].push(value);
    }
    else {
      attribut[keys[keys.length - 1]] = value;
    }
  }

  private async resetMedia(i: number): Promise<void> {
    for (i; i > 0; i--) {
      await this.deleteMedia(this.filesToCreate[i]._id);
    }
    this.loadingState$.next();
  }

  public async deleteMedia(id: string): Promise<void> {
    await this.mediaService.delete(id).toPromise();
  }

  protected async createMedia(
    file: File,
    type: MediaType,
    id: string = null,
    metadata = {}
  ): Promise<string> {
    console.log('file => ', file.name, metadata, this.data);

    const media: IMedia = {};
    /** @ts-ignore - File may have an authorName if the image came from unsplash */
    media.authorName = file.authorName
    media.size = `${file.size}`;
    media.name = file.name;
    media._id = id;
    media.mimetype = file.type;
    media.type = type;
    media.typeId = this?.data?._id;
    media.upl = file;
    media.private = false;
    if (metadata['name']) {
      media.name = metadata['name'];
    }
    if (metadata['category']) {
      media.category = metadata['category'];
    }
    if (metadata['ticketTypeIds']) {
      media.ticketTypeIds = metadata['ticketTypeIds'];
    }
    if (metadata['typeId']) {
      media.typeId = metadata['typeId'];
    }
    if (!id && this.formProvider instanceof OrganisationService) {
      media.organisationId = this.data;
    }
    console.log('media => ', media);

    const form = this.filesHandler.createFormData(media);
    console.log("Form", form);

    try {
      const result = await this.mediaService.upload(form).toPromise();
      return result._id || id;
    }
    catch (err) {
      console.error(err)
      return null;
    }
  }

  /**
   * Use the `FilesHandler` to create a `FormData` based on `form`.
   *
   * Set the `contentType` attribut of the `HTTPTools` namespace to `application/form-data` for the next request
   *
   * @param form The form to used to create the `FormData`. By default, it uses the value of `mainForm`
   * @returns The `FormData` to send
   */
  protected setFormData(form: any = this.mainForm.value): FormData {
    const formData = this.filesHandler.createFormData(form);
    HTTPTools.setNextContentType('application/form-data');
    return formData;
  }

  /**
   * Navigate to the previous page
   */
  public goBack(currentId?: string): void {
    const url = this.router.url;
    let previousUrl: string;
    if (currentId) {
      previousUrl = this.router.url.split('/' + currentId)[0]
    }
    else {
      const edit = url.includes('edit');
      previousUrl = this.router.url.split((edit ? '/editer' : '/creer'))[0];
    }
    this.router.navigate([previousUrl]);
  }

}

/**
 * @abstract Component that provide properties and methods for the reactive form logic for an `EditableEventData`
 */
@AbstractComponent()
export abstract class FormFromEventComponent<T extends EditableEventData> extends FormComponent<T> {
  protected event: IEvent;
  protected abstract getEvent: GetEvent;
  protected getEventParams: GetParams<IEvent> = {select: ['organisationId']}

  public async ngOnInit(): Promise<void> {
    this.event = await this.getEvent.get(this.getEventParams);
    super.ngOnInit();
  }

  /**
   * Override of the `createOrUpdate` from `FormComponent` to add the `eventId`
   *
   * @param form
   * @param successCallback
   * @param errorCallback
   */
  protected async createOrUpdate(
    form: T | FormData,
    successCallback?: (response: APIResponse) => void | Promise<void>,
    errorCallback?: () => void | Promise<void>,
    back: boolean = true,
    passSuccess = false): Promise<void> {
    (form as T).eventId = this.event;
    super.createOrUpdate(form, successCallback, errorCallback, back, passSuccess);
  }


  /**
   *
  * Set the param `filter.eventId` of the `GetParams` object and call the `getListOf` method
  * @param provider The reference to the `Provider`
  * @param getParams The options of the request
  */
  protected async getListFromEventOf<D extends EditableEventData>(
    provider: Provider<D>,
    getParams: GetParams<D> = DefaultGetParams()
  ): Promise<D[]> {
    getParams.filter = { ...getParams.filter, eventId: this.event._id };
    return await this.getListOf(provider, 'data', getParams);
  }

}

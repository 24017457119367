import { BehaviorSubject } from 'rxjs';
import { Provider } from './provider';
import { StorageService } from "../services/storage.service";
import { injector } from '../etc/injector';

export abstract class ReactiveProvider<T> extends Provider<T>{
  protected storageService: StorageService;
  public readonly data$: BehaviorSubject<T[]> = new BehaviorSubject(null);
  public readonly selected$: BehaviorSubject<T> = new BehaviorSubject(null);

  constructor() {
    super();
    this.storageService = injector.get(StorageService);
  }

  public clear(): void {
    this.data$.next(null);
    this.selected$.next(null);
  }
}

<section id="form_page" class="container loading_container" [ngClass]="{ 'loaded' : mainForm }">
	<div class="loading_content">
		<div id="page_header">
			<h1>Import billetterie</h1>
			<app-button (clickEvent)="goBack()" text="retour" [style]="'border blue'"></app-button>
		</div>
		<div id="form_wrapper">
			<h2>Ajouter un nouvel import</h2>
			<form *ngIf="mainForm" [formGroup]="mainForm" class="large">

				<div class="form_item">
					<label for="ticketTypeId">Source</label>
					<div class="input_wrap select">
						<select id="provider" (change)="changeProvider($event.target.value)" formControlName="provider" [value]="currentProvider">
							<option selected="selected" value="FNAC">Fnac</option>
							<option value="TICKETMASTER">TicketMaster</option>
						</select>
						<svg role="img" title="chevron" class="icon-chevron">
							<use href="assets/images/sprite.svg#icon-chevron"></use>
						</svg>
					</div>
				</div>
				<div *ngIf="currentProvider == 'FNAC'" class="form_item helptext">
					<ol>
						<li>
							Rendez-vous sur le site
							<a href="https://www.dispobillet.com/web/dispobillet/" target="_blank">Dispobillet</a>
							et connectez-vous avec vos identifiants.
						</li>
						<li>Accédez à la rubrique <strong>Contrôle d'accès</strong>.</li>
						<li>
							Cliquez sur <strong>Recherche</strong> pour localiser votre événement, puis sélectionnez-le dans la liste.
						</li>
						<li>
							Choisissez les formats de billets à inclure dans le contrôle (par exemple, Print@Home, TicketGo ou Mobile),
							puis cliquez à nouveau sur <strong>Recherche</strong>.
						</li>
						<li>
							Téléchargez le fichier CSV généré sur votre disque dur sans l’ouvrir.
						</li>
					</ol>
					<p>
						<strong>Astuce :</strong> Il est recommandé de sélectionner au minimum les formats
						<em>Print@Home/A4</em>, <em>TicketGo/Coffret</em> et <em>Mobile</em>.
						Le format <em>Thermique</em> regroupe les billets traditionnels imprimés sur papier à souche.
					</p>
				</div>
				<div *ngIf="currentProvider == 'TICKETMASTER'" class="form_item helptext">
					<ol>
						<li>
							Accédez au site
							<a href="https://stats.ticketnet.fr" target="_blank">stats.ticketnet.fr</a>
							et connectez-vous avec vos identifiants.
						</li>
						<li>Cliquez sur le nom de l’événement que vous souhaitez contrôler.</li>
						<li>Repérez l’un de vos tarifs et cliquez sur <strong>Rapport</strong> associé.</li>
						<li>Sélectionnez le rapport intitulé <strong>Contrôle d'accès</strong>.</li>
						<li>Choisissez l’option <strong>Tous les billets</strong>. (Assurez-vous que le fichier généré est au format <em>.TXT</em>.)</li>
						<li>Cliquez sur <strong>Imprimer</strong>.</li>
						<li>Téléchargez le fichier sur votre disque dur sans l’ouvrir.</li>
					</ol>
				</div>

                <div class="form_item">
                    <p class="error" *ngIf="errorLoadingImage">
                        Un problème est survenu lors du chargement de votre image. Veuillez en ajouter une à nouveau.
                    </p>
                    <app-file-input
                        disabledText="Visuel non modifiable car votre événement est en ligne"
                        [loading]="loadingImage.includes('imageId')" class="form_item"
                        [fullWidth]="true"
                        [addFormat]="['txt', 'csv']"
                        [removeFormat]="['png', 'jpeg', 'jpg']"
                        text="Ajouter votre fichier d'import ici"
                        (imageChange)="changeFile($event)"
                        [maintainAspectRatio]="false">
                    </app-file-input>
                </div>

				<div class="form_actions right">
					<app-button [style]="'plain'" type="submit" [loadingOff]="loadingState$" [disabled]="mainForm.invalid"
						(clickEvent)="submitForm()" text="Importer">
					</app-button>
				</div>
			</form>
			<!-- END FORMULAIRE EN DUR -->
		</div>
	</div>
</section>
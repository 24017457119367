<div>
  <div id="page_header" class="container">
    <div id="page_header_left">
      <h1>Tableau de bord</h1>
    </div>
  </div>
</div>

<app-popup *ngIf="noEmail" (backEvent)="noEmail = false" title="Aucune adresse e-mail trouvée"
  message="Cette organisation n'a renseigné aucune adresse e-mail"></app-popup>

<app-popup *ngIf="organisationToEdit" (backEvent)="organisationToEdit = null"
  [title]="'Administrer l\'organisation ' + organisationToEdit.name" [inputs]="[['text']]"
  [formCat]="['Paiement', 'Commissions']"
  [inputDisabled]="[[!!organisationToEdit.paymentGateway.stripe.accountId]]"
  [labels]="[['Identifiant Stripe']]"
  [currentValues]="[[organisationToEdit.paymentGateway.stripe.accountId || '']]"
  [suffixes]="[[]]" buttonText="Valider" (clickEvent)="editStripeOk($event)"></app-popup>

<app-popup *ngIf="commissionToEdit" (backEvent)="commissionToEdit = null"
  [title]="'Administrer l\'organisation ' + commissionToEdit.name" [inputs]="[['number', 'number']]"
  [formCat]="['Paiement', 'Commissions']"
  [inputDisabled]="[[false, false]]"
  [labels]="[['Commission variable', 'Commission fixe']]"
  [currentValues]="[[commissionToEdit.commission.percentage, commissionToEdit.commission.flat / 100]]"
  [suffixes]="[['%', '€']]" buttonText="Valider" (clickEvent)="editCommissionOk($event)"></app-popup>

<app-delete-popup *ngIf="elementToDelete" [name]="elementToDelete.name" type='événement' [startWithVowel]="true"
  (clickEvent)="deleteOk()" (close)="elementToDelete = null"></app-delete-popup>

<main id="page_error">
	<figure>
		<svg xmlns="http://www.w3.org/2000/svg" width="562" height="390" viewBox="0 0 562 390">
		    <g fill="none">
		        <ellipse cx="107.19" cy="51.63" fill="#FF6584" rx="32.029" ry="32.002"/>
		        <path fill="#F2F2F2" d="M262.21 171.88c0 75.646-44.932 102.059-100.357 102.059-55.426 0-100.358-26.413-100.358-102.06C61.495 96.235 161.853 0 161.853 0S262.21 96.234 262.21 171.88z"/>
		        <path fill="#3F3D56" d="M158.847 262.469L159.87 199.135 202.422 120.783 160.03 189.2 160.49 160.723 189.817 104.036 160.611 153.187 160.611 153.187 161.438 101.969 192.842 56.839 161.568 93.915 162.084 0 158.838 124.327 159.105 119.198 127.176 70.009 158.593 129.043 155.618 186.244 155.529 184.727 118.72 132.963 155.418 190.09 155.046 197.244 154.979 197.351 155.01 197.939 147.462 343.063 157.546 343.063 158.756 268.103 195.363 211.116z"/>
		        <path fill="#F2F2F2" d="M562 202.268c0 61.978-36.901 83.618-82.421 83.618s-82.42-21.64-82.42-83.618c0-61.978 82.42-140.824 82.42-140.824S562 140.29 562 202.268z"/>
		        <path fill="#3F3D56" d="M476.446 276.904L477.29 224.914 512.462 160.595 477.423 216.758 477.803 193.381 502.043 146.846 477.904 187.195 477.904 187.195 478.587 145.15 504.544 108.103 478.694 138.539 479.121 61.444 476.438 163.503 476.659 159.293 450.268 118.914 476.236 167.376 473.777 214.332 473.703 213.086 443.28 170.592 473.611 217.488 473.303 223.361 473.249 223.449 473.274 223.931 467.035 343.063 475.37 343.063 476.37 281.529 506.627 234.748z"/>
		        <ellipse cx="278.011" cy="340.93" fill="#3F3D56" rx="278.011" ry="14.081"/>
		        <ellipse cx="447.977" cy="364.398" fill="#3F3D56" rx="47.403" ry="2.56"/>
		        <ellipse cx="274.594" cy="387.44" fill="#3F3D56" rx="47.403" ry="2.56"/>
		        <ellipse cx="144.771" cy="368.239" fill="#3F3D56" rx="108.898" ry="5.547"/>
		        <ellipse cx="48.684" cy="284.179" fill="#2F2E41" rx="39.289" ry="39.256"/>
		        <path fill="#2F2E41" d="M30.131 314.798L42.163 314.798 42.163 336.353 30.131 336.353z" transform="rotate(.675 36.147 325.575)"/>
		        <path fill="#2F2E41" d="M54.194 315.081L66.226 315.081 66.226 336.637 54.194 336.637z" transform="rotate(.675 60.21 325.859)"/>
		        <ellipse cx="40.028" cy="336.65" fill="#2F2E41" rx="3.76" ry="10.027" transform="rotate(-89.325 40.028 336.65)"/>
		        <ellipse cx="64.096" cy="336.432" fill="#2F2E41" rx="3.76" ry="10.027" transform="rotate(-89.325 64.096 336.432)"/>
		        <ellipse cx="49.538" cy="273.939" fill="#FFF" rx="13.666" ry="13.654"/>
		        <ellipse cx="49.111" cy="273.512" fill="#3F3D56" rx="4.698" ry="4.694"/>
		        <path fill="#307BF7" d="M10.778 246.598c-3.054-14.576 7.426-29.144 23.41-32.538 15.983-3.394 31.416 5.67 34.47 20.247 3.055 14.576-7.572 19.813-23.555 23.207-15.984 3.394-31.27 3.66-34.325-10.916z"/>
		        <path fill="#307BF7" d="M108.471 305.864c0 27.572-16.25 37.2-36.3 37.2-.464 0-.927-.006-1.39-.016-.93-.02-1.85-.065-2.758-.127-18.094-1.292-32.15-11.414-32.15-37.057 0-26.537 33.623-60.022 36.148-62.5l.005-.005c.097-.096.146-.142.146-.142s36.3 35.075 36.3 62.647z"/>
		        <path fill="#3F3D56" d="M71.111 338.86l13.413-18.712-13.446 20.767-.036 2.148c-.938-.02-1.868-.064-2.786-.127l1.445-27.59-.01-.214.024-.04.136-2.605-13.48-20.82 13.522 18.865.032.554 1.092-20.846-11.54-21.513 11.681 17.854 1.137-43.217.005-.147v.142l-.19 34.08 11.49-13.51-11.537 16.446-.304 18.664 10.728-17.912-10.772 20.658-.17 10.376 15.574-24.93-15.632 28.55-.376 23.08z"/>
		        <ellipse cx="357.015" cy="284.179" fill="#2F2E41" rx="39.289" ry="39.256"/>
		        <path fill="#2F2E41" d="M351.976 316.201L364.008 316.202 364.008 337.757 351.976 337.757z" transform="rotate(-17.083 357.992 326.98)"/>
		        <path fill="#2F2E41" d="M374.978 309.135L387.01 309.136 387.01 330.691 374.978 330.691z" transform="rotate(-17.083 380.994 319.913)"/>
		        <ellipse cx="365.066" cy="336.343" fill="#2F2E41" rx="10.027" ry="3.76" transform="rotate(-17.083 365.066 336.343)"/>
		        <ellipse cx="387.921" cy="328.795" fill="#2F2E41" rx="10.027" ry="3.76" transform="rotate(-17.083 387.921 328.795)"/>
		        <ellipse cx="354.453" cy="273.939" fill="#FFF" rx="13.666" ry="13.654"/>
		        <ellipse cx="354.88" cy="273.512" fill="#3F3D56" rx="4.698" ry="4.694"/>
		        <path fill="#307BF7" d="M309.846 259.303c-7.316-12.718-1.756-29.512 12.417-37.51 14.173-7.998 31.593-4.17 38.909 8.548 7.315 12.718-1.207 20.824-15.38 28.822-14.174 7.998-28.63 12.858-35.946.14zM286.125 301.045c0 25.451-14.913 34.338-33.31 34.338-.427 0-.852-.005-1.277-.014-.852-.019-1.697-.06-2.53-.118-16.604-1.192-29.503-10.536-29.503-34.206 0-24.495 30.854-55.405 33.172-57.692l.004-.005.134-.131s33.31 32.377 33.31 57.828z"/>
		        <path fill="#3F3D56" d="M251.807 331.503l12.295-17.273-12.326 19.17-.033 1.983c-.86-.019-1.712-.06-2.554-.117l1.325-25.468-.01-.198.023-.037.125-2.405-12.357-19.217 12.396 17.413.028.51 1.002-19.241-10.58-19.858 10.709 16.48 1.042-39.892.005-.136v.131l-.175 31.458 10.532-12.47-10.575 15.18-.278 17.229 9.833-16.535-9.874 19.07-.156 9.577 14.276-23.012-14.329 26.355-.344 21.303z"/>
		        <path fill="#307BF7" d="M418.51 310.985c0 27.572-16.25 37.199-36.299 37.199-.464 0-.928-.005-1.39-.016-.93-.02-1.85-.064-2.759-.127-18.093-1.291-32.15-11.414-32.15-37.056 0-26.537 33.623-60.023 36.149-62.5l.004-.005.146-.143s36.3 35.076 36.3 62.648z"/>
		        <path fill="#3F3D56" d="M381.15 343.98l13.414-18.712-13.447 20.767-.036 2.149c-.938-.02-1.868-.065-2.786-.127l1.446-27.59-.011-.214.024-.04.137-2.606-13.48-20.819 13.522 18.865.031.553 1.093-20.846-11.54-21.513 11.68 17.854 1.138-43.217.004-.147v.143l-.19 34.08 11.49-13.511-11.536 16.446-.304 18.664 10.727-17.912-10.772 20.658-.17 10.376 15.574-24.93-15.632 28.55-.375 23.08z"/>
		        <ellipse cx="341.819" cy="298.656" fill="#2F2E41" rx="6.216" ry="19.802" transform="rotate(-64.626 341.819 298.656)"/>
		        <ellipse cx="36.504" cy="296.149" fill="#2F2E41" rx="6.216" ry="19.803" transform="rotate(-68.158 36.504 296.15)"/>
		    </g>
		</svg>
	</figure>
	<p class="text big">Vous n'avez pas le droit d'accéder à cette page.</p>
	<a class="button" routerLink="{{url}}">Retour</a>
</main>
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { IEvent } from 'src/app/models/event.model';
import { GetEvent } from '../../getEvent';
import { createFormData } from 'src/app/etc/utilities'
import { FormComponent } from 'src/app/elements/form-component';
import { IMedia } from 'src/app/models/media.model';
import { EventService, TicketTypeService } from 'src/app/providers';
import { WysiwygComponent } from 'src/app/elements/wysiwyg/wysiwyg.component';
import { FileInputComponent } from 'src/app/elements/file-input/file-input.component';
import { ITicket, ITicketType } from 'src/app/models/ticket.model';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-event-ticketing-eticket',
  templateUrl: './event-ticketing-eticket.component.html',
  styleUrls: ['./event-ticketing-eticket.component.scss'],
  providers: [GetEvent]
})
export class EventTicketingEticketComponent extends FormComponent<IEvent> {
  @ViewChild(WysiwygComponent) wysiwyg: WysiwygComponent;
  @ViewChild(FileInputComponent) fileInput: FileInputComponent;
  public dataName = 'E-Ticket';
  public img64: any
  public currentImage: Blob
  public previewImage: Blob
  public error: string;
  public event: IEvent;
  public ticketTypes: ITicketType[];
  public loadingState$: Subject<void> = new Subject<void>();
  public previousImage: Blob;
  public previousText: string;
  public dataPreview: any;
  public defaultText: string;
  public selectedTicketTypeId: string = null;
  public currentImageError: boolean = false
  public errorLoadingImage: boolean = false
  public textAddMedia = "Ajouter une image à votre ticket";
  public selectTicketForm: FormGroup;
  public selectedTicketType: ITicketType

  constructor(
    private getEvent: GetEvent,
    protected formProvider: EventService,
    protected rateService: TicketTypeService,
    private notification: NotificationService
  ) {
    super();
  }

  async onInit() {
    this.data = await this.getEvent.get({ select: ['ticketing', 'place', 'status'] });
    await this.loadTicketTypes()
    this.dataPreview = this.data;
    this.defaultText = this.data.ticketing.ticketVisual?.text || '';
    this.previousText = this.data.ticketing.ticketVisual?.text || '';
    this.errorLoadingImage = false
    await this.getImage();
    this.loaded = true;
    this.createImagesChangeState(['imageId']);
    if (this.data.bookType === 'nft') {
      this.textAddMedia = "Ajouter une image / vidéo à votre ticket"
    }
  }

  public getTicketConcatName(ticket: ITicketType | ITicket, max: number) {
    let name = ticket.name || (ticket as ITicket).ticketTypeId.name;
    const categoryName = (ticket as ITicket).categoryName || ticket.ticketTypeCategoryId?.name;
    if (categoryName) name += ` - ${categoryName}`;
    if (name.length > max) name = `${name.substring(0, max - 3)}...`;
    return name;
  }

  async getList<T>(subject: Observable<{ data: T[] }>): Promise<T[]> {
    return (await subject.toPromise())?.data || [];
  }

  private async getImage(): Promise<void> {

    let id = this.data.ticketing.ticketVisual?.imageId?._id
    if (this.selectedTicketType) {
      if (this.selectedTicketType.ticketVisual?.overriden) {
        id = this.selectedTicketType.ticketVisual?.imageId._id
      }
    }
    if (this.mainForm) {
      this.mainForm.patchValue({ imageId: id });
    }

    if (id) {
      this.loadingImage.push("imageId");
      try {
        this.previousImage = await this.mediaService.getStream(id).toPromise();
        this.currentImage = this.previousImage
        this.dataPreview.ticketing.ticketVisual.image = this.previousImage;
        this.loadingImage.splice(this.loadingImage.indexOf("imageId"));
      } catch (error) {
          this.currentImageError = true
          this.errorLoadingImage = true
      } finally {
        this.loadingImage.splice(this.loadingImage.indexOf("imageId"));
      }
    }
    else {
      this.previousImage = null
      this.currentImage = this.previousImage
      this.dataPreview.ticketing.ticketVisual.image = this.previousImage;
    }
    if (this.fileInput) {
      await this.fileInput.reset();
    }
  }

  async loadTicketTypes() {
    this.ticketTypes = await this.getList(
      this.rateService.getList({
        filter: {
          eventId: this.data._id,
        },
        select: ['name', 'price', 'ticketVisual', 'ticketTypeCategoryId'],
        populate: ['ticketTypeCategoryId']
      })
    );
  }

  initForm() {
    this.mainForm = this.formBuilder.group({
      image: new FormControl(null),
      ticketTypeId: new FormControl(""),
      imageId: new FormControl(this.data.ticketing.ticketVisual?.imageId?._id),
      text: new FormControl(this.previousText),
      overriden: new FormControl(false),
    });
  }

  setDescriptionValue(value: string) {
    this.mainForm.patchValue({ text: value });
    this.dataPreview.ticketing.ticketVisual.text = value;
  }

  getTicketTypeLabel(ticketType: ITicketType) {
    if (ticketType?.ticketTypeCategoryId?.name) {
      return `${ticketType.ticketTypeCategoryId.name} > ${ticketType.name}`
    }
    return ticketType.name
  }

  async changeTicketTypeId(ticketTypeId) {

    this.selectedTicketTypeId = ticketTypeId
    const selected = this.ticketTypes.find(ticketType => ticketType._id == ticketTypeId)
    let description = this.defaultText
    if (selected) {
      this.selectedTicketType = selected

      const isOverriden = (this.selectedTicketType.ticketVisual?.overriden) ? true : false
      this.mainForm.patchValue({ overriden: isOverriden });
      if (isOverriden) {
        description = selected.ticketVisual?.text || ''
      }
    }
    else {
      this.selectedTicketType = null
      this.selectedTicketTypeId = null

    }
    this.getImage()
    this.setDescriptionValue(description)
    this.wysiwyg.reset(description)
  }

  async submitTicketType() {

    const ticketVisual = this.mainForm.value

    const formData: ITicketType = {
      eventId: this.data,
      ticketVisual
    }

    this.getFileToCreate(this.mainForm.value.image, 'imageId', this.selectedTicketType.ticketVisual?.imageId?._id, 'ticketVisual.imageId');
    if (this.filesToCreate[0]) {
      await this.createOrDeleteMedia(formData, 0, 'ticketTypeId', false, {typeId: this.selectedTicketType._id});
    }

    if (!formData.ticketVisual.overriden) {
      formData.ticketVisual.text = ''
      if (formData.ticketVisual?.imageId) {
        await this.deleteMedia(formData.ticketVisual?.imageId?._id);
        formData.ticketVisual.imageId = null
      }
    }

    this.rateService.update(this.selectedTicketType._id, formData).subscribe(
      async (result) => {
        this.selectedTicketType.ticketVisual = formData.ticketVisual
        this.notification.newNotification({
          message: `E-Ticket édité avec succès`,
          state: 'success',
        });
      },
      (err) => {
        this.notification.newNotification({
          message: `Un problème est survenu`,
          state: 'error',
        });
      }
    );

    this.loadingState$.next();
  }

  async submitForm() {
    if (this.selectedTicketType) {
      await this.submitTicketType()
    }
    else {
      const formData: IEvent = {
        ticketing: {
          ...this.data.ticketing,
          ticketVisual: this.mainForm.value
        }
      };

      const currentImageId = (!this.currentImageError) ? this.data.ticketing.ticketVisual?.imageId?._id : undefined
      this.getFileToCreate(this.mainForm.value.image, 'imageId', currentImageId, 'ticketing.ticketVisual.imageId');

      if (this.filesToCreate[0]) {
        await this.createOrDeleteMedia(formData, 0, 'eventId');
      }
      this.createOrUpdate(formData, res => {
        this.data.ticketing.ticketVisual = formData.ticketing.ticketVisual
        this.previousText = this.data.ticketing.ticketVisual.text;
      });
    }
  }

  public changeImage(file: File): void {

    const image = (this.selectedTicketType) ? this.selectedTicketType.ticketVisual.imageId : this.data.ticketing.ticketVisual?.imageId?._id;

    if (this.currentImageError || this.imageChanged.imageId.load || !image) {
      this.imageChanged.imageId.changed = true;
    }
    else {
      this.imageChanged.imageId.load = true;
    }
    console.log(this.imageChanged);

    this.setValue('image', file);
    if (!file) {
      this.setValue('imageId', null);
      this.errorLoadingImage = false
    }
    this.errorLoadingImage = false
    this.dataPreview.ticketing.ticketVisual.image = file;
  }

  public async cancel(): Promise<void> {
    this.mainForm.patchValue({ text: this.previousText, image: this.previousImage, ticketTypeId: '' });
    this.dataPreview.ticketing.ticketVisual.image = this.previousImage;
    this.dataPreview.ticketing.ticketVisual.text = this.previousText;

    this.wysiwyg.reset();
    this.fileInput.reset();
    this.loadingState$.next();
  }
}

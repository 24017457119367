import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";

// App
import { AppComponent } from '../../app.component';

// Providers
import {
  EventWidgetService
} from '../../providers/index';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-init-page',
  templateUrl: './init-page.html',
  styleUrls: ['./init-page.scss']
})
export class InitPage implements OnInit, OnDestroy {

  private _loading: boolean;
  _subscription = new Subscription();

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public appComponent: AppComponent
  ) { }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ngOnInit(): void {
    this._loading = true;
    this.router.navigate(['connexion']);
    this._subscription.add(this.route.params.subscribe(_params => {
      
    }));
  }

  checkType() {
    return 2;
  }
}
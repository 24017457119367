<section id="form_page" class="container">
  	<div id="page_header">
    	<h1>Opérateurs</h1>
    	<app-button (clickEvent)="goBack()" text="retour" [style]="'border blue'"></app-button>
  	</div>
  	<div id="form_wrapper">
    	<h2>Ajouter un opérateur</h2>
    	<!-- FORMULAIRE EN DUR -->
    	<form [formGroup]="operatorForm">
    		<div class="form_cols">
			    <div class="form_item half">
			      	<label for="lastName">Nom</label>
			        <input type="text" formControlName="lastName"/>
			    </div>
			    <div class="form_item half">
			      	<label for="firstName">Prénom</label>
			        <input type="text" formControlName="firstName"/>
			    </div>
			    <div class="form_item half">
			      	<label for="email">E-mail (Facultatif)</label>
			        <input type="email" formControlName="email"/>
			    </div>
			    <div class="form_item half">
			      	<label for="phone">Téléphone (Facultatif)</label>
			        <input type="text" formControlName="phone"/>
			    </div>
			    <div class="form_item full">
			      	<label for="control">Contrôle d'accès (Facultatif)</label>
	          		<div class="input_wrap select">
				      	<select formControlName="control">
				      		<option>Coucou</option>
				      		<option>Bonjour</option>
				      	</select>
				        <svg role="img" title="chevron" class="icon-chevron">
				          	<use href="assets/images/sprite.svg#icon-chevron"></use>
				        </svg>
				    </div>
			    </div>
			</div>
		    <div class="form_actions right">
		        <app-button (clickEvent)="goBack()" [style]="'border blue'" type="submit" text="Annuler"></app-button>
		        <app-button [style]="'plain'" type="submit" text="Ajouter"></app-button>
		    </div>
		</form>
	</div>
</section>
<div class="form_cols">
  <ng-container *ngIf="!noHour">
    <ng-container *ngIf="endDateHidden">
      <div class="form_item three_quarters"[ngClass]="{'required': required.includes('start')}">
        <label for="start-date">{{startText}}</label>
        <div class="input_wrap date">
          <div class="input_date">
            <input #startDate id="start-date" type="date" [value]="getStartDateValue()"
              (change)="changeStartDate(startDate, startTime)">
            <svg role="img" title="calendar" class="calendar-icon">
              <use href="assets/images/sprite.svg#icon-calendar"></use>
            </svg>
          </div>
          <div class="input_time">
            <input #startTime type="time" (focusout)="changeStartTime(startTime)"
              [value]="formatTime(getStartTimeValue())">
            <svg role="img" title="chevron" class="chevron-icon">
              <use href="assets/images/sprite.svg#icon-chevron"></use>
            </svg>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!endDateHidden">
      <div class="form_item {{row && 'half'}}" [ngClass]="{'required': required.includes('start')}">
        <label for="start-date">{{startText}}</label>
        <div class="input_wrap date">
          <div class="input_date">
            <input #startDate id="start-date" type="date" [value]="getStartDateValue()"
              (change)="changeStartDate(startDate, startTime, endDate, endTime)">
            <svg role="img" title="calendar" class="calendar-icon">
              <use href="assets/images/sprite.svg#icon-calendar"></use>
            </svg>
          </div>
          <div class="input_time">
            <input #startTime type="time" (focusout)="changeStartTime(startTime, endTime)"
              [value]="formatTime(getStartTimeValue())">
            <svg role="img" title="chevron" class="chevron-icon">
              <use href="assets/images/sprite.svg#icon-chevron"></use>
            </svg>
          </div>
        </div>
      </div>
      <div class="form_item {{row && 'half'}}" [ngClass]="{'required': required.includes('end')}">
        <label for="end-date">{{endText}}</label>
        <div class="input_wrap date">
          <div class="input_date">
            <input #endDate id="end-date" type="date" [value]="getEndDateValue()"
              (change)="changeEndDate(endDate, endTime, startDate, startTime)">
            <svg role="img" title="calendar" class="calendar-icon">
              <use href="assets/images/sprite.svg#icon-calendar"></use>
            </svg>
          </div>
          <div class="input_time">
            <input #endTime type="time" (focusout)="changeEndTime(endTime, startTime)"
              [value]="formatTime(getEndTimeValue())">
            <svg role="img" title="chevron" class="chevron-icon">
              <use href="assets/images/sprite.svg#icon-chevron"></use>
            </svg>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>


  <ng-container *ngIf="noHour">
    <ng-container *ngIf="endDateHidden">
      <div class="form_item three_quarters" [ngClass]="{'required': required.includes('start')}">
        <label for="start-date">{{startText}}</label>
        <div class="input_wrap date">
          <div class="input_date" class="input_date only_date">
            <input #startDate id="start-date" type="date" [value]="getStartDateValue()"
              (change)="changeStartDate(startDate, startTime)">
            <svg role="img" title="calendar" class="calendar-icon">
              <use href="assets/images/sprite.svg#icon-calendar"></use>
            </svg>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!endDateHidden">
      <div class="form_item {{row && 'half'}}" [ngClass]="{'required': required.includes('start')}">
        <label for="start-date">{{startText}}</label>
        <div class="input_wrap date">
          <div class="input_date" class="input_date only_date">
            <input #startDate id="start-date" type="date" [value]="getStartDateValue()"
              (change)="changeStartDate(startDate, startTime, endDate, endTime)">
            <svg role="img" title="calendar" class="calendar-icon">
              <use href="assets/images/sprite.svg#icon-calendar"></use>
            </svg>
          </div>
        </div>
      </div>
      <div class="form_item {{row && 'half'}}"  [ngClass]="{'required': required.includes('end')}">
        <label for="end-date">{{endText}}</label>
        <div class="input_wrap date">
          <div class="input_date" class="input_date only_date">
            <input #endDate id="end-date" type="date" [value]="getEndDateValue()"
              (change)="changeEndDate(endDate, endTime, startDate, startTime)">
            <svg role="img" title="calendar" class="calendar-icon">
              <use href="assets/images/sprite.svg#icon-calendar"></use>
            </svg>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <div *ngIf="displayTimeZoneSelector" (click)="$event.stopPropagation()" class="time_zone_input form_item half">
    <label for="date_zone">Chercher un fuseau horaire</label>
    <!-- <input #timeZoneInput type="text" id="date_zone" [value]="timeZone" (keydown)="keyDownTimeZoneSelect($event)"
      (keyup)="searchTimeZone($event, timeZoneInput.value)"> -->

    <div class="input_wrap select">
      <select (change)="selectTimeZone($event.target.value)">
        <option *ngFor="let currentTimeZone of allTimeZones" [value]="currentTimeZone"
          [attr.selected]="currentTimeZone == timeZone ? true : null">{{currentTimeZone}}</option>
      </select>
      <svg role="img" title="chevron" class="icon-chevron">
        <use href="assets/images/sprite.svg#icon-chevron"></use>
      </svg>
    </div>

    <!-- <div *ngIf="timeZoneToSelect" class="timezone_list_select">
      <ul>
        <li *ngFor="let tz of timeZoneToSelect; let i = index" [ngClass]="{'hover': i == timeZoneSelectorPos}"
          (mouseover)="changeSelectorPosOnHover(tz)" (click)="selectTimeZone(tz)">{{tz}}</li>
      </ul>
    </div> -->
  </div>
</div>
<!-- <p *ngIf="displayTimeZoneSelector" class="date_zone_info">Écrivez le nom de la zone souhaitée pour trouver le fuseau
  horaire correspondant</p> -->
<aside [ngClass]="{ 'has_submenu' : selectedEvent }">
    <div class="logo_ctn" *ngIf="!isAdmin || selectedEvent || adminInOrganization" [style.display]="'flex'">
        <figure id="sidebar_logo" [style.paddingRight]="'20%'">
            <img src="assets/images/logo_black.svg" />
        </figure>
        <app-button *ngIf="adminInOrganization" (clickEvent)="goBackToAdminDashboard()" text="retour"
            [style]="'border red' + (selectedEvent ? ' medium' : '')">
        </app-button>
    </div>

    <figure *ngIf="isAdmin && !selectedEvent && !adminInOrganization" id="admin_logo">
        <img src="assets/images/logo_black.svg" />
        <h2>ADMINISTRATEUR</h2>
    </figure>
    <ul id="sidebar_menu">
        <ng-container *ngFor="let route of mainRoutes">
            <li *ngIf="((!isAdmin || adminInOrganization) && !route.forAdmin) || (route.forAdmin && (isAdmin && !adminInOrganization))"
                class="route">
                <a [routerLink]="[route.route]"
                    [ngClass]="{'active' : route.active, 'admin': isAdmin && !adminInOrganization}">
                    <i>
                        <svg role="img" [attr.title]="route.icon" class="icon-{{route.icon}}">
                            <use [attr.href]="'assets/images/sprite.svg#icon-' + route.icon"></use>
                        </svg>
                    </i>
                    <span *ngIf="!selectedEvent">{{route.title}}</span>
                </a>
                <ul class="sidebar_submenu" *ngIf="route.displaySubmenu && route.submenu.length > 0 && route.active">
                    <li *ngFor="let subroute of route.submenu">
                        <a [routerLink]="(subroute.fromRoot === true) ? subroute.route : [route.route + '/' + subroute.route]"
                            [ngClass]="{'active' : subroute.active}">
                            {{subroute.title}}
                        </a>
                    </li>
                </ul>
            </li>
        </ng-container>
    </ul>
    <div id="sidebar_menu_event" *ngIf="selectedEvent">
        <span id="sidebar_return" (click)="goBack()">
            <svg role="img" title="arrow-full-left" class="icon-arrow-full-left">
                <use href="assets/images/sprite.svg#icon-arrow-full-left"></use>
            </svg>
        </span>
        <div *ngIf="allEvents && (!isAdmin && !adminInOrganization)" id="select_event">
            <select #eventSelect (change)="changeEvent(eventSelect.value)" [disabled]="allEvents.length == 1">
                <option hidden>{{selectedEvent.name}}</option>
                <ng-container *ngFor="let event of allEvents">
                    <option *ngIf="event._id !== selectedEvent._id" value="{{event._id}}">{{event.name}}</option>
                </ng-container>
            </select>
            <svg *ngIf="allEvents.length > 1" role="img" title="chevron" class="icon-chevron">
                <use href="assets/images/sprite.svg#icon-chevron"></use>
            </svg>
        </div>
        <ul>
            <ng-container *ngFor="let route of eventRoutes">
            <li *ngIf="route.route !== 'exclusive-content'" class="route">
                <a [routerLink]="['evenements/' + selectedEvent._id + '/' + route.route]"
                    [ngClass]="{'active' : route.active}">
                    <i>
                        <svg role="img" [attr.title]="route.icon" class="icon-{{route.icon}}">
                            <use [attr.href]="'assets/images/sprite.svg#icon-' + route.icon"></use>
                        </svg>
                    </i>
                    <span>{{route.title}}</span>
                </a>
                <ul class="sidebar_submenu" *ngIf="route.displaySubmenu && route.submenu.length > 0 && route.active">
                    <ng-container *ngFor="let subroute of route.submenu">
                        <li *ngIf="
                            subroute.route != 'securite' &&
                            (subroute.route == 'badges' && selectedEvent.ticketing.hasBadge == true) ||
                            (subroute.route == 'sessions' && displaySessions) ||
                            (subroute.route == 'seating-plan' && displaySeatingPlan) ||
                            (subroute.route == 'produits' && displayProduct) ||
                            (subroute.route !== 'badges' && subroute.route !== 'sessions' && subroute.route !== 'seating-plan' && subroute.route !== 'securite' && subroute.route !== 'produits')
                        ">
                            <a [routerLink]="['evenements/' + selectedEvent._id + '/' + route.route + '/' + subroute.route]"
                                [ngClass]="{'active' : subroute.active}">{{subroute.title}}</a>
                        </li>
                        <li *ngIf="subroute.route === 'securite' && selectedEvent.bookType === 'nft'
                        ">
                            <a [routerLink]="['evenements/' + selectedEvent._id + '/' + route.route + '/' + subroute.route]"
                                [ngClass]="{'active' : subroute.active}">{{subroute.title}}</a>
                        </li>
                    </ng-container>
                </ul>
            </li>
            <li *ngIf="route.route === 'exclusive-content' && selectedEvent.bookType === 'nft'" class="route">
                <a [routerLink]="['evenements/' + selectedEvent._id + '/' + route.route]"
                    [ngClass]="{'active' : route.active}">
                    <i>
                        <svg role="img" [attr.title]="route.icon" class="icon-{{route.icon}}">
                            <use [attr.href]="'assets/images/sprite.svg#icon-' + route.icon"></use>
                        </svg>
                    </i>
                    <span>{{route.title}}</span>
                </a>
                <ul class="sidebar_submenu" *ngIf="route.displaySubmenu && route.submenu.length > 0 && route.active">
                    <ng-container *ngFor="let subroute of route.submenu">
                        <li *ngIf="
                            (subroute.route == 'sessions' && displaySessions) ||
                            (subroute.route == 'seating-plan' && displaySeatingPlan) ||
                            (subroute.route == 'securite' && displaySecurity) ||
                            (subroute.route == 'produits' && displayProduct) ||
                            (subroute.route !== 'sessions' && subroute.route !== 'seating-plan' && subroute.route !== 'securite' && subroute.route !== 'produits')
                        ">
                            <a [routerLink]="['evenements/' + selectedEvent._id + '/' + route.route + '/' + subroute.route]"
                                [ngClass]="{'active' : subroute.active}">{{subroute.title}}</a>
                        </li>
                    </ng-container>
                </ul>
            </li>
            </ng-container>
        </ul>
    </div>
</aside>
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Session } from 'inspector';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { ClickOutService } from 'src/app/services/click-out.service';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit, OnDestroy {
  @Output() dateSelection: EventEmitter<[Date, Date]> = new EventEmitter();
  @Input() availableSessions: Session[];
  @Input() firstDate: Date;
  @Input() firstSelection: [Date, Date];
  public inputValue: string;
  public open = false;
  public selectedDay: Date;
  public currentHover: Date;
  public selectableDays: Date[];
  public firstAvailableDate: moment.Moment;
  public days: Date[] = [];
  public subscription: Subscription;
  constructor(private clickOutService: ClickOutService) { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription = this.clickOutService.closeToggle$.subscribe(() => {
      this.open = false;
    });
    moment.locale('fr');
    this.setInputValue(this.firstSelection);
    this.days = this.setDays();
  }

  get firstDayOfMonth(): moment.Moment {
    return moment(this.firstAvailableDate).startOf('month');
  }

  get lastDayOfMonth(): moment.Moment {
    return moment(this.firstAvailableDate).endOf('month');
  }

  get startDayOfView(): moment.Moment {
    const firstDayOfMonthWeekDayNum = this.firstDayOfMonth.weekday();
    if (firstDayOfMonthWeekDayNum === 0) {
      return this.firstDayOfMonth;
    }
    else {
      return this.firstDayOfMonth.add(-firstDayOfMonthWeekDayNum, 'days');
    }
  }

  get endDayOfView(): moment.Moment {
    const lastDayOfMonthWeekDayNum = this.lastDayOfMonth.weekday();
    if (lastDayOfMonthWeekDayNum === 6) {
      return this.lastDayOfMonth;
    }
    else {
      return this.lastDayOfMonth.add((6 - lastDayOfMonthWeekDayNum), 'days');
    }
  }

  get currentMonth(): string {
    return moment(this.firstAvailableDate).format('MMMM YYYY');
  }

  setDays(): Date[] {
    this.selectableDays = [];
    const days: Date[] = [];
    let day = this.startDayOfView;
    while (day <= this.endDayOfView) {
      days.push(day.toDate());
      day = day.clone().add(1, 'd');
    }
    return days;
  }

  isToday(date: Date): boolean {
    return true ? moment().isSame(date, 'day') : false;
  }

  isInMonth(date): boolean {
    return moment(date).isBetween(this.firstDayOfMonth, this.lastDayOfMonth, null, '[)')
  }

  isNotSelectable(date): boolean {
    if (moment(date).isSameOrAfter(this.firstDate)
      && moment(date).isSameOrBefore(moment.now())) {
      return false;
    } else {
      return true;
    }
  }

  displayDayNum(date): string {
    return moment(date).format('DD');
  }

  previousMonth(): void {
    this.firstAvailableDate = moment(this.firstAvailableDate).add(-1, 'month');
    this.days = this.setDays();
  }
  nextMonth(): void {
    this.firstAvailableDate = moment(this.firstAvailableDate).add(1, 'month');
    this.days = this.setDays();
  }

  checkIfSelectable(day: Date): boolean {
    for (const selectableDay of this.selectableDays) {
      const check = this.compareDate(day, selectableDay);
      if (check) {
        return check;
      }
    }
    return false;
  }

  checkIfSelected(day: any): boolean {
    return this.compareDate(day.dayDate, this.selectedDay);
  }

  compareDate(date1: Date, date2?: Date) {
    const dayTime = date1.getTime();
    const selectedTime = date2?.getTime();
    return dayTime === selectedTime;
  }

  isSelected(day: Date) {
    return moment(this.selectedDay).isSame(day);
  }

  clickOnDay(day: Date) {
    if (!this.selectedDay) {
      this.selectedDay = day;
    }
    else if (!moment(this.selectedDay).isSame(day)) {
      const dates: [Date, Date] = this.isBefore(day) ? [day, this.selectedDay] : [this.selectedDay, day];
      this.dateSelection.emit(dates);
      this.setInputValue(dates);
      this.open = false;
      this.selectedDay = null;
    }
    else {
      this.selectedDay = null;
    }
  }

  isInRange(date: Date) {
    return (moment(date).isBetween(this.selectedDay, this.currentHover)
      || moment(date).isBetween(this.currentHover, this.selectedDay))
      && !this.isNotSelectable(date)
  }

  isBefore(date: Date) {
    return moment(date).isBefore(this.selectedDay)
  }

  isAfter(date: Date) {
    return moment(date).isAfter(this.selectedDay)
  }

  private setInputValue(dates: [Date, Date]): void {
    this.inputValue = `${moment(dates[0]).format('DD/MM/yyyy')} - ${moment(dates[1]).format('DD/MM/yyyy')}`
  }
}

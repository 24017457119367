import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
// Animations
import { popupAnimation } from 'src/app/animations/animations';
import { IAccountingInfo } from 'src/app/models/accounting.model';
import { DateInterval, TableOptions } from 'src/app/models/type.definition';
import { AccountingService, AuthService, EventService, OrganisationService, UserService } from 'src/app/providers';
import { DateService } from 'src/app/services/date.service';
import User from 'src/app/models/user.model';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { IEvent, translateStatus } from 'src/app/models/event.model';
import { ListComponent } from 'src/app/elements/list-component';

@Component({
  selector: 'app-accounting-settings',
  templateUrl: './accounting-settings.component.html',
  styleUrls: ['./accounting-settings.component.scss'],
  animations: [
    popupAnimation
  ]
})
export class AccountingSettingsComponent extends ListComponent<IEvent> {
  dataName = "Evénement";
  public loadingState$ = new Subject<void>();
  public dateForm: FormGroup;
  public totalPages: number;
  public page = 1;
  public wantedEventName = "";
  public totalData: number;
  private subscription = new Subscription();
  public data: IEvent[];
  public partialRedditionOpenedFor: IAccountingInfo;
  public partialDates: DateInterval;
  public fullLoader: boolean;
  public tableOptions: TableOptions<IAccountingInfo>[] = [
    {
      title: "Statut",
      class: "status",
      sort: true,
      classKey: 'status',
      key: 'translateStatus',
      sortMethod: order => this.sort("status", order),
    },
    {
      title: "Événements",
      class: "name",
      sort: true,
      key: 'name',
      sortMethod: order => this.sort("name", order),
    },
    {
      title: "Participants",
      class: "participants",
      sort: true,
      key: 'participants',
      sortMethod: order => this.sort("statistics.participants", order)
    },
    {
      title: "Total brut (EUR)",
      class: "total",
      sort: true,
      key: 'stringSellingPrice',
      sortMethod: order => this.sort("statistics.sellingPrice", order)
    },
    {
      class: "more",
      toggle: [
        {
          title: 'Reddition complète',
          method: this.getFullRedition
        },
        {
          title: 'Reddition d\'une période',
          method: this.openPartialRedition
        },
      ]
    }
  ];
  public me: User;
  public isAdmin: boolean;
  public adminOnOrganisationPage: boolean;

  constructor(
    protected provider: EventService,
    private accountingService: AccountingService,
    private dateService: DateService,
    private userService: UserService,
    private authService: AuthService,
    private organisationService: OrganisationService,
    protected router: Router
  ) {
    super()
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public async ngOnInit() {
    this.isAdmin = this.authService.isAdmin;
    // this.adminOnOrganisationPage = !!this.storage.getItem('adminInOrganization');
    this.adminOnOrganisationPage = this.organisationService.adminConnected$.value;
    this.subscription.add(this.userService.me$.subscribe(me => {
      this.me = me;
    }));
    if (this.isAdmin && !this.adminOnOrganisationPage) {
      this.tableOptions.unshift(
        {
          title: "Organisation",
          class: "organisation_name",
          key: 'organisationName'
        })
    }
    this.getData();
    this.filtersForm = this.formBuilder.group({ search: '' });
    //this.initForm();
  }

  setGetParams(): void {
    this.getParams.select = ['organisationId', 'name', 'statistics', 'status'];
    this.getParams.populate = ['organisationId'];
    this.getParams.filter = { status: 'closed,passed,published' }
    super.setGetParams();
  }

  formatElement(event: IEvent): IEvent {
    event.participants = event.statistics.participants;
    event.stringSellingPrice = `${event.statistics.sellingPrice / 100}€`;
    event.organisationName = event.organisationId?.name;
    event.translateStatus = translateStatus(event.status);
    return event;
  }

  public initForm(): void {
    this.dateForm = this.formBuilder.group({
      date: new FormControl(),
    });
  }

  public async getFullRedition(data: IEvent, ref = this): Promise<void> {
    ref.fullLoader = true;
    ref.subscription.add(ref.accountingService.getReddition(data._id).subscribe(reddition => {
      ref.openPdf(reddition);
      ref.fullLoader = false;
    }, err => {
      ref.fullLoader = false;
    }));
  }

  public openPartialRedition(data: IAccountingInfo, ref = this): void {
    setTimeout(() => ref.partialRedditionOpenedFor = data);
  }

  public async getPartialRedition(): Promise<void> {
    const eventId = this.partialRedditionOpenedFor.id;
    const startDate = this.formatDate(this.partialDates?.startDate);
    const endDate = this.formatDate(this.partialDates?.endDate);
    this.subscription.add(this.accountingService.getReddition(eventId, startDate, endDate).subscribe(reddition => {
      this.loadingState$.next();
      this.partialRedditionOpenedFor = null;
      this.openPdf(reddition);
    }, err => {
      this.loadingState$.next();
    }));
  }

  private formatDate(date: Date): string {
    if (date) {
      return this.dateService.getInternationalString(date);
    }
    return null;
  }

  changePartialDates(dates: DateInterval) {
    this.partialDates = dates;
  }

  openPdf(data: Blob): void {
    const file = new Blob([data], { type: 'application/pdf' })
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    const link = document.createElement('a');
    link.href = fileURL;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  createEvent(): void {
    this.router.navigate(['evenements/creer-un-evenement']);
  }

  @HostListener('document:click') clickDoc(): void {
    if (this.partialRedditionOpenedFor) {
      this.partialRedditionOpenedFor = null;
    }
  }

  protected changePage(page: number) {
    this.page = page;
    this.getData();
  }

  searchEvent(input: string) {
    this.page = 1;
    const searchFilterValue = input?.toLowerCase().trim().replace(/\s+/g, '');
    this.wantedEventName = searchFilterValue
    this.getData();
  }

}

import { Injectable } from '@angular/core';
import User from 'src/app/models/user.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { Provider } from '../provider';
import { GetParams } from 'src/app/models/type.definition';

@Injectable()
export class UserService extends Provider<User> {
  public url = 'users';
	public me$: BehaviorSubject<User> = new BehaviorSubject(null);

	public getMe(body: GetParams<User> = null) {
		this.httpClient.get<User>(this.getUrl('profile/me', body)).subscribe(result => {
			this.me$.next(new User(result))
		});
	}

	public updateMe(body: User): Observable<{ msg: string }> {
		return this.httpClient.put<{ msg: string }>(this.getUrl('profile/me'), body);
	}

	public recoverPassword(body: { email: string }): Observable<{ data: {userId?: string }, message: string, status: string }> {
		return this.httpClient.post<{ data: {userId?: string }, message: string, status: string }>(this.getUrl('reset/send'), body);
	}

	public checkCodeReset(body: { id: string, code: string }): Observable<{ msg: string }> {
		return this.httpClient.post<{ msg: string }>(this.getUrl('reset/check'), body);
	}

	public setNewPassword(body: { id: string, code: string, data: { password: string } }): Observable<{ token: string }> {
		return this.httpClient.post<{ token: string }>(this.getUrl('reset/setNewPassword'), body);
	}
}

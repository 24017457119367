import { EditableData } from './editable-data';
import { IEvent } from './event.model';
import { IUserOrganisation } from './user-organisation.model';

export default class User implements EditableData {
  public _id?: string;
  public id?: string;
  public name?: string;
  public firstName?: string;
  public lastName?: string;
  public email?: string;
  public organisationIds?: string[];
  public langCode?: string;
  public avatarId?: string;
  public roles: string[];
  public avatar?: Blob;
  public phone?: string;
  public status?: string;
  public events?: IEvent[][];
  public updatedAt?: Date;
  public createdAt?: Date;
  public isSuperAdmin?: boolean;
  public organisation?: IUserOrganisation
  public organisations?: IUserOrganisation[]
  public organisationsIds?: {
    id: string,
    roles: ('organizer' | 'collaborator' | 'operator')[]
    restrictEventIds: string[],
    status: 'active' | 'blocked'
  }[];

  constructor(user?: any) {
    if (user) {
      if (![null, undefined].includes(user.id)) {
        this.id = user.id;
      }
      if (![null, undefined].includes(user._id)) {
        this._id = user._id;
      }
      if (![null, undefined].includes(user.firstName)) {
        this.firstName = user.firstName;
      }
      if (![null, undefined].includes(user.lastName)) {
        this.lastName = user.lastName;
        this.name = `${this.firstName} ${this.lastName}`
      }
      if (![null, undefined].includes(user.email)) {
        this.email = user.email;
      }
      if (![null, undefined].includes(user.organisationIds)) {
        this.organisationIds = user.organisationIds;
      }
      if (![null, undefined].includes(user.langCode)) {
        this.langCode = user.langCode;
      }
      if (![null, undefined].includes(user.avatarId)) {
        /** Fix to prevent avatarId to be an object  */
        this.avatarId = user.avatarId._id || user.avatarId;
      }
      if (![null, undefined].includes(user.phone)) {
        this.phone = user.phone;
      }
      if (![null, undefined].includes(user.status)) {
        this.status = user.status;
      }
      if (![null, undefined].includes(user.roles)) {
        this.roles = user.roles;
      }
      if (![null, undefined].includes(user.updatedAt)) {
        this.updatedAt = user.updatedAt;
      }
      if (![null, undefined].includes(user.createdAt)) {
        this.createdAt = user.createdAt;
      }
      if (![null, undefined].includes(user.organisationsIds)) {
        this.organisationsIds = user.organisationsIds;
      }
      if (![null, undefined].includes(user.events)) {
        this.events = user.events;
      }
      if (![null, undefined].includes(user.isSuperAdmin)) {
        this.isSuperAdmin = user.isSuperAdmin;
      }
    }
  }
}

export function translateRole(role: 'authenticated' | 'organizer' | 'collaborator' | 'operator'): 'Lecteur' | 'Organisateur' | 'Collaborateur' | 'Opérateur' {
  switch (role) {
    case 'authenticated': return 'Lecteur';
    case 'collaborator': return 'Collaborateur';
    case 'operator': return 'Opérateur';
    case 'organizer': return 'Organisateur';
  }
}

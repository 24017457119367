<main *ngIf="authForm" id="auth_page" class="container">
    <div id="auth_img">
        <div class="inner">
            <div class="imgs">
                <div class="logo">
                    <img src="assets/images/logo_white.svg" alt="" />
                </div>
                <div class="baseline">
                    <img src="assets/images/baseline_white.svg" alt="" />
                </div>
            </div>
        </div>
    </div>
    <div id="auth_form">
        <div class="inner">
            <div class="form-ctn">
                <h1>Heureux de vous retrouver</h1>
                <form [formGroup]="authForm">
                    <div class="form_fields">
                        <div class="form_item">
                            <label for="email">Adresse e-mail</label>
                            <input id="email" type="email" formControlName="email">
                        </div>
                        <div class="form_item">
                            <label for="password">Mot de passe</label>
                            <div class="input_wrap password">
                                <input id="password" [attr.type]="showPwd ? 'text' : 'password'" formControlName="password">
                                <svg role="img" title="eye" class="eye-icon" [ngClass]="{'active': showPwd}" (click)="showPwd = !showPwd">
                                    <use href="assets/images/sprite.svg#icon-eye"></use>
                                </svg>
                            </div>
                            <a id="password_forgotten" href="/mot-de-passe-oublie">Mot de passe oublié ?</a>
                        </div>
                    </div>
                    <app-button [loadingOff]="loadingState$" [disabled]="authForm.invalid" (clickEvent)="logIn()" type="submit" text="Connexion" [style]="'plain full'"></app-button>
                    <app-popup *ngIf="error != null" title="Une erreur est survenue" message="{{error}}" popupOpened="true" (backEvent)="error = null"></app-popup>
                </form>
                <app-button text="Créer mon compte" (clickEvent)="signUp()" [style]="'border full'"></app-button>
            </div>
        </div>
    </div>
</main>

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { IEvent } from 'src/app/models/event.model';
import { GetEvent } from '../../../getEvent';

@Component({
  selector: 'app-event-access-control-operator-create',
  templateUrl: './event-access-control-operator-create.component.html',
  styleUrls: ['./event-access-control-operator-create.component.scss'],
  providers: [GetEvent]
})
export class EventAccessControlOperatorCreateComponent implements OnInit {
  public event: IEvent;
  public operatorForm: FormGroup;

  constructor(
    private router: Router,
    private getEvent: GetEvent,
    private formBuilder: FormBuilder
  ) { }

  async ngOnInit(): Promise<void> {
    this.event = await this.getEvent.get();
    this.initForm();
  }

  initForm(): void {
    this.operatorForm = this.formBuilder.group({
      lastName: new FormControl(),
      firstName: new FormControl(),
      email: new FormControl(),
      phone: new FormControl(),
      control: new FormControl(),
    });
  }

  goBack(): void {
    this.router.navigate([`evenements/${this.event._id}/controle-d-acces/operateurs`]);
  }

}

<main id="auth_page" class="container">
    <div id="auth_img">
        <div class="inner">
            <div class="imgs">
                <div class="logo">
                    <img src="assets/images/logo_white.svg" alt="" />
                </div>
                <div class="baseline">
                    <img src="assets/images/baseline_white.svg" alt="" />
                </div>
            </div>
        </div>
    </div>
    <div id="auth_form">
        <div class="inner">
            <div class="form-ctn">
                <h1>Mot de passe oublié</h1>
                <ng-container [ngSwitch]="currentStep">
                    <form *ngSwitchCase="0" [formGroup]="emailForm">
                        <div class="form_fields">
                            <div class="form_item">
                                <label for="email">Adresse e-mail</label>
                                <input id="email" type="email" formControlName="email">
                            </div>
                        </div>
                        <app-button [loadingOff]="loadingState$"  [disabled]="emailForm.invalid" type="submit" text="Confirmer" (clickEvent)="sendEmail()"
                            [style]="'plain full'"></app-button>
                    </form>
                    <form *ngSwitchCase="1" [formGroup]="codeForm">
                        <div class="form_fields">
                            <div class="form_item">
                                <label for="code">Veuillez entrer le code de 5 chiffres qui vient de vous être envoyé par
                                    email</label>
                                <input maxlength="5" minlength="5" id="code" type="text" formControlName="code">
                            </div>
                        </div>
                        <app-button [loadingOff]="loadingState$"  [disabled]="codeForm.invalid" type="submit" text="Confirmer" (clickEvent)="sendCode()"
                            [style]="'plain full'"></app-button>
                    </form>
                    <form *ngSwitchCase="2" [formGroup]="passwordsForm">
                        <h2>Vous pouvez désormais réinitialiser votre mot de passe</h2>
                        <div class="form_fields">
                            <div class="form_item">
                                <label for="password">Nouveau mot de passe</label>
                                <input (keyup)="checkPasswords()" id="password" type="password" formControlName="password">
                            </div>
                            <div class="form_item" >
                                <label for="confirm-password">Confirmer nouveau mot de passe</label>
                                <input (keyup)="checkPasswords()" [ngClass]="{'red_input': passwordIncorrect}" id="confirm-password" type="password"
                                    formControlName="confirmPassword">
                                <p class="error">
                                    <span *ngIf="passwordIncorrect">Les mots de passes doivent correspondre</span>
                                </p>
                            </div>
                        </div>
                        <app-button [loadingOff]="loadingState$"  [disabled]="passwordsForm.invalid" type="submit" text="Confirmer"
                            (clickEvent)="resetPassword()" [style]="'plain full'"></app-button>
                    </form>
                </ng-container>
                <app-button text="Annuler" (clickEvent)="goBack()" [style]="'border full'"></app-button>
                <app-popup *ngIf="error != null" title="Une erreur est survenue" message="{{error}}" popupOpened="true"
                    (backEvent)="error = null"></app-popup>
            </div>
        </div>
    </div>

</main>

import { Injectable } from '@angular/core';
import { Provider } from '../provider';
import { IPromoCode } from 'src/app/models/promo-code.model';
import { Observable } from 'rxjs';
import { APIResponse } from 'src/app/models/type.definition';

@Injectable()
export class PromoCodeService extends Provider<IPromoCode> {
  protected url = 'events/promo-codes';

  public generate(body: Partial<IPromoCode>, eventId: string): Observable<APIResponse> {
    return this.httpClient.post(this.getUrl(`generate/${eventId}`), body);
  }
}

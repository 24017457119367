<div>
  <div id="page_header" class="container">
    <div id="page_header_left">
      <h1>Tous les événements</h1>
    </div>
  </div>
  <ng-container *ngIf="data">
    <section id="block_first" class="container" *ngIf="!totalData">
      <p class="description">
        Aucun événement créé pour le moment
      </p>
      <figure>
        <img src="assets/images/visuel_evenement.svg" />
      </figure>
    </section>
    <section *ngIf="totalData">
      <div *ngIf="filtersForm && totalData" id="filters_wrap" class="container"
          [ngClass]="{ 'more_filters': moreFilters }">
          <form id="filters_form" [formGroup]="filtersForm">
              <app-search-input placeholder="Rechercher un événement" (sendInput)="setSearch($event)"></app-search-input>
              <div class="fields">
                  <div class="input_wrap select">
                      <select id="filter_accounting" (change)="selectedAccounting = $event.target.value">
                          <option [attr.selected]="selectedAccounting == null ? true : null" value="">
                              Reddition de compte
                          </option>
                          <option [attr.selected]="selectedAccounting == 'true' ? true : null" value="true">
                              Envoyée
                          </option>
                          <option [attr.selected]="selectedAccounting == 'false' ? true : null" value="false">
                              Non envoyée
                          </option>
                      </select>
                      <svg role="img" title="chevron" class="icon-chevron">
                          <use href="assets/images/sprite.svg#icon-chevron"></use>
                      </svg>
                  </div>
                  <div class="input_wrap select">
                      <select id="filter_status" (change)="selectedStatus = $event.target.value">
                          <option [attr.selected]="selectedStatus == null ? true : null" value="">
                              Statut billetterie
                          </option>
                          <option [attr.selected]="selectedStatus == 'draft' ? true : null" value="draft">
                              Brouillon
                          </option>
                          <option [attr.selected]="selectedStatus == 'published' ? true : null" value="published">
                              Publié
                          </option>
                          <option [attr.selected]="selectedStatus == 'passed' ? true : null" value="passed">
                              Passé
                          </option>
                          <option [attr.selected]="selectedStatus == 'closed' ? true : null" value="closed">
                              Fermé
                          </option>
                      </select>
                      <svg role="img" title="chevron" class="icon-chevron">
                          <use href="assets/images/sprite.svg#icon-chevron"></use>
                      </svg>
                  </div>
                  <span id="more_filters" (click)="moreFilters = !moreFilters">
                      {{moreFilters ? "Moins" : "Plus"}} de filtres
                  </span>
              </div>
              <div id="filters_more" *ngIf="moreFilters">
                  <div class="input_wrap date small">
                      <div class="input_date">
                          <input id="filter_date" type="date" formControlName="startDate">
                          <svg role="img" title="calendar" class="calendar-icon">
                              <use href="assets/images/sprite.svg#icon-calendar"></use>
                          </svg>
                      </div>
                  </div>
                  <div class="input_wrap date small">
                      <div class="input_date">
                          <input id="filter_end_date" type="date" formControlName="endDate">
                          <svg role="img" title="calendar" class="calendar-icon">
                              <use href="assets/images/sprite.svg#icon-calendar"></use>
                          </svg>
                      </div>
                  </div>
              </div>
              <div class="form_actions">
                  <app-button [loadingOff]="loadingState$" [style]="'plain'" type="submit" text="Filtrer"
                      (clickEvent)="filter()"></app-button>
              </div>
          </form>
      </div>
      <div *ngIf="totalData" class="between_filter_and_list container">
          <h2>{{currentTotalData}} événement{{currentTotalData > 1 ? 's' : ''}} trouvé{{currentTotalData > 1 ? 's' : ''}}</h2>
      </div>

      <app-main-table *ngIf="data.length; else noData" [ref]="this" (clickEvent)="selectEvent($event)"
        idSection="admin_content" idTable="table_admin" tableType="admin" id="table_admin" [columns]="tableOptions"
        [datum]="data"></app-main-table>
      <ng-template #noData>
        <section id="block_first" class="container">
          <h2>Aucun événement ne correspond à votre recherche !</h2>
          <figure>
            <img src="assets/images/visuel_evenement.svg" />
          </figure>
        </section>
      </ng-template>
      <app-pager *ngIf="totalPages" [totalPages]="totalPages" [page]="page" (changePage)="changePage($event)">
      </app-pager>
    </section>
  </ng-container>
</div>

<app-popup *ngIf="noEmail" (backEvent)="noEmail = false" title="Aucune adresse e-mail trouvée"
  message="Cette organisation n'a renseigné aucune adresse e-mail"></app-popup>

<app-popup *ngIf="organisationToEdit" (backEvent)="organisationToEdit = null"
  [title]="'Administrer l\'organisation ' + organisationToEdit.name" [inputs]="[['text'], ['number', 'number']]"
  [formCat]="['Paiement', 'Commissions']"
  [inputDisabled]="[[false], [false, false]]"
  [labels]="[['Identifiant Stripe'] ,['Commission variable', 'Commission fixe']]"
  [currentValues]="[[stripeGateway?.accountId || ''], [organisationToEdit.commission.percentage, organisationToEdit.commission.flat / 100]]"
  [suffixes]="[[], ['%', '€']]" buttonText="Valider" (clickEvent)="editOrganisationOk($event)"></app-popup>

  <app-delete-popup *ngIf="elementToDelete" [name]="elementToDelete.name" type='événement' [startWithVowel]="true"
  (clickEvent)="deleteOk()" (close)="elementToDelete = null"></app-delete-popup>
import { Component, OnInit, OnDestroy } from '@angular/core';
import User from 'src/app/models/user.model';
import { Subscription } from 'rxjs';
import { UserService, EventService, OrganisationService, EventSiteService, InstagramService } from 'src/app/providers';
import { StorageService } from 'src/app/services/storage.service';
import { Router } from '@angular/router';
import { RouteType } from 'src/app/models/type.definition';
import { RoutesService } from 'src/app/services/routes.service';
import { IEventsStats } from 'src/app/models/stats.model';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  private _subscription = new Subscription();
  public eventsCount: number;
  public eventsStats: IEventsStats;
  public me: User;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private eventService: EventService,
    private eventSiteService: EventSiteService,
    private instagramService: InstagramService,
    private router: Router,
    private organisationService: OrganisationService
  ) { }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ngOnInit(): void {
    // Add instagram auth

    this.route.queryParams.subscribe(_queryParams => {
      if (_queryParams.code) {
        var loc = window.localStorage.getItem('instaLoc').split('/');
        var location = ([loc[3] + "/" + loc[4] + "/" + loc[5] + "/" + loc[6] + "/" +loc[7]]).toString();
        this.instagramService.getShortToken(_queryParams.code).subscribe((shortToken) => {
          this.instagramService.getLongToken(shortToken.access_token).subscribe((longToken) => {
            this.instagramService.getMedias(longToken.access_token).subscribe((medias) => {
              let mediaList = medias.data.map(element => {
                return element.media_url
              })
              mediaList.length = 6;
              this.instagramService.pushMedias(loc[7], mediaList).subscribe((element)=> {

              },
              (error) => {
                this.router.navigate([location]);
              })
            this.eventSiteService.update(loc[7], { instagramAuth: { instagramToken: longToken.access_token, instagramAppId: shortToken.user_id.toString() }}).toPromise();
            this.router.navigate([location]);
            },
            (error) => {
              this.router.navigate([location]);
            });

          },
          (error) => {
            this.router.navigate([location]);
          })
        },
        (error) => {
          this.router.navigate([location]);
        })
      }
    });

    this._subscription.add(this.organisationService.selected$.subscribe(organisation => {
      if (organisation) {
        const organisationId = organisation._id;
        if (!organisationId) {
          this.router.navigate(['organisations'])
        }
        else {
          this._subscription.add(this.userService.me$.subscribe(me => {
            this.me = me;
          }));
          this._subscription.add(this.eventService.getAllStats().subscribe(stats => {
            this.eventsStats = stats;
          }))
        }
      }
    }));
  }

  navigate(route: string, index) {
    this.router.navigate([route]);
  }

  createEvent() {
    this.router.navigate(['evenements/creer-un-evenement']);
  }

}

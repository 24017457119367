<div (click)="clickEvent()">
	<app-notification-displayer></app-notification-displayer>
	<app-header *ngIf="isAuth && (organisationId || isAdmin)"></app-header>
	<header id="header_simple" *ngIf="isAuth && (!organisationId  && !isAdmin)">
		<div class="container">
			<figure>
				<img src="assets/images/logo_black.svg" />
			</figure>
			<app-button text="Déconnexion" (clickEvent)="logOut()" [style]="'link'"></app-button>
		</div>
	</header>
	<div *ngIf="isAuth && (organisationId || isAdmin)" id="sidebar_wrapper" [ngClass]="{'active' : showSidebar }">
		<i id="burger_menu" (click)="toggleSidebar()">
			<svg role="img" title="menu" class="icon-menu">
				<use [attr.href]="'assets/images/sprite.svg#icon-' + [showSidebar ? 'close' : 'menu']"></use>
			</svg>
		</i>
		<app-sidebar [isAdmin]="isAdmin"></app-sidebar>
	</div>
	<div id="main_container" [ngClass]="{'has_sidebar': (isAuth && (organisationId || isAdmin))}">
		<div id="main_bg">
			<router-outlet></router-outlet>
		</div>
	</div>
</div>
<div>
  <div id="page_header" class="container">
    <div id="page_header_left">
      <h1>Tous les participants</h1>
    </div>
  </div>
  <ng-container *ngIf="data">
    <section id="block_first" class="container" *ngIf="!totalData">
      <p class="description">
        Aucun participant créé pour le moment
      </p>
      <figure>
        <img src="assets/images/visuel_evenement.svg" />
      </figure>
    </section>
    <section *ngIf="totalData">
      <div *ngIf="filtersForm && totalData" id="filters_wrap" class="container"
          [ngClass]="{ 'more_filters': moreFilters }">
          <form id="filters_form" [formGroup]="filtersForm">
					<app-search-input class="medium" placeholder="Rechercher un participant"
						(sendInput)="setSearch($event)">
					</app-search-input>

					<app-search-input class="medium" placeholder="Numéro de commande"
						[preData]="filtersForm.value.orderNumber" (sendInput)="searchOrderNumber($event)">
					</app-search-input>


					<div class="fields">
						 <span id="more_filters" (click)="moreFilters = !moreFilters">
                            {{moreFilters ? "Moins" : "Plus"}} de filtres
                        </span>
					</div>
					<div id="filters_more" *ngIf="moreFilters">
						<div class="input_wrap select">
                            <select id="filter_origin" (change)="selectedOrigin = $event.target.value">
                                <option [attr.selected]="selectedOrigin == null ? true : null" value="">
                                    Toutes origines
                                </option>
                                <option [attr.selected]="selectedOrigin == 'web' ? true : null" value="web">
                                    Web
                                </option>
                                <option [attr.selected]="selectedOrigin == 'ticketOffice' ? true : null" value="ticketOffice">
                                    Guichet
                                </option>
                                <option [attr.selected]="selectedOrigin == 'invitation' ? true : null" value="invitation">
                                    Invitation
                                </option>
                                <option [attr.selected]="selectedOrigin == 'stockImpression' ? true : null" value="stockImpression">
                                    Impression de stock
                                </option>
                            </select>
                            <svg role="img" title="chevron" class="icon-chevron">
                                <use href="assets/images/sprite.svg#icon-chevron"></use>
                            </svg>
                        </div>
						<div class="input_wrap date small">
							<div class="input_date">
								<input id="filtr_start_date" type="date" formControlName="createdAt__$gte">
								<svg role="img" title="calendar" class="calendar-icon">
									<use href="assets/images/sprite.svg#icon-calendar"></use>
								</svg>
							</div>
						</div>
                        <div class="input_wrap date small">
							<div class="input_date">
								<input id="filtr_end_date" type="date" formControlName="createdAt__$lte">
								<svg role="img" title="calendar" class="calendar-icon">
									<use href="assets/images/sprite.svg#icon-calendar"></use>
								</svg>
							</div>
						</div>

                    </div>
					<div class="form_actions">
						<app-button [style]="'plain'" type="submit" text="Rechercher" [loadingOff]="loadingState$"
							(clickEvent)="filter()"></app-button>
					</div>
				</form>
      </div>
      <div *ngIf="totalData" class="between_filter_and_list container">
          <h2>{{currentTotalData}} participant{{currentTotalData > 1 ? 's' : ''}} trouvé{{currentTotalData > 1 ? 's' : ''}}</h2>
      </div>

      <app-main-table
        *ngIf="data?.length; else noData"
        idSection="participants_content"
		idTable="table_participants" tableType="participants" [columns]="tableOptions" [datum]="data"
		[ref]="this">
      </app-main-table>

      <ng-template #noData>
        <section id="block_first" class="container">
          <h2>Aucun participant ne correspond à votre recherche !</h2>
          <figure>
            <img src="assets/images/visuel_evenement.svg" />
          </figure>
        </section>
      </ng-template>
      <app-pager *ngIf="totalPages > 1" [totalPages]="totalPages" [page]="page" (changePage)="changePage($event)">
      </app-pager>
    </section>
  </ng-container>
</div>

<div class="full_loader" *ngIf="fullLoader">
  <div></div>
</div>


<app-popup *ngIf="ticketToResend" (backEvent)="ticketToResend = null"
	[title]="'Renvoyer le ticket N°' + ticketToResend.ticketNumber"
	message="Le ticket sera envoyé à l'adresse e-mail utilisé lors de la commande" buttonText="Renvoyer"
	(clickEvent)="resendConfirmOk()"></app-popup>

<app-delete-popup *ngIf="ticketToDelete"
	[customText]="'Désirez-vous supprimer ' + getParticipantName(ticketToDelete) + ' des participants de l\événement ?'"
	type='participant' (clickEvent)="deleteOk()" (close)="ticketToDelete = null"></app-delete-popup>
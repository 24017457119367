import { IForm, IFormField } from './form.model';
import { ICurrency } from './currency.model';
import { ISite } from './site.model';
import { EditableOrganisationData } from './editable-data';
import { EntityStocks, ITicketTypeCategory } from './ticket.model';
import { IEventCategory } from './event-category.model';
import { ICountry } from './country.model';
import { IOrganisation } from './organisation.model';
import { IEventStatistics } from './stats.model';
import { ITax } from './tax.model';
import User from './user.model';
import { IMedia } from './media.model';

export interface IEvent extends EditableOrganisationData {
  name?: string;
  isAccountingSent?: boolean;
  isAccountingSentTranslated?: string;
  categoriesIds?: IEventCategory[];
  organisation?: IOrganisation;
  organisationName?: string;
  date?: string;
  eventCode?: string;
  eventType?: 'presential' | 'remote';
  eventLink?: string;
  statistics?: IEventStatistics;
  stringSellingPrice?: string;
  participants?: number;
  place?: [
    {
      name?: string;
      address?: string;
      address2?: string;
      zipcode?: string;
      city?: string;
      countryId?: ICountry;
      lat?: string;
      lng?: string;
    }
  ];
  dates?: {
    type?: 'interval' | 'sessions';
    sessionsIds?: string[];
    startDate?: Date;
    endDate?: Date;
    timeZone?: string;
  };
  description?: string;
  stats?: {
    costPrice: number;
    participants: number;
  };
  bookType?: 'registration' | 'ticketing' | 'nft';
  security?: {
    qrcode? : {
      timeBeforeEvent?: number
    }
  };
  status?: 'draft' | 'published' | 'passed' | 'closed';
  translateStatus?: 'Brouillon' | 'Publié' | 'Passé' | 'Fermé';
  isInSearch?: boolean;
  emailAlerts?: {
    eachOrder?: boolean;
    dailySummary?: boolean;
    weeklySummary?: boolean;
    customMailingList?: boolean;
    mailingList?: string[];
  };
  mailingList?: string[];
  ticketing?: ITicketing;
  ticketOffice?: ITicketOffice;
  langCode?: string;
  commission?: {
    flat?: number;
    percentage?: number;
  };
  commissionIncreased?: boolean;
  forms?: IForm[];
  site?: ISite;
  ticketTypeCategories?: ITicketTypeCategory[];
  productTypeCategories?: ITicketTypeCategory[];
  useRoomPlacement?: boolean;
  seatsIoId?: string;
  authorId?: User;
}

export interface ITicketOffice {
  skipOrderInfo?: boolean
  skipTicketInfo?: boolean
  skipAddonInfo?: boolean
  includeProductCommission?: boolean
  includeTicketCommission?: boolean
  paymentMethods?: string[]
  stripeReaders?: string[]
  extraPaymentMethods?: {
    label: string
    slug: string
  }[]
}

export interface ITicketing {
  hasBadge?: boolean;
  hasInsurance?: boolean;
  quota?: number;
  stock?: number;
  stocks?: EntityStocks;
  totalPrice?: {
    sellingPrice?: number;
    costPrice?: number;
  };
  taxId?: ITax;
  termAndConditions?: {
    url?: string;
    text?: string;
  };
  bookingTime?: number;
  defaultCurrencyId?: ICurrency;
  currenciesIds?: string[];
  date?: {
    startDate?: Date;
    endDate?: Date;
    timezone?: string;
  };
  ticketVisual?: {
    text?: string;
    imageId?: IMedia;
    image?: Blob;
  };
  badgeVisual?: {
    text?: string;
    footerText?: string;
    imageId?: IMedia;
    mainColor?: string;
    formFieldId?: IFormField;
    badgeImage?: Blob;
  };
  inOut: boolean;
  limitAddonAmount?: number;
  minimumAddonAmount?: number;
  showCountDown?: boolean;
  showRemainingPlace?: boolean;
}

export type IEventPublishableStatus = {
  publishable: boolean;
  rateCreated: boolean;
  ticketCustomized: boolean;
  quotaDefined: boolean;
  eventCustomized: boolean;
  eventWidgetCreated: boolean;
  allConfigured: boolean;
};

export function translateStatus(
  value: string
): 'Brouillon' | 'Publié' | 'Passé' | 'Fermé' {
  switch (value) {
    case 'draft':
      return 'Brouillon';
    case 'published':
      return 'Publié';
    case 'passed':
      return 'Passé';
    case 'closed':
      return 'Fermé';
  }
}

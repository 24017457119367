import { IEvent } from "./event.model";
import { IOrganisation } from "./organisation.model";
import User from "./user.model";

export type TranslatedMemberRole = 'Organisateur' | 'Collaborateur' | 'Opérateur' | 'Lecteur'
export type MemberRole = 'reader' | 'organizer' | 'collaborator' | 'operator'

export type TranslatedMemberStatus = 'En attente d\'activation' | 'Actif' | 'Bloqué'
export type MemberStatus = 'pendingActivation' | 'active' | 'blocked'
export interface IMember {
    _id?: string,
    restrictEventIds?: IEvent[],
    organisationId?: IOrganisation
    userId?: User
    status?: MemberStatus
    translatedStatus?: TranslatedMemberStatus
    roles?: MemberRole[];
    translatedRoles?: TranslatedMemberRole[];
    currentRole?: string;
    name?: string;
    email?: string;
    avatarId?: {
        _id?: string,
        url?: string
    },
    isActiveInCurrentOrganisation?: boolean;
    eventsNames?: string;
    eventsNamesArray?: string[];
}


export function translateStatus(value: MemberStatus): TranslatedMemberStatus {
    switch (value) {
      case 'pendingActivation': return 'En attente d\'activation';
      case 'blocked': return 'Bloqué';
      case 'active': return 'Actif';
    }
}

export function translateRoles(value: MemberRole[]): TranslatedMemberRole[] {
    return value.map(role => {
        switch (role) {
            case 'reader': return 'Lecteur';
            case 'organizer': return 'Organisateur';
            case 'collaborator': return 'Collaborateur';
            case 'operator': return 'Opérateur';
        }
    })
}
import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, HostListener, ViewChildren, QueryList, ElementRef, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
// Animations
import { moreAnimation } from 'src/app/animations/animations';
import { ClickOutService } from 'src/app/services/click-out.service';
import { Subscription } from 'rxjs';

type DraggedElement = {
  element: HTMLLIElement,
  order: number,
  id: string,
  parentId: string
}

@Component({
  selector: 'app-main-table',
  templateUrl: './main-table.component.html',
  styleUrls: ['./main-table.component.scss'],
  animations: [
    moreAnimation
  ]
})
export class MainTableComponent implements AfterViewChecked, OnInit, OnDestroy {
  private _subscription = new Subscription();
  @ViewChildren('liSubElement') liSubElement: QueryList<ElementRef<HTMLLIElement>>;
  @Output() clickEvent: EventEmitter<any> = new EventEmitter();
  @Output() dragEvent: EventEmitter<{ id: string, parentId: string, order: number }> = new EventEmitter();
  @Output() selectRow: EventEmitter<string[]> = new EventEmitter();

  @Input() columns: any[];
  @Input() datum: any[];
  @Input() idSection: string;
  @Input() idTable: string;
  @Input() tableType: string;
  @Input() ref: any;
  @Input() subTableKey: string;
  @Input() noHeader: boolean;
  @Input() loading: boolean;
  @Input() tableClass: string;

  public gotClickEvent: boolean;
  public draggable: boolean;
  public arrayLoop: number[];
  public parentDragging: boolean;
  public toggleDropdown: boolean = false;
  private isDragging: boolean;
  private currentDraggedElement: DraggedElement;
  private draggingClone: HTMLLIElement | HTMLUListElement;
  private lastMousePose: { x: number, y: number };
  private endAnimationInProgress: boolean;
  private selectedRowsIds: string[] = [];

  constructor(private clickOutService: ClickOutService, private cd: ChangeDetectorRef) { }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.gotClickEvent = this.clickEvent.observers.length > 0;
    this.draggable = this.dragEvent.observers.length > 0;
    this.columns = this.columns.map(_ => ({ ..._, isSorted: _.key === 'name' }))
    this.initArrayLoop();
    this._subscription.add(this.clickOutService.closeToggle$.subscribe(() => {
      this.closeAllToggle();
    }));
    console.log("====", this.columns, this.datum);

  }

  ngAfterViewChecked(): void {
    if (this.isDragging && !this.currentDraggedElement.element) {
      this.getDraggedElement();
    }
  }

  sortTable(column: any) {
    column.isSorted = !column.isSorted;
    const order = column.isSorted ? 'asc' : 'desc'
    column.sortMethod(order)
  }

  checkBoxTableChange(id: string): void {
    const idIndex = this.selectedRowsIds.indexOf(id)
    if (idIndex === -1) {
      this.selectedRowsIds.push(id);
    }
    else {
      this.selectedRowsIds.splice(idIndex, 1);
    }
    this.selectRow.emit(this.selectedRowsIds);

  }
  checkRowIsSelected(id: string): boolean {
    return this.selectedRowsIds.indexOf(id) === -1 ? false : true
  }

  initArrayLoop() {
    this.arrayLoop = [];
    for (let i = 0; i < this.columns.length; i++) {
      this.arrayLoop.push(i);
    }
  }

  closeAllToggle(data = this.datum) {
    data = data.map(_ => {
      _.toggleOpen = false;
      if (this.subTableKey && _[this.subTableKey]) {
        this.closeAllToggle(_[this.subTableKey]);
      }
      return _;
    });
  }

  openToggle(data: any) {
    this.closeAllToggle();
    setTimeout(() => data.toggleOpen = !data.toggleOpen)
  }

  onRowClick(data: any) {
    this.clickEvent.emit(data);
  }

  toggleClick(link) {

  }

  sort() {
    this.datum.forEach(data => {
      this.sortByOrder(data[this.subTableKey]);
    })
  }

  sortByOrder(data: { order: number }[]) {
    return data.sort((a, b) => a.order > b.order ? 1 : -1);
  }

  dragging(event: PointerEvent) {
    if (!this.isDragging && !this.endAnimationInProgress && !event.button) {
      document.body.style.cursor = "pointer";
      this.isDragging = true;
      this.currentDraggedElement = this.getTargetLi(event);
      this.parentDragging = !this.currentDraggedElement.id;
      if (this.parentDragging) {
        this.setParentClone(event);
        this.hideOrShowChildren(this.currentDraggedElement.element.nextElementSibling, true);
      }
      else {
        this.draggingClone = this.setClone(event);
      }
      this.currentDraggedElement.element.classList.add("is_dragged");
    }
  }

  hideOrShowChildren(element: Element, hide: boolean): void {
    for (const child of this.getChild(element)) {
      child.classList[hide ? "add" : "remove"]("child_hidden");
    }
  }

  * getChild(element: Element): Generator<HTMLLIElement, void, void> {
    if (!(element instanceof HTMLLIElement) || element.id == "") {
      return
    }
    yield element;
    yield* this.getChild(element.nextElementSibling);
  }

  setParentClone(event: PointerEvent | MouseEvent): void {
    this.draggingClone = this.setClone(event, this.currentDraggedElement.element, true);
    this.currentDraggedElement.element.parentElement.appendChild(this.draggingClone);
    const topBase = this.getPxNumberValue(this.draggingClone.style.top);
    let i = 1;
    for (const child of this.getChild(this.currentDraggedElement.element.nextElementSibling)) {
      const li = this.setClone(event, child);
      li.style.top = `${topBase + (child.offsetHeight * i++)}px`;
      this.currentDraggedElement.element.parentElement.appendChild(li);
    }
  }

  setClone(event: PointerEvent | MouseEvent, element = this.currentDraggedElement.element, keepColor: boolean = false): HTMLLIElement {
    const cloneLi = element.cloneNode(true) as HTMLLIElement;
    cloneLi.style.position = 'fixed';
    cloneLi.style.backgroundColor = keepColor ? cloneLi.style.backgroundColor : 'white';
    cloneLi.style.top = `${event.y - (event.offsetY || 0)}px`;
    cloneLi.style.left = `${event.x - (event.offsetX || 0)}px`;
    cloneLi.style.pointerEvents = "none";
    cloneLi.style.width = element.offsetWidth + 'px'
    this.lastMousePose = { x: event.pageX, y: event.pageY };
    if (!this.parentDragging) {
      element.parentElement.appendChild(cloneLi);
    }
    return cloneLi
  }

  setParentClonePosition(event: PointerEvent | MouseEvent): void {
    this.setClonePosition(event);
    for (const child of this.getChild(this.draggingClone.nextElementSibling)) {
      this.setClonePosition(event, child);
    }
  }

  setClonePosition(event: PointerEvent | MouseEvent, element = this.draggingClone): void {
    const x = event.pageX - this.lastMousePose.x;
    const y = event.pageY - this.lastMousePose.y;
    const top = this.getPxNumberValue(element.style.top);
    const left = this.getPxNumberValue(element.style.left);
    element.style.top = `${top + y}px`;
    element.style.left = `${left + x}px`;
  }

  getTargetLi(event: Partial<PointerEvent | MouseEvent>): DraggedElement {
    const element = this.getLi(event.target as HTMLElement);
    if (element) {
      const parentId = this.getParentId(element);
      if (parentId) {
        return {
          element,
          parentId: parentId !== '1' ? parentId : null,
          order: parseInt(element.dataset.order),
          id: element.id
        }
      }
    }
  }

  @HostListener('document:mousemove', ['$event']) onMouseMove(event: MouseEvent): void {
    if (this.isDragging) {
      let target = this.getTargetLi(event);
      this.parentDragging ? this.setParentClonePosition(event) : this.setClonePosition(event);
      this.lastMousePose = { x: event.pageX, y: event.pageY };
      if (target) {
        if (this.parentDragging) {
          target = this.getParentLi(target.element);
          if (target.id === "" && target.parentId && target.order !== this.currentDraggedElement.order) {
            this.changeOrder(this.datum, target, event, null);
            this.sortByOrder(this.datum);
          }
        }
        else {
          if (this.currentDraggedElement.parentId !== target.parentId) {
            this.changeParent(target.parentId, !target.id);
            this.currentDraggedElement.element = null;
          }
          if (target.order !== this.currentDraggedElement.order) {
            const parent = this.datum.find(_ => _._id === this.currentDraggedElement.parentId);
            if (target.id) {
              this.changeOrder(parent, target, event);
            }
            this.sortByOrder(parent[this.subTableKey]);

          }
        }
      }
    }
  }

  getParentLi(element: Element): DraggedElement {
    if (!(element instanceof HTMLLIElement)) {
      return null;
    }
    else if (element.id) {
      return this.getParentLi(element.previousElementSibling);
    }
    else {
      return this.getTargetLi({ target: element });
    }
  }

  getDraggedElement(): void {
    const li = this.liSubElement.toArray().map(_ => _.nativeElement).find(_ => _.id == this.currentDraggedElement.id);
    li.classList.add('is_dragged');
    this.currentDraggedElement.element = li;
  }

  @HostListener('document:mouseup') async onMouseUp(): Promise<void> {
    if (this.isDragging) {
      this.isDragging = false;
      await this.endAnimation();
      if (this.parentDragging) {
        this.removeChildren(this.draggingClone.nextElementSibling);
        this.hideOrShowChildren(this.currentDraggedElement.element.nextElementSibling, false);
      }
      this.currentDraggedElement.element.classList.remove('is_dragged');
      this.currentDraggedElement.element.parentElement.removeChild(this.draggingClone);
      delete this.currentDraggedElement.element;
      document.body.style.cursor = "default";
      this.dragEvent.emit(this.currentDraggedElement);
      this.currentDraggedElement = null;
      this.parentDragging = false;
    }
  }

  removeChildren(element: Element): void {
    const children = []
    for (const child of this.getChild(element)) {
      children.push(child);
    }
    children.forEach(child => this.currentDraggedElement.element.parentElement.removeChild(child))
  }

  async endAnimation(): Promise<void> {
    this.endAnimationInProgress = true;
    const frames = 50;
    const { x, y } = this.currentDraggedElement.element.getBoundingClientRect();
    const moveX = (x - this.getPxNumberValue(this.draggingClone.style.left)) / frames;
    const moveY = (y - this.getPxNumberValue(this.draggingClone.style.top)) / frames;
    for (let i = 0; i < frames; i++) {
      await new Promise((res) => {
        setTimeout(() => {
          this.draggingClone.style.left = this.addPxValue(this.draggingClone.style.left, moveX, true);
          this.draggingClone.style.top = this.addPxValue(this.draggingClone.style.top, moveY, true);
          res(null);
        }, 0)
        if (this.parentDragging) {
          for (const child of this.getChild(this.draggingClone.nextElementSibling)) {
            setTimeout(() => {
              child.style.left = this.addPxValue(child.style.left, moveX, true);
              child.style.top = this.addPxValue(child.style.top, moveY, true);
              res(null);
            }, 0)
          }
        }
      })
    }
    this.endAnimationInProgress = false;
  }

  addPxValue(currentValue: string, valueToAdd: number, plus: boolean): string {
    return `${this.getPxNumberValue(currentValue) + (plus ? valueToAdd : -valueToAdd)}px`;
  }

  getPxNumberValue(value: string): number {
    return parseFloat(value.split("px")[0]);
  }

  changeParent(newId: string, before: boolean): void {
    let oldParent: any[] = this.datum.find(_ => _._id == this.currentDraggedElement.parentId);
    let newParent: any[] = this.datum.find(_ => _._id == newId);
    this.resetArray(newParent, before);
    const index = oldParent[this.subTableKey].findIndex(data => data._id == this.currentDraggedElement.id);
    this.currentDraggedElement.order = before ? 0 : newParent[this.subTableKey].length;
    oldParent[this.subTableKey][index].order = this.currentDraggedElement.order;
    newParent[this.subTableKey].push(oldParent[this.subTableKey].splice(index, 1)[0]);
    this.resetArray(oldParent);
    this.currentDraggedElement.parentId = newId;
  }

  changeOrder(parent: any, target: DraggedElement, event: MouseEvent, key = this.subTableKey): void {
    const oldOrder = this.currentDraggedElement.order;
    const newOrder = this.parentDragging ? this.getNewParentOrder(event, target, oldOrder) : this.getNewOrder(event, target, oldOrder);
    this.reorganizeArray(parent, oldOrder, newOrder, key);
    this.currentDraggedElement.order = newOrder;
  }

  getNewOrder(event: MouseEvent, target: DraggedElement, oldOrder: number): number {
    const size = target.element.offsetHeight;
    const newOrder = target.order;
    const up = oldOrder < newOrder;
    const inc = event.offsetY < (size / 2) ? (up ? -1 : 0) : (up ? 0 : 1);
    return newOrder + inc;
  }

  getNewParentOrder(event: MouseEvent, target: DraggedElement, oldOrder: number): number {
    const eventTarget = event.target as HTMLLIElement;
    let currentOrder = 0;
    let totalElement = 1;
    for (const child of this.getChild(target.element.nextElementSibling)) {
      if (child.id === eventTarget.id) {
        currentOrder = totalElement;
      }
      totalElement++;
    }

    const baseHeight = eventTarget.offsetHeight
    const size = totalElement * baseHeight;
    let offset = event.offsetY < 0 ? 0 : event.offsetY;
    offset += baseHeight * currentOrder;
    const newOrder = target.order;
    const up = oldOrder < newOrder;
    const inc = offset < (size / 2) ? (up ? -1 : 0) : (up ? 0 : 1);
    return newOrder + inc;

  }

  resetArray(array: any, plusOne: boolean = false) {
    for (let i = 0; i < array[this.subTableKey].length; i++) {
      array[this.subTableKey][i].order = i + (plusOne ? 1 : 0);
    }
  }

  reorganizeArray(array: any, oldOrder: number, newOrder: number, key: string): void {
    const up = oldOrder < newOrder;
    for (let i = up ? oldOrder : newOrder; i <= (up ? newOrder : oldOrder); i++) {
      const element = key ? array[this.subTableKey][i] : array[i];
      if (!element) {
        break;
      }
      else if (element.id == this.currentDraggedElement[this.parentDragging ? "parentId" : "id"]) {
        element.order = newOrder
      }
      else {
        element.order = element.order + (up ? -1 : 1)
      }
    }
  }


  getLi(element: HTMLElement): HTMLLIElement {
    return element ? element.tagName == 'LI' ? element as HTMLLIElement : this.getLi(element.parentElement) : null;
  }

  getParentId(li: HTMLLIElement): string {
    if (li instanceof HTMLLIElement) {
      if (li.hasAttribute('data-parent-id')) {
        return li.dataset.parentId;
      }
      else {
        li = li.previousElementSibling as HTMLLIElement;
        return this.getParentId(li);
      }
    }
  }

}

<section class="container" *ngIf="me">
    <h1>Bonjour {{me.firstName}}</h1>
    <span class="sub_title">Sélectionnez l'organisation sur laquelle vous souhaitez travailler...</span>
    <ul id="list_organisations">
        <li *ngFor="let organisation of organisations">
            <button class="bg" (click)="goToOrganisation(organisation._id)">
                <figure>
                    <img [src]="organisation.imageId?.url ? organisation.imageId.url : 'assets/images/default-logo.png'" [alt]="organisation.name" />
                </figure>
                <h2>{{organisation.name}}</h2>
                <span class="button">Rejoindre cet espace</span>
            </button>
        </li>
        <li class="create_new">
            <button class="bg" (click)="createOrganisation()">
                <figure>
                    <i>
                        <svg role="img" title="add" class="icon-add">
                            <use href="assets/images/sprite.svg#icon-add"></use>
                        </svg>
                    </i>
                </figure>
                <span class="label">Nouvelle entité ?</span>
                <h2>Créer votre organisation</h2>
            </button>
        </li>
    </ul>
</section>
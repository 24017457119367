<div class="loading_container" [ngClass]="{ 'loaded' : data }">
    <span class="loader_global"></span>
    <div class="loading_content">
        <ng-container *ngIf="data">
            <div id="page_header" class="container">
                <h1>
                    Commandes
                    <span>({{totalData}} commande{{totalData > 1 ? 's' : ''}})</span>
                </h1>
                <div *ngIf="totalData" class="export_button_container" (click)="$event.stopPropagation()">
                    <app-button class="button_create" text="Exporter" [style]="'border blue light'" [icon]="'chevron'"
                        (clickEvent)="exportToggleOpen = false; actionToggleOpen = !actionToggleOpen; loadingState$.next();"
                        [loadingOff]="loadingState$">
                    </app-button>
                    <nav class="drop drop_more" *ngIf="actionToggleOpen" [@moreAnimation]>
                        <ul>
                            <li (click)="export('search')" (click)="$event.stopPropagation()">
                                Exporter la recherche
                            </li>
                            <li (click)="export('all')" (click)="$event.stopPropagation()">
                                Tout exporter
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <div *ngIf="filtersForm" id="filters_wrap" class="container"
                [ngClass]="{ 'more_filters': moreFilters }">
                <form id="filters_form" [formGroup]="filtersForm">
                    <app-search-input placeholder="Rechercher (nom, prenom, société...)" (sendInput)="setSearch($event)"></app-search-input>
                    <div class="fields">



                        <div class="input_wrap date small">
                            <div class="input_date">
                                <input id="filter_date" type="date" formControlName="createdAt__$gte">
                                <svg role="img" title="calendar" class="calendar-icon">
                                    <use href="assets/images/sprite.svg#icon-calendar"></use>
                                </svg>
                            </div>
                        </div>

                        <div class="input_wrap date small">
                            <div class="input_date">
                                <input id="filter_date" type="date" formControlName="createdAt__$lte">
                                <svg role="img" title="calendar" class="calendar-icon">
                                    <use href="assets/images/sprite.svg#icon-calendar"></use>
                                </svg>
                            </div>
                        </div>

                        <div class="input_wrap select">
                            <select id="filter_origin" (change)="selectedOrigin = $event.target.value">
                                <option [attr.selected]="selectedOrigin == null ? true : null" value="">
                                    Toutes origines
                                </option>
                                <option [attr.selected]="selectedOrigin == 'web' ? true : null" value="web">
                                    Web
                                </option>
                                <option [attr.selected]="selectedOrigin == 'ticketOffice' ? true : null" value="ticketOffice">
                                    Guichet
                                </option>
                                <option [attr.selected]="selectedOrigin == 'invitation' ? true : null" value="invitation">
                                    Invitation
                                </option>
                                <option [attr.selected]="selectedOrigin == 'stockImpression' ? true : null" value="stockImpression">
                                    Impression de stock
                                </option>
                                <option [attr.selected]="selectedOrigin == 'import' ? true : null" value="import">
                                    Import
                                </option>
                            </select>
                            <svg role="img" title="chevron" class="icon-chevron">
                                <use href="assets/images/sprite.svg#icon-chevron"></use>
                            </svg>
                        </div>

                        <span id="more_filters" (click)="moreFilters = !moreFilters">
                            {{moreFilters ? "Moins" : "Plus"}} de filtres
                        </span>
                    </div>
                    <div id="filters_more" *ngIf="moreFilters">

                        <div class="input_wrap">
                            <input type="text" placeholder="N° commande" formControlName="orderNumber__$like">
                        </div>

                        <div class="input_wrap has_suffix">
                            <input type="number" placeholder="Montant min" formControlName="totalPrice.sellingPrice__$gte">
                            <span class="suffix">€</span>
                        </div>
                         <div class="input_wrap has_suffix">
                            <input type="number" placeholder="Montant max" formControlName="totalPrice.sellingPrice__$lte">
                            <span class="suffix">€</span>
                        </div>
                        <div class="input_wrap select">
                            <select id="filter_status" (change)="selectedStatus = $event.target.value">
                                <option [attr.selected]="selectedStatus == null ? true : null" value="">
                                    Tous les statuts
                                </option>
                                <option [attr.selected]="selectedStatus == 'completed' ? true : null" value="completed">
                                    Complétée
                                </option>
                                <option [attr.selected]="selectedStatus == 'pending' ? true : null" value="pending">
                                    En attente
                                </option>
                                <option [attr.selected]="selectedStatus == 'canceled' ? true : null" value="canceled">
                                    Annulée
                                </option>
                                <option [attr.selected]="selectedStatus == 'draft' ? true : null" value="draft">
                                    Brouillon
                                </option>
                            </select>
                            <svg role="img" title="chevron" class="icon-chevron">
                                <use href="assets/images/sprite.svg#icon-chevron"></use>
                            </svg>
                        </div>
                        <div class="input_wrap select">
                            <select id="filter_origin" formControlName="eventWidgetId">
                                <option value="">
                                    Tous les widgets
                                </option>
                                <option *ngFor=" let widget of widgets" [value]="widget._id">{{widget.name}}</option>
                            </select>
                            <svg role="img" title="chevron" class="icon-chevron">
                                <use href="assets/images/sprite.svg#icon-chevron"></use>
                            </svg>
                        </div>
                        <div class="input_wrap select">

                            <select id="filter_insurance" formControlName="insurance.type">
                                <option value="">
                                    Avec et sans assurance
                                </option>
                                <option value="neat">
                                    Avec assurance
                                </option>
                            </select>
                            <svg role="img" title="chevron" class="icon-chevron">
                                <use href="assets/images/sprite.svg#icon-chevron"></use>
                            </svg>
                        </div>

                    </div>
                    <div class="form_actions">
                        <app-button [loadingOff]="loadingState$" [style]="'plain'" type="submit" text="Filtrer"
                            (clickEvent)="filter()"></app-button>
                    </div>
                </form>
            </div>
            <div *ngIf="currentTotalData" class="between_filter_and_list container">
                <h2>{{currentTotalData}} commande{{currentTotalData > 1 ? 's' : ''}} trouvée{{currentTotalData > 1 ? 's' : ''}}</h2>
            </div>

            <div *ngIf="data.length else noData">
                <app-main-table idSection="orders_content" idTable="table_orders" tableType="orders" [loading]="loading"
                    [columns]="tableOptions" [datum]="data" [ref]="this" (selectRow)="selectedOrderIds = $event">
                </app-main-table>
                <app-pager *ngIf="totalPages > 1" [totalPages]="totalPages" [page]="page" (changePage)="changePage($event)">

                </app-pager>
            </div>
            <ng-template #noData>
                <section id="block_first" class="container">
                    <h2 *ngIf="totalData">Aucune commande ne correspond à votre recherche !</h2>
                    <h2 *ngIf="!totalData">Aucune commande n'a été passée pour le moment !</h2>
                    <figure>
                        <img src="assets/images/visuel_evenement.svg" />
                    </figure>
                </section>
            </ng-template>
        </ng-container>
    </div>
</div>

<app-popup
    *ngIf="resendConfirmOfOrder"
    (backEvent)="resendConfirmOfOrder = null"
    [title]="'Renvoyer la confirmation de la commande N°' + resendConfirmOfOrder.orderNumber"
    message="L'e-mail de confirmation ainsi que les tickets concernées seront envoyés à l'adresse e-mail ayant été utilisée pour cette commande"
    buttonText="Renvoyer"
    (clickEvent)="resendConfirmOk()"
></app-popup>

<app-popup
    *ngIf="resendInvoiceOfOrder"
    (backEvent)="resendInvoiceOfOrder = null"
    [title]="'Renvoyer la facture de la commande N°' + resendInvoiceOfOrder.orderNumber"
    message="L'e-mail contenant la facture sera envoyé à l'adresse e-mail ayant été utilisée pour cette commande"
    buttonText="Renvoyer"
    (clickEvent)="resendInvoiceOk()"
></app-popup>

<app-popup
    *ngIf="orderToPay"
    (backEvent)="orderToPay = null"
    [title]="'Souhaitez-vous marquer la commande N°' + orderToPay.orderNumber + ' comme Payée'"
    message="Attention : après validation il ne sera plus possible de modifier le règlement de cette commande."
    buttonText="Marquer comme payée"
    (clickEvent)="payOrderOk()"
></app-popup>

<app-popup
    *ngIf="orderToRefund"
    (backEvent)="orderToRefund = null"
    [title]="'Souhaitez-vous rembourser la commande N°' + orderToRefund.orderNumber"
    message="Le virement de remboursement sera envoyé au participant sur le compte en banque qu'il a utilisé pour effectuer la commande"
    [buttonText]="'Rembourser '+ ( orderToRefund.totalPrice.sellingPrice | currency: 'EUR' ) "
    (clickEvent)="askRefundOk()"
></app-popup>

<app-delete-popup
    *ngIf="orderToCancel"
    [name]="orderToCancel.orderNumber"
    deleteWord="annuler la commande"
    [isFemale]="true"
    buttonText = "Confirmer l'annulation"
    type="commande"
    (clickEvent)="cancelOrderOk()"
    (close)="orderToCancel = null"
></app-delete-popup>

<div
    class="full_loader"
    *ngIf="fullLoader"
>
    <div></div>
</div>

<div>
  <div id="page_header" class="container">
    <div id="page_header_left">
      <h1>Toutes les organisations</h1>
    </div>
  </div>
  <ng-container *ngIf="data">
    <section id="block_first" class="container" *ngIf="!totalData">
      <p class="description">
        Aucune organisation créée pour le moment
      </p>
      <figure>
        <img src="assets/images/visuel_evenement.svg" />
      </figure>
    </section>
    <section *ngIf="totalData">
      <div id="filters_wrap" class="container">
        <app-search-input placeholder="Rechercher une organisation" (sendInput)="searchByKeyword($event, 'search')">
        </app-search-input>
      </div>
      <app-main-table *ngIf="data.length; else noData" [ref]="this" (clickEvent)="selectOrganization($event)"
        idSection="admin_organization_content" idTable="table_admin_organization" tableType="admin_organization" id="table_admin_organization" [columns]="tableOptions"
        [datum]="data"></app-main-table>
      <ng-template #noData>
        <section id="block_first" class="container">
          <h2>Aucune organisation ne correspond à votre recherche !</h2>
          <figure>
            <img src="assets/images/visuel_evenement.svg" />
          </figure>
        </section>
      </ng-template>
      <app-pager *ngIf="totalPages" [totalPages]="totalPages" [page]="page" (changePage)="changePage($event)">
      </app-pager>
    </section>
  </ng-container>
</div>

<div id="page_header" class="container">
    <div id="page_header_left">
        <h1>Campagnes</h1>
    </div>
    <div id="page_header_right">
        <app-button (click)="createCampaign()" class="button" text="Lancer ma campagne" [style]="'plain'"></app-button>
    </div>
</div>

<section id="block_first" class="container">
    <p class="description grey">Vous n’avez pas encore créé de campagnes</p>
    <figure>
        <img src="assets/images/visuel_campagnes.svg" />
    </figure>
    <app-button class="button_create no_margin" text="Lancer ma campagne" [style]="'plain full'"></app-button>
</section>

<app-main-table [ref]="this" idSection="campaigns_content" idTable="table_campaigns" tableType="campaigns" [columns]="tableOptions" [datum]="campaigns"></app-main-table>
import ClassicEditor from './ckeditor'

import {
  Component,
  OnInit,
  ElementRef,
  AfterViewInit,
  ViewChild,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'app-wysiwyg',
  templateUrl: './wysiwyg.component.html',
  styleUrls: ['./wysiwyg.component.scss'],
})
export class WysiwygComponent implements OnInit, AfterViewInit {
  @Output() changeText: EventEmitter<string> = new EventEmitter(null);
  @ViewChild("editor") editor: ElementRef;
  @ViewChild('textArea') textArea: ElementRef;
  @Input() previousValue: string = '';
  @Input() height: number;
  taskBar: string[][];
  error: boolean = false
  textValue: string = '';
  paste: boolean;
  mountedEditor: any
  constructor() {}

  ngOnInit(): void {
    this.height = this.height || 173;
    this.taskBar = [
      ['bold', 'black'],
      ['italic', 'underline copy'],
      ['underline', 'underline'],
      ['justifyLeft', 'align-left'],
      ['justifyCenter', 'align-center'],
      ['justifyRight', 'align-right'],
      ['insertUnorderedList', 'bullet'],
    ];
  }

  async ngAfterViewInit() {
    try {
      this.mountedEditor = await ClassicEditor.create(this.editor.nativeElement, {
        htmlSupport: {
          allow: [
            {
              name: 'div',
              styles: true
            }
          ],
          disallow: [
            {
              name: 'p'
            }
          ]
        },
        link: {
          addTargetToExternalLinks: true,
        },
        toolbar: [
          'undo', 'bold', 'italic', 'link', 'bulletedList', 'alignment'
        ],
      })

      const previousValue = this.previousValue || ''
      this.mountedEditor.setData(previousValue)
      this.mountedEditor.model.document.on( 'change:data', () => {
        let value = this.mountedEditor.getData()
        value = value.replace(new RegExp('<p></p>', 'g'), '<br/>');
        if (value.substr(0, 3) !== '<p>') {
          value = '<p>' + value + '</p>';
        }
        this.changeText.emit(value);


      })
    } catch (error) {
      console.log("Impossible de charger l'éditeur", error);
      this.error = true
    }
  }

  public reset(value = null) {
    let resetValue = this.previousValue || ''
    if (value) {
      resetValue = value
    }
    this.mountedEditor.setData(resetValue)
  }
}

<div *ngIf="loaded" class="loading_container" [ngClass]="{ 'loaded' : event }">
  <div class="loading_content">
    <div id="page_header" class="container">
      <div id="page_header_left">
        <h1>Produits</h1>
      </div>
      <div id="page_header_right">
        <app-button (clickEvent)="displayCreateToggle()" [loadingFunction]="false" class="button" text="Créer"
          [style]="'plain'"></app-button>
        <nav class="drop drop_more" *ngIf="createToggleOpen" [@moreAnimation]>
          <ul>
            <li *ngFor="let link of createToggle.links" (click)="link.method(createToggle.ref)"
              (click)="$event.stopPropagation()" class="{{link.class}}">{{link.title}}</li>
          </ul>
        </nav>
      </div>
    </div>
    <ng-container *ngIf="event">
      <section *ngIf="!event.productTypeCategories?.length" id="block_first" class="container">
        <p class="description grey">Vous n’avez pas encore créé de produits pour cet événement.</p>
        <figure>
          <img src="assets/images/visuel_ticketing.svg" />
        </figure>
        <app-button (click)="createRate()" class="button_create no_margin" text="Créer un produit" [style]="'plain full'">
        </app-button>
        <app-button (click)="createCategory()" class="button_create" text="Créer une catégorie"
          [style]="'border blue full'">
        </app-button>
      </section>

      <app-main-table *ngIf="event.productTypeCategories?.length" [ref]="this" idSection="tickets_content"
        idTable="table_tickets" tableType="tickets" [columns]="tableOptions" [datum]="event.productTypeCategories"
        subTableKey="ticketTypes" (dragEvent)="ticketDragged($event)">
      </app-main-table>
    </ng-container>
  </div>
</div>
<section id="form_page" class="container loading_container" [ngClass]="{ 'loaded' : stripe }">
  	<div class="loading_content">
	    <div id="page_header">
	        <h1>Paramètres</h1>
	    </div>
	    <div id="form_wrapper">
	        <h2>Compte de paiement</h2>
	        <form *ngIf="stripe" [formGroup]="paymentAccountForm" class="large">
		        <ng-container *ngIf="!stripe.accountId">
		        	<h3>Vous n'avez pas encore de compte stripe actif sur ce compte.</h3>
		        	<app-button [loadingOff]="loadingState$" [style]="'plain'" type="submit" (clickEvent)="paymentAccountCreate()" text="Créer un compte Stripe"></app-button>
		        </ng-container>

		        <div *ngIf="stripe.accountId && !stripe.chargesEnabled && !stripe.detailsSubmitted">
		        	<h3>Il manque des informations sur votre compte Stripe.</h3>
		        	<app-button [loadingOff]="loadingState$" [style]="'plain'" type="submit" (clickEvent)="paymentAccountGetLink()" text="Compléter les informations"></app-button>
		        </div>

		        <div *ngIf="!stripe.chargesEnabled && stripe.detailsSubmitted">
		        	<h3>Votre compte Stripe est en attente de validation. Une fois validé vous pourrez publiez vos événements et recevoir des paiements. Veuillez cliquer sur "Compléter les informations" afin de vérifier que des documents ne sont pas en attente de réception.</h3>
		        	 <app-button [loadingOff]="loadingState$" [style]="'plain'" type="submit" (clickEvent)="paymentAccountGetLink()" text="Compléter les informations"></app-button>
		        </div>
		        
		        <div *ngIf="stripe.chargesEnabled && stripe.detailsSubmitted">
		        	<h3>Votre compte Stripe peut recevoir des paiements.</h3>
		        	<app-button [loadingOff]="loadingState$" [style]="'plain'" type="submit" (clickEvent)="paymentAccountGetLink()" text="Modifier les informations"></app-button>
		        </div>
	    	</form>
	    </div>
	</div>
</section>
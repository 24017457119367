import { Component } from '@angular/core';
import { ListFromEventComponent } from 'src/app/elements/list-component';
import { EventSiteService, EventWidgetService } from 'src/app/providers';
import { TableOptions } from 'src/app/models/type.definition';
import { GetEvent } from '../getEvent';
import { ISite } from 'src/app/models/site.model';
import { IWidget } from 'src/app/models/widget.model';
import * as config from 'src/config.json';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-event-mini-site',
  templateUrl: './event-mini-site.component.html',
  styleUrls: ['./event-mini-site.component.scss'],
  providers: [GetEvent]
})
export class EventMiniSiteComponent extends ListFromEventComponent<ISite> {
  protected dataName = 'Site';
  public noWidgetPopup: boolean;
  public widgets: IWidget[];
  public siteToDelete: { id: string, name: string };
  public tableOptions: TableOptions[] = [
    {
      title: "Nom",
      class: "name",
      sort: true,
      key: 'name',
    },
    {
      class: 'url',
      subClass: "doselect",
      title: "Liens",
      link: true,
      target: "_blank",
      key: "url"
    },
    this.DefaultToggle
  ];



  constructor(protected provider: EventSiteService,
    protected getEvent: GetEvent,
    protected widgetService: EventWidgetService) {
    super();
  }

  protected formatElement(element: ISite): ISite {
    element.url = `${environment.eventSiteBaseUrl}fr/event/${element.path}`;
    return element
  }

  async afterInit(): Promise<void> {
    this.widgets = await this.getListOfDataOfEvent(this.widgetService)
  }

  protected setGetParams(): void {
    this.getParams.select = ['name', 'path'];
  }

  public createData(): void {
    if (!this.widgets.length) {
      this.noWidgetPopup = true;
    }
    else {
      super.createData();
    }
  }

  public createWidget(): void {
    this.goTo('billetterie/widgets/creer');
  }

  public deleteData(site: ISite, ref = this): void {
    ref.siteToDelete = {
      id: site._id,
      name: site.name
    }
  }

  validateDeletion(): void {
    super.deleteData(this.siteToDelete, this, () => {
      this.siteToDelete = null;
    });
  }

}

<div class="loading_container" [ngClass]="{ 'loaded' : data }">
  <div class="loading_content">
    <div id="page_header" class="container">
      <div id="page_header_left">
        <h1>Mini-sites</h1>
      </div>
      <div id="page_header_right">
        <app-button (clickEvent)="createData()" [loadingFunction]="false" class="button" text="Créer un mini-site"
          [style]="'plain'"></app-button>
      </div>
    </div>

    <ng-container *ngIf="data">
      <section *ngIf="data.length">
        <app-main-table [ref]="this"
        idSection="sites_content" idTable="table_site" tableType="sites"
        [columns]="tableOptions" [datum]="data"></app-main-table>
      </section>

      <section *ngIf="!data.length" id="block_first" class="container">
        <p class="description grey">Vous n’avez pas encore créé de mini-site.</p>
        <figure>
          <img src="assets/images/visuel_forms.svg" />
        </figure>
        <app-button (click)="createData()" class="button_create no_margin" text="Créer un mini-site" [style]="'plain full'">
        </app-button>
      </section>

      <app-pager *ngIf="totalPages" [totalPages]="totalPages" [page]="page" (changePage)="changePage($event)">
      </app-pager>

      <app-popup *ngIf="noWidgetPopup" (backEvent)="noWidgetPopup = false" title = "Aucun widget crée"
        message="Vous devez avoir au moins un widget de crée pour pouvoir créer un mini-site"
        buttonText="Créer un widget" (clickEvent)="createWidget()"
      >

      </app-popup>

      <app-delete-popup *ngIf="siteToDelete" [name]="siteToDelete.name" type='mini-site' 
      (clickEvent)="validateDeletion()" (close)="siteToDelete = null"></app-delete-popup>


    </ng-container>
  </div>
</div>
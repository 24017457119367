import { Component } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { UserService } from 'src/app/providers';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthComponent } from '../auth-component';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent extends AuthComponent {
  private subscription: Subscription;
  public emailOk: boolean;
  public signForm: FormGroup
  public captchaKey: string;
  public passwordOk: boolean;
  public passwordsMatches: boolean;
  public displayDisabledText = false;
  public disabledText: string;
  public showPwd: boolean = false;
  public showPwd2: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private notificationService: NotificationService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    super();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.signForm = this.formBuilder.group({
      lastName: new FormControl('', Validators.required),
      firstName: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required),
      agreement: new FormControl(false, Validators.requiredTrue),
      langCode: new FormControl('fr'), /** @todo À voir */
      recaptcha: new FormControl('')
    });
    this._subscription.add(this.signForm.get('email').valueChanges.subscribe(res => {
      this.emailOk = this.signForm.get('email').status == 'VALID';
    }))
  }

  signUp(): void {
    const formData = this.signForm.value;
    delete formData.agreement;
    this.recaptchaV3Service.execute('signup').subscribe((token) => {
      this._subscription.add(this.userService.create({...formData, recaptcha: token }).subscribe((result: { data: any, success: boolean, msg: string }) => {
        this.notificationService.newNotification({
          message: "Compte créé avec succès",
          state: 'success'
        })
        super.logIn(formData);
      },
        (err: any) => this.error = this.errorHandler.getError(err)
      ))
    }, (err) => {
      this.error = 'Erreur lors de la validation de votre inscription';
    });

  };

  logIn(): void {
    this.router.navigate(['connexion']);
  }

  closeError(): void {
    this.error = null;
  }

  checkPassword(pwd1: string, pwd2: string): void {
    /** Au moins un (?=.) nombre (\d) peu importe la position */
    /** Au moins un caractère spécial (\W) peu importe la position */
    /** Au moins une lettre (\w) peu importe la position */
    this.passwordOk = /(?=.*\d)(?=.*\W)(?=.*\w)[\d\W\w]{8}/.test(pwd1);
    this.passwordsMatches = pwd1 === pwd2;
  }

  getDisabledText(): string {
    if (!this.emailOk) {
      if (!this.signForm.get('email').value) {
        return 'Veuillez remplir tous les champs requis'
      }
      else {
        return 'Veuillez saisir une adresse email valide'
      }
    }
    if (!this.fieldsAreValids(['lastName', 'firstName', 'password'])) {
      return 'Veuillez remplir tous les champs requis'
    }
    if (!this.passwordOk) {
      return 'Veuillez entrer un mot de passe valide'
    }
    if (!this.passwordsMatches) {
      return 'Les mots de passes ne correspondent pas'
    }
    if (!this.fieldIsValid('agreement')) {
      return "Merci d'accepter nos conditions"
    }
  }

  fieldsAreValids(keys: string[]): boolean {
    for (let key of keys) {
      if (!this.fieldIsValid(key)) {
        return false;
      }
    }
    return true
  }

  fieldIsValid(key: string): boolean {
    return this.signForm.get(key).status == 'VALID'
  }

}

import { GetParams, APIListResponse, APIResponse, APICreateResponse } from "../models/type.definition";
import { Observable } from 'rxjs';
import { Requester } from './requester';
import { Data } from "@angular/router";

export abstract class Provider<T extends Data> extends Requester<T> {

  public getList(body: GetParams<T> = null): Observable<APIListResponse<T>> {
    return this.httpClient.get<APIListResponse<T>>(this.getUrl('list', body));
  }

  public getById(id: string, body: GetParams<T> = null): Observable<T> {
    return this.httpClient.get<T>(this.getUrl(id, body));
  }

  public create(body: T | FormData): Observable<APICreateResponse> {
    return this.httpClient.post<APIResponse>(this.getUrl(), body);
  }

  public update(id: string, body: T | FormData): Observable<APIResponse> {
    return this.httpClient.put<APIResponse>(this.getUrl(id), body);
  }

  public delete(id: string): Observable<APIResponse> {
    return this.httpClient.delete<APIResponse>(this.getUrl(id));
  }
}

<div class="popup-overlay" (click)="back()">
	<div class="popup" [@popupAnimation] (click)="$event.stopPropagation()">
		<div class="popup_header">
			<h2>{{deleteWord}} {{isFemale ? 'une' : 'un'}} {{type}}</h2>
			<button class="button_close" (click)="back()">
				<svg role="img" title="close" class="close-icon">
					<use href="assets/images/sprite.svg#icon-close"></use>
				</svg>
			</button>
		</div>
		<div class="popup-content">
			<p class="message" *ngIf="!customText">Souhaitez-vous {{deleteWord}} {{startWithVowel ? "l'" : isFemale ? 'la ' : 'le '}}{{type}} {{name}} ? </p>
			<p class="message" *ngIf="customText">{{customText}}</p>
			<app-button [text]="buttonText" (clickEvent)="onClick()" [style]="'plain red'"></app-button>
		</div>
	</div>
</div>
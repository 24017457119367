import { Injectable } from '@angular/core';
import { Requester } from '../requester';
import { Observable } from 'rxjs';
import { StripeTpeToken } from 'src/app/models/type.definition';

@Injectable()
export class StripeTpeService extends Requester {

  protected url = "tpe/connection_token";

  constructor() {
    super();
  }

  public getToken(): Observable<{ token: string }> {
    return this.httpClient.post<StripeTpeToken>(this.getUrl(), {});
  }
}

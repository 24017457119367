import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { moreAnimation } from 'src/app/animations/animations';
import { IEvent } from 'src/app/models/event.model';
import { GetParams, TableOptions } from 'src/app/models/type.definition';
import { EventService, TicketService, TicketTypeCategoryService, TicketTypeService } from 'src/app/providers';
import { DateService } from 'src/app/services/date.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { NotificationService } from 'src/app/services/notification.service';
import { GetEvent } from '../../getEvent';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { UpdateService } from 'src/app/services/update.service';


@Component({
  selector: 'app-event-ticketing-security',
  templateUrl: './event-ticketing-security.component.html',
  styleUrls: ['./event-ticketing-security.component.scss'],
  providers: [GetEvent],
  animations: [
    moreAnimation
  ]
})
export class EventTicketingSecurityComponent implements OnInit {

  public event: IEvent;
  public loaded: boolean;
  public todayDate: Date;
  public maxDate: Date;
  public error: string;
  public loadingState$: Subject<void> = new Subject<void>();
  public currentUrl: string;
  private _subscription = new Subscription();
  public mainForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private getEvent: GetEvent,
    private eventService: EventService,
    private router: Router,
    private update: UpdateService,
    private errorHandler: ErrorHandlerService,
    private notification: NotificationService,
  ) { }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  async ngOnInit() {
    const options: GetParams<IEvent> = {
      select: ['security']
    };
    this.currentUrl = this.router.url;
    this.event = await this.getEvent.get(options);
    this.todayDate = new Date();
    this.maxDate = this.event.dates.startDate;
    this.initForm();
    this.loaded = true;
  }

  goBack(): void {
    this.router.navigate([`evenements/${this.event._id}/billetterie/ticket/`]);
  }

  initForm(): void {
    this.mainForm = this.formBuilder.group({
      timeBeforeEvent: new FormControl((this.event.security && this.event.security.qrcode && this.event.security.qrcode.timeBeforeEvent ) ? this.event.security.qrcode.timeBeforeEvent : null)
    });
  }

  updateSecurity(): void {
    const formData = this.mainForm.value;

    this.eventService.update(this.event._id, { security: { qrcode: formData }}).subscribe(result => {
      this.loadingState$.next();
      this.event = this.update.object(this.event, { security: { qrcode: formData }});
      this.eventService.selected$.next(this.event);
      this.notification.newNotification({
        message: 'Paramètres de sécurité mis à jour avec succès',
        state: 'success'
      });
    },
      (err: any) => {
        this.error = this.errorHandler.getError(err)
        this.loadingState$.next();
      }
    )
  }
}

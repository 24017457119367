import { trigger, state, style, animate, transition } from '@angular/animations';

export const popupAnimation = trigger('popupAnimation', [
  transition(':enter', [
    style({ opacity: 0, bottom: '-90px' }),
    animate(100, style({ opacity: 1, bottom: 0 }))
  ]),
  transition(':leave', [
    style({ opacity: 1, bottom: 0 }),
    animate(100, style({ opacity: 0, bottom: '-90px' }))
  ])
]);

export const menuAnimation = trigger('menuAnimation', [
  transition(':enter', [
    style({ opacity: 0, top: '40px' }),
    animate(100, style({ opacity: 1, top: 0 }))
  ]),
  transition(':leave', [
    style({ opacity: 1, top: 0 }),
    animate(100, style({ opacity: 0, top: '40px' }))
  ])
]);

export const toastAnimation = trigger('toastAnimation', [
  transition(':enter', [
    style({ opacity: 0, top: '-100px' }),
    animate(150, style({ opacity: 1, top: '15px' }))
  ]),
  transition(':leave', [
    style({ opacity: 1, top: '15px' }),
    animate(150, style({ opacity: 0, top: '-100px' }))
  ])
]);

export const moreAnimation = trigger('moreAnimation', [
  transition(':enter', [
    style({ opacity: 0, top: '-10px' }),
    animate(100, style({ opacity: 1, top: 0 }))
  ]),
  transition(':leave', [
    style({ opacity: 0, top: 0 }),
    animate(100, style({ opacity: 0, top: '-10px' }))
  ])
]);

import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { Subscription } from 'rxjs';
// Animations
import { toastAnimation } from 'src/app/animations/animations';

@Component({
  selector: 'app-notification-displayer',
  templateUrl: './notification-displayer.component.html',
  styleUrls: ['./notification-displayer.component.scss'],
  animations: [
    toastAnimation
  ]
})
export class NotificationDisplayerComponent implements OnInit, OnDestroy {

  private _subscription = new Subscription();
  public notifications: { message: string, state: string }[];

  constructor(private _notificationService: NotificationService) { }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.notifications = [];
    this._subscription.add(this._notificationService.notification$.subscribe(
      (result: { message: string, state: string }) => {
        this.notifications.push(result);
        setTimeout( () => this.notifications.shift(), 5000)
      })
    )
  }

}

import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit, OnDestroy {
  @Output() clickEvent: EventEmitter<void> = new EventEmitter();
  @Input() type: string = 'button';
  @Input() text: string;
  @Input() disabled: boolean;
  @Input() style: string;
  @Input() icon: string;
  @Input() loadingFunction = true
  @Input() loadingOff: Observable<void>;
  private _subscription: Subscription;
  public loading: boolean = false;
 
  constructor() { }

  ngOnInit() {
    this._subscription = this.loadingOff?.subscribe(() => this.loading = false);
  }
  
  ngOnDestroy() {
    this._subscription?.unsubscribe();
  }

  onClick(): void {
    if (this.clickEvent.observers.length) {
      this.loading = this.loadingFunction;
      this.clickEvent.emit()
    }
  }

  enterPressed(){
    if(!this.disabled && this.type === "submit"){
      this.loading = this.loadingFunction;
      this.clickEvent.emit()
    }
  }

}

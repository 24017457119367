import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class FilesHandler {

  create(file: File): Promise<File> {
    if (file) {
      return new Promise(resolve => {
        const reader = new FileReader();
        reader.onload = () => resolve(file);
        reader.readAsDataURL(file);
      });
    }
  }

  createFormData(form: any): FormData {
    const formData = new FormData();
    for (const key in form) {
      if (![undefined, ''].includes(form[key])) {
        if (Array.isArray(form[key])) {
          for (const element of form[key]) {
            formData.append(key + (key !== 'upl' ? '[]' : ''), element);
          }
        }
        else {
          formData.append(key, form[key]);
        }
      }
    }
    return formData;
  }

  downloadFile(file: Blob, name: string): void {
    const a = document.createElement("a");
    a.href = URL.createObjectURL(file);
    a.download = name.split('').map(_ => _ != '.' ? _ : '_').join('');
    a.click();
  }

  public createFormUrlEncoded(object: any): string[] {
    const formBody = [];
    for (var key in object) {
      var encodedKey = encodeURIComponent(key);
      var encodedValue = encodeURIComponent(object[key]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    return formBody
  }

  public createSimpleFormData(object: any): FormData {
    const formData = new FormData();
    formData.append('upl', object.upl);
    delete object.upl
    formData.append('body',  JSON.stringify(object))
    return formData
  }

  public async getBase64(blob: Blob): Promise<string> {
    return await new Promise((res: (value: string) => void) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => res(reader.result as string);
    });
}

}

import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { moreAnimation } from 'src/app/animations/animations';
import { ListComponent } from 'src/app/elements/list-component';
import { IEvent } from 'src/app/models/event.model';
import { IOrder, translateStatus, translatePaymentMethod, translatedOrigin } from 'src/app/models/order.model';
import { IOrganisation } from 'src/app/models/organisation.model';
import { ITicket, ITicketType } from 'src/app/models/ticket.model';
import { GetParams, TableOptions } from 'src/app/models/type.definition';
import { EventService, OrganisationService, OrderService, CurrencyService, TicketService, RefundService, AuthService } from 'src/app/providers';
import { DateService } from 'src/app/services/date.service';
import { FilesHandler } from 'src/app/services/file-handler.service';

@Component({
  selector: 'app-adminTickets',
  templateUrl: './adminTickets.component.html',
  styleUrls: ['./adminTickets.component.scss'],
  animations: [
    moreAnimation
  ]
})
export class AdminTicketsComponent extends ListComponent<ITicket>  {
  dataName = "Participant";
  public filtersForm: FormGroup;
  public noEmail = false;
  public moreFilters: boolean = false;
  public selectedStatus: string = 'completed';
  public selectedOrigin: string = null;
  public organisationToEdit: IOrganisation;
  public firstDate: Date;
  public dateSelected = [null, null];
  public fullLoader: boolean = false;
  public actionToggleOpen: boolean = false;
  public ticketToResend: ITicket;
  public ticketToDelete: ITicket;
  public tableOptions: TableOptions<ITicket>[] = [
    {
      title: "Origine",
      class: "origin",
      classKey: 'origin',
      sort: true,
      tooltip: true,
      tooltipKey: 'translatedOrigin',
      key: 'origin',
      sortMethod: order => this.sort("origin", order),
    },
    {
      title: "Participant",
      class: "name",
      sort: true,
      key: 'fullName',
      sortMethod: order => this.sort("lastName", order),
    },
    {
      title: "Evénement",
      class: "ticketNumber",
      tooltip: true,
      tooltipKey: 'organisationName',
      sort: false,
      key: 'eventName',
      sortMethod: order => this.sort("eventName", order),
    },
    {
      title: "Tarif",
      class: "rate",
      key: 'ticketName',

    },
    {
      title: "N° de commande",
      class: "serial",
      subClass: "doselect",
      sort: false,
      key: 'orderNumber',
    },
    {
      title: "Date de commande",
      class: "date",
      sort: true,
      key: 'orderDate',
      sortMethod: order => this.sort("createdAt", order),
    },
    {
      class: "more",
      toggle: [
        {
          title: 'Télécharger le ticket',
          method: this.download
        },
        {
          title: 'Télécharger les tickets',
          method: this.downloadOrderTickets
        },
        {
          title: 'Renvoyer le ticket',
          method: this.resendConfirm
        },
        {
          title: 'Détails',
          method: this.detail
        }
      ]
    }
  ]

  constructor(
    protected provider: TicketService,
    protected dateService: DateService,
    private filesHandler: FilesHandler,
    private route: ActivatedRoute,
    private orderService: OrderService,
    private authService: AuthService,
    protected organisationService: OrganisationService) {
    super()
  }

  protected async afterInit(): Promise<void> {
    const orderNumber = this.route.snapshot.queryParams.order;
    this.initForm(orderNumber);
  }

  initForm(orderNumber?: string): void {
    this.filtersForm = this.formBuilder.group({
      search: new FormControl(),
      orderNumber: new FormControl(orderNumber || null),
      createdAt__$gte: new FormControl(),
      createdAt__$lte: new FormControl(),
    });
  }

  public setSearch(search: string): void {
    this.filtersForm.patchValue({ search })
  }

  public searchOrderNumber(orderNumber: string): void {
    this.filtersForm.patchValue({ orderNumber })
  }

  setGetParams(): void {
    this.getParams.select = ['orderId', 'origin', 'firstName', 'lastName', 'ticketTypeId', 'status', 'createdAt', 'ticketNumber', 'categoryName', 'eventId', 'organisationId']
    this.getParams.filter.status = 'draft,pending,completed,canceled';
    this.getParams.filter.origin = this.selectedOrigin || null;
    this.getParams.populate = ['orderId', 'ticketTypeId', 'eventId', 'organisationId'];
    super.setGetParams();
  }

  protected async getData(): Promise<void> {
    this.loading = true;
    this.setGetParams();
    this.getParams.page = this.page;

    let results = await this.provider.getList(this.getParams).toPromise();
    if (results) {
      this.data = await Promise.all(results?.data.map(async element => this.formatElement(element)));
      this.currentTotalData = results.total;
      this.page = results?.pager?.page;
      this.totalPages = results?.pager?.pages;
      this.loadingState$.next();
      this.loading = false;
    }
    else {
      this.data = [];
      this.loadingState$.next();
      this.loading = false;
      this.currentTotalData = 0;
      this.totalPages = 0;
    }
    if (this.totalData == undefined) {
      this.totalData = this.currentTotalData || 0
    }
  }

  protected formatElement(ticket: ITicket): ITicket {
    ticket.eventName = ticket.eventId?.name;
    ticket.organisationName = ticket.organisationId?.name;
    ticket.fullName = 'Non renseigné'
    if (ticket.firstName || ticket.lastName) {
     ticket.fullName = ticket.firstName + ' ' + ticket.lastName
    }
    ticket.translatedOrigin = translatedOrigin(ticket.origin)
    ticket.ticketName = this.getTicketConcatName(ticket, 45);
    ticket.orderNumber = ticket.orderId?.orderNumber || "Invitation";
    ticket.orderDate = this.dateService.formatDate(ticket.createdAt)

    return ticket;
  }

  public filter(): void {
    this.page = 1;
    this.addFilterOnNextRequest = true;
    this.getParams.sort = [];
    //this.updateFilterStorage();
    this.setGetParams()
    this.getData();
  }

  public view(order: IOrder, ref = this): void {
    ref.router.navigate(['/evenements/' + order.eventId + '/commandes/' + order._id]);
  }

  public download(ticket: ITicket, ref = this): void {
    if (ticket.orderId.createdAt) {
      ref.fullLoader = true;
      ref.orderService.streamOrder(ticket._id, ticket.orderId?.token, 'ticketId').subscribe(result => {
        ref.fullLoader = false;
        ref.filesHandler.downloadFile(result, 'Ticket n°' + ticket.ticketNumber)
      }, err => {
        ref.notificationService.newNotification({ state: 'error', message: ref.errorHandler.getError(err) });
        ref.fullLoader = false;
      });
    }
    else {
      ref.notificationService.newNotification({ state: 'error', message: "Ce ticket n'est rattaché à aucune commande" });
    }
  }

  public downloadOrderTickets(ticket: ITicket, ref = this): void {
    ref.fullLoader = true;
    ref.actionToggleOpen = false;
    ref.provider.streamOrder(ticket.orderId._id, ticket.orderId?.token, 'orderIdTickets').subscribe(result => {
      ref.fullLoader = false;
      ref.filesHandler.downloadFile(result, 'Commande n°' + ticket.orderId?.orderNumber)
    }, err => {
      ref.notificationService.newNotification({ state: 'error', message: ref.errorHandler.getError(err) });
      ref.fullLoader = false;
    });
  }

  public resendConfirm(ticket: ITicket, ref = this): void {
    ref.ticketToResend = {
      ...ticket,
      _id: ticket._id
    }
  }

  public resendConfirmOk(): void {
    this.provider.resendTicket(this.ticketToResend._id).subscribe(_result => {
      this.ticketToResend = null
      if (_result) {
        this.notificationService.newNotification({
          message: 'Le ticket a bien été renvoyé',
          state: 'success'
        })
      }
      else {
        this.notificationService.newNotification({
          message: 'Une erreur s\'est produite, veuillez réessayer plus tard',
          state: 'error'
        })
      }
    });
  }

  public async detail(data: ITicket, ref = this) {
    const organisationToken = await ref.authService.authOrganisation(data.organisationId._id).toPromise();
    ref.organisationService.adminConnected$.next(true);
    ref.organisationService.storeToken(organisationToken)
    ref.router.navigate([`/evenements/${data.eventId}/participants/tous/details/${data._id}`]);
  }

  public getTicketConcatName(ticket: ITicketType | ITicket, max: number) {
    let name = ticket.name || (ticket as ITicket).ticketTypeId?.name;
    const categoryName = (ticket as ITicket).categoryName || ticket.ticketTypeCategoryId?.name;
    if (categoryName) name += ` - ${categoryName}`;
    if (name.length > max) name = `${name.substring(0, max - 3)}...`;
    return name;
  }

}

import { Component, HostListener } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FormFromEventComponent } from 'src/app/elements/form-component';
import { ISiteColors } from 'src/app/models/site.model';
import { ITicketType } from 'src/app/models/ticket.model';
import { IWidget } from 'src/app/models/widget.model';
import { AuthService, EventWidgetService, TicketTypeService } from 'src/app/providers';
import { environment } from 'src/environments/environment';
import { GetEvent } from '../../getEvent';

@Component({
  selector: 'app-event-widget-create',
  templateUrl: './event-widget-create.component.html',
  styleUrls: ['./event-widget-create.component.scss'],
  providers: [GetEvent]
})

export class EventWidgetCreateComponent extends FormFromEventComponent<IWidget> {
  public dataName = 'Widget';
  public ticketTypes: ITicketType[];
  public isAdmin: boolean;
  public checkedTicketTypes: string[] = [];
  public autoHeight = false;
  public displayTrackingScript = false;
  public dropDown: boolean = false;
  public widgetUrl = environment.widgetUrl;
  public hoverLink = false;
  public linkCopied = false;
  public forProduct = false;
  public isRegistration = false;
  public colors: ISiteColors = {
    background: '#F6F7FB',
    title: "#000",
    text: '#000',
    link: '#0627d9'
  }

  constructor(
    protected formProvider: EventWidgetService,
    protected ticketTypeService: TicketTypeService,
    protected getEvent: GetEvent,
    private authService: AuthService,) {
    super();
  }

  async onInit(): Promise<void> {
    this.isAdmin = this.authService.isAdmin;
    await this.getDataToEdit('widgetId', { populate: ["ticketTypeIds.ticketTypeCategoryId"]});
    if (this.data) {
      this.forProduct = this.data.forProduct;
      this.isRegistration = this.data.isRegistration;
      this.colors = this.data.customStyles;
      this.checkedTicketTypes = this.data.ticketTypeIds.map(_ => _._id) || [];
      this.displayTrackingScript = !!this.data.trackingScript;
    }
    this.ticketTypes = await this.getTicketTypes();
    this.loaded = true;
  }

  initForm(): void {
    this.mainForm = this.createFormGroup({
      name: new FormControl(this.data?.name, Validators.required),
      defaultLangCode: 'fr',
      trackingScript: this.data?.trackingScript,
      customStyles: this.data?.customStyles,
      ticketTypeRestricted: this.data?.ticketTypeRestricted || false,
      ticketTypeIds: this.data?.ticketTypeIds || [],
      eventId: this.event._id,
      height: this.data?.height || 500,
      autoHeight: this.data?.autoHeight || false,
      forProduct: this.data?.forProduct || false,
      isRegistration: this.data?.isRegistration || false,
      askZone:  this.data?.askZone || false
    });
    this.subscribeTo(this.mainForm.get('forProduct').valueChanges, async value => {
      this.forProduct = value;
      this.checkedTicketTypes = [];
      this.ticketTypes = await this.getTicketTypes();
    })
    this.subscribeTo(this.mainForm.get('isRegistration').valueChanges, async value => {
      this.isRegistration = value;
    })
  }

  private getTicketTypes(): Promise<ITicketType[]> {
    return this.getListFromEventOf(this.ticketTypeService, {
      filter: { isProduct: this.forProduct   },
      populate: ['ticketTypeCategoryId'],
      perPage: 500
    });
  }

  public changeAutoHeight(value: boolean): void {
    this.autoHeight = value;
    this.setValue(['autoHeight'], value);
    //this.mainForm.patchValue({ autoHeight: value })
  }

  async submitForm(): Promise<void> {
    const form = this.mainForm.value;
    form.ticketTypeIds = this.checkedTicketTypes
    form.askZone = form.forProduct ? form.askZone : false;
    await this.createOrUpdate(this.mainForm.value);
  }

  public changeColor(key: keyof ISiteColors, value: string): void {
    this.colors[key] = value;
    this.setValue(["customStyles", key], value);
  }

  public heightDisabled(): void {
    return this.mainForm.value.autoHeight || null
  }

  @HostListener('document:click') clickDoc(): void {
    this.dropDown = false;
  }

  public checkTicket(id: string, value: boolean): void {
    const inArray = this.checkedTicketTypes.includes(id);
    if (value && !inArray) {
      this.checkedTicketTypes.push(id);
    }
    else if (!value && inArray) {
      this.checkedTicketTypes = this.checkedTicketTypes.filter(_ => _ !== id);
    }
  }

  public ticketChecked(id: string): boolean {
    return this.checkedTicketTypes.includes(id) || null;
  }

  public ticketRestricted(): boolean {
    return this.mainForm.value.ticketTypeRestricted;
  }

  public getTicketsName(): string {
    const names = this.ticketTypes.filter(_ => this.checkedTicketTypes.includes(_._id));
    if (!names.length) {
      return `Séléctionner des ${this.forProduct ? 'produits' : 'tickets'}`;
    }
    else {
      const fullString = names.map(_ => _.name).join(', ');
      return fullString.length < 80 ? fullString : `${fullString.substring(0, 77)} ...`
    }

  }

  public copyLink(): void {
    this.linkCopied = true;
    navigator.clipboard.writeText(this.widgetUrl);
  }

  public leaveLink(): void {
    this.hoverLink = false;
    setTimeout(() => this.linkCopied = false, 200);
  }


}

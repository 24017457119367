import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { IEvent } from 'src/app/models/event.model';
import { ISession } from 'src/app/models/session.model';
import { GetParams, TableOptions } from 'src/app/models/type.definition';
import { EventSessionService, TicketTypeService } from 'src/app/providers';
import { DateService } from 'src/app/services/date.service';
import { NotificationService } from 'src/app/services/notification.service';
import { GetEvent } from '../../getEvent';

@Component({
  selector: 'app-event-ticketing-session',
  templateUrl: './event-ticketing-session.component.html',
  styleUrls: ['./event-ticketing-session.component.scss'],
  providers: [GetEvent]
})
export class EventTicketingSessionComponent implements OnInit {
  public currentUrl: string;
  public event: IEvent;
  public sessions: ISession[];
  public getOptions: GetParams<ISession>;
  public tableOptions: TableOptions[];
  constructor(private router: Router, private getEvent: GetEvent,
    private sessionService: EventSessionService,
    private dateService: DateService,
    private ticketService: TicketTypeService,
    private notificationService: NotificationService) { }

  async ngOnInit(): Promise<void> {
    this.currentUrl = this.router.url;
    this.event = await this.getEvent.get();

    this.tableOptions = [{
      title: "Date",
      class: "date",
      sort: true,
      key: 'dateBase',
      sortMethod: order => this.sort('date', order),
    },
    {
      title: "Heure",
      class: "hour",
      key: 'dateHours'
    },
    {
      title: "Quota (en cours)",
      class: "quota",
      key: 'quotaShow'
    },
    {
      title: "Tarifs",
      class: "rates",
      sort: false,
      key: 'rates',
      hover: true,
      hoverKey: 'ratesArray'
      //sortMethod: (order) => this.sort("status", order)
    },
    {
      class: "more",
      toggle: [
        {
          title: 'Voir le plan de salle',
          if: (data) => this.event.useRoomPlacement,
          method: this.viewSeatingPlan
        },
        {
          title: 'Modifier',
          method: this.editSession
        },
        {
          title: 'Supprimer',
          method: this.deleteSession,
          class: 'red'
        }
      ]
    }];

    this.getOptions = {
      filter: {
        eventId: this.event._id
      }
    };
    await this.getData();
  }

  async getData(): Promise<void> {
    const tickets = (await this.ticketService.getList({ filter: { eventId: this.event._id } }).toPromise())?.data || [];
    const sessionsResults = await this.sessionService.getList(this.getOptions).toPromise();
    this.sessions = sessionsResults?.data.map(session => {
      const date = new Date(session.date);
      session.dateBase = this.dateService.getDateString(date);
      session.dateHours = this.dateService.getTimeString(date);
      session.quotaShow = `${session.stocks.sold}/`;
      session.quotaShow += (session.stocks.quota || session.stocks.quota === 0) ? session.stocks.quota : '∞';
      session.quotaShow += ` (${session.stocks.inDraft})`;

      session.ratesArray = tickets.filter(_ => {
        const find = session.ticketTypeIds.findIndex(ticket => ticket._id === _._id);
        return find !== -1;
      }).map( _ => _.name);
      if (session.ratesArray.length) {
        session.rates = session.ratesArray.join(', ');
      }
      else {
        session.rates = 'Tous'
      }
      return session;
    }) || [];


  }

  public viewSeatingPlan(session: ISession, ref = this): void {
    ref.router.navigate([`${ref.currentUrl}/seating-plan/${session._id}`]);
  }

  public createSession(): void {
    this.router.navigate([`${this.currentUrl}/creer`]);
  }

  public editSession(session: ISession, ref = this): void {
    ref.router.navigate([`${ref.currentUrl}/editer/${session._id}`]);
  }

  public deleteSession(session: ISession, ref = this): void {
    ref.sessionService.delete(session._id).pipe(first()).subscribe(() => {
      ref.notificationService.newNotification({ message: "Session supprimée", state: 'success' });
      ref.sessions = ref.sessions.filter(_session => _session._id !== session._id);
    });
  }

  public sort(key: keyof ISession, order: number) {
    this.getOptions.sort = [[key, order]]
    this.getData();
  }

}

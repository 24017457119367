import { GetParams } from "src/app/models/type.definition";

export enum FilterOperator {
  EQ = "$eq",
  LTE = "$lte",
  GTE = "$gte",
  LT = "$lt",
  GT = "$gt",
  NE = "$ne",
  IN = "$in",
  NIN = "$nin",
}

export class QueryParams<T> {

  constructor() { }

  public translate(body?: GetParams<T>) {
    let query = '';
    let filter = null;
    let select = null;
    let template = null;
    let populate = null;
    let pdfTemplate = null;
    let sort = null;
    let page = null;
    let perPage = null;
    let search = null;
    let limit = null;
    let firstQuery = false;
    if (body) {
      if (body.search) {
        search = this.makeSearch(body.search);
      }
      if (body.filter) {
        filter = this.makeFilter(body.filter);
      }
      if (body.select) {
        select = this.makeSelect(body.select);
      }
      if (body.populate) {
        populate = this.makePopulate(body.populate);
      }
      if (body.sort) {
        sort = this.makeSort(body.sort);
      }
      if (body.page || body.page == 0) {
        page = this.makePage(body.page);
      }
      if (body.perPage) {
        perPage = this.makePerPage(body.perPage);
      }
      if (body.limit || body.limit == 0) {
        limit = this.makeLimit(body.limit);
      }
      if (body.template) {
        template = this.makeTemplate(body.template);
      }
      if (body.pdfTemplate) {
        pdfTemplate = this.makePdfTemplate(body.pdfTemplate);
      }

      if (filter != null) {
        query = `?filter=${filter}`;
        firstQuery = true;
      }
      if (search != null) {
        if (firstQuery) {
          query = `${query}&search=${search}`;
        } else {
          query = `?search=${search}`;
          firstQuery = true;
        }
      }
      if (select != null) {
        if (firstQuery) {
          query = `${query}&select=${select}`;
        } else {
          query = `?select=${select}`;
          firstQuery = true;
        }
      }
      if (populate != null) {
        if (firstQuery) {
          query = `${query}&populate=${populate}`;
        } else {
          query = `?populate=${populate}`;
          firstQuery = true;
        }
      }
      if (page != null) {
        if (firstQuery) {
          query = `${query}&page=${page}`;
        } else {
          query = `?page=${page}`;
          firstQuery = true;
        }
      }
      if (perPage) {
        if (firstQuery) {
          query = `${query}&perPage=${perPage}`;
        } else {
          query = `?perPage=${perPage}`;
          firstQuery = true;
        }
      }
      if (limit != null) {
        if (firstQuery) {
          query = `${query}&limit=${limit}`;
        } else {
          query = `?limit=${limit}`;
          firstQuery = true;
        }
      }
      if (sort != null) {
        if (firstQuery) {
          query = `${query}&sort=${sort}`;
        } else {
          query = `?sort=${sort}`;
          firstQuery = true;
        }
      }
      if (template != null) {
        if (firstQuery) {
          query = `${query}&template=${template}`;
        } else {
          query = `?template=${template}`;
          firstQuery = true;
        }
      }
      if (pdfTemplate != null) {
        if (firstQuery) {
          query = `${query}&pdfTemplate=${pdfTemplate}`;
        } else {
          query = `?pdfTemplate=${pdfTemplate}`;
          firstQuery = true;
        }
      }
      return query;
    } else {
      return query;
    }
  }

  makeSearch(data: string): string {
    return `${data}`;
  }

  makeFilter(data: { [key in keyof Partial<T> | any]: T[keyof T] }): string {
    let filter = null;
    let keys = Object.keys(data) as (keyof T)[];
    for (let i = 0; i < keys.length; ++i) {
      let operator: FilterOperator = FilterOperator.EQ
      const keyOperator = String(keys[i]).split('__')

      const key = keyOperator[0]
      if (keyOperator.length > 1)  {
        operator = keyOperator[1] as FilterOperator
      }
      if (i == 0) {
        filter = `${key}[${operator}]${data[keys[i]]}`;
      } else {
        filter = `${filter}__${key}[${operator}]${data[keys[i]]}`;
      }
    }
    return filter;
  }

  makePage(data: number): string {
    return `${data}`;
  }

  makePerPage(data: number): string {
    return `${data}`;
  }

  makeLimit(data: number): string {
    return `${data}`;
  }

  makeTemplate(data: 'base' | 'full'): string {
    return `${data}`;
  }

  makePdfTemplate(data: 'ticket' | 'badge'): string {
    return `${data}`;
  }

  makeSelect(data: string[]): string {
    let sort = null;
    for (let i = 0; i < data.length; ++i) {
      if (i == 0) {
        sort = data[i];
      } else {
        sort = `${sort}__${String(data[i])}`;
      }
    }
    return sort;
  }

  makePopulate(data: (keyof T)[]): string {
    let sort = null;
    for (let i = 0; i < data.length; ++i) {
      if (i == 0) {
        sort = data[i];
      } else {
        sort = `${sort}__${String(data[i])}`;
      }
    }
    return sort;
  }

  makeSort(data: [string, number][]): string {
    let sort = null;
    for (let i = 0; i < data.length; i++) {
      if (i == 0) {
        sort = `${data[i][0]}==${data[i][1]}`;
      } else {
        sort = `${sort}__${data[i][0]}==${data[i][1]}`;
      }
    }
    return sort;
  }
}

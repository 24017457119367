import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-event-campaign-create',
  templateUrl: './event-campaign-create.component.html',
  styleUrls: ['./event-campaign-create.component.scss']
})
export class EventCampaignCreateComponent implements OnInit {
  public campaignForm: FormGroup;
  public activeStep: number = 1;

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  async ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.campaignForm = this.formBuilder.group({
      name: new FormControl(''),
      type: new FormControl(''),
      event: new FormControl('')
    })
  }

  nextStep() {
    this.activeStep += 1;
  }

  previousStep() {
    this.activeStep -= 1;
  }
}

import 'reflect-metadata';
import { Component, TypeDecorator } from '@angular/core';


interface AbstractComponentDecorator {
  /**
   * Custom decorator that mark a class has an `AbstractComponent`.
   */
  (obj?: AbstractComponent): TypeDecorator;
  new(obj: AbstractComponent): Component;
}


/**
 * Supplies configuration metadata for an Angular abstract component.
 */
declare interface AbstractComponent extends Component { }

function AbstractComponentDecorator(props: AbstractComponent = {}): TypeDecorator {
  props.template = '';
  return function (target: Object): void {
    const metadata = new Component(props);
    Reflect.defineMetadata('annotations', [metadata], target);
  }
}

export let AbstractComponent: AbstractComponentDecorator = AbstractComponentDecorator as AbstractComponentDecorator;


import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';


@Component({
  selector: 'app-pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.scss']
})
export class PagerComponent implements OnInit, OnChanges {
  @Output() changePage: EventEmitter<number> = new EventEmitter();
  @Input() totalPages: number;
  @Input() page: number;
  minPage: any;

  constructor() { }

  ngOnInit(): void {
    this.setPagers();
  }

  setPagers(): void {
    if (this.totalPages > 9 && this.page > 5) {
      if (this.page + 3 < this.totalPages) {
        this.minPage = this.page - 4;
      }
      else {
        this.minPage = this.totalPages - 8;
      }
    }
    else {
      this.minPage = 1;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.page) {
      this.setPagers();
    }
  }

  pageArray() {
    const nbPages = this.totalPages > 9 ? 9 : this.totalPages;
    return Array.from(Array(nbPages), (_, i) => i + this.minPage);
  }

  chosenPage(page: number) {
    if (page !== this.page && page > 0 && page <= this.totalPages) {
      this.changePage.emit(page);
    }
  }

}

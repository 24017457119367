import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

// Providers
import { OrganisationService, OrderService } from 'src/app/providers';

@Component({
  selector: 'app-payment-account',
  templateUrl: './payment-account.component.html'
})
export class PaymentAccountComponent implements OnInit {

	me: any;
	stripe: any;
	public paymentAccountForm: FormGroup;

	constructor(
		private route: ActivatedRoute,
		private organisationService: OrganisationService,
		private orderService: OrderService,
		private formBuilder: FormBuilder
	) { }

	ngOnInit(): void {
		if (localStorage["currentUser"]) {
			this.me = JSON.parse(localStorage["currentUser"]);
		}

        this.route.queryParams.subscribe(_queryParams => {
        	if (_queryParams.refreshUrl) {
        		this.paymentAccountGetLink();
        	} else if (_queryParams.returnUrl) {
        		this.paymentAccountCheck();
        	} else {
        		this.getOrganisationData();
        	}
        });

	    this.paymentAccountForm = this.formBuilder.group({});
	}

	getOrganisationData() {
		if (this.me) {
			if (this.me.organisationId) {
				this.organisationService.getById(this.me.organisationId).subscribe(_result => {
					if (_result) {
						const stripegateway = _result.paymentGateways.find(_gateway => _gateway.type === 'stripe');
						if (stripegateway) {
							this.stripe = stripegateway;
						}
					}
				});
			}
		}
	}

	paymentAccountCreate() {
		if (!this.stripe.accountId) {
			this.orderService.paymentAccountCreate().subscribe(_result => {
				if (_result) {
					window.location.href = _result['url'];
				}
			});
		}
	}

	paymentAccountGetLink() {
		if (this.stripe.accountId) {
			this.orderService.paymentAccountGetLink().subscribe(_result => {
				if (_result) {
					window.location.href = _result['url'];
				}
			});
		}
	}

	// @TODO: Checker si cette methode est OK après API V2
	paymentAccountCheck() {
		if (this.me) {
			if (this.me.organisationId) {
				this.orderService.paymentAccountCheck().subscribe(_result => {
					if (_result) {
						if (_result['paymentGateway']) {
							if (_result['paymentGateway'].stripe) {
								this.stripe = _result['paymentGateway'].stripe;
							}
						}
					}
				});
			}
		}
	}

	// return_url : Get accountId for check if OK

	// refresh_url dans : Get new accountLinks

	// Account.charges_enabled ; if = true : Its activated, ready for payment

	// Account.details_submitted ; if true : Document its OK, check if charges_enabled for verify if enable


}

<section id="form_page" class="container loading_container" [ngClass]="{ 'loaded' : mainForm }">
    <div class="loading_content">
        <div id="page_header">
            <h1>Événements {{eventsCount ? "(" + eventsCount + ")" : ''}}</h1>
            <app-button (clickEvent)="goBack()" text="retour" [style]="'border blue'"></app-button>
        </div>
        <div id="form_wrapper">
            <h2>Créer un événement</h2>

            <form *ngIf="mainForm" [formGroup]="mainForm">
                <h3>Votre événement</h3>
                <!-- <app-file-input class="form_item" text="Ajoutez le logo de votre événement"
                    (imageChange)="uploadFile($event)"></app-file-input> -->
                <div class="form_cols">
                    <div class="form_item full required">
                        <label for="name">Nom</label>
                        <input id="name" type="text" formControlName="name">
                    </div>
                    <div class="form_item half">
                        <label for="category">Catégorie</label>
                        <div class="input_wrap select">
                            <select #parentCategory id="category" (change)="selectCategory(parentCategory.value)"
                                formControlName="categoryId">
                                <option *ngFor="let category of parentCategories" value="{{category._id}}">
                                    {{category.name}}
                                </option>
                            </select>
                            <svg role="img" title="chevron" class="icon-chevron">
                                <use href="assets/images/sprite.svg#icon-chevron"></use>
                            </svg>
                        </div>
                    </div>
                    <div class="form_item half" *ngIf="childCategories && childCategories.length > 0">
                        <label for="child-category">Sous-categorie</label>
                        <div class="input_wrap select">
                            <select #childCategory id="child-category" formControlName="categoryId2">
                                <option *ngFor="let category of childCategories" value="{{category._id}}">
                                    {{category.name}}
                                </option>
                            </select>
                            <svg role="img" title="chevron" class="icon-chevron">
                                <use href="assets/images/sprite.svg#icon-chevron"></use>
                            </svg>
                        </div>
                    </div>
                    <div class="form_item half">
                        <label for="category">Type d'événement</label>
                        <div class="input_wrap select">
                            <select #typeSelector id="eventType" (change)="selectType(typeSelector.value)"
                                formControlName="eventType">
                                <option value="presential">Présentiel</option>
                                <option value="remote">Distanciel</option>
                            </select>
                            <svg role="img" title="chevron" class="icon-chevron">
                                <use href="assets/images/sprite.svg#icon-chevron"></use>
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="form_item">
                    <label for="booking-type">Type de réservation</label>
                    <div class="input_wrap select">
                        <select #childCategory id="booking-type" formControlName="bookType" (change)="checkType($event.target.value)">
                            <option value="ticketing">Avec billetterie</option>
                            <option value="registration">Avec inscription</option>
                            <option value="nft" *ngIf="isAdmin">Avec NFT</option>
                        </select>
                        <svg role="img" title="chevron" class="icon-chevron">
                            <use href="assets/images/sprite.svg#icon-chevron"></use>
                        </svg>
                    </div>
                </div>
                <!-- <div class="form_item tags">
                    <label for="tags">Tags</label>
                    <ul>
                        <li *ngFor="let tag of tags" (click)="deleteTag(tag)">
                            {{tag}}
                            <svg role="img" title="close" class="icon-close">
                                <use href="assets/images/sprite.svg#icon-close"></use>
                            </svg>
                        </li>
                    </ul>
                    <input #tagInput type="text" (keydown.enter)="addTag(tagInput)"
                        (keydown.enter)="$event.preventDefault()">
                </div> -->

                <div formGroupName="dates">
                    <div class="form_item" *ngIf="mainForm.value.bookType !== 'nft'">
                        <input #dateType id="date-type" type="checkbox" (change)="checkTypeDate(dateType.checked)"
                            class="toggle">
                        <label for="date-type">
                            Mon événement compte plusieurs dates
                            <i class="check">
                                <svg role="img" title="check" class="check-icon">
                                    <use href="assets/images/sprite.svg#icon-check"></use>
                                </svg>
                            </i>
                        </label>
                        <p class="session_info" *ngIf="session">Vous pourrez déterminer les sessions de votre événement
                            après la création
                            dans l'onglet "Billetterie > Sessions"</p>
                    </div>

                    <app-date-interval *ngIf="!session" (newDates)="changeDates($event, 'dates')"
                        startText="Début de l'événement" endText="Fin de l'événement"
                        (changeTimeZone)="selectTimeZone($event)"></app-date-interval>

                </div>
                <div (keydown.enter)="$event.stopPropagation()" class="form_item required" *ngIf="mainForm.value.bookType === 'nft'">
                    <label for="description">Description (Affiché sur la page du ticket NFT)</label>
                    <app-wysiwyg (changeText)="setDescriptionValue($event)"></app-wysiwyg>
                </div>
                <ng-container *ngIf="currentType == 'presential'">
                    <h3 style="margin-top: 20px;">Votre lieu</h3>
                    <div formGroupName="place">
                        <div class="form_item">
                            <label for="place-name">Nom de l'adresse</label>
                            <input id="place-name" type="text" formControlName="name"
                                placeholder="Par exemple, Zenith de Paris">
                        </div>

                        <app-places (onChange)="setEnteredAddress($event)" label="Adresse de votre événement">
                        </app-places>

                        <ng-container *ngIf="addressEntered">
                            <div class="form_item">
                                <label for="address">Adresse</label>
                                <input id="address" type="text" formControlName="address">
                            </div>
                            <div class="form_item">
                                <label for="address2">Adresse bis</label>
                                <input id="address2" type="text" formControlName="address2">
                            </div>
                            <div class="form_cols">
                                <div class="form_item half">
                                    <label for="zipcode">Code Postal</label>
                                    <input id="zipcode" type="text" formControlName="zipcode">
                                </div>
                                <div class="form_item half">
                                    <label for="city">Ville</label>
                                    <input id="city" type="text" formControlName="city">
                                </div>
                                <div class="form_item three_cols">
                                    <label for="countries{{i}}">Pays</label>
                                    <div class="input_wrap select">
                                        <select id="countries{{i}}" formControlName="countryId">
                                            <option *ngFor="let country of countries" [value]="country._id">
                                                {{country.name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
                <ng-container *ngIf="currentType == 'remote'">
                    <h3>Accès à votre événement</h3>
                    <div class="form_item">
                        <label for="eventLink">URL</label>
                        <input id="eventLink" type="text" formControlName="eventLink">
                    </div>
                </ng-container>

                <h3>Plan de salle</h3>
                <div class="form_item">
                    <input #useRoomPlacement id="useRoomPlacement" type="checkbox" formGroupName="useRoomPlacement" class="toggle"
                        (change)="changeSeatingPlan(useRoomPlacement.checked)">
                    <label for="useRoomPlacement">
                        Attacher cet événement à un plan de salle
                        <i class="check">
                            <svg role="img" title="check" class="check-icon">
                                <use href="assets/images/sprite.svg#icon-check"></use>
                            </svg>
                        </i>
                    </label>
                </div>
                <div class="form_item required" *ngIf="charts.length > 0 && mainForm.value.useRoomPlacement">
                    <label for="seatingPlan">Choisir le plan de salle</label>
                    <div class="input_wrap select">
                        <select id="seatingPlan" formControlName="seatsIoId">
                            <option value="null" disabled>Sélectionner un plan</option>
                            <option *ngFor="let chart of charts" value="{{ chart.key }}">{{ chart.name }}</option>
                        </select>
                        <svg role="img" title="chevron" class="icon-chevron">
                            <use href="assets/images/sprite.svg#icon-chevron"></use>
                        </svg>
                    </div>
                    <p class="description"><strong>Important :</strong> assurez-vous que votre plan de salle est définitif, il ne sera plus possible de le modifier pour cet événement. Assurez-vous également d'avoir créé les catégories de tickets depuis votre plan de salle car vous ne pourrez plus en ajouter. Vous pourrez cependant modifier le nom de la catégorie.</p>
                </div>

                <p class="description" *ngIf="charts.length == 0 && mainForm.value.useRoomPlacement" style="margin-bottom: 30px;">Vous n'avez aucun plan de salle valide, vous pouvez créer un plan de salle dans <a routerLink="/parametres/seating-plan" target="_blank">Paramètres > Plan de salle</a></p>

                <h3>Informations Financières</h3>
                <div formGroupName="ticketing">
                    <div class="form_cols">
                        <div class="form_item half">
                            <label for="currency">Devise</label>
                            <div class="input_wrap select">
                                <select id="currency" formControlName="defaultCurrencyId">
                                    <option value="null" selected hidden>Choisir une devise</option>
                                    <option *ngFor="let currency of currencies" value="{{currency._id}}">
                                        {{currency.isoCode}}</option>
                                </select>
                                <svg role="img" title="chevron" class="icon-chevron">
                                    <use href="assets/images/sprite.svg#icon-chevron"></use>
                                </svg>
                            </div>
                        </div>
                        <div class="form_item half required">
                            <label for="tax">TVA</label>
                            <div class="input_wrap select">
                                <select id="tax" formControlName="taxId">
                                    <option value="null" selected hidden>Choisir une TVA</option>
                                    <option *ngFor="let tax of taxes" value="{{tax._id}}">
                                        {{tax.value / 100 + "%"}}</option>
                                </select>
                                <svg role="img" title="chevron" class="icon-chevron">
                                    <use href="assets/images/sprite.svg#icon-chevron"></use>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form_cols commission">
                    <div class="form_item half">
                        <label for="commissionType">Type de commission</label>
                        <div class="input_wrap select">
                            <select id="commissionType" formControlName="commissionIncreased">
                                <option [ngValue]="false">Incluse</option>
                                <option [ngValue]="true">Majorée</option>
                            </select>
                            <svg role="img" title="chevron" class="icon-chevron">
                                <use href="assets/images/sprite.svg#icon-chevron"></use>
                            </svg>
                        </div>
                    </div>
                    <p class="commission_info">La commission incluse sera déduite du prix du billet pour l'organisateur alors que la commission majorée
                        augmentera le prix du billet</p>
                </div>
                <div class="form_item">
                    <input #isInSearch id="is-in-search" type="checkbox" formGroupName="isInSearch" class="toggle"
                        (change)="changeIsInSearch(isInSearch.checked)">
                    <label for="is-in-search">
                        Référencer l'événement sur les moteurs de recherches
                        <i class="check">
                            <svg role="img" title="check" class="check-icon">
                                <use href="assets/images/sprite.svg#icon-check"></use>
                            </svg>
                        </i>
                    </label>
                </div>
                <div class="form_item">
                    <input #termsInput id="agreed-terms" type="checkbox" formGroupName="agreedTerms"
                        (change)="agreeTerms(termsInput.checked)">
                    <label for="agreed-terms">
                        J'ai bien pris connaissance des termes et conditions. <a href="evenements/termes-et-conditions"
                            target="blank">En savoir plus</a>
                        <svg role="img" title="check" class="check-icon">
                            <use href="assets/images/sprite.svg#icon-check"></use>
                        </svg>
                    </label>
                </div>
                <div class="form_actions right">
                    <app-button (clickEvent)="goBack()" text="retour" [style]="'border blue'"></app-button>
                    <app-button [loadingOff]="loadingState$" [style]="'plain'" [disabled]="mainForm.invalid"
                        (clickEvent)="submitForm()" text="Créer" type="submit">
                    </app-button>
                    <app-popup *ngIf="error != null" title="Une erreur est survenue" message="{{error}}"
                        popupOpened="true" (backEvent)="error = null"></app-popup>
                </div>
            </form>
        </div>
    </div>
</section>

<section id="form_page" class="container">
  <div id="page_header">
    <h1>Sessions</h1>
    <app-button (clickEvent)="goBack()" text="retour" [style]="'border blue'"></app-button>
  </div>
  <div id="form_wrapper">
    <h2>{{data ? 'Modifier' : 'Créer'}} une session</h2>
    <form *ngIf="mainForm" [formGroup]="mainForm" class="small">
      <app-date-interval [endDateHidden]="true" (newDate)="changeDate($event, 'date')"
        startText="Date et heure du début de la session" [preData]="data ? {startDate: data.date} : null">
      </app-date-interval>
      <div class="form_cols">
        <div formGroupName="stocks" class="form_item half">
          <label for="quota">Quota de la session</label>
          <input id="quota" type="text" formControlName="quota">
        </div>
        <div *ngIf="data && ![undefined, null].includes(data.stocks.quota)" class="form_item half">
          <label for="stock">Stock Actuel</label>
          <input id="stock" type="text" disabled [value]="data.stocks.inStock">
        </div>
      </div>
      <div>
        <div class="form_item form_radios no_padding">
          <input id="discount_percent" type="radio" [value]="false" formControlName="isTicketTypeRestricted"
            (change)="ticketTypeApplied = false">
          <label for="discount_percent" class="big_margin">Appliquer tous les tarifs de la billetterie</label>
          <input id="discount_euro" type="radio" [value]="true" formControlName="isTicketTypeRestricted"
            (change)="ticketTypeApplied = true">
          <label for="discount_euro" class="big_margin">Appliquer une sélection de tarifs</label>
        </div>
        <div *ngIf="ticketTypeApplied" class="form_item">
          <div *ngIf="ticketTypes?.length">
            <label for="ticket_type">Sélectionner des tarifs</label>
            <div class="form_item">
              <div class="input_wrap select multiple">
                <span class="select_multiple_toggle" (click)="dropDown = !dropDown; $event.stopPropagation()">
                  {{getTicketsName()}}
                </span>
                <svg role="img" title="chevron" class="icon-chevron">
                  <use href="assets/images/sprite.svg#icon-chevron"></use>
                </svg>
                <div class="dropdown multiple" *ngIf="dropDown" (click)="$event.stopPropagation()">
                  <ul>
                    <li *ngFor="let ticket of ticketTypes; let i = index">
                      <input id="ticket_{{i}}" type="checkbox" [attr.checked]="ticketChecked(ticket._id)"
                        (change)="checkTicket(ticket._id, $event.target.checked)" />
                      <label for="ticket_{{i}}">
                        {{ticket.name}} <span class="ticket_category"> - {{ticket.ticketTypeCategory?.name || 'Sans
                          catégorie'}}</span>
                        <svg role="img" title="check" class="check-icon">
                          <use href="assets/images/sprite.svg#icon-check"></use>
                        </svg>
                      </label>
                    </li>
                  </ul>
                  <div class="dropdown_action">
                    <app-button (clickEvent)="dropDown = false" text="Sélectionner" [style]="'plain full'"></app-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p *ngIf="!ticketTypes?.length" class="form_item error">Il semble que vous n’ayez pas encore créé de tarifs,
            <a routerLink="../../ticket/creer-ticket">créez-en un !</a>
          </p>
        </div>
      </div>
      <div class="form_item tax_block required">
        <label for="tax">TVA spécifique à la session</label>
        <div class="input_wrap select">
          <select id="tax" formControlName="taxId">
            <option value="null" selected hidden></option>
            <option *ngFor="let tax of taxes" value="{{tax._id}}">
              {{tax.value / 100 + "%"}}</option>
          </select>
          <svg role="img" title="chevron" class="icon-chevron">
            <use href="assets/images/sprite.svg#icon-chevron"></use>
          </svg>
        </div>
      </div>
      <div class="form_actions right">
        <app-button (clickEvent)="goBack()" text="Annuler" [style]="'border blue'"></app-button>
        <app-button [loadingOff]="loadingState$" [style]="'plain'" [disabled]="mainForm.invalid"
          (clickEvent)="submitForm()" [text]="data ? 'Éditer la session' : 'Créer la session'" type="submit">
        </app-button>
        <app-popup *ngIf="error != null" title="Une erreur est survenue" message="{{error}}" popupOpened="true"
          (backEvent)="error = null"></app-popup>
      </div>
    </form>
  </div>
</section>
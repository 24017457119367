<div id="page_header" class="container">
  <div id="page_header_left">
    <h1>Plan de la salle</h1>
  </div>
  <div id="page_header_right">
    
  </div>
</div>

<section class="container">
	<ul id="event_numbers">
		<li>
			<svg class="down" role="img" title="money" class="money-icon">
				<use href="assets/images/sprite.svg#icon-money"></use>
			</svg>
			<span class="label">Disponible</span>
			<span class="value">{{ reports?.available?.count }}</span>
		</li>
		<li>
			<svg class="down" role="img" title="money" class="money-icon">
				<use href="assets/images/sprite.svg#icon-money"></use>
			</svg>
			<span class="label">Temporairement bloqué</span>
			<span class="value">{{ reports?.reservedByToken?.count }}</span>
		</li>
		<li>
			<svg class="down" role="img" title="ticket" class="ticket-icon">
				<use href="assets/images/sprite.svg#icon-ticket"></use>
			</svg>
			<span class="label">Réservé</span>
			<span class="value">{{ reports?.booked?.count }}</span>
		</li>
		<li>
			<svg class="down" role="img" title="ticket" class="ticket-icon">
				<use href="assets/images/sprite.svg#icon-ticket"></use>
			</svg>
			<span class="label">Bloqué à la vente</span>
			<span class="value">{{ reports?.not_for_sale?.count }}</span>
		</li>
	</ul>
</section>

<section id="form_page" class="container">
  <div id="chartRenderer" style="height: 100%; margin-top: -14px;"></div>
</section>
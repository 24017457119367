<div *ngIf="me && eventsStats">
	<!-- CREATION PREMIER EVENEMENT -->
	<section id="block_first" class="container" *ngIf="eventsStats.eventNumber === 0">
		<h1>Bonjour {{me.firstName}} !</h1>
		<p class="description">
			Envie d’organiser votre premier événement ? N’hésitez pas à vous lancer !<br />
			C’est simple, cela prend moins de 3 minutes.
		</p>
		<app-button (click)="createEvent()" class="button_create" text="Créer mon premier événement" [style]="'plain'">
		</app-button>
		<figure>
			<img src="assets/images/visuel_evenement.svg" />
		</figure>
	</section>
	<!-- END CREATION PREMIER EVENEMENT -->

	<!-- TABLEAU DE BORD -->
	<section id="tdb_content" class="container" *ngIf="eventsStats.eventNumber">
		<div id="page_header">
			<div id="page_header_left">
				<h1>Bonjour {{me.firstName}} !</h1>
				<span class="sub_title">Bienvenue sur votre espace organisateur</span>
			</div>
			<div id="page_header_right">
				<app-button (click)="createEvent()" class="button" text="Créer un nouvel événement" [style]="'plain'">
				</app-button>
			</div>
		</div>
		<div id="page_content">
			<ul id="tdb_links">
				<li>
					<a class="bg" (click)="navigate('/evenements', 1)">
						<div class="tdb_link_left">
							<span class="title">Événements</span>
							<p>
							<figure>
								<img src="assets/images/tdb_event.svg" />
							</figure>
							Créez et gérez vos événements : commandes, paramètres, participants…
							</p>
						</div>
						<div class="tdb_link_right">
							<span class="number">{{eventsStats.eventNumber}}</span>
							<span class="label">créé{{eventsStats.eventNumber > 1 ? 's' : ''}}</span>
						</div>
					</a>
				</li>
				<li>
					<a class="bg" (click)="navigate('/equipe', 2)">
						<div class="tdb_link_left">
							<span class="title">Équipe</span>
							<p>
							<figure>
								<img src="assets/images/tdb_team.svg" />
							</figure>
							Gérez les membres de votre équipe et leurs rôles sur la plateforme.
							</p>
						</div>
						<div class="tdb_link_right">
							<span class="number">{{eventsStats.teamSize}}</span>
							<span class="label">équipier{{eventsStats.teamSize > 1 ? 's' : ''}}</span>
						</div>
					</a>
				</li>
				<li>
					<a class="bg" (click)="navigate('/parametres', 3)">
						<div class="tdb_link_left full">
							<span class="title">Paramètres</span>
							<p>
							<figure>
								<img src="assets/images/tdb_settings.svg" />
							</figure>
							Gérez les informations de votre entreprise, votre RIB/IBAN...
							</p>
						</div>
					</a>
				</li>
			</ul>
			<div id="tdb_stats">
				<span>Statistiques des 7 derniers jours</span>
				<ul id="list_tdb_stats">
					<li>
						<svg role="img" title="ticket" class="ticket-icon">
							<use href="assets/images/sprite.svg#icon-ticket"></use>
						</svg>
						<span class="title">Commandes</span>
						<span class="number">{{eventsStats.orderLastDay}}</span>
						<span *ngIf="eventsStats.percentDiffOrderLastDay"
							class="diff {{eventsStats.percentDiffOrderLastDay.includes('-') ? 'red' : 'green'}}">
							{{eventsStats.percentDiffOrderLastDay.includes('-') ? '' : '+'}}
							{{eventsStats.percentDiffOrderLastDay }}%</span>
					</li>
					<li>
						<svg role="img" title="user" class="user-icon">
							<use href="assets/images/sprite.svg#icon-user"></use>
						</svg>
						<span class="title">Participants inscrits</span>
						<span class="number">{{eventsStats.participantLastDay}}</span>
						<span *ngIf="eventsStats.percentDiffParticipantLastDay"
							class="diff {{eventsStats.percentDiffParticipantLastDay.includes('-') ? 'red' : 'green'}}">
							{{eventsStats.percentDiffParticipantLastDay.includes('-') ? '' : '+'}}
							{{eventsStats.percentDiffParticipantLastDay }}%</span>

					</li>
					<li>
						<svg role="img" title="money" class="money-icon">
							<use href="assets/images/sprite.svg#icon-money"></use>
						</svg>
						<span class="title">Recette (nette)</span>
						<span class="number">{{(eventsStats.costPriceLastDay || 0) | currency: 'EUR'}}</span>
						<span *ngIf="eventsStats.percentDiffCostPriceLastDay"
							class="diff {{eventsStats.percentDiffCostPriceLastDay.includes('-') ? 'red' : 'green'}}">
							{{eventsStats.percentDiffCostPriceLastDay.includes('-') ? '' : '+'}}
							{{eventsStats.percentDiffCostPriceLastDay }}%</span>
					</li>
					<li>
						<svg role="img" title="money" class="money-icon">
							<use href="assets/images/sprite.svg#icon-money"></use>
						</svg>
						<span class="title">Recette (brute)</span>
						<span class="number">{{(eventsStats.sellingPriceLastDay || 0) | currency: 'EUR'}}</span>
						<span *ngIf="eventsStats.percentDiffSellingPriceLastDay"
							class="diff {{eventsStats.percentDiffSellingPriceLastDay.includes('-') ? 'red' : 'green'}}">
							{{eventsStats.percentDiffSellingPriceLastDay.includes('-') ? '' : '+'}}
							{{eventsStats.percentDiffSellingPriceLastDay }}%</span>
					</li>
				</ul>
			</div>
		</div>
	</section>
	<!-- END TABLEAU DE BORD -->
</div>
<div class="loading_container" [ngClass]="{ 'loaded' : data }">
  <div class="loading_content">
    <div id="page_header" class="container">
      <div id="page_header_left">
        <h1>Widgets</h1>
      </div>
      <div id="page_header_right">
        <app-button (clickEvent)="createData()" [loadingFunction]="false" class="button" text="Créer un widget"
          [style]="'plain'"></app-button>
      </div>
    </div>

    <ng-container *ngIf="data">
      <section *ngIf="data.length">
        <app-main-table [ref]="this" idSection="widgets_content" idTable="table_widgets" tableType="widgets"
          [columns]="tableOptions" [datum]="data"></app-main-table>
      </section>

      <section *ngIf="!data.length" id="block_first" class="container">
        <p class="description grey">Vous n’avez pas encore créé de widget.</p>
        <figure>
          <img src="assets/images/visuel_ticketing.svg" />
        </figure>
        <app-button (click)="createData()" class="button_create no_margin" text="Créer un widget" [style]="'plain full'">
        </app-button>
      </section>

    </ng-container>

    <app-pager *ngIf="totalPages" [totalPages]="totalPages" [page]="page" (changePage)="changePage($event)">
    </app-pager>

    <app-delete-popup *ngIf="widgetToDelete" [name]="widgetToDelete.name" type='widget' (clickEvent)="validateDeletion()"
      (close)="widgetToDelete = null"></app-delete-popup>

    <app-popup *ngIf="messageIFrame" title="Intégrez votre widget"
      message="Copier le code ci-dessous pour intégrer votre widget sur une page web" (backEvent)="messageIFrame = null"
      (clickEvent)="copyIFrame()" [specialContent]="messageIFrame" specialContentClass="iframe_code" buttonText="Copier le code"></app-popup>
  </div>
</div>
<div class="loading_container" [ngClass]="{ 'loaded' : data }">
    <div class="loading_content">
      <div id="page_header" class="container">
        <div id="page_header_left">
          <h1>Addons</h1>
        </div>
        <div id="page_header_right">
          <app-button (clickEvent)="createData()" [loadingFunction]="false" class="button" text="Créer un addon"
            [style]="'plain'"></app-button>
        </div>
      </div>
  
      <ng-container *ngIf="data">
        <section *ngIf="data.length">
          <app-main-table [ref]="this" idSection="addons_content" idTable="table_addons" tableType="addons"
            [columns]="tableOptions" [datum]="data"></app-main-table>

            
        <app-pager *ngIf="totalPages" [totalPages]="totalPages" [page]="page" (changePage)="changePage($event)">
        </app-pager>

        </section>
  
        <section *ngIf="!data.length" id="block_first" class="container">
          <p class="description grey">Vous n’avez pas encore créé d'addon.</p>
          <figure>
            <img src="assets/images/visuel_ticketing.svg" />
          </figure>
          <app-button (click)="createData()" class="button_create no_margin" text="Créer un addon" [style]="'plain full'">
          </app-button>
        </section>
  
      </ng-container>
  
      <app-delete-popup *ngIf="addonToDelete" [name]="addonToDelete.name" type='addon' (clickEvent)="validateDeletion()"
        (close)="addonToDelete = null"></app-delete-popup>

  </div>
import { Component, HostListener, OnInit } from '@angular/core';
import { FormFromEventComponent } from 'src/app/elements/form-component';
import { ISite, ISiteColors } from 'src/app/models/site.model';
import { EventSiteService, EventWidgetService, InstagramService } from 'src/app/providers';
import { GetEvent } from '../../getEvent';
import { FormControl, Validators } from '@angular/forms';
import { ImageChangeState, ImagesChangeState } from 'src/app/models/type.definition';
import { IWidget } from 'src/app/models/widget.model';
import * as config from 'src/config.json';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { base64ToFile } from 'ngx-image-cropper';
import { environment } from 'src/environments/environment';

const DefaultImageChangedState: ImageChangeState = {
  load: false,
  changed: false
}

@Component({
  selector: 'app-event-create-mini-site',
  templateUrl: './event-create-mini-site.component.html',
  styleUrls: ['./event-create-mini-site.component.scss'],
  providers: [GetEvent]
})
export class EventCreateMiniSiteComponent extends FormFromEventComponent<ISite> implements OnInit {
  public dataName = 'Mini-Site';
  public tags: string[] = [];
  public widgets: IWidget[] = [];
  public loadingImage: string[] = [];
  public chooseUnsplash: boolean;
  public currentAuthor: string;
  public unsplashList: { url: string, author: string, base64?: SafeUrl }[];
  public typing: boolean;
  public unsplashSelected: any;
  public currentTimeOut: NodeJS.Timeout;
  public isConnected: boolean;
  public isInstagramConnected: boolean;
  public imageChanged: ImagesChangeState = {
    presentationImage: { ...DefaultImageChangedState },
    covers: { ...DefaultImageChangedState }
  };
  public colors: ISiteColors = {
    background: "#275AD9",
    title: "#000000",
    text: '#000000',
    link: '#224BB9'
  }

  constructor(
    protected formProvider: EventSiteService,
    protected widgetService: EventWidgetService,
    private instagramService: InstagramService,
    private sanitizer: DomSanitizer,
    protected getEvent: GetEvent) {
    super();
  }

  protected async onInit(): Promise<void> {
    await this.getDataToEdit('siteId', { template: 'full' })
    if (this.data) {
      this.colors = this.data.customStyles.colors;
      this.tags = this.data.tags;
      this.getImages();
    }
    this.widgets = await this.getListFromEventOf(this.widgetService, {perPage: 500});
    this.loaded = true;
    await this.verifyToken();
  }

  private async verifyToken(): Promise<void> {
    this.instagramService.hasToken(window.location.toString().split('/')[7]).subscribe(el => {
      this.isInstagramConnected = Boolean(el);
    });
  }

  private async deconnectInsta(): Promise<void> {
    this.instagramService.deleteToken(window.location.toString().split('/')[7]).toPromise()
    this.instagramService.pushMedias(window.location.toString().split('/')[7], []).toPromise()
    location.reload();
  }

  private async getImages(): Promise<void> {
    await this.getImage('presentationImageId', 'presentationImage');
    await this.getImage('coverId', 'covers');
  }

  private async getImage(idKey: 'presentationImageId' | 'coverId', destinationKey: 'presentationImage' | 'covers'): Promise<void> {
    const id = this.getImageFirstIndex(idKey);
    if (id) {
      this.loadingImage.push(destinationKey);
      try {
        this.data.customStyles[destinationKey] = await this.mediaService.getStream(id).toPromise();
      } catch (err) {
        console.error("Impossible de charger le media", err);
      }
      this.loadingImage.splice(this.loadingImage.indexOf(destinationKey));
    }
  }

  protected initForm(): void {
    this.mainForm = this.createFormGroup({
      name: new FormControl(this.data?.name, Validators.required),
      tags: new FormControl([]),
      defaultLangCode: 'fr',
      description: this.data?.description,
      gettingThere: this.data?.gettingThere,
      about: this.data?.about,
      website: this.data?.website,
      eventWidgetId: this.data?.eventWidgetId._id,
      instagramAuth: {
        instagramAppId: this.data?.instagramAuth?.instagramAppId,
        instagramToken: this.data?.instagramAuth?.instagramToken,
      },
      socials: {
        facebook: this.data?.socials?.facebook,
        instagram: this.data?.socials?.instagram,
        linkedin: this.data?.socials?.linkedin,
        twitter: this.data?.socials?.twitter,
        tiktok: this.data?.socials?.tiktok,
      },
      customStyles: {
        colors: this.data?.customStyles?.colors || this.colors,
        presentationImage: new FormControl(this.data?.customStyles?.presentationImage || []),
        presentationImageId: new FormControl(this.data?.customStyles?.presentationImageId?._id || null),
        coverId: new FormControl(this.data?.customStyles?.coverId?._id || null),
        covers: new FormControl(this.data?.customStyles?.covers || [])
      },
      eventId: new FormControl(this.event._id)
    });
  }

  private getImageFirstIndex(key: 'presentationImageId' | 'coverId'): string {
    const array = this.data?.customStyles[key];
    return array ? array._id : null;
  }

  public async submitForm(): Promise<void> {
    const form = this.mainForm.value;
    this.addFileIfChanged(form, 'presentationImageId', 'presentationImage');
    this.addFileIfChanged(form, 'coverId', 'covers');
    this.createMediaAndUpdate(form, 'eventSiteId', ['name', 'defaultLangCode']);
  }

  public async submitFormInsta(): Promise<void> {
    const form = this.mainForm.value;
    this.addFileIfChanged(form, 'presentationImageId', 'presentationImage');
    this.addFileIfChanged(form, 'coverId', 'covers');
    this.createMediaAndUpdate(form, 'eventSiteId', ['name', 'defaultLangCode'], res => {
      window.localStorage.setItem('instaLoc', window.location.toString());
      window.location.href = environment.instagramRedirectUrl;
    }, null, false, true);

  }

  private addFileIfChanged(
    form: any,
    idKey: 'presentationImageId' | 'coverId',
    destinationKey: 'presentationImage' | 'covers')
    : void {
    const imageKey = this.data?.customStyles && this.data?.customStyles[destinationKey];

    if (this.imageChanged[destinationKey].changed) {
      if (form.customStyles[destinationKey]) {
        this.filesToCreate.push({
          file: form.customStyles[destinationKey][0],
          _id: this.getImageFirstIndex(idKey),
          destination: `customStyles.${idKey}`
        })
      }
      else if (imageKey) {
        this.filesToCreate.push({
          file: null,
          _id: this.getImageFirstIndex(idKey),
          destination: `customStyles.${idKey}`
        });
        this.mainForm.value.customStyles[idKey] = [];
      }
    }
  }

  public preview(): void {
    window.open(`${environment.eventSiteBaseUrl}fr/event/${this.data.path}`, '_blank');
    this.loadingState$.next();
  }

  public changeColor(key: keyof ISiteColors, value: string): void {
    this.colors[key] = value;
    this.setValue(["customStyles", "colors", key], value);
  }

  public addTag(tag: HTMLInputElement): void {
    this.tags.push(tag.value);
    tag.value = "";
    this.setValue("tags", this.tags);
  }

  public deleteTag(tag: string): void {
    this.tags = this.tags.filter(_ => _ !== tag);
    this.setValue("tags", this.tags);
  }

  public changeImage(key: string, file: File): void {
    if (this.currentAuthor) {
      Object.defineProperty(file[0], 'authorName', { value: this.currentAuthor });
      this.currentAuthor = null;
    }

    const imageKey = this.data?.customStyles && this.data.customStyles[key];
    if (this.imageChanged[key].load || !imageKey || !imageKey[0]) {
      this.imageChanged[key].changed = true;
    }
    else {
      this.imageChanged[key].load = true;
    }
    this.setValue(['customStyles', key], file);
  }

  public changeChooseUnsplash(value: boolean): void {
    this.chooseUnsplash = value;
  }

  public searchUnsplash(value: string): void {
    this.typing = false;
    clearTimeout(this.currentTimeOut);
    if (value) {
      this.currentTimeOut = setTimeout(() => {
        if (!this.typing) {
          this.mediaService.getUnsplash(value).subscribe(res => {
            this.unsplashList = res;
            this.getUnsplashImages();
          }, err => {
            if (err.status == 404) {
              this.unsplashList = [];
            }
            else {
              this.error = this.errorHandler.getError(err);
            }
          })
        }
      }, 500)
    }
    else {
      this.unsplashList = null;
    }
  }

  public getUnsplashImages() {
    const sanitizer = this.sanitizer
    for (let image of this.unsplashList) {
      new Promise<SafeUrl>(res => {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", image.url, true);
        xhr.responseType = "blob";
        xhr.onload = function (e){
          var reader = new FileReader();
          reader.onload = function (event) {
            const base64 = sanitizer.bypassSecurityTrustUrl(event.target.result as string)
            res(base64)
          }
          reader.readAsDataURL(this.response);
        };
        xhr.send()
      }).then(base64 => image.base64 = base64);
    }
  }

  selectUnsplashImage(image: { url: string, author: string, base64: any }) {
    const blob = base64ToFile(image.base64.changingThisBreaksApplicationSecurity);
    this.currentAuthor = image.author;
    this.unsplashSelected = blob;
      this.unsplashList = null;
    const imageKey = this.data?.customStyles && this.data.customStyles.covers;
    if (this.imageChanged.covers.load || !imageKey || !imageKey[0]) {
      this.imageChanged.covers.changed = true;
    }
    else {
      this.imageChanged.covers.load = true;
    }
    this.setValue(['customStyles', 'covers'], blob);
    this.chooseUnsplash = false;
  }

  @HostListener('document:click') clickDoc(): void {
    this.unsplashList = null;
  }

}

<section id="form_page" class="container loading_container" [ngClass]="{ 'loaded' : mainForm }">
  <div class="loading_content">
    <div id="page_header">
      <div id="page_header_left">
        <h1>Contenus exclusifs</h1>
      </div>
      <div *ngIf="data" id="page_header_right">
        <app-button (clickEvent)="preview()" text="Prévisualiser" [style]="'border blue'"></app-button>
      </div>
    </div>
    <div id="form_wrapper">
      <h2>Votre contenu exclusif</h2>
      <form *ngIf="mainForm" [formGroup]="mainForm">

        <div class="form_item">
          <label for="name">Titre du contenu exclusif</label>
          <input id="name" type="text" formControlName="name">
        </div>

        <div class="form_item">
          <ng-container *ngIf="ticketTypes.length">
            <!-- <label for="rates">Tarifs</label> -->
            <div class="input_wrap select multiple">
              <span class="select_multiple_toggle"
                (click)="dropdownTickets = !dropdownTickets; $event.stopPropagation()">
                {{getTicketsName()}}
              </span>
              <svg role="img" title="chevron" class="icon-chevron">
                <use href="assets/images/sprite.svg#icon-chevron"></use>
              </svg>
              <div class="dropdown multiple" *ngIf="dropdownTickets" (click)="$event.stopPropagation()">
                <ul>
                  <li *ngFor="let ticket of ticketTypes; let i = index">
                    <input id="ticket_{{i}}" type="checkbox"
                      [attr.checked]="elementChecked(ticket.id, 'checkedTicketTypes')"
                      (change)="checkElement(ticket.id, $event.target.checked, 'checkedTicketTypes')" />
                    <label for="ticket_{{i}}">
                      {{ticket.name}} <span class="ticket_category"> - {{ticket.ticketTypeCategory?.name || 'Sans catégorie'}}</span>
                      <svg role="img" title="check" class="check-icon">
                        <use href="assets/images/sprite.svg#icon-check"></use>
                      </svg>
                    </label>
                  </li>
                </ul>
                <div class="dropdown_action">
                  <app-button (clickEvent)="dropdownTickets = false" text="Sélectionner" [style]="'plain full'">
                  </app-button>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!ticketTypes.length">
            <p class="form_item error">Il semble que vous n’ayez pas encore créé de tarifs</p>
          </ng-container>
        </div>

        <div>
          <h3>Média</h3>

          <app-file-input 
            *ngIf="loaded" 
            [loading]="loadingImage.includes('presentationImage')" 
            class="form_item"
            text="Ajoutez un média que pourra visualiser le détenteur du ticket NFT"
            [addFormat]="['mp4']"
            [limitSizeMo]="50"
            (imageChange)="changeImage('presentationImage', [$event])" [cropperStaticHeight]="768"
            [cropperStaticWidth]="1024"
          >
          </app-file-input>
        </div>

        <div class="form_actions right">
          <app-button *ngIf="data" (clickEvent)="preview()" text="Prévisualiser" [style]="'border blue'"></app-button>
          <app-button [loadingOff]="loadingState$" [style]="'plain'" [disabled]="mainForm.invalid"
            (clickEvent)="submitForm()" text="Enregistrer" type="submit">
          </app-button>
          <app-popup *ngIf="error != null" title="Une erreur est survenue" message="{{error}}" popupOpened="true"
            (backEvent)="error = null"></app-popup>
        </div>
      </form>
    </div>
  </div>
</section>
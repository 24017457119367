import { Injectable, OnDestroy } from '@angular/core';
import { RouteType } from '../models/type.definition';
import { MAINROUTES } from '../elements/sidebar/main-sidemenu.items';
import { EVENTROUTES } from '../elements/sidebar/event-sidemenu.items';
import { Router, NavigationEnd } from '@angular/router';
import { Route } from '@angular/compiler/src/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { EventService } from '../providers';

@Injectable({
  providedIn: 'root'
})
export class RoutesService {
  private _currentRoute: RouteType;
  private _currentParentRoute: RouteType;
  private _selectedEvent: any;
  private _routeIsNotEvent: boolean;
  private _currentPath: string;
  private _parentPath: string;

  public mainRoutes: RouteType[] = MAINROUTES;
  public eventRoutes: RouteType[] = EVENTROUTES;
  public exceptionalCrumb$: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor(
    private router: Router,
    private eventService: EventService
  ) {
    this.findRoutes(this.router.url);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const url = this.router.url;
        this.clearRouteArray(this.mainRoutes);
        this.clearRouteArray(this.eventRoutes);
        this.findRoutes(url);
        if (this._selectedEvent && this._routeIsNotEvent) {
          this.eventService.selectEvent(null);

        }
      }
    })
    this.eventService.selected$.subscribe(value => {
      this._selectedEvent = value;
    })
  }

  findRoutes(path: string): void {
    const pathArray = path.split('/');
    this._routeIsNotEvent = pathArray[1] !== 'evenements' || pathArray.length <= 2;
    if(['401', '403', '404'].includes(pathArray[1])){
      this.clearRoute(this._currentRoute);
      return
    }
    this._parentPath = this._currentPath;
    this._currentPath = path;
    this._currentRoute = this.clearRoute(this._currentRoute);
    this._currentParentRoute = this.clearRoute(this._currentParentRoute);
    if (pathArray[1] !== "mon-compte") {
      this.checkRoutes(pathArray, this.mainRoutes);
      if (!this._routeIsNotEvent) {
        let subPathArray = [];
        for (let i = 3; i < pathArray.length; i++) {
          subPathArray.push(pathArray[i])
        }
        this.checkRoutes(subPathArray, this.eventRoutes, 0);
      }
    }
  }

  clearRoute(route: RouteType) {
    if (route) {
      route.active = false;
      route.submenu.forEach(_ => _.active = false)
      return null;
    }
  }

  clearRouteArray(routes: RouteType[]){
    routes.forEach( route => {
      route.active = false;
      this.clearRouteArray(route.submenu)
    })
  }

  checkRoutes(path: string[], routes: RouteType[], i = 1): RouteType {
    for (const route of routes) {
      if (route.route === path[i]) {

        route.active = true;
        if (i + 1 < path.length) {
          this._currentParentRoute = route;
          const checkSub = this.checkRoutes(path, route.submenu, ++i);
          if (checkSub) {
            this._currentRoute = checkSub;
            return checkSub;
          }
        }
        else {
          route.submenu.forEach(_ => {
            if (_.route === '') {
              _.active = true;
            }
          })
        }
        this._currentRoute = route
        return route
      }
    }
  }

  getCurrentPath(){
    return this._parentPath || ''
  }


}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { IEvent } from 'src/app/models/event.model';
import { ITicketTypeCategory } from 'src/app/models/ticket.model';
import { EventService, TicketTypeCategoryService } from 'src/app/providers';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UpdateService } from 'src/app/services/update.service';
import { GetEvent } from '../../../getEvent';

@Component({
  selector: 'app-event-ticketing-create-category',
  templateUrl: './event-ticketing-create-category.component.html',
  styleUrls: ['./event-ticketing-create-category.component.scss'],
  providers: [GetEvent]
})
export class EventTicketingCreateCategoryComponent implements OnInit {
  private _subscription = new Subscription();
  public event: IEvent;
  public error: string;
  public loadingState$: Subject<void> = new Subject<void>();
  public categoryForm: FormGroup;
  public currentUrl;
  public ticketTypeCategory?: ITicketTypeCategory;
  public seatColor: string = '#999';

  constructor(
    private errorHandler: ErrorHandlerService,
    private getEvent: GetEvent,
    private update: UpdateService,
    private formBuilder: FormBuilder,
    private categoryService: TicketTypeCategoryService,
    private notification: NotificationService,
    private router: Router,
    private eventService: EventService,
    private route: ActivatedRoute,
  ) { }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  async ngOnInit() {
    this.currentUrl = this.router.url;
    this.event = await this.getEvent.get({select: ['dates', 'name', 'organisationId', 'useRoomPlacement']});
    const id = this.route.snapshot.paramMap.get('categoryId');
    if (id) {
      this.ticketTypeCategory = await this.categoryService.getById(id).toPromise();
      this.seatColor = this.ticketTypeCategory.seatsIoColor;
    }
    this.initForm();
  }

  initForm() {
    this.categoryForm = this.formBuilder.group({
      name: new FormControl(this.ticketTypeCategory?.name || '', Validators.required),
      stocks: new FormGroup({
        quota: new FormControl(this.ticketTypeCategory?.stocks?.quota || null),
      }),
      isOpened: new FormControl(this.ticketTypeCategory ? this.ticketTypeCategory?.isVisible : true),
    })
  }

  createCategory() {
    const formData = {
      eventId: this.event._id,
      ...this.categoryForm.value
    }
    if (this.ticketTypeCategory) {
      this.categoryService.update(this.ticketTypeCategory._id, formData).subscribe(result => {
        this.onSuccess('éditée');
      },
        err => this.onError(err))
    }
    else {
      this.categoryService.create(formData).subscribe(result => {
        this.onSuccess('créée');
      }, err => this.onError(err));
    }
  }

  onSuccess(type: 'créée' | 'éditée'): void {
    delete this.event.ticketTypeCategories;
    this.loadingState$.next();
    this.notification.newNotification({
      message: `Catégorie ${type} avec succès`,
      state: 'success'
    });
    this.eventService.selectEvent(this.event);
    this.goBack();
  }

  onError(err: any): void {
    this.error = this.errorHandler.getError(err)
    this.loadingState$.next();
  }

  goBack(): void {
    this.router.navigate([`evenements/${this.event._id}/billetterie/ticket/`]);
  }

  public changeColor(value: string): void {
    this.seatColor = value;
    this.categoryForm.patchValue({
      seatsIoColor: value
    });
  }
}

<section id="form_page" class="container loading_container" [ngClass]="{ 'loaded' : mainForm }">
  <div class="loading_content">
    <div id="page_header">
      <h1>Contrôle d'accès</h1>
      <app-button (clickEvent)="goBack()" text="retour" [style]="'border blue'"></app-button>
    </div>
    <div id="form_wrapper">
      <h2>{{data ? 'Éditer' : 'Créer'}} une liste</h2>
      <form *ngIf="mainForm" [formGroup]="mainForm" class="medium">
        <div class="form_item">
          <label for="name">Nom de la liste de contrôle</label>
          <input type="text" formControlName="name" />
        </div>
        <div *ngIf="showLimit" class="form_item no_margin">
          <label for="passages">Nombre de passages autorisés</label>
          <div class="form_cols">
            <div class="form_item third">
              <input type="number" formControlName="nbAccess" />
            </div>
            <div class="form_item third">
              <input id="passages_unlimited" [checked]="unlimited" (change)="changeUnlimited($event.target.checked)"
                class="boxed" type="checkbox" />
              <label for="passages_unlimited">Illimité</label>
            </div>
          </div>
        </div>
        <div class="rules_form">
          <div *ngIf="sessions.length" class="form_item">
            <label for="sessions">Session(s) Concernée(s)</label>
            <div class="input_wrap select multiple">
              <span class="select_multiple_toggle"
                (click)="dropdownSessions = !dropdownSessions; $event.stopPropagation()">
                {{getSessionsName()}}
              </span>
              <svg role="img" title="chevron" class="icon-chevron">
                <use href="assets/images/sprite.svg#icon-chevron"></use>
              </svg>
              <div class="dropdown multiple" *ngIf="dropdownSessions" (click)="$event.stopPropagation()">
                <ul>
                  <li *ngFor="let session of sessions; let i = index">
                    <input id="session_{{i}}" type="checkbox"
                      [attr.checked]="elementChecked(session._id, 'checkedSessions')"
                      (change)="checkElement(session._id, $event.target.checked, 'checkedSessions')" />
                    <label for="session_{{i}}">
                      {{session.name}}
                      <svg role="img" title="check" class="check-icon">
                        <use href="assets/images/sprite.svg#icon-check"></use>
                      </svg>
                    </label>
                  </li>
                </ul>
                <div class="dropdown_action">
                  <app-button (clickEvent)="dropdownSessions = false" text="Sélectionner" [style]="'plain full'">
                  </app-button>
                </div>
              </div>
            </div>
          </div>

          <div class="form_item">
            <label for="rates">Choix des tarifs contrôlés</label>
            <div class="input_wrap select">
              <select id="restriction" (change)="changeRestriction($event.target.value)">
                <option [value]="null" [attr.selected]="!restrictionSelectPosition ? true : null">Tous les tickets & addons</option>
                <option value="ticket" [attr.selected]="restrictionSelectPosition == 'ticket' ? true : null">Sélection de tickets</option>
                <option value="addon" [attr.selected]="restrictionSelectPosition == 'addon' ? true : null">Sélection d'Addons</option>
              </select>
              <svg role="img" title="chevron" class="icon-chevron">
                <use href="assets/images/sprite.svg#icon-chevron"></use>
              </svg>
            </div>
            <!-- <div class="form_radios">
              <input id="rates_all" type="radio" [value]="false" formControlName="ticketTypeFiltered">
              <label for="rates_all">Tous</label>
              <input id="rates_choice" type="radio" [value]="true" formControlName="ticketTypeFiltered">
              <label for="rates_choice">Au choix</label>
            </div> -->
          </div>
          <div class="form_item" *ngIf="ticketRestricted() && !selectProduct">
            <ng-container *ngIf="!selectAddon && !selectProduct">
              <ng-container *ngIf="ticketTypes.length">
                <!-- <label for="rates">Tarifs</label> -->
                <div class="input_wrap select multiple">
                  <span class="select_multiple_toggle"
                    (click)="dropdownTickets = !dropdownTickets; $event.stopPropagation()">
                    {{getTicketsName()}}
                  </span>
                  <svg role="img" title="chevron" class="icon-chevron">
                    <use href="assets/images/sprite.svg#icon-chevron"></use>
                  </svg>
                  <div class="dropdown multiple" *ngIf="dropdownTickets" (click)="$event.stopPropagation()">
                    <ul>
                      <li *ngFor="let ticket of ticketTypes; let i = index">
                        <input id="ticket_{{i}}" type="checkbox"
                          [attr.checked]="elementChecked(ticket._id, 'checkedTicketTypes')"
                          (change)="checkElement(ticket._id, $event.target.checked, 'checkedTicketTypes')" />
                        <label for="ticket_{{i}}">
                          {{ticket.name}} <span class="ticket_category"> - {{ticket.ticketTypeCategory?.name || 'Sans catégorie'}}</span>
                          <svg role="img" title="check" class="check-icon">
                            <use href="assets/images/sprite.svg#icon-check"></use>
                          </svg>
                        </label>
                      </li>
                    </ul>
                    <div class="dropdown_action">
                      <app-button (clickEvent)="dropdownTickets = false" text="Sélectionner" [style]="'plain full'">
                      </app-button>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="!ticketTypes.length">
                <p class="form_item error">Il semble que vous n’ayez pas encore créé de tarifs,
                  <a routerLink="../../../billetterie/ticket/creer-ticket">créez-en un !</a>
                </p>
              </ng-container>
            </ng-container>


            <!--  ADDONS -->
            <ng-container *ngIf="selectAddon">
              <ng-container *ngIf="addons.length">
                <!-- <label for="rates">Addons</label> -->
                <div class="input_wrap select multiple">
                  <span class="select_multiple_toggle"
                    (click)="dropdownAddons = !dropdownAddons; $event.stopPropagation()">
                    {{getAddonsName()}}
                  </span>
                  <svg role="img" title="chevron" class="icon-chevron">
                    <use href="assets/images/sprite.svg#icon-chevron"></use>
                  </svg>
                  <div class="dropdown multiple" *ngIf="dropdownAddons" (click)="$event.stopPropagation()">
                    <ul>
                      <li *ngFor="let addon of addons; let i = index">
                        <input id="addon_{{i}}" type="checkbox"
                          [attr.checked]="elementChecked(addon._id, 'checkedAddons')"
                          (change)="checkElement(addon._id, $event.target.checked, 'checkedAddons')" />
                        <label for="addon_{{i}}">
                          {{addon.name}}
                          <svg role="img" title="check" class="check-icon">
                            <use href="assets/images/sprite.svg#icon-check"></use>
                          </svg>
                        </label>
                      </li>
                    </ul>
                    <div class="dropdown_action">
                      <app-button (clickEvent)="dropdownAddons = false" text="Sélectionner" [style]="'plain full'">
                      </app-button>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="!ticketTypes.length">
                <p class="form_item error">Il semble que vous n’ayez pas encore créé d'addon,
                  <a routerLink="../../../billetterie/addons/creer">créez-en un !</a>
                </p>
              </ng-container>
            </ng-container>
            <!-- PRODUCTS -->
            <ng-container *ngIf="selectProduct">
              <ng-container *ngIf="products.length">
                <!-- <label for="rates">Addons</label> -->
                <div class="input_wrap select multiple">
                  <span class="select_multiple_toggle"
                    (click)="dropdownProducts = !dropdownProducts; $event.stopPropagation()">
                    {{getProductsName()}}
                  </span>
                  <svg role="img" title="chevron" class="icon-chevron">
                    <use href="assets/images/sprite.svg#icon-chevron"></use>
                  </svg>
                  <div class="dropdown multiple" *ngIf="dropdownProducts" (click)="$event.stopPropagation()">
                    <ul>
                      <li *ngFor="let product of products; let i = index">
                        <input id="product_{{i}}" type="checkbox"
                          [attr.checked]="elementChecked(product._id, 'checkedProducts')"
                          (change)="checkElement(product._id, $event.target.checked, 'checkedProducts')" />
                        <label for="product_{{i}}">
                          {{product.name}}
                          <svg role="img" title="check" class="check-icon">
                            <use href="assets/images/sprite.svg#icon-check"></use>
                          </svg>
                        </label>
                      </li>
                    </ul>
                    <div class="dropdown_action">
                      <app-button (clickEvent)="dropdownProducts = false" text="Sélectionner" [style]="'plain full'">
                      </app-button>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="!ticketTypes.length">
                <p class="form_item error">Il semble que vous n’ayez pas encore créé de produit,
                  <a routerLink="../../../billetterie/addons/creer">créez-en un !</a>
                </p>
              </ng-container>
            </ng-container>


          </div>

          <div class="form_item">
                <input #dateFilter id="date-type" type="checkbox" [attr.checked]="showDateFilter || null" class="toggle" (change)="checkFilterDate(dateFilter.checked)">
                <label for="date-type">
                    Filtrer par date d'achat
                    <i class="check">
                        <svg role="img" title="check" class="check-icon">
                            <use href="assets/images/sprite.svg#icon-check"></use>
                        </svg>
                    </i>
                </label>
            </div>

            <app-date-interval *ngIf="showDateFilter" [preData]="mainForm.value.rules" [allowBefore]="true" (newDates)="changeDates($event, 'rules')"
                startText="De" endText="à"></app-date-interval>

        </div>



        <div class="form_item">
          <label for="operators">Opérateurs</label>
          <div *ngIf="operators?.length" class="input_wrap select multiple">
            <span class="select_multiple_toggle"
              (click)="dropdownOperators = !dropdownOperators; $event.stopPropagation()">
              {{getOperatorsName()}}
            </span>
            <svg role="img" title="chevron" class="icon-chevron">
              <use href="assets/images/sprite.svg#icon-chevron"></use>
            </svg>
            <div class="dropdown multiple" *ngIf="dropdownOperators" (click)="$event.stopPropagation()">
              <ul>
                <li *ngFor="let operator of operators; let i = index">
                  <input id="operator_{{i}}" type="checkbox"
                    [attr.checked]="elementChecked(operator._id, 'checkedOperators')"
                    (change)="checkOperator(operator._id, $event.target.checked)" />
                  <label for="operator_{{i}}">
                    {{operator.name}}
                    <svg role="img" title="check" class="check-icon">
                      <use href="assets/images/sprite.svg#icon-check"></use>
                    </svg>
                  </label>
                </li>
              </ul>
              <div class="dropdown_action">
                <app-button (clickEvent)="dropdownOperators = false" text="Sélectionner" [style]="'plain full'">
                </app-button>
              </div>
            </div>
          </div>

          <p class="form_item description_medium">Si vous n’avez pas encore d’opérateurs, <a
              (click)="createOperator()">ajoutez-en</a> !</p>

        </div>
        <div class="form_actions right">
          <app-button [style]="'plain mw'" type="submit" text="Enregistrer" [disabled]="mainForm.invalid"
            (clickEvent)="submitForm()" [loadingOff]="loadingState$">
          </app-button>
        </div>
      </form>
      <!-- FIN FORMULAIRE EN DUR -->
    </div>
  </div>
</section>
import { Component, OnInit, Output, EventEmitter, Input, HostListener } from '@angular/core';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent {
  @Output() sendInput = new EventEmitter<string>();
  @Input() placeholder: string;
  @Input() preData?: string;
  private _typingTimer: NodeJS.Timeout;
  private _keyPressed: boolean;

  constructor() { }

  onKeyUp(input: HTMLInputElement) {
    this._keyPressed = false;
    clearTimeout(this._typingTimer);
    this._typingTimer = setTimeout(() => {
      if (!this._keyPressed) {
        this.sendInput.emit(input?.value);
      }
    }, 100);
  }

  onKeyPressed() {
    this._keyPressed = true;
  }

}

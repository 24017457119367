<nav *ngIf="totalPages > 1" id="pager_containter">
	<ul>
		<li *ngIf="page > 6">
    		<button (click)="chosenPage(1)" title="Aller à la première page" class="pager_first">
    			<svg role="img" title="double-arrow-full-left" class="icon-arrow-full-left">
	                <use href="assets/images/sprite.svg#icon-double-arrow-full-left"></use>
	            </svg>
    		</button>
    	</li>
    	<li>
    		<button (click)="chosenPage(page-1)" class="pager_previous">
    			<svg role="img" title="arrow-full-left" class="icon-arrow-full-left">
	                <use href="assets/images/sprite.svg#icon-arrow-full-left"></use>
	            </svg>
    		</button>
    	</li>
	    <li *ngFor="let currentPage of pageArray()">
	        <button (click)="chosenPage(currentPage)"
	            [ngClass]="{'current_page' : currentPage === page}"> {{currentPage}}</button>
	    </li>
	    <li>
		    <button (click)="chosenPage(page+1)" class="pager_next">
    			<svg role="img" title="arrow-full-left" class="icon-arrow-full-left">
	                <use href="assets/images/sprite.svg#icon-arrow-full-left"></use>
	            </svg>
		    </button>
		</li>
		<li *ngIf="page < totalPages && totalPages > 9">
		    <button (click)="chosenPage(totalPages)" title="Aller à la dernière page" class="pager_next">
    			<svg role="img" title="double-arrow-full-left" class="icon-arrow-full-left">
	                <use href="assets/images/sprite.svg#icon-double-arrow-full-left"></use>
	            </svg>
		    </button>
		</li>
	</ul>
</nav>
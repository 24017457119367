import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
// Animations
import { popupAnimation } from 'src/app/animations/animations';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  animations: [
    popupAnimation
  ]
})
export class PopupComponent implements OnInit {
  @Output() clickEvent = new EventEmitter<string | (string | number)[][]>();
  @Output() backEvent = new EventEmitter<void>()
  @Input() title: string;
  @Input() specialContent: string;
  @Input() input: string;
  @Input() formCat: string[];
  @Input() labels: string[][];
  @Input() inputs: string[][];
  @Input() selects: string[][];
  @Input() inputDisabled: boolean[][];
  @Input() specialContentClass = "";
  @Input() buttonText = "Retour"
  @Input() message: string;
  @Input() currentValues: (string | number)[][];
  @Input() suffixes: string[][];
  delete: boolean = false
  inputValue: string;
  inputValues: (string | number)[][];
  @Input() returnInitialValue: boolean = false;
  @Input() data: any;

  ngOnInit(): void {
    if (this.inputs) {
        this.inputValues = this.currentValues || new Array(this.inputs.length);
    }
  }

  onClick(): void {
    if (this.clickEvent.observers.length) {
      if (this.returnInitialValue) {
        this.clickEvent.emit(this.data);
      } else {
        this.clickEvent.emit(this.inputValues || this.inputValue);
      }
    }
    else {
      this.back()
    }
  }

  back(): void {
    this.backEvent.emit();
  }
}

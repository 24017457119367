<section id="form_page" class="container loading_container" [ngClass]="{ 'loaded' : mainForm }">
  <div class="loading_content">
    <div id="page_header">
      <h1>Sécurité</h1>
      <app-button (clickEvent)="goBack()" text="retour" [style]="'border blue'"></app-button>
    </div>
    <div id="form_wrapper">
      <h2>Informations de sécurité</h2>
      <form *ngIf="mainForm" [formGroup]="mainForm">

        <div class="form_item half">
          <label for="timeBeforeEvent">Temps de disponibilité du QR Code</label>
          <input id="timeBeforeEvent" type="number" formControlName="timeBeforeEvent">
        </div>

        <p class="description_medium"><strong>À quoi correspond ce paramétrage ? </strong><p>

        <p class="description_medium">Vous pouvez indiquer ici combien de temps avant le début de l'événement le QR Code sera disponible. <strong>La valeur que vous insérez ici est en heure.</strong></p>

        <p class="description_medium"><strong>Exemple : </strong> Votre événement démarre à 20h00. Vous indiquez "12", alors le QR Code de vos billets générés sera disponible 12h avant l'événement soit à partir de 8h00 le jour de l'événement.</p>

        <p class="description_medium"><strong>Note : </strong> Si vous laissez ce champ vide, le QR Code sera immédiatement disponible après l'achat du billet. Sachez que les QR Codes sont régénérés de base toutes les 5 minutes pour empêcher tout duplicata ou copie.</p>

        <div class="form_actions right">
          <app-button [loadingOff]="loadingState$" [style]="'plain'" type="submit"
              [disabled]="mainForm.invalid" (clickEvent)="updateSecurity()" text="Modifier les paramètres de sécurité">
          </app-button>
          <app-popup *ngIf="error != null" title="Une erreur est survenue" message="{{error}}"
              popupOpened="true" (backEvent)="error = null"></app-popup>
        </div>
        
      </form>
    </div>
  </div>
</section>
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { FormComponent } from 'src/app/elements/form-component';
import { CustomValidators } from 'src/app/etc/form-validators';
import { IFormField } from 'src/app/models/form.model';
import { GetParams } from 'src/app/models/type.definition';
import { FormFieldService } from 'src/app/providers';

type FieldType = {
  name: string;
  translateName: string;
  options?: boolean;
  date?: boolean;
  extension?: boolean;
  length?: boolean;
  nbLength?: boolean;
}



@Component({
  selector: 'app-event-form-fields-create',
  templateUrl: './event-form-fields-create.component.html',
  styleUrls: ['./event-form-fields-create.component.scss']
})


export class EventFormFieldsCreateComponent extends FormComponent<IFormField> implements OnInit {
  protected dataName = 'Champ';
  @Output() public addField = new EventEmitter<IFormField>();
  @Output() public addFields = new EventEmitter<IFormField[]>();
  @Input() public selectedDataToEdit: IFormField;
  public customField: boolean;
  public currentField: FieldType;
  public selectedExtensions: string[] = [];
  public extensions: string[] = ['.jpg', '.png'];
  public selectedPredefinedField: IFormField[] = [];
  public optionsToShow = {
    description: false,
    extensions: false,
    extensionDropDown: false,
    size: false,
    date: false,
    length: false,
    mandatory: false
  };
  public predefinedField: IFormField[] = [{
    label: "Civilité",
    type: "select",
    options: ["Madame", "Monsieur"],
    isRequired: true
  }, {
    label: "Date de naissance",
    type: "date",
    isRequired: true
  }, {
    label: "E-mail",
    type: "email",
    isRequired: true
  }, {
    label: "Code Postal",
    type: "textfield",
    isRequired: true
  }, {
    label: "Téléphone",
    type: "phone",
    isRequired: true
  }, {
    label: "Site internet",
    type: "textfield",
    isRequired: true
  }, {
    label: "Âge",
    type: "number",
    isRequired: true
  }, {
    label: "Adresse",
    type: "textfield",
    isRequired: true
  }, {
    label: "Ville",
    type: "textfield",
    isRequired: true
  }, {
    label: "Pays",
    type: "textfield",
    isRequired: true
  }];

  public fieldTypes: FieldType[] = [{
    name: 'textfield',
    translateName: 'Texte',
    length: true
  }, {
    name: 'textarea',
    translateName: 'Texte long',
    length: true
  }, {
    name: 'email',
    translateName: "E-mail"
  }, {
    name: 'phone',
    translateName: 'Téléphone'
  }, {
    name: 'date',
    translateName: 'Date',
    date: true
  }, {
    name: 'radio',
    translateName: 'Choix unique',
    options: true
  }, {
    name: 'checkbox',
    translateName: 'Choix multiple',
    options: true
  }, {
    name: 'select',
    translateName: 'Sélection',
    options: true
  }, {
    name: 'upload',
    translateName: 'Fichier',
    extension: true
  }, {
    name: 'time',
    translateName: 'Temps'
  }, {
    name: 'link',
    translateName: 'Lien'
  }, {
    name: 'number',
    translateName: 'Nombre',
    nbLength: true
  }, {
    name: 'score',
    translateName: 'Score',
  }, {
    name: 'address',
    translateName: 'Adresse',
    length: true
  }]

  constructor(protected formProvider: FormFieldService) {
    super();
  }

  protected async onInit(): Promise<void> {
    if (this.selectedDataToEdit) {
      if (this.selectedDataToEdit._id.includes('new_field')) {
        this.data = this.selectedDataToEdit;
      }
      else {
        await this.getDataToEditWithId(this.selectedDataToEdit._id, { template: 'full' });
        for (const key in this.selectedDataToEdit) {
          this.data[key] = this.selectedDataToEdit[key]
        }
      }
      this.customField = true;
      this.changeType(this.data.type);
      this.openUsedToggle();
    }
  }

  protected initForm(): void {
    this.mainForm = this.createFormGroup({
      type: new FormControl(this.data?.type, Validators.required),
      label: new FormControl(this.data?.label, Validators.required),
      description: this.data?.description,
      isRequired: this.data?.isRequired || false,
      options: new FormArray([]),
      constraints: {
        startDate: new FormControl(this.data?.constraints?.startDate, CustomValidators.dateNotAfterEnd),
        endDate: new FormControl(this.data?.constraints?.endDate, CustomValidators.dateNotBeforeStart),
        extension: this.data?.constraints?.extension,
        minLength: this.data?.constraints?.minLength,
        maxLength: this.data?.constraints?.maxLength,
        min: this.data?.constraints?.min,
        max: this.data?.constraints?.max
      }
    })
    this.subscribeTo(this.mainForm.controls['type'].valueChanges, this.changeType);
    if (this.data?.options?.length) {
      for (const option of this.data.options) {
        this.addAnswer(option);
      }
    }
  }

  public submitForm(): void {
    if (this.customField) {
      const form = this.setFinalForm(this.mainForm.value);
      if (this.data) {
        form._id = this.data._id;
      }
      this.addField.emit(form);
    }
    else {
      this.selectedPredefinedField = this.selectedPredefinedField.map(field => {
        field.typeTranslated = this.fieldTypes.find(_ => _.name == field.type).translateName;
        return field
      });
      this.addFields.emit(this.selectedPredefinedField);
    }
  }

  private setFinalForm(form: IFormField): IFormField {
    if (!this.currentField.date || !this.optionsToShow.date) {
      form.constraints.startDate = null;
      form.constraints.endDate = null;
    }
    if (this.currentField.extension) {
      form.constraints.extension = this.selectedExtensions;
    }
    else {
      this.currentField.extension = null;
    }
    if (!this.currentField.length || !this.optionsToShow.length) {
      form.constraints.minLength = null;
      form.constraints.maxLength = null;
    }
    if (!this.currentField.nbLength || !this.optionsToShow.size) {
      form.constraints.min = null;
      form.constraints.max = null;
    }
    if (!this.currentField.options) {
      form.options = [];
    }
    if (!this.optionsToShow.description) {
      form.description = '';
    }
    form.typeTranslated = this.fieldTypes.find(_ => _.name == form.type).translateName;
    return form
  }

  public changeType = (type: string): void => { this.currentField = this.fieldTypes.find(_ => _.name == type) };

  public getExtensionNames(): string {
    const names = this.extensions.filter(_ => this.selectedExtensions.includes(_));
    if (!names.length) {
      return 'Séléctionner des extensions';
    }
    else {
      const fullString = names.join(', ');
      return fullString.length < 50 ? fullString : `${fullString.substring(0, 47)} ...`
    }
  }

  public extensionChecked(name: string): boolean {
    return this.selectedExtensions.includes(name) || null;
  }


  public checkExtension(name: string, value: boolean): void {
    const inArray = this.selectedExtensions.includes(name);
    if (value && !inArray) {
      this.selectedExtensions.push(name);
    }
    else if (!value && inArray) {
      this.selectedExtensions = this.selectedExtensions.filter(_ => _ !== name);
    }
  }

  @HostListener('document:click') clickDoc(): void {
    this.optionsToShow.extensionDropDown = false;
  }

  private openUsedToggle(): void {
    if (this.currentField.extension) {
      if (this.data.constraints?.extension?.length) {
        this.optionsToShow.extensions = true;
        this.selectedExtensions = this.data.constraints.extension;
      }
    }
    if (this.currentField.length) {
      if (![undefined, null].includes(this.data.constraints?.minLength) || [undefined, null].includes(this.data.constraints?.maxLength)) {
        this.optionsToShow.length = true;
      }
    }
    if (this.currentField.nbLength) {
      if (![undefined, null].includes(this.data.constraints?.min) || [undefined, null].includes(this.data.constraints?.max)) {
        this.optionsToShow.size = true;
      }
    }
    if (this.currentField.date) {
      if (this.data.constraints?.startDate || this.data.constraints?.startDate) {
        this.optionsToShow.date = true;
      }
    }
    if (this.data.description) {
      this.optionsToShow.description = true;
    }
    if (this.data.isRequired) {
      this.optionsToShow.mandatory = true
    }
  }

  public addAnswer(value = ''): void {
    const array = this.mainForm.controls['options'] as FormArray;
    array.push(new FormControl(value));
  }

  public deleteAnswer(i: number): void {
    const array = this.mainForm.controls['options'] as FormArray;
    array.removeAt(i);
  }

  public selectPredefinedField(field: IFormField, value: boolean) {
    if (!value) {
      const index = this.selectedPredefinedField.findIndex(_ => _._id == field._id);
      if (index > -1) {
        this.selectedPredefinedField.splice(index, 1);
      }
    }
    else {
      this.selectedPredefinedField.push(field);
    }
  }

}

<div id="page_header" class="container">
  <div id="page_header_left">
    <h1>Questions du formulaire</h1>
  </div>
</div>

<section id="form_field_section">
  <section id="form_field_list_block" class="form_wrapper" *ngIf="data">
    <h2>Ajoutez des champs au formulaire</h2>
    <div class="orange_subtitle">
      <h4 *ngIf="fieldsToCreate.length || fieldsToDelete.length || fieldsToUpdate.length">
        Enregistrez le formulaire pour valider les modifications apportées aux champs
      </h4>
    </div>

    <app-main-table [ref]="this" idTable="table_form_field" idSection="form_field_content" tableType="formField"
      [columns]="tableOptions" [datum]="data" [noHeader]="true"></app-main-table>
    <div class="add_field_button_container">
      <app-button [style]="'border blue'" [loadingFunction]="false" (clickEvent)="createField()"
        text="Ajouter un champ">
      </app-button>
    </div>
  </section>
  <section *ngIf="createFieldSelected || !!selectedDataToEdit" id="form_field_form_block">
    <app-event-form-fields-create [selectedDataToEdit]="selectedDataToEdit" (addFields)="addFields($event)"
      (addField)="addField($event)"></app-event-form-fields-create>
  </section>
</section>
import { Observable, of } from 'rxjs';
import { APICreateResponse, APIListResponse, APIResponse, GetParams } from 'src/app/models/type.definition';
import { Invitations } from '../../models/invitation.model';
import { Provider } from '../provider';

export class InvitationService extends Provider<Invitations> {
    protected url = "events/tickets/generate-order";

    public generate(body: Invitations, eventId: string): Observable<any> {
        return this.httpClient.post<any>(this.getUrl(eventId), body)
    }

    /**
     * @Forbidden - Will throw an error if called
     */
    public getList(body: GetParams<Invitations> = null): Observable<APIListResponse<Invitations>> {
        throw Error("Impossible to call this method")
    }

    /**
     * @Forbidden - Will throw an error if called
     */
    public getById(id: string, body: GetParams<Invitations> = null): Observable<Invitations> {
        throw Error("Impossible to call this method")
    }

    /**
     * @Forbidden - Will throw an error if called
     */
    public create(body: Invitations | FormData): Observable<APICreateResponse> {
        throw Error("Impossible to call this method")
    }

    /**
     * @Forbidden - Will throw an error if called
     */
    public update(id: string, body: Invitations | FormData): Observable<APIResponse> {

        throw Error("Impossible to call this method")
    }

    /**
     * @Forbidden - Will throw an error if called
     */
    public delete(id: string): Observable<APIResponse> {
        throw Error("Impossible to call this method")
    }
}
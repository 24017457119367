import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ListComponent } from 'src/app/elements/list-component';
import { IOrganisation } from 'src/app/models/organisation.model';
import { TableOptions } from 'src/app/models/type.definition';
import { AuthService, OrganisationService } from 'src/app/providers';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-adminOrganizations',
  templateUrl: './adminOrganizations.component.html',
  styleUrls: ['./adminOrganizations.component.scss']
})
export class AdminOrganizationsComponent extends ListComponent<IOrganisation> {
  dataName = "Organisation";
  tableOptions: TableOptions[] = [
    {
      title: "Nom",
      class: "name",
      sort: true,
      key: 'name',
      sortMethod: order => this.sort("name", order),
    },
    {
      title: "Type",
      class: "type",
      sort: true,
      key: 'type',
      sortMethod: order => this.sort("type", order),
    },
    {
      title: "Date de création",
      class: "date",
      sort: true,
      key: 'stringCreatedAt',
      sortMethod: order => this.sort("createdAt", order)
    },
    {
      class: "more",
      toggle: [
        {
          title: 'Voir l\'organisation',
          method: this.selectOrganization
        }
      ]
    }
  ];

  constructor(
    protected provider: OrganisationService,
    private authService: AuthService,
    private storage: StorageService,
  ) {
    super();
  }

  private async selectOrganization({ _id }: IOrganisation, ref = this): Promise<void> {
    const organisationToken = await ref.authService.authOrganisation(_id).toPromise();
    this.provider.adminConnected$.next(true);
    this.storage.setItem('adminInOrganization', 'true');
    this.provider.goToOrganisation(_id, organisationToken);
  }

  public async ngOnInit(): Promise<void> {
    this.filtersForm = this.formBuilder.group({ search: '' });
    super.ngOnInit();
  }

  protected formatElement(el: IOrganisation): IOrganisation {
    el.stringCreatedAt = formatDate(new Date(el.createdAt), 'dd/MM/yyyy', 'fr');
    return el;
  }

  protected setGetParams(): void {
    this.getParams.select = ['name', 'createdAt', 'type'];
    super.setGetParams();
  }

}

import { Component, HostListener } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormFromEventComponent } from 'src/app/elements/form-component';
import { IAddon } from 'src/app/models/addon.model';
import { IControlList } from 'src/app/models/control-list';
import { IEvent } from 'src/app/models/event.model';
import { ISession } from 'src/app/models/session.model';
import { ITicketType } from 'src/app/models/ticket.model';
import { GetParams } from 'src/app/models/type.definition';
import {
  AddonService,
  ControlListService,
  EventSessionService,
  MemberService,
  TicketTypeService,
} from 'src/app/providers';
import { DateService } from 'src/app/services/date.service';
import { GetEvent } from '../../../getEvent';
import User from 'src/app/models/user.model';

@Component({
  selector: 'app-event-access-control-create',
  templateUrl: './event-access-control-create.component.html',
  styleUrls: ['./event-access-control-create.component.scss'],
  providers: [GetEvent],
})
export class EventAccessControlCreateComponent extends FormFromEventComponent<IControlList> {
  public dataName = 'Liste de contrôle';
  public event: IEvent;
  public selectAddon = false;
  public selectProduct = false;
  public showDateFilter = false;
  public sessions: ISession[] = [];
  public operators: User[] = [];
  public ticketTypes: ITicketType[] = [];
  public addons: IAddon[] = [];
  public products: ITicketType[] = [];
  public checkedSessions: string[] = [];
  public checkedOperators: string[] = [];
  public checkedTicketTypes: string[] = [];
  public checkedProducts: string[] = [];
  public checkedAddons: string[] = [];
  public dropdownSessions: boolean = false;
  public dropdownOperators: boolean = false;
  public dropdownTickets: boolean = false;
  public dropdownAddons: boolean = false;
  public dropdownProducts: boolean = false;
  public unlimited: boolean = true;
  public showLimit = true;
  public restrictionSelectPosition: 'ticket' | 'addon';
  protected getEventParams: GetParams<IEvent> = {
    select: ['organisationId', 'dates'],
  };

  constructor(
    protected formProvider: ControlListService,
    protected memberService: MemberService,
    protected sessionService: EventSessionService,
    protected dateService: DateService,
    protected ticketTypeService: TicketTypeService,
    protected addonService: AddonService,
    protected getEvent: GetEvent
  ) {
    super();
  }

  async onInit(): Promise<void> {
    await this.getDataToEdit('listId', { template: 'full' });
    const members = await this.getListOf(this.memberService, 'data', {
      populate: ['userId'],
      perPage: 500
    });
    this.operators = members
      // .filter(operator => operator.roles.includes('operator'))
      .map((operator) => operator.userId)
      .map((user) => {
        user.name = `${user.firstName} ${user.lastName}`;
        return user;
      });
    this.ticketTypes = await this.getListFromEventOf(this.ticketTypeService, {
      select: ['name', 'ticketTypeCategoryId'],
      populate: ['ticketTypeCategoryId'],
      perPage: 500
    });
    if (this.event.dates.type == 'sessions') {
      this.sessions = (
        await this.getListFromEventOf(this.sessionService, { perPage: 500 })
      ).map((session) => {
        session.name = this.dateService.formatDateAndTime(session.date, '-');
        return session;
      });
    }
    this.addons = await this.getListFromEventOf(this.addonService, { perPage: 500 });
    this.products = await this.getListFromEventOf(this.ticketTypeService, {
      perPage: 500,
      filter: { isProduct: true },
    });
    if (this.data) {
      this.checkedTicketTypes = this.data.ticketTypeIds.map((_) => _._id)
      this.checkedAddons = this.data.addonIds.map((_) => _._id)
      this.selectAddon = !!this.checkedAddons?.length;
      this.checkedSessions = this.data.eventSessionId.map((_) => _._id);
      this.checkedOperators = this.data.operatorIds.map((_) => _._id);
      this.unlimited = this.data.nbAccess == null;

      if (this.data.filterType) {
        this.restrictionSelectPosition = this.data.filterType;
      } else
        this.restrictionSelectPosition = this.data.ticketTypeFiltered
          ? this.selectAddon
            ? 'addon'
            : 'ticket'
          : undefined;
      this.selectAddon = this.restrictionSelectPosition == 'addon';
      this.showLimit = !this.selectProduct;
      this.showDateFilter = !!this.data.purchaseDateMin || !!this.data.purchaseDateMax;
    }
  }

  protected initForm(): void {
    this.mainForm = this.formBuilder.group({
      name: new FormControl(this.data?.name, Validators.required),
      nbAccess: new FormControl(this.data?.nbAccess),
      ticketTypeFiltered: new FormControl(
        this.data?.ticketTypeFiltered || false
      ),
      eventSessionId: new FormControl(
        this.data?.eventSessionId || []
      ),
      ticketTypeIds: new FormControl(this.data?.ticketTypeIds || []),
      addonIds: new FormControl(this.data?.addonIds || []),
      filterType: new FormControl(this.data?.filterType || null),
      operatorIds: new FormControl(
        this.data?.operatorIds || [],
        Validators.required
      ),
      rules: new FormGroup({
        startDate: new FormControl(this.data?.purchaseDateMin || null),
        endDate: new FormControl(this.data?.purchaseDateMax || null),
      })
    });
    this.subscribeTo(this.mainForm.get('nbAccess').valueChanges, (res) => {
      if (res || res == 0) {
        this.unlimited = false;
      }
    });
  }

  checkFilterDate(value: boolean): void {
    this.showDateFilter = value;
    if (!value) {
      this.mainForm.get('rules').patchValue({ startDate: null , endDate: null });
    }
  }

  public createRules() {

    let basedData = {
      ticketTypeFiltered: this.mainForm.value.ticketTypeFiltered,
      eventSessionId: this.checkedSessions,
      ticketTypeIds: this.selectProduct
        ? this.checkedProducts
        : this.checkedTicketTypes,
      addonIds: this.checkedAddons,
      purchaseDateMin: this.mainForm.value.rules.startDate,
      purchaseDateMax: this.mainForm.value.rules.endDate,
      filterType: this.mainForm.value.filterType
    };

    return basedData


  }

  public submitForm(): void {
    const form = {
      ...this.mainForm.value,
      ...this.createRules(),
      operatorIds: this.checkedOperators,
    };

    if (this.selectAddon) {
      form.ticketTypeIds = [];
    } else {
      form.addonIds = [];
    }

    this.createOrUpdate(form);
  }

  public createOperator(): void {
    this.router.navigate([`equipe/ajouter-un-membre`]);
  }

  toggleOperators(): void {
    this.dropdownOperators = !this.dropdownOperators;
  }

  changeUnlimited(value: boolean): void {
    this.unlimited = !this.unlimited;
    if (value) {
      this.mainForm.patchValue({ nbAccess: null });
    }
  }

  @HostListener('document:click') clickDoc(): void {
    this.dropdownSessions = false;
    this.dropdownOperators = false;
    this.dropdownTickets = false;
  }

  checkOperator(id: string, value: boolean) {
    this.checkElement(id, value, 'checkedOperators');
    this.mainForm.patchValue({ operatorIds: this.checkedOperators });
  }

  public checkElement(
    id: string,
    value: boolean,
    arrayKey:
      | 'checkedTicketTypes'
      | 'checkedOperators'
      | 'checkedSessions'
      | 'checkedAddons'
  ): void {
    const inArray = this[arrayKey].includes(id);

    if (value && !inArray) {
      this[arrayKey].push(id);
    } else if (!value && inArray) {
      this[arrayKey] = this[arrayKey].filter((_) => _ !== id);
    }
  }

  public elementChecked(
    id: string,
    arrayKey:
      | 'checkedTicketTypes'
      | 'checkedOperators'
      | 'checkedSessions'
      | 'checkedAddons'
  ): boolean {
    return this[arrayKey].includes(id) || null;
  }

  public ticketRestricted(): boolean {
    return this.mainForm.value.ticketTypeFiltered;
  }

  public getTicketsName(): string {
    return this.getElementName(
      this.ticketTypes.filter((_) => this.checkedTicketTypes.includes(_._id)),
      'tickets'
    );
  }

  public getOperatorsName(): string {
    return this.getElementName(
      this.operators.filter((_) => this.checkedOperators.includes(_._id)),
      'operateurs'
    );
  }

  public getSessionsName(): string {
    return this.getElementName(
      this.sessions.filter((_) => this.checkedSessions.includes(_._id)),
      'sessions'
    );
  }

  public getAddonsName(): string {
    return this.getElementName(
      this.addons.filter((_) => this.checkedAddons.includes(_._id)),
      'addons'
    );
  }

  public getProductsName(): string {
    return this.getElementName(
      this.products.filter((_) => this.checkedProducts.includes(_._id)),
      'produits'
    );
  }

  public getElementName(
    names: { name?: string }[],
    type: 'tickets' | 'sessions' | 'operateurs' | 'addons' | 'produits'
  ): string {
    if (!names.length) {
      return 'Séléctionner des ' + type;
    } else {
      const fullString = names.map((_) => _.name).join(', ');
      return fullString.length < 80
        ? fullString
        : `${fullString.substring(0, 77)} ...`;
    }
  }

  public changeRestriction(_value: string | null): void {
    const value = (_value === "null") ? null : _value;
    if (!value) {
      this.checkedTicketTypes = [];
      this.checkedAddons = [];
    }
    this.mainForm
      .patchValue({ ticketTypeFiltered: value !== null });
    this.selectAddon = value == 'addon';
    this.selectProduct = value == 'product';
    this.mainForm.patchValue({ filterType: value });
    this.showLimit = !this.selectProduct;
    if (this.selectProduct) {
      this.unlimited = false;
      this.mainForm.patchValue({ nbAccess: 1 });
    }
  }
}

<div>
  <div id="page_header" class="container">
    <div id="page_header_left">
      <h1>Toutes les commandes</h1>
    </div>
  </div>
  <ng-container *ngIf="data">
    <section id="block_first" class="container" *ngIf="!totalData">
      <p class="description">
        Aucune commande créée pour le moment
      </p>
      <figure>
        <img src="assets/images/visuel_evenement.svg" />
      </figure>
    </section>
    <section *ngIf="totalData">
      <div *ngIf="filtersForm && totalData" id="filters_wrap" class="container"
          [ngClass]="{ 'more_filters': moreFilters }">
          <form id="filters_form" [formGroup]="filtersForm">
              <app-search-input placeholder="Rechercher (Commande, acheteur, n°...)" (sendInput)="setSearch($event)"></app-search-input>
              <div class="fields">

                  <div class="input_wrap select">
                      <select id="filter_origin" (change)="selectedOrigin = $event.target.value">
                          <option [attr.selected]="selectedOrigin == null ? true : null" value="">
                              Toutes origines
                          </option>
                          <option [attr.selected]="selectedOrigin == 'web' ? true : null" value="web">
                              Web
                          </option>
                          <option [attr.selected]="selectedOrigin == 'ticketOffice' ? true : null" value="ticketOffice">
                              Guichet
                          </option>
                          <option [attr.selected]="selectedOrigin == 'invitation' ? true : null" value="invitation">
                              Invitation
                          </option>
                          <option [attr.selected]="selectedOrigin == 'stockImpression' ? true : null" value="stockImpression">
                              Impression de stock
                          </option>
                      </select>
                      <svg role="img" title="chevron" class="icon-chevron">
                          <use href="assets/images/sprite.svg#icon-chevron"></use>
                      </svg>
                  </div>
                  <div class="input_wrap select">
                      <select id="filter_status" (change)="selectedStatus = $event.target.value">
                          <option [attr.selected]="selectedStatus == null ? true : null" value="">
                              Tous les statuts
                          </option>
                          <option [attr.selected]="selectedStatus == 'completed' ? true : null" value="completed">
                              Complétée
                          </option>
                          <option [attr.selected]="selectedStatus == 'pending' ? true : null" value="pending">
                              En attente
                          </option>
                          <option [attr.selected]="selectedStatus == 'canceled' ? true : null" value="canceled">
                              Annulée
                          </option>
                          <option [attr.selected]="selectedStatus == 'draft' ? true : null" value="draft">
                              Brouillon
                          </option>
                      </select>
                      <svg role="img" title="chevron" class="icon-chevron">
                          <use href="assets/images/sprite.svg#icon-chevron"></use>
                      </svg>
                  </div>
                  <span id="more_filters" (click)="moreFilters = !moreFilters">
                      {{moreFilters ? "Moins" : "Plus"}} de filtres
                  </span>
              </div>
              <div id="filters_more" *ngIf="moreFilters">
                  <div class="input_wrap date small">
                      <div class="input_date">
                          <input id="filter_date" type="date" formControlName="createdAt__$gte">
                          <svg role="img" title="calendar" class="calendar-icon">
                              <use href="assets/images/sprite.svg#icon-calendar"></use>
                          </svg>
                      </div>
                  </div>
                  <div class="input_wrap date small">
                      <div class="input_date">
                          <input id="filter_end_date" type="date" formControlName="createdAt__$lte">
                          <svg role="img" title="calendar" class="calendar-icon">
                              <use href="assets/images/sprite.svg#icon-calendar"></use>
                          </svg>
                      </div>
                  </div>
              </div>
              <div class="form_actions">
                  <app-button [loadingOff]="loadingState$" [style]="'plain'" type="submit" text="Filtrer"
                      (clickEvent)="filter()"></app-button>
              </div>
          </form>
      </div>
      <div *ngIf="totalData" class="between_filter_and_list container">
          <h2>{{currentTotalData}} commande{{currentTotalData > 1 ? 's' : ''}} trouvée{{currentTotalData > 1 ? 's' : ''}}</h2>
      </div>

      <app-main-table idSection="orders_content" idTable="table_orders" tableType="orders" [loading]="loading"
                    [columns]="tableOptions" [datum]="data" [ref]="this" (selectRow)="selectedOrderIds = $event">
      </app-main-table>

      <ng-template #noData>
        <section id="block_first" class="container">
          <h2>Aucun événement ne correspond à votre recherche !</h2>
          <figure>
            <img src="assets/images/visuel_evenement.svg" />
          </figure>
        </section>
      </ng-template>
      <app-pager *ngIf="totalPages > 1" [totalPages]="totalPages" [page]="page" (changePage)="changePage($event)">
      </app-pager>
    </section>
  </ng-container>
</div>

<div class="full_loader" *ngIf="fullLoader">
  <div></div>
</div>

  <app-popup
      *ngIf="resendConfirmOfOrder"
      (backEvent)="resendConfirmOfOrder = null"
      [title]="'Renvoyer la confirmation de la commande N°' + resendConfirmOfOrder.orderNumber"
      message="L'e-mail de confirmation ainsi que les tickets concernées seront envoyés à l'adresse e-mail ayant été utilisée pour cette commande"
      buttonText="Renvoyer"
      (clickEvent)="resendConfirmOk()"
  ></app-popup>

  <app-popup
      *ngIf="orderToPay"
      (backEvent)="orderToPay = null"
      [title]="'Souhaitez-vous marquer la commande N°' + orderToPay.orderNumber + ' comme Payée'"
      message="Attention : après validation il ne sera plus possible de modifier le règlement de cette commande."
      buttonText="Marquer comme payée"
      (clickEvent)="payOrderOk()"
  ></app-popup>

  <app-popup
      *ngIf="orderToRefund"
      (backEvent)="orderToRefund = null"
      [title]="'Souhaitez-vous rembourser la commande N°' + orderToRefund.orderNumber"
      message="Le virement de remboursement sera envoyé au participant sur le compte en banque qu'il a utilisé pour effectuer la commande"
      [buttonText]="'Rembourser '+ ( orderToRefund.totalPrice.sellingPrice | currency: 'EUR' ) "
      (clickEvent)="askRefundOk()"
  ></app-popup>

  <app-delete-popup
      *ngIf="orderToCancel"
      [name]="orderToCancel.orderNumber"
      deleteWord="annuler la commande"
      [isFemale]="true"
      buttonText = "Confirmer l'annulation"
      type="commande"
      (clickEvent)="cancelOrderOk()"
      (close)="orderToCancel = null"
  ></app-delete-popup>

import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { moreAnimation } from 'src/app/animations/animations';
import { IEvent } from 'src/app/models/event.model';
import { ITicketType, ITicketTypeCategory } from 'src/app/models/ticket.model';
import { GetParams, TableOptions } from 'src/app/models/type.definition';
import { EventService, TicketService, TicketTypeCategoryService, TicketTypeService } from 'src/app/providers';
import { DateService } from 'src/app/services/date.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { NotificationService } from 'src/app/services/notification.service';
import { GetEvent } from '../../getEvent';

@Component({
  selector: 'app-event-ticketing-product',
  templateUrl: './event-ticketing-product.component.html',
  styleUrls: ['./event-ticketing-product.component.scss'],
  providers: [GetEvent],
  animations: [
    moreAnimation
  ]
})
export class EventTicketingProductComponent implements OnInit {
  @HostListener('document:click')
  documentClick() {
    this.createToggleOpen && (this.createToggleOpen = false);
  }
  private _subscription = new Subscription();
  public event: IEvent;
  public loaded: boolean;
  public createToggleOpen: boolean;
  public createToggle: { ref, links: { title: string, method: Function, class?: string }[] } = {
    ref: this,
    links: [
      {
        title: 'Nouveau produit',
        method: this.createRate
      },
      {
        title: 'Nouvelle catégorie',
        method: this.createCategory
      }
    ]
  }

  public error: string;
  public loadingState$: Subject<void> = new Subject<void>();
  public currentUrl: string;
  public tableOptions: TableOptions[] = [
    {
      title: "Nom",
      class: "name",
      sort: true,
      key: 'name',
      //sortMethod: (order) => this.sort("name", order),
    },
    {
      title: "Prix TTC",
      class: "price",
      sort: false,
      key: 'stringPrice',

      //sortMethod: (order) => this.sort("dates.startDate", order)
    },
    {
      title: "Quota",
      class: "quota",
      sort: false,
      key: 'totalQuota',
      //sortMethod: (order) => this.sort("ticketing.quota", order)
    },
    {
      title: "Disponibilité",
      class: "availability",
      sort: false,
      key: 'available',
      //sortMethod: (order) => this.sort("ticketing.totalPrice.sellingPrice", order),
    },
    {
      title: "En ligne",
      class: "active",
      sort: false,
      key: 'isVisible',
      otherKey: 'isOpened',
      toggleBox: true,
      callback: this.changeVisibility
      //sortMethod: (order) => this.sort("status", order)
    },
    {
      class: "more",
      toggle: [
        {
          title: 'Modifier',
          method: this.edit
        },
        {
          title: 'Supprimer',
          class: "red",
          method: this.delete
        }
      ]
    }
  ]

  constructor(
    private getEvent: GetEvent,
    private eventService: EventService,
    private router: Router,
    private errorHandler: ErrorHandlerService,
    private typeService: TicketTypeService,
    private ticketService: TicketService,
    private categoryService: TicketTypeCategoryService,
    private notification: NotificationService,
    private dateService: DateService
  ) { }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  async ngOnInit() {
    const options: GetParams<IEvent> = {
      select: ['productTypeCategories', 'ticketing']
    }
    this.currentUrl = this.router.url;
    this.event = await this.getEvent.get(options);
    this.event.productTypeCategories?.forEach(category => category.ticketTypes?.forEach(ticket => {
      ticket.price = ticket.price < 0 ? 0 : ticket.price;
      ticket.stringPrice = ticket.price ? (ticket.price / 100) + '€' : 'Gratuit';
      const startDate = ticket.scheduled?.startDate;
      const endDate = ticket.scheduled?.endDate;
      if (startDate && endDate) {
        ticket.available = `du ${this.dateService.formatDateAndTime(startDate)} au ${this.dateService.formatDateAndTime(endDate)}`;
      }
      else if (startDate) {
        ticket.available = `à partir du ${this.dateService.formatDateAndTime(startDate)}`;
      }
      else if (endDate) {
        ticket.available = `jusqu'au ${this.dateService.formatDateAndTime(endDate)}`;
      }
      else {
        ticket.available = 'toujours';
      }
      // await resetOrders(this.event, this.categoryService, this.typeService);
    }));
    await this.getTicketSells();
    this.event.productTypeCategories.sort((a, b) => a.order < b.order ? -1 : 1)
    this.loaded = true;
  }

  async getTicketSells(): Promise<void> {
    const soldCounts = await this.ticketService.getSoldCounts(this.event._id).toPromise();
    const soldTicketIds = soldCounts.map(_ => _.id);
    soldCounts.forEach(soldTicket => {
      const ticket = this.getTicket(soldTicket.id);
      if (ticket) {
        ticket.sold = soldTicket.sold;
        ticket.totalQuota = `${soldTicket.sold}/${ticket.quota || 'ꝏ'}`;
      }
    });
    this.event.productTypeCategories.forEach(cat => {
      cat.ticketTypes.forEach(ticket => {
        if (!soldTicketIds.includes(ticket._id)) {
          ticket.totalQuota = `0/${ticket.quota || 'ꝏ'}`
        }
      })
    })
    this.calcSoldByCategory()
  }

  getTicket(id: string): ITicketType {
    for (const category of this.event.productTypeCategories) {
      for (const ticketType of category.ticketTypes) {
        if (id == ticketType._id) {
          return ticketType;
        }
      }
    }
  }

  calcSoldByCategory(): void {
    this.event.productTypeCategories.forEach(category => {
        category.sold = 0;
        category.ticketTypes.forEach(ticket => {
          category.sold += ticket.sold || 0;
        });
        category.totalQuota = `${category.sold}/${category.quota || 'ꝏ'}`;
    })
  }

  displayCreateToggle() {
    setTimeout(() => this.createToggleOpen = !this.createToggleOpen);
  }

  createRate(ref = this) {
    ref.router.navigate([`${ref.currentUrl}/creer`])
  }

  createCategory(ref = this) {
    ref.router.navigate([`${ref.currentUrl}/creer-categorie`])
  }

  edit(element: ITicketTypeCategory | ITicketType, ref = this): void {
    const keyword = (element as ITicketType).stringPrice ? 'produit' : 'categorie';
    ref.router.navigateByUrl(`${ref.currentUrl}/editer-${keyword}/${element._id}`);
  }

  delete(element: ITicketTypeCategory | ITicketType, ref = this): void {
    const type = (element as ITicketType).stringPrice ? 'Tarif' : 'Catégorie';
    const service = type == 'Tarif' ? ref.typeService : ref.categoryService;
    ref._subscription.add(service.delete(element._id).subscribe(() => {
      ref.notification.newNotification({ message: `${type} supprimé avec succés`, state: 'success' });
      if (type == 'Catégorie') {
        const catIndex = ref.event.productTypeCategories.findIndex(_ => _._id == element._id);
        ref.event.productTypeCategories.splice(catIndex, 1)[0];
      }
      else {
        const catIndex = ref.event.productTypeCategories.findIndex(_ => _._id == (element as ITicketType).ticketTypeCategoryId);
        const ticketIndex = ref.event.productTypeCategories[catIndex].ticketTypes.findIndex(_ => _._id == element._id);
        ref.event.productTypeCategories[catIndex].ticketTypes.splice(ticketIndex, 1);
        if (!ref.event.productTypeCategories[catIndex]._id && !ref.event.productTypeCategories[catIndex].ticketTypes.length) {
          ref.event.productTypeCategories.pop();
        }
      }
    }, err => {
      ref.notification.newNotification({ message: ref.errorHandler.getError(err), state: 'error' });
    })
    );
  }

  ticketDragged(event: { id: string, order: number, parentId: string }) {
    const updateObject = {
      order: event.order,
      eventId: { _id: this.event._id },
      ticketTypeCategoryId: { _id: event.parentId }
    }
    if (!event.id) {
      delete updateObject.ticketTypeCategoryId;
    }
    const observable = event.id ? this.typeService.update(event.id, updateObject) : this.categoryService.update(event.parentId, updateObject)
    observable.subscribe(res => { this.calcSoldByCategory() }, err => {
      this.notification.newNotification({ message: "Une erreur est survenue", state: "error" });
    })
  }

  public async changeVisibility(data: ITicketType | ITicketTypeCategory, ref = this): Promise<void> {
    const key = 'stock' in (data as ITicketType) ? 'isVisible' : 'isOpened';
    const provider = 'stock' in (data as ITicketType) ? ref.typeService : ref.categoryService
    try {
      data[key] = !data[key];
      await provider.update(data._id, data).toPromise()
    }
    catch (err) {
      console.error(err);
    }
  }

  public async close(ref = this): Promise<void> {
    const date = new Date;
    date.setMinutes(date.getMinutes() - 1);
    ref.event.ticketing.date.endDate = date;
    ref.createToggleOpen = false;
    await ref.eventService.update(ref.event._id, ref.event).toPromise();
    ref.notification.newNotification({ message: "Billetterie clôturer avec succès", state: "success" });
    ref.createToggle.links.splice(2, 1);
  }


}

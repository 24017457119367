<div class="loading_container" [ngClass]="{ 'loaded' : data }">
  <span class="loader_global"></span>
  <div class="loading_content">
    <ng-container *ngIf="data">
      <div id="page_header" class="container">
        <h1>Liste d'attente <span>({{totalData}} personne{{totalData > 1 ? 's' : ''}})</span></h1>
      </div>

      <div id="filters_wrap" class="container" *ngIf='totalData && filtersForm'>
        <form id="filters_form" [formGroup]="filtersForm">
          <app-search-input placeholder="Rechercher un participant" (sendInput)="searchByKeyword($event)">
          </app-search-input>
        </form>
      </div>

      <app-main-table *ngIf="data?.length; else noData" idSection="waiting_list_content" idTable="table_waiting_list"
        tableType="waiting_list" [columns]="tableOptions" [datum]="data" [ref]="this"></app-main-table>
      <ng-template #noData>
        <section id="block_first" class="container">
          <h2 *ngIf="totalData">Aucun participant ne correspond à votre recherche !</h2>
          <h2 *ngIf="!totalData">Aucun participant inscrit sur liste d'attente pour le moment !</h2>
          <figure>
            <img src="assets/images/visuel_evenement.svg" />
          </figure>
        </section>
      </ng-template>
      <app-pager *ngIf="totalPages" [totalPages]="totalPages" [page]="page" (changePage)="changePage($event)">
      </app-pager>
    </ng-container>
  </div>
</div>
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  formatDateAndTime(date: string | Date, separator = ''): string {
    const newDate = new Date(date);
    const dateString = this.getDateString(newDate);
    const timeString = this.getTimeString(newDate);
    return `${dateString} ${separator} ${timeString}`
  }

  formatDate(date: string | Date): string {
    const newDate = new Date(date);
    return this.getDateString(newDate);
  }

  getDateString(date: Date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${(day < 10 ? '0' : '') + day}/${(month < 10 ? '0' : '') + month}/${year}`;
  }

  getTimeString(date: Date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${hours < 10 ? '0' + hours : '' + hours}:${minutes < 10 ? '0' + minutes : '' + minutes}`;
  }

  getInternationalString(date: Date, separator = '-') {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}${separator}${(month < 10 ? '0' : '') + month}${separator}${(day < 10 ? '0' : '') + day}`;
  }

  getDateFromEuropeanString(date: string): Date {
    const dates = date.split('/').map(_ => parseInt(_));
    return new Date(dates[2], dates[1] - 1, dates[0]);
  }

  setToBeginningOfDay(date: Date): void {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
  }
}

<div class="loading_container container" [ngClass]="{ 'loaded' : data }">
  <div class="loading_content" *ngIf="data">
    <div id="page_header" class="container">
      <div id="page_header_left">
        <h1>Contenus exclusifs</h1>
      </div>
      <div id="page_header_right">
        <app-button (clickEvent)="createData()" [loadingFunction]="false" class="button" text="Ajouter un média"
          [style]="'plain'"></app-button>
      </div>
    </div>

    <section *ngIf="!data.length" id="block_first" class="container">
        
        <p class="description grey">Vous n’avez pas encore ajouté de contenus exclusifs</p>
        <figure>
            <img src="assets/images/visuel_promo.svg" />
        </figure>
        <app-button (click)="createData()" class="button_create no_margin" text="Ajouter un média"
            [style]="'plain full'"></app-button>
    </section>

    <div id="event_customize" *ngIf="data.length">
      <ul>
        <li *ngFor="let media of data">
          <img *ngIf="media.mimetype !== 'video/mp4'" [src]="media.url" style="max-width: 484px;"/>
          <video 
              *ngIf="media.mimetype === 'video/mp4'"
              controls="true"
              style="max-width: 484px;"
          >
              <source [src]="media.url"
                      type="video/mp4">
          </video>
          <span class="title">{{ media.name }}</span>
          <p><span *ngFor="let ticketType of media.ticketType">{{ ticketType.name }}<br /></span></p>
          <app-button class="button" text="Supprimer" (clickEvent)="deleteData(media)"
            [style]="'border red'"></app-button>
        </li>
      </ul>
    </div>

    <app-delete-popup *ngIf="mediaToDelete" [name]="mediaToDelete.name" type='contenu exclusif' 
      (clickEvent)="validateDeletion()" (close)="mediaToDelete = null"></app-delete-popup>

  </div>
</div>
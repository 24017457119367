import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DateInterval } from 'src/app/models/type.definition';
import { TimeZones } from './time-zone';

@Component({
  selector: 'app-date-interval',
  templateUrl: './date-interval.component.html',
  styleUrls: ['./date-interval.component.scss']
})
export class DateIntervalComponent implements OnInit {
  @Output() newDate: EventEmitter<Date> = new EventEmitter();
  @Output() newDates: EventEmitter<DateInterval> = new EventEmitter();
  @Output() changeTimeZone: EventEmitter<any> = new EventEmitter();
  @Input() startText: string;
  @Input() endText: string;
  @Input() endDateHidden: boolean;
  @Input() preData: any;
  @Input() row = true;
  @Input() noHour = false;
  @Input() timeZone = "Europe/Paris";
  @Input() allowBefore = false;
  @Input() allowAfter = true;
  @Input() required: ('start' | 'end')[] = ['start'];
  public allTimeZones = TimeZones;
  public timeZoneToSelect: string[];
  public displayTimeZoneSelector: boolean;
  private _selectedStartDate: string;
  private _selectedStartTime: string[];
  private _selectedEndDate: string;
  private _selectedEndTime: string[];
  private _dates: DateInterval = { startDate: null, endDate: null };
  public timeZoneSelectorPos: number = 0;

  constructor() { }

  ngOnInit(): void {
    this.displayTimeZoneSelector = !!this.changeTimeZone.observers.length;
    if (this.displayTimeZoneSelector) {
      this.timeZone = this.timeZone || 'Europe/Paris';
    }
    if (this.preData) {
      if (this.preData.startDate) {
        this.initStartDate();
      }
      if (this.preData.endDate) {
        this.initEndDate();
      }
    }
  }

  getStartDateValue() {
    return this._selectedStartDate
  }

  getStartTimeValue() {
    return this._selectedStartTime
  }

  getEndDateValue() {
    return this._selectedEndDate
  }

  getEndTimeValue() {
    return this._selectedEndTime
  }


  changeStartDate(startDate: any, startTime: any, endDate?: any, endTime?: any): void {
    if (!this.allowBefore) {
      startDate.value = this.checkIfTodayOrBefore(startDate.value);
      if (this._selectedStartTime) {
        this._selectedStartTime = this.compareTimeWithNow(this._selectedStartTime, new Date(startDate.value))
        startTime.value = this.numberToTime(this._selectedStartTime);
      }
    }
    if (!this.allowAfter) {
      startDate.value = this.checkIfTodayOrAfter(startDate.value);
    }
    if (endDate && this._selectedEndDate) {
      this.checkStartDate(startDate, startTime, endDate, endTime);
    }
    this._selectedStartDate = startDate.value;
    this.setStartDate();
  }

  changeStartTime(startTime: any, endTime: any): void {
    if (startTime.value) {
      this._selectedStartTime = this.compareTimeWithNow(startTime.value.split(':'), new Date(this._selectedStartDate))
      startTime.value = this.numberToTime(this._selectedStartTime);
      if (this._selectedEndDate === this._selectedStartDate
        && this._selectedStartTime && this._selectedEndTime) {
        this.resetTime("_selectedStartTime", "_selectedEndTime");
        startTime.value = this._selectedStartTime.join(':');
        const endTimeValue = this._selectedEndTime.join(':');
        if (endTime.value !== endTimeValue) {
          endTime.value = endTimeValue
          this.changeEndTime(endTime, startTime);
        }
      }
      this.setStartDate();
    }
    else {
      this._selectedStartTime = undefined
      this._dates.startDate = null;
      if (this.endDateHidden) {
        this.newDate.emit(this._dates.startDate);
      }
      else {
        this.newDates.emit(this._dates);
      }
    }
  }

  changeEndDate(endDate: any, endTime: any, startDate: any, startTime: any): void {
    if (!this.allowBefore) {
      endDate.value = this.checkIfTodayOrBefore(endDate.value);
    }
    if (!this.allowAfter) {
      endDate.value = this.checkIfTodayOrAfter(endDate.value);
    }
    if (this._selectedStartDate) {
      this.checkEndDate(endDate, endTime, startDate, startTime);
    }
    this._selectedEndDate = endDate.value;
    this.setEndDate();
  }

  changeEndTime(endTime: any, startTime: any): void {
    if (endTime.value) {
      this._selectedEndTime = endTime.value.split(':');
      if (this._selectedEndDate === this._selectedStartDate
        && this._selectedStartTime && this._selectedEndTime) {
        this.resetTime("_selectedEndTime", "_selectedStartTime");
        endTime.value = this._selectedEndTime.join(':');
        const startTimeValue = this._selectedStartTime.join(':');
        if (startTime.value !== startTimeValue) {
          startTime.value = startTimeValue
          this.changeStartTime(startTime, endTime);
        }
      }
      this.setEndDate();
    }
    else {
      this._selectedEndTime = undefined
      this._dates.endDate = null;
      this.newDates.emit(this._dates);
    }
  }

  checkIfTodayOrBefore(selectedDate: string): string {
    let date = this.getFormatDate(new Date(selectedDate));
    const today = this.getFormatDate(new Date())
    if (date.getTime() <= today.getTime()) {
      date = this.getFormatDate(new Date());
    }
    return this.getStringDate(date);
  }

  checkIfTodayOrAfter(selectedDate: string): string {
    let date = this.getFormatDate(new Date(selectedDate));
    const today = this.getFormatDate(new Date())
    if (date.getTime() > today.getTime()) {
      date = this.getFormatDate(new Date());
    }
    return this.getStringDate(date);
  }

  getFormatDate(date: Date, plusDay: number = 0) {
    return new Date(date.getFullYear(), date.getMonth(), (date.getDate() + plusDay));
  }

  getStringDate(date: Date): string {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${(month < 10 ? '0' : '') + month}-${(day < 10 ? '0' : '') + day}`;
  }

  getStringTime(date: Date): string[] {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    if (![null, undefined].includes(hours) && ![null, undefined].includes(minutes)) {
      return [(hours < 10 ? '0' + hours : '' + hours), (minutes < 10 ? '0' + minutes : '' + minutes)];
    }
    else {
      return undefined;
    }
  }

  initStartDate(): void {
    const startDate = new Date(this.preData.startDate);
    this._selectedStartDate = this.getStringDate(startDate);
    this._selectedStartTime = this.getStringTime(startDate);
    this._dates.startDate = startDate;
  }

  initEndDate(): void {
    const endDate = new Date(this.preData.endDate);
    this._selectedEndDate = this.getStringDate(endDate);
    this._selectedEndTime = this.getStringTime(endDate);
    this._dates.endDate = endDate;
  }

  formatTime(time: string[]): string {
    if (time) {
      let hours = parseInt(time[0]);
      let minutes = parseInt(time[1]);
      return (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes)
    }
    else {
      return null;
    }
  }

  setStartDate(): void {
    if ((this._selectedStartTime || this.noHour) && this._selectedStartDate) {
      let date = new Date(this._selectedStartDate);
      if (!this.noHour) {
        const [hours, minutes] = this._selectedStartTime.map(_ => parseInt(_));
        date.setHours(hours, minutes);
      }
      this._dates.startDate = date;
      if (this.endDateHidden) {
        this.newDate.emit(this._dates.startDate);
      }
      else {
        this.newDates.emit(this._dates);
      }
    }
    else {
      this._dates.startDate = null;
      if (this.endDateHidden) {
        this.newDate.emit(this._dates.startDate);
      }
      else {
        this.newDates.emit(this._dates);
      }
    }
  }

  setEndDate(): void {
    if ((this._selectedEndTime || this.noHour) && this._selectedEndDate) {
      let date = new Date(this._selectedEndDate);
      if (!this.noHour) {
        const hours = parseInt(this._selectedEndTime[0]);
        const minutes = parseInt(this._selectedEndTime[1]);
        date.setHours(hours, minutes);
      }
      this._dates.endDate = date;
      this.newDates.emit(this._dates);
    }
    else {
      this._dates.endDate = null;
      this.newDates.emit(this._dates);
    }
  }

  checkStartDate(startDate: any, startTime: any, endDate: any, endTime: any) {
    const start = new Date(startDate.value);
    const end = new Date(this._selectedEndDate);
    if (start.getTime() > end.getTime()) {
      endDate.value = startDate.value;
      this._selectedEndDate = endDate.value
      if (this._selectedStartTime && this._selectedEndTime) {
        this.resetTime("_selectedStartTime", "_selectedEndTime");
        startTime.value = this._selectedStartTime.join(':');
        endTime.value = this._selectedEndTime.join(':');
      }
      this.changeEndDate(endDate, endTime, startDate, startTime);
    }
    else if (start.getTime() === end.getTime()) {
      if (this._selectedStartTime && this._selectedEndTime) {
        this.resetTime("_selectedStartTime", "_selectedEndTime");
        startTime.value = this._selectedStartTime.join(':');
        endTime.value = this._selectedEndTime.join(':');
        this.changeEndTime(endTime, startTime);
      }
    }
  }

  checkEndDate(endDate: any, endTime: any, startDate: any, startTime: any) {
    const start = new Date(this._selectedStartDate);
    const end = new Date(endDate.value);
    if (start.getTime() > end.getTime()) {
      startDate.value = endDate.value;
      this._selectedStartDate = startDate.value
      if (this._selectedStartTime && this._selectedEndTime) {
        this.resetTime("_selectedEndTime", "_selectedStartTime");
        startTime.value = this._selectedStartTime.join(':');
        endTime.value = this._selectedEndTime.join(':');
      }
      this.changeStartDate(startDate, startTime, endDate, endTime);
    }
    else if (start.getTime() === end.getTime()) {
      if (this._selectedStartTime && this._selectedEndTime) {
        this.resetTime("_selectedEndTime", "_selectedStartTime");
        startTime.value = this._selectedStartTime.join(':');
        endTime.value = this._selectedEndTime.join(':');
        this.changeStartTime(startTime, endTime);
      }
    }
  }

  resetTime(time1: string, time2: string) {
    if (parseInt(this._selectedStartTime[0]) > parseInt(this._selectedEndTime[0])) {
      this[time2][0] = this[time1][0];
      if (parseInt(this._selectedStartTime[1]) > parseInt(this._selectedEndTime[1])) {
        this[time2][1] = this[time1][1];
      }
    }
    else if (parseInt(this._selectedStartTime[0]) === parseInt(this._selectedEndTime[0]) &&
      parseInt(this._selectedStartTime[1]) > parseInt(this._selectedEndTime[1])) {
      this[time2][1] = this[time1][1];
    }
  }

  searchTimeZone(event: KeyboardEvent, value: string) {
    if (!['ArrowUp', 'ArrowDown', 'Enter'].includes(event.code)) {
      this.timeZoneToSelect = TimeZones.filter(tz => tz.toLowerCase().includes(value.toLowerCase())).splice(0, 10);
      if (!this.timeZoneToSelect.length) {
        this.timeZoneToSelect = null;
      }
    }
  }

  selectTimeZone(value: string) {
    if (value !== this.timeZone) {
      this.changeTimeZone.emit(value);
    }
    this.timeZoneToSelect = null;
    this.timeZoneSelectorPos = 0;
    this.changeTimeZoneValue(value);
  }

  // @HostListener('document:click') clickDoc(): void {
  //   this.resetTimeZoneSelector();
  // }

  // resetTimeZoneSelector(): void {
  //   this.timeZoneToSelect = null;
  //   this.timeZoneSelectorPos = 0;
  //   this.changeTimeZoneValue();
  // }

  public changeTimeZoneValue(newValue = this.timeZone): void {
    if (newValue == this.timeZone) {
      this.timeZone = '';
    }
    setTimeout(() => this.timeZone = newValue);
  }

  // public keyDownTimeZoneSelect(event: KeyboardEvent): void {
  //   if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
  //     if (this.timeZoneToSelect) {
  //       this.changeSelectorPos(event.key == 'ArrowUp');
  //     }
  //     event.preventDefault();
  //   }
  //   else if (event.key == 'Enter') {
  //     event.preventDefault();
  //     this.validateSelectorPos();
  //   }
  //   else if (event.key == 'Tab') {
  //     this.resetTimeZoneSelector();
  //   }
  // }

  // public changeSelectorPos(up: boolean): void {
  //   this.timeZoneSelectorPos += up ? -1 : 1;
  //   if (this.timeZoneSelectorPos < 0) {
  //     this.timeZoneSelectorPos = 0;
  //   }
  //   else if (this.timeZoneSelectorPos >= this.timeZoneToSelect.length) {
  //     this.timeZoneSelectorPos = this.timeZoneToSelect.length - 1;
  //   }
  // }

  // public changeSelectorPosOnHover(value: string): void {
  //   this.timeZoneSelectorPos = this.timeZoneToSelect.findIndex(current => current == value);
  // }

  // public validateSelectorPos(): void {
  //   this.selectTimeZone(this.timeZoneToSelect[this.timeZoneSelectorPos]);
  // }

  public compareTimeWithNow(times: string[], date: Date): [string, string] {
    const now = new Date();
    const timesInt = times.map(_ => parseInt(_));
    if (date.getFullYear() == now.getFullYear() && date.getMonth() == now.getMonth() && date.getDay() == now.getDay()) {
      if (timesInt[0] <= now.getHours()) {
        timesInt[0] = now.getHours();
        if (timesInt[1] <= now.getMinutes()) {
          timesInt[1] = now.getMinutes();
        }
      }
    }
    return [`${timesInt[0] > 9 ? '' : '0'}${timesInt[0]}`, `${timesInt[1] > 9 ? '' : '0'}${timesInt[1]}`];
  }

  numberToTime(time: string[]): string {
    const hours = parseInt(time[0]);
    const minutes = parseInt(time[1]);
    return `${hours > 9 ? '' : '0'}${hours}:${minutes > 9 ? '' : '0'}${minutes}`
  }

}

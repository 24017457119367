<ng-container *ngIf="data">

    <div id="page_header" class="container">
        <div id="page_header_left">
            <h1>Import billetterie</h1>
        </div>
        <div id="page_header_right">
            <app-button (click)="createImport()" class="button" text="Créer un import" [style]="'plain'"></app-button>
        </div>
    </div>

    <section *ngIf="data.length">
        <app-main-table [ref]="this" idSection="controls_content" idTable="table_imports" tableType="imports"
            [columns]="tableOptions" [datum]="data"></app-main-table>

        <app-pager *ngIf="totalPages" [totalPages]="totalPages" [page]="page" (changePage)="changePage($event)"></app-pager>
    </section>

    <section *ngIf="!data.length" id="block_first" class="container">

        <p class="description grey">Vous n’avez pas encore ajouté d'import billetterie pour cet événement</p>
        <figure>
            <img src="assets/images/visuel_controle_acces.svg" />
        </figure>
        <app-button (click)="createImport()" class="button_create no_margin" text="Créer un import"
            [style]="'plain full'"></app-button>
    </section>
</ng-container>


<div class="full_loader" *ngIf="fullLoader">
	<div></div>
</div>
<app-delete-popup *ngIf="importToDeleteTickets" customText="Souhaitez-vous supprimer tous les tickets associés à cet import ?" [name]="importToDeleteTickets.name" type='import'
(clickEvent)="deleteTicketsOk()" (close)="importToDeleteTickets = null"></app-delete-popup>
<app-delete-popup *ngIf="elementToDelete" [name]="elementToDelete.name" customText="Souhaitez-vous supprimer cet import ?" type='import'
(clickEvent)="deleteOk()" (close)="elementToDelete = null"></app-delete-popup>
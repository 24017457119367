<div id="page_header" class="container">
    <div id="page_header_left">
        <h1>Opérateurs</h1>
    </div>
    <div id="page_header_right">
        <app-button (click)="createOperator()" class="button" text="Ajouter un opérateur" [style]="'plain'"></app-button>
    </div>
</div>

<section>
    <app-main-table [ref]="this"
    idSection="operators_content" idTable="table_operators" tableType="operators"
    [columns]="tableOptions" [datum]="operators"></app-main-table>
</section>

<section id="block_first" class="container">
    <p class="description grey">Vous n’avez pas encore d’opérateurs pour vous aider sur votre événement !</p>
    <figure>
        <img src="assets/images/visuel_operateur.svg" />
    </figure>
    <app-button (click)="createOperator()" class="button_create no_margin" text="Ajouter un opérateur" [style]="'plain full'"></app-button>
</section>

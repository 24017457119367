import { Component, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { FormGroup, FormBuilder, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService, UserService } from 'src/app/providers';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';

@Component({
  selector: 'app-password-forgotten',
  templateUrl: './password-forgotten.component.html',
  styleUrls: ['./password-forgotten.component.scss']
})
export class PasswordForgottenComponent implements OnInit {
  private _subscription = new Subscription();
  private _recoveryId: string;
  private _recoveryCode: string;
  public loadingState$ = new Subject<void>();

  public emailForm: FormGroup;
  public codeForm: FormGroup;
  public passwordsForm: FormGroup;
  public error: string;
  public steps: string[] = ['enterEmail', 'enterCode', 'resetPassword'];
  public currentStep = 0
  public passwordsOk: boolean;
  public passwordIncorrect: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private errorHandler: ErrorHandlerService,
    private authService: AuthService
  ) { }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.initEmailForm();
  }

  initEmailForm(): void {
    this.emailForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }

  initCodeForm(): void {
    this.codeForm = this.formBuilder.group({
      id: this._recoveryId,
      code: new FormControl('', [Validators.required])
    });
  }

  initPasswordsForm(): void {
    this.passwordsForm = this.formBuilder.group({
      password: new FormControl('', Validators.required),
      confirmPassword: new FormControl('', Validators.required),
      same: new FormControl(false, Validators.requiredTrue)
    });
  }

  checkPasswords(): void {
    const pwd1 = this.passwordsForm.get('password').value;
    const pwd2 = this.passwordsForm.get('confirmPassword').value;
    this.passwordIncorrect = pwd1 && pwd2 && pwd1 !== pwd2;
    this.passwordsForm.patchValue({ same: pwd1 === pwd2 });
  }

  sendEmail(): void {
    const formData = this.emailForm.value;
    this._subscription.add(this.userService.recoverPassword(formData).subscribe(result => {
      this._recoveryId = result.data?.userId;
      this.initCodeForm();
      this.currentStep++;
    },
      err => {
        this.error = this.errorHandler.getError(err);
        this.loadingState$.next();
      }))
  }

  sendCode(): void {
    const formData = this.codeForm.value;
    this._subscription.add(this.userService.checkCodeReset(formData).subscribe(result => {
      this._recoveryCode = formData.code;
      this.initPasswordsForm();
      this.currentStep++;
    },
      err => {
        this.error = this.errorHandler.getError(err);
        this.loadingState$.next();
      }))
  }

  resetPassword(): void {
    const body = {
      id: this._recoveryId,
      code: this._recoveryCode,
      data: {
        password: this.passwordsForm.value.password
      }
    }
    this._subscription.add(this.userService.setNewPassword(body).subscribe(result => {
      this.authService.saveToken(result.token);
      this.router.navigate(['dashboard']);
    },
      err => {
        this.error = this.errorHandler.getError(err);
        this.loadingState$.next();
      }))
  }

  goBack(): void {
    this.router.navigate(['connexion']);
  }

  closeError(): void {
    this.error = null;
  }

}

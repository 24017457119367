import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PopupComponent } from '../popup/popup.component';

@Component({
  selector: 'app-delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.scss']
})
export class DeletePopupComponent extends PopupComponent {
  @Output() close = new EventEmitter();
  @Input() type: string;
  @Input() name: string;
  @Input() deleteWord = "supprimer";
  @Input() isFemale = false
  @Input() startWithVowel = false;
  @Input() customText: string;
  @Input() buttonText = 'Supprimer'
  
  public back(): void {
    this.close.emit();
  }
}

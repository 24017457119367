import { Injectable } from '@angular/core';
import { ReactiveProvider } from '../reactive-provider';
import { ICountry } from 'src/app/models/country.model';
import { APIListResponse, GetParams } from 'src/app/models/type.definition';
import { Observable, of } from 'rxjs';

@Injectable()
export class CountryService extends ReactiveProvider<ICountry> {
  protected url = "vocabularies/countries";

  public getList(body?: GetParams<ICountry>): Observable<APIListResponse<ICountry>> {
    if (this.data$.value) {
      return of({ data: this.data$.value, pager: null, total: null });
    }
    else {
      return super.getList(body);
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { IEvent } from 'src/app/models/event.model';
import { ITicketType } from 'src/app/models/ticket.model';
import { GetParams, TableOptions } from 'src/app/models/type.definition';
import { ImportOrderService, TicketTypeService } from 'src/app/providers';
import { GetEvent } from '../../getEvent';
import { IImportOrder, translateStatus } from 'src/app/models/import-order';
import { ListFromEventComponent } from 'src/app/elements/list-component';
import { DateService } from 'src/app/services/date.service';
import { FilesHandler } from 'src/app/services/file-handler.service';

@Component({
  selector: 'app-event-orders-import',
  templateUrl: './event-orders-import.component.html',
  styleUrls: ['./event-orders-import.component.scss'],
  providers: [GetEvent]
})
export class EventOrdersImportComponent extends ListFromEventComponent<IImportOrder> implements OnInit {
  public dataName = "Import billetterie";
  public currentUrl: string;
  public event: IEvent;
  public fullLoader: boolean;
  public getEventParams: GetParams<IEvent> = { filter: {}, select: ['eventCode'] }
  public importToDeleteTickets: IImportOrder;
  public ticketTypes: ITicketType[];

  public tableOptions: TableOptions[] = [
  {
    title: "Source",
    class: "provider",
    classKey: "provider",
    sort: false,
    key: 'provider',
  },
  {
    title: "Nombre de tickets importés",
    class: "name",
    sort: false,
    key: 'name',
  },
  {
    title: "Créé le",
    class: "createdat",
    sort: false,
    key: 'date',
  },
  {
    title: "Statut",
    class: "status",
    classKey: "status",
    sort: false,
    key: 'translatedStatus',
  },
  {
    class: "more",
    toggle: [
      {
        title: 'Lancer l\'import',
        if: (data: IImportOrder) => data.status === "NOT_STARTED",
        method: this.gotoStart,
      },
      {
        title: 'Télécharger le fichier',
        method: this.downloadFile
      },
      {
        title: 'Voir les tickets',
        if: (data: IImportOrder) => data.status === "COMPLETED",
        method: this.goToTickets,
      },
      {
        title: 'Rapport d\'erreur',
        if: (data: IImportOrder) => data.status === "FAILED",
        class: "red",
        method: this.downloadErrorReport,
      },
      {
        title: 'Supprimer tous les tickets',
        if: (data: IImportOrder) => data.status === "COMPLETED",
        class: "red",
        method: this.deleteTickets,
      },
      {
        title: 'Supprimer',
        if: (data: IImportOrder) => data.status === "NOT_STARTED" || data.status === "FAILED" || data.status === "CANCELED",
        class: "red",
        method: this.deleteData,
      },
    ]
  }];

  constructor(
    protected provider: ImportOrderService,
    protected getEvent: GetEvent,
    protected ticketTypeService: TicketTypeService,
    private dateService: DateService,
    private filesHandler: FilesHandler,
  ) {
    super();
  }

  public goToTickets(data: IImportOrder, ref = this) {
    ref.router.navigate([`evenements/${ref.event._id}/participants/tous`], { queryParams: { importId: data._id } });
  }

  public downloadErrorReport(data: IImportOrder, ref = this) {
    const logs = data.logs.join('\n');
    const blob = new Blob([logs], { type: 'text/plain' });
    ref.filesHandler.downloadFile(blob, `error-report-${data._id}.txt`);
  }

  public downloadFile(data: IImportOrder, ref = this) {
      ref.fullLoader = true;
      ref.provider.streamFile(data._id).subscribe(result => {
        ref.filesHandler.downloadFile(result, data.fileKey);
        ref.fullLoader = false;
      }, err => {
        ref.notificationService.newNotification({ state: 'error', message: ref.errorHandler.getError(err) });
        ref.fullLoader = false;
      });
  }

  public gotoStart(data: IImportOrder, ref = this) {
    ref.router.navigate([`${ref.currentUrl}/resume/${data._id}`]);
  }


  protected async formatElement(importOrder: IImportOrder): Promise<IImportOrder> {
    importOrder.translatedStatus = translateStatus(importOrder.status);
    importOrder.name = `${importOrder.success}/${importOrder.total}`;
    importOrder.date = this.dateService.formatDateAndTime(importOrder.createdAt);
    return importOrder
  }

  protected setGetParams(): void {
    this.getParams.filter.organisationId = this.event.organisationId._id;
    if (!this.getParams.sort) {
      this.getParams.sort = [['createdAt', -1]];
    }
  }

  public createImport(): void {
    this.router.navigate([`${this.currentUrl}/creer`]);
  }

  public deleteData(data: IImportOrder, ref: ListFromEventComponent<IImportOrder> = this): void {
    ref.elementToDelete = data;
  }

  public deleteTickets(data: IImportOrder, ref = this): void {
    ref.importToDeleteTickets = data;
  }

  public deleteTicketsOk(): void {
    console.log("....");
    this.fullLoader = true;
    this.provider.deleteTickets(this.importToDeleteTickets._id).subscribe((response) => {
      this.importToDeleteTickets = null;
      this.getData()
      this.notificationService.newNotification({ state: 'success', message: 'Les tickets ont bien été supprimés' });
      this.fullLoader = false
    }, (err) => {
      this.notificationService.newNotification({ state: 'error', message: this.errorHandler.getError(err) });
      this.fullLoader = false
    })

  }

  public deleteOk(): void {
    super.deleteData(this.elementToDelete, this, () => {
      this.elementToDelete = null;
    });
  }

}

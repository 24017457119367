<section
  id="form_page"
  class="container loading_container"
  [ngClass]="{ loaded: guichetSettingForm }"
>
    <div class="loading_content">
        <div id="page_header">
            <div id="page_header_left">
                <h1>Guichet</h1>
            </div>
            <div id="page_header_right">

            </div>
        </div>
        <div id="form_wrapper">
            <h2>Paramétrer votre guichet</h2>
            <form *ngIf="guichetSettingForm" [formGroup]="guichetSettingForm">
                <div formGroupName="ticketOffice" class="form_cols">

                    <div *ngIf="isAdmin" class="admin_only">
                        <h3 class="form_item full">Commissions</h3>
                        <div class="form_item full smallest_margin">
                            <input id="includeTicketCommission" type="checkbox" formControlName="includeTicketCommission" />
                            <label for="includeTicketCommission">
                            Inclure les commissions pour la billetterie
                            <svg role="img" title="check" class="check-icon">
                                <use href="assets/images/sprite.svg#icon-check"></use>
                            </svg>
                            </label>
                        </div>

                        <div class="form_item full smallest_margin">
                            <input id="includeProductCommission" type="checkbox" formControlName="includeProductCommission" />
                            <label for="includeProductCommission">
                            Inclure les commissions pour la digibuvette
                            <svg role="img" title="check" class="check-icon">
                                <use href="assets/images/sprite.svg#icon-check"></use>
                            </svg>
                            </label>
                        </div>
                     </div>
                    <h3 class="form_item full">Formulaires</h3>
                    <div class="form_item full smallest_margin">
                        <input id="skipOrderInfo" type="checkbox" formControlName="skipOrderInfo" />
                        <label for="skipOrderInfo">
                        Ne pas demander les informations commandes par défaut (nom, prénom, email)
                        <svg role="img" title="check" class="check-icon">
                            <use href="assets/images/sprite.svg#icon-check"></use>
                        </svg>
                        </label>
                    </div>
                    <div class="form_item full smallest_margin">
                        <input id="skipTicketInfo" type="checkbox" formControlName="skipTicketInfo" />
                        <label for="skipTicketInfo">
                        Ne pas demander les informations tickets (formulaires)
                        <svg role="img" title="check" class="check-icon">
                            <use href="assets/images/sprite.svg#icon-check"></use>
                        </svg>
                        </label>
                    </div>

                    <div class="form_item full smallest_margin">
                        <input id="skipAddonInfo" type="checkbox" formControlName="skipAddonInfo" />
                        <label for="skipAddonInfo">
                        Ne pas utiliser les addons
                        <svg role="img" title="check" class="check-icon">
                            <use href="assets/images/sprite.svg#icon-check"></use>
                        </svg>
                        </label>
                    </div>

                    <h3 class="form_item full">Modes de paiement</h3>

                    <div formGroupName="paymentMethods" class="form_item full">
                        <div *ngIf="isAdmin" class="form_item full smallest_margin">
                            <input
                                id="creditCardTerminal"
                                type="checkbox"
                                formControlName="creditCardTerminal"
                                (change)="changeCreditCardTerminal($event.target.checked)"
                            />
                            <label for="creditCardTerminal">
                            Carte bancaire (TPE OandB)
                            <svg role="img" title="check" class="check-icon">
                                <use href="assets/images/sprite.svg#icon-check"></use>
                            </svg>
                            </label>
                        </div>
                        <div class="form_item full smallest_margin">
                            <input id="creditCardExternal" type="checkbox" formControlName="creditCardExternal" />
                            <label for="creditCardExternal">
                            Carte bancaire (TPE Externe)
                            <svg role="img" title="check" class="check-icon">
                                <use href="assets/images/sprite.svg#icon-check"></use>
                            </svg>
                            </label>
                        </div>

                        <div class="form_item full smallest_margin">
                            <input id="cash" type="checkbox" formControlName="cash" />
                            <label for="cash">
                            Espèce
                            <svg role="img" title="check" class="check-icon">
                                <use href="assets/images/sprite.svg#icon-check"></use>
                            </svg>
                            </label>
                        </div>
                        <div class="form_item full smallest_margin">
                            <input id="check" type="checkbox" formControlName="check" />
                            <label for="check">
                            Chèque
                            <svg role="img" title="check" class="check-icon">
                                <use href="assets/images/sprite.svg#icon-check"></use>
                            </svg>
                            </label>
                        </div>
                    </div>

                    <div *ngIf="showReadersList">
                        <h3 class="form_item full">TPE Stripe</h3>
                        <div formArrayName="stripeReaders" class="form_item full">
                            <div [formGroupName]="i" *ngFor="let stripeReader of stripeReaders; let i = index" [value]="stripeReader" class="form_item full smallest_margin">
                                <input
                                    [id]="stripeReader.id"
                                    type="checkbox"
                                    formControlName="checked"
                                />
                                <label [for]="stripeReader.id">
                                {{ stripeReader.label }}
                                <svg role="img" title="check" class="check-icon">
                                    <use href="assets/images/sprite.svg#icon-check"></use>
                                </svg>
                                </label>
                            </div>
                        </div>
                    </div>

                    <h3 class="form_item full">Modes de paiement supplémentaires</h3>
                    <mat-form-field formArrayName="extraPaymentMethods">
                        <mat-chip-list class="form_item full" #extraPaymentMethodsList aria-label="Extra payment methods">
                        <mat-chip
                            *ngFor="let pm of extraPaymentMethodForm.controls; index as i"
                            [selectable]="selectable"
                            [removable]="removable"
                            (removed)="removePaymentMethod(i)"
                            selected
                        >
                            {{ pm.get("label").value }}
                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                        </mat-chip>
                        </mat-chip-list>
                        <input
                            type="text"
                            placeholder="Nouvelle méthode de paiement..."
                            [matChipInputFor]="extraPaymentMethodsList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            [matChipInputAddOnBlur]="addOnBlur"
                            (matChipInputTokenEnd)="add($event)"
                        />
                    </mat-form-field>

                </div>



                <div class="form_actions right">
                    <app-button
                        [loadingOff]="loadingState$"
                        [style]="'plain'"
                        type="submit"
                        [disabled]="guichetSettingForm.invalid"
                        (clickEvent)="updateEvent()"
                        text="Enregistrer"
                    >
                    </app-button>
                </div>

            </form>
        </div>

    </div>

</section>

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService, OrganisationService } from 'src/app/providers';
import { StorageService } from 'src/app/services/storage.service';

@Injectable({
    providedIn: 'root'
})
export class NotAdminGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        private organisationService: OrganisationService,
        private storageService: StorageService,
        private router: Router) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.authService.isAdmin) {
            if (this.organisationService.adminConnected$.value /** || this.storageService.getItem('adminInOrganization') */) {
                return true
            }
            else {
                this.router.navigate(['admin']);
                return false
            }
        }
        return true;
    }
}
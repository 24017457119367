<main id="auth_page" class="container">
    <div id="auth_img">
        <div class="inner">
            <div class="imgs">
                <div class="logo">
                    <img src="assets/images/logo_white.svg" alt="" />
                </div>
                <div class="baseline">
                    <img src="assets/images/baseline_white.svg" alt="" />
                </div>
            </div>
        </div>
    </div>
    <div id="auth_form">
        <div class="inner">
            <div class="form-ctn">
                <h1>Inscription</h1>
                <form [formGroup]="signForm">
                    <div class="form_fields">
                        <div class="form_item">
                            <label for="firstName">Prénom *</label>
                            <input id="firstName" type="text" formControlName="firstName">
                        </div>
                        <div class="form_item">
                            <label for="lastName">Nom *</label>
                            <input id="lastName" type="text" formControlName="lastName">
                        </div>
                        <div class="form_item">
                            <label for="email">Adresse e-mail *</label>
                            <input id="email" type="email" formControlName="email">
                        </div>
                        <div class="form_item">
                            <label for="password">Mot de passe *</label>
                            <div class="input_wrap password">
                                <input #pwd1 (keyup)="checkPassword(pwd1.value, pwd2.value)" id="password"
                                    [attr.type]="showPwd ? 'text' : 'password'" formControlName="password">
                                <svg role="img" title="eye" class="eye-icon" [ngClass]="{'active': showPwd}"
                                    (click)="showPwd = !showPwd">
                                    <use href="assets/images/sprite.svg#icon-eye"></use>
                                </svg>
                            </div>
                            <span class="description">Votre mot de passe doit contenir au moins 8 caractères dont un
                                chiffre et un caractère spécial</span>
                        </div>

                        <div class="form_item">
                            <label for="password2">Confirmer mot de passe *</label>
                            <div class="input_wrap password">
                                <input #pwd2 (keyup)="checkPassword(pwd1.value, pwd2.value)" id="password2"
                                    [attr.type]="showPwd2 ? 'text' : 'password'">
                                <svg role="img" title="eye" class="eye-icon" [ngClass]="{'active': showPwd2}"
                                    (click)="showPwd2 = !showPwd2">
                                    <use href="assets/images/sprite.svg#icon-eye"></use>
                                </svg>
                            </div>
                        </div>

                        <div class="form_item">
                            <input id="agreement" type="checkbox" formControlName="agreement">
                            <label for="agreement">
                                J'accepte que les données renseignées permettent de créer un compte sur la plateforme.
                                <a href="/informations" target="blank">En savoir plus</a>
                                <svg role="img" title="check" class="check-icon">
                                    <use href="assets/images/sprite.svg#icon-check"></use>
                                </svg>
                            </label>
                        </div>
                    </div>
                    <app-button [disabled]="signForm.invalid || !passwordOk || !passwordsMatches" text="Créer mon compte" type="submit"
                        (clickEvent)="signUp()" [style]="'plain full'"
                        (mouseover)="(signForm.invalid || !passwordOk || !passwordsMatches) && displayDisabledText = true"
                        (mouseleave)="displayDisabledText = false">
                    </app-button>

                    <div *ngIf="displayDisabledText" class="disabled_text">
                        {{getDisabledText()}}
                    </div>

                    <app-popup *ngIf="error != null" title="Une erreur est survenue" message="{{error}}" popupOpened="true"
                        (backEvent)="error = null"></app-popup>
                </form>
                <app-button class="button_login" text="Déjà un compte ?" (clickEvent)="logIn()" [style]="'border full'">
                </app-button>
            </div>
        </div>
    </div>
</main>
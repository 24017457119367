<div class="form_item">
  <label for="address">{{ label }}</label>
  <input
    #input
    type="search"
    ngx-google-places-autocomplete
    [options]="options"
    (onAddressChange)="AddressChange($event)"
    placeholder="Recherche de l'adresse de votre évènement"
  />
</div>

<section *ngIf="orgaForm" id="form_page" class="container">
    <div id="page_header">
        <h1>Paramètres</h1>
    </div>
    <div id="form_wrapper">
        <h2>Vos coordonnées bancaires</h2>
        <form *ngIf="orgaForm" [formGroup]="orgaForm" class="large">
            <h3>Vos coordonnées bancaires</h3>

            <div class="form_cols">
                <div class="form_item half">
                    <label for="domiciliation">Domiciliation*</label>
                    <input id="domiciliation" type="text" formControlName="domiciliation">
                </div>

                <div class="form_item half">
                    <label for="currency">Devise</label>
                    <div class="input_wrap select">
                        <select id="currency" formControlName="currencyId">
                            <option value="null" selected hidden>Choisir une devise</option>
                            <option *ngFor="let currency of currencies" value="{{currency.id}}">
                                {{currency.isoCode}}</option>
                        </select>
                        <svg role="img" title="chevron" class="icon-chevron">
                            <use href="assets/images/sprite.svg#icon-chevron"></use>
                        </svg>
                    </div>
                </div>
                <div class="form_item half">
                    <label for="iban">IBAN*</label>
                    <input id="iban" type="text" formControlName="IBAN">
                </div>
                <div class="form_item half">
                    <label for="bic">BIC*</label>
                    <input id="bic" type="text" formControlName="BICSWIFT">
                </div>
            </div>

            <div class="form_item">
                <label for="owner">Propriétaire*</label>
                <input id="owner" type="text" formControlName="owner">
            </div>

            <div class="form_actions right">
                <app-button (clickEvent)="goBack()" text="Annuler" [style]="'border blue'"></app-button>
                <app-button [loadingOff]="loadingState$" [style]="'plain'" type="submit" [disabled]="orgaForm.invalid" (clickEvent)="updateOrganisation()" text="Valider"></app-button>
            </div>
        </form>
    </div>
</section>

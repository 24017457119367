import { BrowserModule } from '@angular/platform-browser';
import { Injector, LOCALE_ID } from '@angular/core';
import { setInjector } from './etc/injector';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);

import { NgModule } from '@angular/core';

import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ColorPickerModule } from 'ngx-color-picker';

// HTTP MODULE
import { Interceptor } from './etc/interceptor';
import { AuthGuard } from './etc/authGuard';
import { AdminGuard } from './etc/admin-guard';
import { NotAdminGuard } from './etc/not-admin-guard';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// QueryParamsTranslator
import { QueryParams } from './etc/queryParams';

// Animations and effects
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Place Search Engine

// Image Cropper
import { ImageCropperModule } from 'ngx-image-cropper';

// Tippy
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

// Pages
import { InitPage } from './pages/init-page/init-page';
import { NotFoundPage } from './pages/not-found/not-found';
import { NotAuthorizedPage } from './pages/not-authorized/not-authorized';

// Services
import { ErrorHandlerService } from './services/error-handler.service';
import { FilesHandler } from './services/file-handler.service';
import { NotificationService } from './services/notification.service';
import { StorageService } from './services/storage.service';
import { ClickOutService } from './services/click-out.service';
import { RoutesService } from './services/routes.service';
import { GrowthService } from './services/growth.service';
import { UpdateService } from './services/update.service';
import { DateService } from './services/date.service';
import { SocketService } from './services/socket.service';

// Material component
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';

import { environment } from 'src/environments/environment';

// Providers
import {
  StripeTpeService,
  AuthService,
  AccountingService,
  AddonService,
  ControlListService,
  CountryService,
  CurrencyService,
  EventService,
  EventCategoryService,
  EventSessionService,
  EventSiteService,
  EventWidgetService,
  FormService,
  FormFieldService,
  OrderService,
  OrganisationService,
  PromoCodeService,
  MediaService,
  ResetService,
  TaxService,
  TicketService,
  TicketTypeService,
  TicketTypeCategoryService,
  UserService,
  InvitationService,
  MemberService,
  WaitingListService,
  RefundService,
  InstagramService,
  OrganisationNotificationService,
  CommonService
} from './providers/index';

import { LogInComponent } from './pages/auth-related/log-in/log-in.component';
import { SignUpComponent } from './pages/auth-related/sign-up/sign-up.component';
import { SeeMoreComponent } from './pages/auth-related/see-more/see-more.component';
import { ButtonComponent } from './elements/button/button.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { NotificationDisplayerComponent } from './elements/notification-displayer/notification-displayer.component';
import { PasswordForgottenComponent } from './pages/auth-related/password-forgotten/password-forgotten.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AdminDashboardComponent } from './pages/adminDashboard/adminDashboard.component';
import { AdminEventsComponent } from './pages/adminEvents/adminEvents.component';
import { AdminOrdersComponent } from './pages/adminOrders/adminOrders.component';
import { AdminTicketsComponent } from './pages/adminTickets/adminTickets.component';
import { AdminRefundComponent } from './pages/adminRefund/adminRefund.component';
import { HeaderComponent } from './elements/header/header.component';
import { SidebarComponent } from './elements/sidebar/sidebar.component';
import { EventsComponent } from './pages/events/events.component';
import { TeamComponent } from './pages/team/team.component';
import { GeneralSettingsComponent } from './pages/settings/general-settings/general-settings.component';
import { RibibanSettingsComponent } from './pages/settings/ribiban-settings/ribiban-settings.component';
import { PaymentAccountComponent } from './pages/settings/payment-account/payment-account.component';
import { SeatingPlanComponent } from './pages/settings/seating-plan/seating-plan.component';
import { SeatingPlanCreateComponent } from './pages/settings/seating-plan/seating-plan-create/seating-plan-create.component';
import { PopupComponent } from './elements/popup/popup.component';
import { AccountingSettingsComponent } from './pages/settings/accounting-settings/accounting-settings.component';
import { OrganisationsComponent } from './pages/organisations/organisations.component';
import { CreateOrganisationComponent } from './pages/organisations/create-organisation/create-organisation.component';
import { WysiwygComponent } from './elements/wysiwyg/wysiwyg.component';
import { MyAccountComponent } from './pages/my-account/my-account.component';
import { CreateEventComponent } from './pages/events/create-event/create-event.component';
import { FileInputComponent } from './elements/file-input/file-input.component';
import { AddMemberComponent } from './pages/team/add-member/add-member.component';
import { MainTableComponent } from './elements/main-table/main-table.component';
import { EventGeneralComponent } from './pages/events/event-general/event-general.component';
import { EventSettingsComponent } from './pages/events/event-settings/event-settings.component';
import { EventTicketingComponent } from './pages/events/ticketing/event-ticketing/event-ticketing.component';
import { EventFormsComponent } from './pages/events/event-forms/event-forms.component';
import { EventCampaignComponent } from './pages/events/event-campaign/event-campaign.component';
import { EventCampaignCreateComponent } from './pages/events/event-campaign/event-campaign-create/event-campaign-create.component';
import { EventMiniSiteComponent } from './pages/events/event-mini-site/event-mini-site.component';
import { EventCreateMiniSiteComponent } from './pages/events/event-mini-site/event-create-mini-site/event-create-mini-site.component';
import { EventExclusiveContentComponent } from './pages/events/event-exclusive-content/event-exclusive-content.component';
import { EventCreateExclusiveContentComponent } from './pages/events/event-exclusive-content/event-create-exclusive-content/event-create-exclusive-content.component';
import { EventOrdersComponent } from './pages/events/event-orders/event-orders.component';
import { EventParticipantsComponent } from './pages/events/event-participants/event-participants.component';
import { WaitingListComponent } from './pages/events/event-participants/waiting-list/waiting-list.component';
import { EventGuichetComponent } from './pages/events/event-guichet/event-guichet.component';
import { EventCounterComponent } from './pages/events/event-counter/event-counter.component';
import { EventAccessControlComponent } from './pages/events/access-control/event-access-control/event-access-control.component';
import { EventAccessControlOperatorComponent } from './pages/events/access-control/event-access-control-operator/event-access-control-operator.component';
import { EventTicketingEticketComponent } from './pages/events/ticketing/event-ticketing-eticket/event-ticketing-eticket.component';
import { EventTicketingBadgeComponent } from './pages/events/ticketing/event-ticketing-badge/event-ticketing-badge.component';
import { EventTicketingEticketUniqueComponent } from './pages/events/ticketing/event-ticketing-eticket-unique/event-ticketing-eticket-unique.component';
import { EventTicketingSettingsComponent } from './pages/events/ticketing/event-ticketing-settings/event-ticketing-settings.component';
import { EventTicketingPromoCodeComponent } from './pages/events/ticketing/event-ticketing-promo-code/event-ticketing-promo-code.component';
import { EventTicketingInvitationComponent } from './pages/events/ticketing/event-ticketing-invitation/event-ticketing-invitation.component';
import { PagerComponent } from './elements/pager/pager.component';
import { FiltersComponent } from './elements/filters/filters.component';
import { PlacesComponent } from './elements/places/places.component';
import { DatePickerComponent } from './elements/date-picker/date-picker.component';
import { SearchInputComponent } from './elements/search-input/search-input.component';
import { EventTicketingSessionComponent } from './pages/events/ticketing/event-ticketing-session/event-ticketing-session.component';
import { EventTicketingCreateCategoryComponent } from './pages/events/ticketing/event-ticketing/event-ticketing-create-category/event-ticketing-create-category.component';
import { EventTicketingCreateRateComponent } from './pages/events/ticketing/event-ticketing/event-ticketing-create-rate/event-ticketing-create-rate.component';
import { EventTicketingProductComponent } from './pages/events/ticketing/event-ticketing-product/event-ticketing-product.component';
import { EventTicketingProductCreateComponent } from './pages/events/ticketing/event-ticketing-product/event-ticketing-product-create/event-ticketing-product-create.component';
import { EventTicketingProductCreateCategoryComponent } from './pages/events/ticketing/event-ticketing-product/event-ticketing-product-create-category/event-ticketing-product-create-category.component';
import { EventTicketingSecurityComponent } from './pages/events/ticketing/event-ticketing-security/event-ticketing-security.component';
import { EventTicketingCreatePromoCodeComponent } from './pages/events/ticketing/event-ticketing-promo-code/event-ticketing-create-promo-code/event-ticketing-create-promo-code.component';
import { EventTicketingGeneratePromoCodeComponent } from './pages/events/ticketing/event-ticketing-promo-code/event-ticketing-generate-promo-code/event-ticketing-generate-promo-code.component';
import { EventTicketingSessionCreateComponent } from './pages/events/ticketing/event-ticketing-session/event-ticketing-session-create/event-ticketing-session-create.component';
import { EventTicketingSeatingPlanComponent } from './pages/events/ticketing/event-ticketing-seating-plan/event-ticketing-seating-plan.component';
import { EventFormsCreateComponent } from './pages/events/event-forms/event-forms-create/event-forms-create.component';
import { DateIntervalComponent } from './elements/date-interval/date-interval.component';
import { EventTicketingUpdatePromoCodeComponent } from './pages/events/ticketing/event-ticketing-promo-code/event-ticketing-update-promo-code/event-ticketing-update-promo-code.component';
import { registerLocaleData } from '@angular/common';
import { EventFormFieldsComponent } from './pages/events/event-forms/event-forms-create/event-form-fields/event-form-fields.component';
import { EventFormFieldsCreateComponent } from './pages/events/event-forms/event-forms-create/event-form-fields/event-form-fields-create/event-form-fields-create.component';
import { EventTicketingInvitationCreateComponent } from './pages/events/ticketing/event-ticketing-invitation/event-ticketing-invitation-create/event-ticketing-invitation-create.component';
import { EventAccessControlCreateComponent } from './pages/events/access-control/event-access-control/event-access-control-create/event-access-control-create.component';
import { EventAccessControlOperatorCreateComponent } from './pages/events/access-control/event-access-control-operator/event-access-control-operator-create/event-access-control-operator-create.component';
import { EventParticipantsDetailComponent } from './pages/events/event-participants/event-participants-detail/event-participants-detail.component';
import { EventWidgetComponent } from './pages/events/widget/event-widget.component';
import { EventOrdersDetailComponent } from './pages/events/event-orders/event-orders-detail/event-orders-detail.component';
import { EventOrdersCreateComponent } from './pages/events/event-orders/event-orders-create/event-orders-create.component';
import { EventWidgetCreateComponent } from './pages/events/widget/event-widget-create/event-widget-create.component';
import { TermsAndConditionComponent } from './pages/events/create-event/terms-and-condition/terms-and-condition.component';
import { DeletePopupComponent } from './elements/delete-popup/delete-popup.component';
import { TicketPreviewComponent } from './elements/ticket-preview/ticket-preview.component';
import { BadgePreviewComponent } from './elements/badge-preview/badge-preview.component';
import { EventTicketingAddonComponent } from './pages/events/ticketing/event-ticketing-addon/event-ticketing-addon.component';
import { EventTicketingCreateAddonComponent } from './pages/events/ticketing/event-ticketing-addon/event-ticketing-create-addon/event-ticketing-create-addon.component';
import { OrganisationNotificationsMenuComponent } from './elements/organisation-notifications-menu/organisation-notifications-menu.component';
import { AdminOrganizationsComponent } from './pages/adminOrganizations/adminOrganizations.component';
import { MaintenancePage } from './pages/maintenance/maintenance';

@NgModule({
  declarations: [
    AppComponent,

    // Pages
    MaintenancePage,
    InitPage,
    NotFoundPage,
    NotAuthorizedPage,
    LogInComponent,
    SeeMoreComponent,
    SignUpComponent,
    ButtonComponent,
    NotificationDisplayerComponent,
    PasswordForgottenComponent,
    DashboardComponent,
    AdminEventsComponent,
    AdminDashboardComponent,
    AdminOrdersComponent,
    AdminTicketsComponent,
    AdminRefundComponent,
    AdminOrganizationsComponent,
    HeaderComponent,
    SidebarComponent,
    EventsComponent,
    TeamComponent,
    GeneralSettingsComponent,
    RibibanSettingsComponent,
    PaymentAccountComponent,
    SeatingPlanComponent,
    SeatingPlanCreateComponent,
    PopupComponent,
    AccountingSettingsComponent,
    OrganisationsComponent,
    CreateOrganisationComponent,
    WysiwygComponent,
    MyAccountComponent,
    CreateEventComponent,
    FileInputComponent,
    AddMemberComponent,
    MainTableComponent,
    EventGeneralComponent,
    EventSettingsComponent,
    EventTicketingComponent,
    EventFormsComponent,
    EventCampaignComponent,
    EventCampaignCreateComponent,
    EventMiniSiteComponent,
    EventCreateMiniSiteComponent,
    EventExclusiveContentComponent,
    EventCreateExclusiveContentComponent,
    EventOrdersComponent,
    EventParticipantsComponent,
    WaitingListComponent,
    EventCounterComponent,
    EventAccessControlComponent,
    EventAccessControlOperatorComponent,
    EventTicketingEticketComponent,
    EventTicketingBadgeComponent,
    EventTicketingEticketUniqueComponent,
    EventTicketingSettingsComponent,
    EventTicketingPromoCodeComponent,
    EventTicketingInvitationComponent,
    PagerComponent,
    FiltersComponent,
    PlacesComponent,
    SearchInputComponent,
    EventGuichetComponent,
    EventTicketingSessionComponent,
    EventTicketingProductComponent,
    EventTicketingProductCreateComponent,
    EventTicketingProductCreateCategoryComponent,
    EventTicketingSecurityComponent,
    EventTicketingCreateCategoryComponent,
    EventTicketingCreateRateComponent,
    EventTicketingCreatePromoCodeComponent,
    EventTicketingGeneratePromoCodeComponent,
    EventTicketingSessionCreateComponent,
    EventTicketingSeatingPlanComponent,
    EventFormsCreateComponent,
    DateIntervalComponent,
    EventTicketingUpdatePromoCodeComponent,
    EventFormFieldsComponent,
    EventFormFieldsCreateComponent,
    EventTicketingInvitationCreateComponent,
    EventAccessControlCreateComponent,
    EventAccessControlOperatorCreateComponent,
    EventParticipantsDetailComponent,
    EventWidgetComponent,
    EventOrdersDetailComponent,
    EventOrdersCreateComponent,
    EventWidgetCreateComponent,
    TermsAndConditionComponent,
    DeletePopupComponent,
    TicketPreviewComponent,
    BadgePreviewComponent,
    EventTicketingAddonComponent,
    EventTicketingCreateAddonComponent,
    OrganisationNotificationsMenuComponent,
    DatePickerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    RecaptchaV3Module,
    BrowserAnimationsModule,
    ImageCropperModule,
    ColorPickerModule,
    NgxTippyModule,
    GooglePlaceModule,
    MatChipsModule,
    MatIconModule,
  ],
  providers: [
    FormBuilder,
    // HTTP Interceptor
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true,
    },
    // Query params translator
    QueryParams,

    //AuthGuard
    [AuthGuard, AdminGuard, NotAdminGuard],

    // Services
    StripeTpeService,
    AuthService,
    CommonService,
    AccountingService,
    AddonService,
    ControlListService,
    CountryService,
    CurrencyService,
    EventService,
    EventCategoryService,
    EventSessionService,
    EventSiteService,
    EventWidgetService,
    FormService,
    FormFieldService,
    OrderService,
    OrganisationService,
    MediaService,
    PromoCodeService,
    ResetService,
    TaxService,
    TicketService,
    TicketTypeService,
    TicketTypeCategoryService,
    UserService,
    ErrorHandlerService,
    FilesHandler,
    NotificationService,
    StorageService,
    ClickOutService,
    RoutesService,
    GrowthService,
    UpdateService,
    DateService,
    InvitationService,
    MemberService,
    WaitingListService,
    RefundService,
    InstagramService,
    OrganisationNotificationService,
    SocketService,
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.captchaKey },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    setInjector(injector);
  }
}

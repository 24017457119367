import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Requester } from '../requester';
import { IInstagram } from 'src/app/models/instagram.model';
import { APICreateResponse, APIResponse, GetParams } from 'src/app/models/type.definition';


@Injectable()
export class InstagramService extends Requester<IInstagram>{
  
  protected url = "events/sites";

  public getShortToken(code: string): Observable<IInstagram> {
    return this.httpClient.get<IInstagram>(this.getUrl(`get-short-token/${code}`));
  }

  public getLongToken(token: string): Observable<IInstagram> {
    return this.httpClient.get<IInstagram>(this.getUrl(`/get-long-token/${token}`));
  }

  public deleteToken(siteId: string) {
    return this.httpClient.get<IInstagram>(this.getUrl(`/delete-token/${siteId}`));
  }

  public hasToken(siteId: string) {
    return this.httpClient.get<IInstagram>(this.getUrl(`/has-token/${siteId}`));
  }
  public pushMedias(siteId: string, medias: any) {
    return this.httpClient.post<IInstagram>(this.getUrl(`/push-medias/${siteId}`), medias);
  }
  public getMedias(token) {
    return this.httpClient.get<IInstagram>(this.getUrl(`/get-medias/${token}`));
  }
}
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormFromEventComponent } from 'src/app/elements/form-component';
import { IEvent } from 'src/app/models/event.model';
import { ITicketType } from 'src/app/models/ticket.model';
import { GetParams } from 'src/app/models/type.definition';
import { AuthService, EventSessionService, ImportOrderService, TicketTypeService } from 'src/app/providers';
import { ISession } from 'src/app/models/session.model';
import { GetEvent } from '../../../getEvent';
import { IImportOrder, ImportProvider } from 'src/app/models/import-order';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-event-orders-import-create',
  templateUrl: './event-orders-import-create.component.html',
  styleUrls: ['./event-orders-import-create.component.scss'],
  providers: [GetEvent]
})
export class EventOrdersImportCreateComponent extends FormFromEventComponent<IImportOrder> implements OnInit {
  public currentProvider: ImportProvider | null = "FNAC"
  public event: IEvent;
  public dataName = 'Invitation'
  public displayEmail: boolean = false;
  public isAdmin: boolean;
  public ticketTypes: {name: string, tickets: ITicketType[]}[];
  public eventSessions: null | ISession[] = null;
  public sendEmail = false;
  public addPlacement = false
  public stock: number;
  public qtyOk: boolean = true;
  public spreadsheetOk: boolean = false;
  public table = null;
  public currentFile: File | null = null
  protected getEventParams: GetParams<IEvent> = {select: ['status', 'ticketing']};

  constructor(
    protected formProvider: ImportOrderService,
    protected getEvent: GetEvent,
    protected ticketTypeService: TicketTypeService,
    protected eventSessionService: EventSessionService,
    protected route: ActivatedRoute,
    private authService: AuthService,
    private notification: NotificationService,
  ) {
    super()
  }

  async ngOnInit(): Promise<void> {
    this.isAdmin = this.authService.isAdmin;
    await super.ngOnInit();
  }

  async onInit(): Promise<void> {

  }

  initForm(): void {
    this.mainForm = this.formBuilder.group({
      provider: new FormControl("FNAC"),
      file: new FormControl(null, [Validators.required]),
    });
  }

  public setEmailContentValue(emailContent: string): void {
    this.mainForm.patchValue({ emailContent })
  }

  changeProvider(provider: ImportProvider): void {
    this.currentProvider = provider;
  }

  async submitForm(): Promise<void> {
    const myFormData = new FormData();
    const form = this.mainForm.value;
    myFormData.append('provider', form.provider);
    myFormData.append('file', this.currentFile);
    myFormData.append('eventId', this.event._id);
    this.formProvider.create(myFormData).subscribe(result => {
      this.loadingState$.next();
      if (result.status === 'error') {
        this.notification.newNotification({
          message: result.message,
          state: 'error'
        });
        return;
      }
      if (result._id) {
        this.notification.newNotification({
          message: `Import créé avec succès`,
          state: 'success'
        });
        this.router.navigate([`evenements/${this.event._id}/commandes/import/resume/${result._id}`]);
      }
    }, error => {
      this.loadingState$.next();
      this.notification.newNotification({
        message: error.error.error || "Une erreur est survenue",
        state: 'error'
      });
    })


  }

  public changeFile(file: File): void {
    this.currentFile = file
    this.mainForm.patchValue({ file });
  }

  goBack(): void {
    this.router.navigate([`evenements/${this.event._id}/commandes/`]);
  }

  toggleEmail(): void {
    this.displayEmail = !this.displayEmail;
  }

}

import { AbstractControl, ValidationErrors } from '@angular/forms';

export class CustomValidators {

  static notEmptyString(control: AbstractControl): ValidationErrors | null {
    if (!(control.value as string).trim()) {
      return { isEmptyString: true }
    }
    return null;
  }

  static dateNotBeforeCustom(date: Date): (control: AbstractControl) => ValidationErrors | null {
    return function (control: AbstractControl): ValidationErrors | null {
      if (control.parent) {
        const minDateFormat = new Date(date).toISOString().split('T')[0]
        const minDateTime = new Date(minDateFormat).getTime();
        const value = new Date(control.value).getTime();
        if (value && minDateTime > value) {
          control.patchValue(minDateFormat)
        }
      }
      return null
    }
  }

  static dateNotBeforeToday(control: AbstractControl): ValidationErrors | null {
    if (control.parent) {
      const nowFormat = new Date().toISOString().split('T')[0]
      const nowTime = new Date(nowFormat).getTime();
      const value = new Date(control.value).getTime();
      if (value && nowTime > value) {
        control.patchValue(nowFormat)
      }
    }
    return null
  }

  static dateNotBeforeStart(control: AbstractControl): ValidationErrors | null {
    if (control.parent && control.parent.value.startDate) {
      const startValue = new Date(control.parent.value.startDate);
      const startFormat = startValue.toISOString().split('T')[0]
      const startTime = startValue.getTime();
      const value = new Date(control.value).getTime();
      if (startTime && value && startTime > value && control.parent) {
        control.patchValue(startFormat)
      }
    }
    return null
  }

  static dateNotAfterEnd(control: AbstractControl): ValidationErrors | null {
    if (control.parent && control.parent.value.endDate) {
      const endValue = new Date(control.parent.value.endDate);
      const endFormat = endValue.toISOString().split('T')[0]
      const endTime = endValue.getTime();
      const value = new Date(control.value).getTime();
      if (endTime && value && endTime < value) {
        control.patchValue(endFormat)
      }
    }
    return null
  }

  static numberIsPositive(control: AbstractControl): ValidationErrors | null {
    if (control.parent && control.value < 0) {
      control.patchValue(control.value * -1);
    }
    return null
  }

  static numberIsInteger(control: AbstractControl): ValidationErrors | null {
    if (control.parent && control.value && (control.value - Math.floor(control.value)) !== 0) {
      control.patchValue(Math.floor(control.value));
    }
    return null
  }

  static numberIsPositiveInteger(control: AbstractControl): ValidationErrors | null {
    CustomValidators.numberIsPositive(control);
    CustomValidators.numberIsInteger(control);
    return null;
  }

}

import { ChangeDetectorRef, Component, Input, OnInit, } from '@angular/core';
import { ListComponent } from 'src/app/elements/list-component';
import { IForm, IFormField } from 'src/app/models/form.model';
import { TableOptions } from 'src/app/models/type.definition';
import { FormFieldService } from 'src/app/providers';

@Component({
  selector: 'app-event-form-fields',
  templateUrl: './event-form-fields.component.html',
  styleUrls: ['./event-form-fields.component.scss']
})
export class EventFormFieldsComponent extends ListComponent<IFormField> implements OnInit {
  @Input() public fieldsToCreate: IFormField[];
  @Input() public fieldsToDelete: string[];
  @Input() public fieldsToUpdate: IFormField[];
  protected dataName = 'Champ';
  public createFieldSelected: boolean;
  public selectedDataToEdit: IFormField;
  public tableOptions: TableOptions[] = [
    {
      title: "Nom",
      class: "label",
      sort: false,
      key: 'label',
    },
    {
      title: "Type",
      class: "type",
      sort: false,
      key: 'typeTranslated',
    },
    this.DefaultToggle,
  ];
  @Input() public formId: string;

  constructor(protected provider: FormFieldService, protected cd: ChangeDetectorRef) {
    super();
  }

  protected setGetParams(): void {
    this.getParams.filter.formId = this.formId;
  }

  protected async getData(): Promise<void> {
    if (this.formId) {
      await super.getData();
    }
    else {
      this.data = [];
    }
  }

  protected formatElement(element: IFormField): IFormField {
    element.typeTranslated = this.getTypeTranslation(element.type)
    return element;
  }

  private getTypeTranslation(type: string): string {
    switch (type) {
      case 'textfield': return 'Texte';
      case 'textarea': return 'Texte long';
      case 'email': return "E-mail";
      case 'phone': return 'Téléphone';
      case 'date': return 'Date';
      case 'radio': return 'Choix unique';
      case 'checkbox': return 'Choix multiple';
      case 'select': return 'Sélection';
      case 'upload': return 'Fichier';
      case 'time': return 'Temps';
      case 'link': return 'Lien';
      case 'number': return 'Nombre';
      case 'score': return 'Score';
      case 'address': return 'Adresse';
    }
  }

  public createField(): void {
    this.selectedDataToEdit = null;
    setTimeout(() => this.createFieldSelected = true);
  }

  public addFields(fields: IFormField[]): void {
    for (const field of fields) {
      setTimeout( _ => this.addField(field), 1);
    }
  }

  public addField(field: IFormField): void {
    this.createFieldSelected = false;
    this.selectedDataToEdit = null;
    if (!field._id) {
      field._id = `new_field_${new Date().getTime()}`
      this.data.push(field);
      this.fieldsToCreate.push(field);
    }
    else {
      const index = this.data.findIndex(_ => _._id == field._id);
      this.data[index] = field;
      this.pushOrReplace(field, this[field._id.includes('new_field') ? 'fieldsToCreate' : 'fieldsToUpdate']);
    }
  }

  public pushOrReplace(field: IFormField, array: IFormField[]): void {
    const index = array.findIndex(_ => _._id == field._id);
    if (index == -1) {
      array.push(field);
    }
    else {
      array[index] = field;
    }
  }

  /** @override **/
  public deleteData(field: IFormField, ref = this): void {
    if (field._id.includes('new_field')) {
      const index = ref.fieldsToCreate.findIndex(_ => _._id == field._id);
      ref.fieldsToCreate.splice(index, 1)
    }
    else {
      ref.fieldsToDelete.push(field._id);
    }
    const index = ref.data.findIndex(_ => _._id == field._id);
    ref.data.splice(index, 1);
  }

  /** @override */
  public editData(field: IFormField, ref = this): void {
    ref.createFieldSelected = false;
    ref.selectedDataToEdit = null;
    setTimeout(() => {
      ref.selectedDataToEdit = field;
    });
  }


}

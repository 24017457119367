import { Component } from '@angular/core';
import { APIListResponse } from './../models/type.definition';

export const EmptyAPIList: APIListResponse<null> = {
  data: [],
  total: 0,
  pager: {
    page: 0,
    pages: 0
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IEvent } from 'src/app/models/event.model';
import { EventService, EventSessionService, TicketTypeService } from 'src/app/providers';
import { DateService } from 'src/app/services/date.service';
import { NotificationService } from 'src/app/services/notification.service';
import { GetEvent } from '../../getEvent';

import { Subscription } from 'rxjs';

declare var seatsio: any;

@Component({
  selector: 'app-event-ticketing-seating-plan',
  templateUrl: './event-ticketing-seating-plan.component.html',
  styleUrls: ['./event-ticketing-seating-plan.component.scss'],
  providers: [GetEvent]
})
export class EventTicketingSeatingPlanComponent implements OnInit {

  private subscription = new Subscription();

  public event: IEvent;
  public eventId: string;
  public chart: any;
  public reports: any;
  public eventType: "event" | "session" = "event";
  constructor(
  	private router: Router,
  	private route: ActivatedRoute,
  	private getEvent: GetEvent,
  	private eventService: EventService,
    private sessionService: EventSessionService,
    private dateService: DateService,
    private ticketService: TicketTypeService,
    private notificationService: NotificationService) { }

  async ngOnInit(): Promise<void> {

    this.route.params.subscribe(_params => {
    	if (_params.id) {
    		if (_params.sessionId) {
    			this.eventId = _params.sessionId;
				this.eventType = "session"
    		} else {
    			this.eventId = _params.id;
    		}
			console.log("====> ", this.eventId);

    		this.runData();
    	}
    });
  }

	runData() {
	    this.subscription.add(this.eventService.getSeatingPlan(null, true, this.eventId, this.eventType).subscribe(results => {
	      if (results.workspaceSecretKey) {
	      	let params = {
    				divId: "chartRenderer",
    				secretKey: results.workspaceSecretKey,
    				event: this.eventId,
    				mode: 'manageForSaleConfig',
            language: "fr"
	      	};
			this.chart = new seatsio.EventManager(params).render();
	      }
	    }));

      this.subscription.add(this.eventService.getEventSeatingPlanReports(this.eventId, this.eventType).subscribe(results => {
        if (results['available']) {
          this.reports = results;
        }
      }));
	}
}

/** Non exhaustive */
type ContentType =
  'application/json' |
  'multipart/form-data' |
  'application/form-data' |
  'application/x-www-form-urlencoded' | 
  'application/pdf' |
  'application/octet-stream'

/**
 * Namespace containing utilities to customize a Http request before being intercepted by the `HTTPInterceptor`
 */
export namespace HTTPTools {
  const defaultContentType: string = "application/json"
  let contentType: string = defaultContentType;

  /**
   * Set the value of the `Content-Type` for the next http request
   * @param _contentType
   */
  export function setNextContentType(_contentType: ContentType): void {
    contentType = _contentType;
  }

  /**
   * Return the value of the `Content-Type` and reinite to the default value
   * @param _contentType
   */
  export function getNextContentType(): string {
    const _contentType = contentType;
    contentType = defaultContentType;
    return _contentType;
  }

}

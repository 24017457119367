import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  public notification$: Subject<{message: string, state: string}> = new Subject();

  constructor() { }

  newNotification(notification: { message: string, state: 'success' | 'error'}){
    this.notification$.next(notification);
  }
}

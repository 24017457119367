import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { EventService, OrganisationService, UserService, AuthService, MediaService, OrganisationNotificationService } from 'src/app/providers';
import { Subscription, Observable } from 'rxjs';
import { APIError, RouteType } from 'src/app/models/type.definition';
import { Router, NavigationEnd } from '@angular/router';
import User from 'src/app/models/user.model';
import { ClickOutService } from 'src/app/services/click-out.service';
import { RoutesService } from 'src/app/services/routes.service';
// Animations
import { menuAnimation } from 'src/app/animations/animations';
import { IOrganisation } from 'src/app/models/organisation.model';
import { FilesHandler } from 'src/app/services/file-handler.service';
import { SocketService } from 'src/app/services/socket.service';
import { OrganisationNotificationsMenuComponent } from '../organisation-notifications-menu/organisation-notifications-menu.component';


const DefaultUser = "assets/images/default-user.png"

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    menuAnimation
  ]
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(OrganisationNotificationsMenuComponent) notificationMenu: OrganisationNotificationsMenuComponent;
  private _subscription = new Subscription();
  private _mainRoutes: RouteType[];
  private _eventRoutes: RouteType[];
  private _eventName: string;
  public avatar = DefaultUser;
  public breadcrumb: string[];
  public me: User;
  public organisations: IOrganisation[];
  public currentOrganisation: IOrganisation;
  public accountMenuIsDisplay: boolean;
  public notificationOpened = false;
  public notificationsCount: number;
  public isAdmin: boolean;


  constructor(
    private eventService: EventService,
    private router: Router,
    private organisationService: OrganisationService,
    private userService: UserService,
    private mediaService: MediaService,
    private fileHandler: FilesHandler,
    private authService: AuthService,
    private clickOutService: ClickOutService,
    private routesService: RoutesService,
    private notificationService: OrganisationNotificationService,
    private socket: SocketService
  ) { }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ngOnInit(): void {
    this._mainRoutes = this.routesService.mainRoutes;
    this._eventRoutes = this.routesService.eventRoutes;
    this.isAdmin = this.authService.isAdmin;
    this.subscribeTo(this.organisationService.data$, data => {
      this.organisations = data;
    });
    this.subscribeTo(this.organisationService.selected$, data => {
      this.currentOrganisation = data;
      this.notificationMenu?.getNotificationsCount();
    });
    this.subscribeTo(this.router.events, event => {
      if (event instanceof NavigationEnd) {
        this.prepareBreadcrumbUpdate();
      }
    });
    this.subscribeTo(this.eventService.selected$, event => {
      this._eventName = event?.name;
      this.prepareBreadcrumbUpdate()
    });
    this.subscribeTo(this.clickOutService.closeToggle$, () => {
      this.accountMenuIsDisplay = false;
    });
    this.subscribeTo(this.userService.me$, async value => {
      if (value) {
        this.me = value;
        if (this.me.avatarId) {
          this.me.avatar = await this.mediaService.getStream(this.me.avatarId).toPromise();
          this.avatar = await this.fileHandler.getBase64(this.me.avatar);
        }
        else {
          this.avatar = DefaultUser;
        }
      }
    });
    this.prepareBreadcrumbUpdate();
  }

  public ngAfterViewInit(): void {

  }

  public subscribeTo<T>(
    observable: Observable<T>,
    callback: (value: T) => void | Promise<void>,
    onError = (error: APIError) => console.error(error)): void {
    this._subscription.add(observable.subscribe(callback, onError));
  }



  prepareBreadcrumbUpdate() {
    if (this.router.url === "/mon-compte") {
      this.breadcrumb = ["Mon compte"];
    }
    else {
      this.breadcrumb = this._eventName ? [this._eventName] : [];
      if (this.organisationService.adminConnected$.value) {
        this.breadcrumb.unshift(...['Admin', this.organisationService.selected$!.value.name])
      }
      else if (this.authService.isAdmin && this._eventName) {
        this.breadcrumb.unshift('Admin');
      }
      this.updateBreadcrumb(this._eventName ? this._eventRoutes : this._mainRoutes);
    }
  }

  updateBreadcrumb(routeArray: RouteType[]): void {
    for (const route of routeArray) {
      if (route.active) {
        if (route.route !== "") {
          this.breadcrumb.push(route.title);
        }
        this.updateBreadcrumb(route.submenu);
        break;
      }
    }
  }

  displayAccountMenu(value: boolean) {
    this.accountMenuIsDisplay = value;
  }

  goToMyAccountPage() {
    this.accountMenuIsDisplay = false;
    this.router.navigate(['mon-compte']);
  }

  async changeOrganisation(id: string) {
    if (id) {
      const organisationToken = await this.authService.authOrganisation(id).toPromise();
      this.organisationService.goToOrganisation(id, organisationToken);
    }
    else {
      this.organisationService.reset();
    }
  }

  logOut() {
    this.userService.me$.next(null);
    this.authService.logOut();
    this.organisationService.clear();
    this.router.navigate(['']);
  }


}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

// Providers
import { EventService } from 'src/app/providers';

declare var seatsio: any;

@Component({
  selector: 'app-seating-plan-create',
  templateUrl: './seating-plan-create.component.html'
})
export class SeatingPlanCreateComponent implements OnInit {

	private subscription = new Subscription();

	chartId: string;
	chart: any;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private eventService: EventService,
	) { }

	ngOnInit(): void {
		console.log('SeatingPlanCreateComponent');

        this.route.params.subscribe(_params => {
        	if (_params.chartId) {
        		this.chartId = _params.chartId;
        	}
        	this.runData(this.chartId);
        });
		console.log('SeatingPlanCreateComponent END');
	}

	runData(_chartId) {
	    this.subscription.add(this.eventService.getSeatingPlan(null, true).subscribe(results => {
	      if (results.workspaceSecretKey) {
	      	let params = {
				divId: "chartDesigner",
				secretKey: results.workspaceSecretKey,
				language: "fr"
	      	};
	      	if (_chartId) {
	      		params['chartKey'] = _chartId;
	      	}

			this.chart = new seatsio.SeatingChartDesigner(params).render();
	      } else {
	      	this.router.navigate(['parametres/seating-plan']);
	      }
	    }));
		console.log('runData END');
	}
}

import { Component, HostListener, OnInit } from '@angular/core';
import { moreAnimation } from 'src/app/animations/animations';
import { FormFromEventComponent } from 'src/app/elements/form-component';
import { IEvent } from 'src/app/models/event.model';
import { InvoiceData, IOrder, translatePaymentMethod, translateStatus } from 'src/app/models/order.model';
import { ITicket } from 'src/app/models/ticket.model';
import { GetParams } from 'src/app/models/type.definition';
import { EventService, OrderService, RefundService, TicketService } from 'src/app/providers';
import { DateService } from 'src/app/services/date.service';
import { GetEvent } from '../../getEvent';

@Component({
  selector: 'app-event-orders-detail',
  templateUrl: './event-orders-detail.component.html',
  styleUrls: ['./event-orders-detail.component.scss'],
  providers: [GetEvent],
  animations: [moreAnimation]
})
export class EventOrdersDetailComponent extends FormFromEventComponent<IOrder> implements OnInit {
  public dataName = "Commande";
  public getEventParams: GetParams<IEvent> = { select: ['ticketing', 'status', 'organisationId'] };
  public editEmail: boolean = false;
  public editName: boolean = false;
  public editAddress: boolean = false;
  public editCity: boolean = false;
  public newEmail: string;
  public fullLoader: boolean;
  public newFirstName: string;
  public newLastName: string;
  public newAddress: string;
  public newZipCode: string;
  public newCity: string;
  public ticketNumber = 0;
  public cancelPopupOpen = false;
  public refundPopupOpen = false;
  public actionToggleOpen = false;
  public products: string;
  public actionToggle = {
    ref: this,
    links: [
      {
        title: 'Rembourser',
        if: () => this.data.totalPrice.sellingPrice,
        method: this.refund
      },
      {
        title: 'Télécharger',
        method: this.download
      },
      // {
      //   title: 'Voir le scan',
      //   method: this.viewScan
      // },
      {
        title: 'Annuler la commande',
        class: "red",
        method: this.cancelOrder
      }
    ]
  }

  constructor(
    protected formProvider: OrderService,
    protected getEvent: GetEvent,
    protected dateService: DateService,
    protected ticketService: TicketService,
    private refundService: RefundService
  ) {
    super()
  }

  async onInit(): Promise<void> {
    await this.getDataToEdit('orderId', { template: 'full', populate: [
      'totalPrice.promoCodeId', 'tickets', 'payments'] });
    const paymentsMethods = this.data.payments.map(payment => translatePaymentMethod(payment.method));
    this.data.translatedStatus = translateStatus(this.data.status);
    this.data.paymentMethod = paymentsMethods.join(", ")  // translatePaymentMethod(this.data.payment.method);
    this.data.date = this.getOrderDate(new Date(this.data.createdAt));
    this.data.totalPrice.sellingPrice /= 100;
    this.data.staggered = 'Non';
    this.newLastName = this.data.invoiceData.lastName;
    this.newFirstName = this.data.invoiceData.firstName;
    this.newEmail = this.data.invoiceData.email;
    this.newAddress = this.data.invoiceData.address;
    this.newZipCode = this.data.invoiceData.zipCode;
    this.newCity = this.data.invoiceData.city;
    const option: GetParams<ITicket> = {
      select: ['name'],
      filter: {
        orderId: this.data._id,
        isProduct: this.data.isProduct
      }
    }

    this.ticketNumber = this.data.tickets.length || 0;
    if (this.data.isProduct) {
      this.setProduct(this.data.tickets);
    }
  }

  initForm(): void {

  }

  submitForm(): void {

  }

  public getOrderDate(date: Date): string {
    return `à ${this.dateService.getTimeString(date)} le ${this.dateService.getDateString(date)}`;
  }

  public saveNewName(): void {
    this.editName = false;
    this.updateInvoiceData({ firstName: this.newFirstName, lastName: this.newLastName });
  }

  public saveNewEmail(): void {
    this.editEmail = false;
    this.updateInvoiceData({ email: this.newEmail });
  }

  public saveNewAddress(): void {
    this.editAddress = false;
    this.updateInvoiceData({ address: this.newAddress });
  }

  public saveNewCity(): void {
    this.editCity = false;
    this.updateInvoiceData({ zipCode: this.newZipCode, city: this.newCity });
  }

  public async updateInvoiceData(invoiceData: InvoiceData): Promise<void> {
    const form: IOrder = { invoiceData: this.data.invoiceData }
    for (const key in invoiceData) {
      form.invoiceData[key] = invoiceData[key]
    }
    await this.update(form).toPromise();
  }

  public download(ref = this): void {
    ref.fullLoader = true;
    ref.actionToggleOpen = false;
    ref.formProvider.streamOrder(ref.data._id, ref.data.token).subscribe(result => {
      ref.fullLoader = false;
      ref.filesHandler.downloadFile(result, 'Facture commande n°' + ref.data.orderNumber)
    }, err => {
      ref.notificationService.newNotification({ state: 'error', message: ref.errorHandler.getError(err) });
      ref.fullLoader = false;
    });
  }

  public refund(ref = this): void {
    ref.refundPopupOpen = true;
    ref.actionToggleOpen = false;
  }

  public async askRefundOk(): Promise<void> {
    await this.abandonOrder();
    await this.refundService.create({
      orderId: this.data,
      eventId: this.event,
      status: 'todo'
    }).toPromise();
    this.refundPopupOpen = false;
  }

  public cancelOrder(ref = this): void {
    ref.cancelPopupOpen = true;
    ref.actionToggleOpen = false;
  }

  public async cancelOrderOk(): Promise<void> {
    await this.abandonOrder();
    this.cancelPopupOpen = false;
  }

  public async abandonOrder(): Promise<void> {
    const result = await this.formProvider.update(this.data._id, { status: 'canceled' }).toPromise()
    if (result) {
      this.notificationService.newNotification({
        message: 'La commande a bien été annulée',
        state: 'success'
      });
      this.goBack();
    }
    else {
      this.notificationService.newNotification({
        message: "Une erreur est survenue, l'annulation de la commande n'a pas pu aboutir",
        state: 'error'
      })
    }
  }

  goToTicketPage(): void {
    this.router.navigate([`evenements/${this.event._id}/participants/tous`], { queryParams: { order: this.data.orderNumber } });
  }

  public setProduct(products: ITicket[]) {
    const productCount = products.reduce((a, b) => {
      a[b.name] ? a[b.name]++ : a[b.name] = 1;
      return a
    }, {})
    this.products = Object.keys(productCount).map(key => `${key} x${productCount[key]}`).join(', ')
  }

  @HostListener('document:click') clickDoc(): void {
    this.actionToggleOpen = false;
  }
}

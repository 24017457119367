<div id="badge_page">
    <div id="line_h"></div>
    <div id="line_v"></div>
    <div *ngFor="let cpt of [0,1]" class="badge_section top_section">
        <div class="inner">

            <div *ngIf="base64Image && !isVideo" id="banner_img" [style]="'background-image: url('+base64Image+')'"></div>
            <div *ngIf="!base64Image && !isVideo" id="banner_empty">
                    <div class="inside">
                        <svg width="35" height="30" viewBox="0 0 40 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.4545 3.15848H25.4101C26.1396 3.15848 26.7309 3.88412 26.7309 4.77825C26.7324 5.06325 26.6697 5.34493 26.5474 5.60246H9.31711C9.19476 5.34496 9.13208 5.06325 9.1337 4.77825C9.1337 3.88412 9.72508 3.15848 10.4545 3.15848Z" fill="#141721"/>
                            <path d="M32.4715 14.0834V27.8434H34.4216C37.1377 27.8434 37.8374 27.7686 38.5902 27.3951C39.4135 26.9907 39.8316 26.3051 39.9549 25.1406C40.0166 24.5692 40.0122 12.4353 39.9506 11.9959C39.8845 11.5125 39.7657 11.2268 39.5105 10.9499C39.2654 10.6909 38.9529 10.5052 38.608 10.4137C38.4627 10.3873 38.3967 10.3084 38.5156 10.3084C38.6287 10.2765 38.738 10.2322 38.8414 10.1764C39.3344 9.93029 39.7306 9.38973 39.9023 8.73939C39.9684 8.47128 39.9772 8.15925 39.9772 5.67182C39.9772 2.96463 39.9727 2.89433 39.8759 2.48562C39.5897 1.25505 38.947 0.696962 37.5207 0.433282C37.239 0.380509 36.6491 0.362982 34.818 0.345359L32.4715 0.32341V14.0834ZM36.5305 2.0769C36.6576 2.11779 36.775 2.18408 36.8756 2.27173C36.9762 2.35938 37.0579 2.46656 37.1157 2.58674L37.2258 2.81083V5.43011C37.2258 8.39655 37.2346 8.32625 36.8913 8.66457C36.6404 8.91958 36.385 8.98987 35.7423 8.98987H35.2449V2.00217H35.7951C36.2045 2.00217 36.3889 2.0197 36.5305 2.0769ZM36.526 10.8927C36.77 11.006 36.973 11.1917 37.1072 11.4245L37.2041 11.6046L37.2171 18.4957C37.2216 23.2728 37.2126 25.4439 37.1819 25.5668C37.1158 25.8041 36.8739 26.0546 36.6008 26.1734C36.416 26.2524 36.2576 26.2789 35.8084 26.2964L35.245 26.3184V10.7784L35.8084 10.8003C36.156 10.818 36.4245 10.8487 36.5258 10.8927H36.526Z" fill="#141721"/>
                            <path d="M0 17.7935H2.60783V22.1037H0V17.7935Z" fill="#141721"/>
                            <path d="M0 17.7935H2.60783V22.1037H0V17.7935Z" fill="#141721"/>
                            <path d="M2.71636 0.332164C1.16678 0.573886 0.400873 1.29462 0.105985 2.78887C0.0443452 3.09656 0.0354734 4.13809 0.0223526 11.4729L0.00469971 19.8009L0.480163 20.2712L0.955534 20.7414H2.60631L2.61518 11.8948C2.62396 4.73574 2.63717 3.02616 2.68559 2.92081C2.87485 2.48128 3.18305 2.27056 3.61887 2.27056C4.1118 2.27056 4.48598 2.62207 4.58283 3.18034C4.61804 3.37363 4.63134 6.0369 4.63134 12.0973V20.7417H6.3261L6.77513 20.2934L7.22823 19.8411V11.557C7.22823 6.11182 7.21067 3.17619 7.18414 2.98724C7.0654 2.25774 6.7924 1.70833 6.30382 1.22055C6.10089 1.00751 5.86072 0.833232 5.59511 0.706288C4.96116 0.403123 4.64428 0.34149 3.68466 0.32839C3.22694 0.318967 2.79121 0.323776 2.71636 0.332164Z" fill="#141721"/>
                            <path d="M0.475737 21.4796L0.000366211 21.95L0.0223631 23.7079C0.044357 25.5844 0.066344 25.7865 0.28666 26.3755C0.815269 27.7686 2.25884 28.4543 4.26613 28.2608C5.26983 28.1641 5.83762 27.9404 6.33064 27.4477C6.65722 27.1351 6.89039 26.738 7.00415 26.3008C7.14942 25.8042 7.17575 25.4262 7.20661 23.6244L7.23748 21.9148L6.77967 21.4577L6.32657 21.0054H4.63616L4.62295 23.2336L4.60983 25.4661L4.50855 25.6727C4.12153 26.4548 3.11311 26.4813 2.73459 25.7209L2.62896 25.51L2.61565 23.2554L2.60254 21.0053H0.955545L0.475737 21.4796Z" fill="#141721"/>
                            <path d="M4.62787 17.7935H7.23495V22.1037H4.62787V17.7935Z" fill="#141721"/>
                            <path d="M1.25545 31.9915H6.43771C7.13084 31.9915 7.69315 31.3883 7.69315 30.6447C7.69355 30.4052 7.63318 30.1694 7.51767 29.9595H0.174262C0.0590729 30.1695 -0.000879675 30.4053 9.75389e-06 30.6448C9.75389e-06 31.3885 0.561845 31.9915 1.25545 31.9915Z" fill="#141721"/>
                            <path d="M33.5623 31.9915H38.7445C39.4377 31.9915 40 31.3883 40 30.6447C40.0004 30.4052 39.94 30.1694 39.8245 29.9595H32.4811C32.3659 30.1695 32.3059 30.4053 32.3068 30.6448C32.3068 31.3885 32.8687 31.9915 33.5623 31.9915Z" fill="#141721"/>
                            <path d="M28.6167 9.99804L28.2446 9.47284C28.0205 9.24247 27.7523 9.05949 27.4559 8.93482C27.1595 8.81015 26.841 8.74634 26.5193 8.74721C25.9076 8.74721 25.14 8.87179 24.8945 9.99841C24.8063 10.4366 24.8041 11.1678 24.819 12.1812V20.0116C24.819 20.0116 24.7624 29.814 24.819 30.0872C25.0202 31.0192 25.5059 31.4764 26.5924 31.4764C27.4777 31.4764 28.0292 31.0916 28.4476 30.505L28.7025 30.1642L28.7376 31.9673H30.3462L30.4275 0.470505H28.6167V9.99804ZM28.175 10.3355C28.5314 10.6619 28.5603 10.6909 28.6167 12.0889V29.3997C28.504 29.6165 28.3136 30.0997 27.6161 30.1637C26.9186 30.2277 26.6297 29.4936 26.6223 29.1759V20.0112C26.6354 11.3577 26.6223 10.9015 26.6223 10.9015C26.6737 10.1519 27.9195 10.1017 28.1751 10.3354L28.175 10.3355Z" fill="#141721"/>
                            <path d="M10.9797 7.77326C8.97377 8.12863 9.15048 10.3678 9.15048 10.3678V18.9664H10.9774C11.0579 15.7686 10.9797 9.74492 10.9797 9.74492C10.9797 9.74492 11.2629 9.17741 11.6328 9.06131C12.0026 8.94521 12.9787 8.93032 12.9288 10.3677C12.9288 10.3677 12.9354 9.36071 12.9354 15.8688V21.8145L11.6477 21.8628C9.93348 21.9351 9.61717 22.0787 9.31133 22.9242C9.23855 23.1254 9.18559 23.5494 9.14784 24.121C9.14784 24.121 9.09573 28.7701 9.24167 29.485C9.43319 30.4231 9.99399 30.8703 11 30.8703C11.8213 30.8623 12.3318 30.5811 12.7825 30.0186L13.0964 29.6168V32.1313L13.9253 32.1072L14.7466 32.0831C14.7781 15.2426 14.7707 10.8573 14.7707 10.8573C14.7411 9.08817 14.6421 9.12002 14.2783 8.59633C13.8812 8.11176 12.9857 7.41788 10.9797 7.77326ZM12.9274 25.8723C12.9274 27.7846 12.9335 28.7217 12.8691 28.9145C12.668 29.509 11.6326 29.7909 11.1958 29.1564C10.9911 28.8587 11.0016 27.9534 11 27.0114C10.9984 26.008 11 24.9751 11 24.9751C10.9558 23.6831 11.0928 23.4598 11.4872 23.2669C11.7931 23.1143 11.9053 23.1404 12.9354 23.1244L12.9274 25.8723Z" fill="#141721"/>
                            <path d="M16.9733 7.87634V35.6768H18.7698C18.8132 27.23 18.7999 12.7004 18.7999 12.7004C18.786 11.1366 18.8018 10.4314 19.1523 10.1089C19.4733 9.81201 20.118 9.84499 20.2982 9.95808C20.4784 10.0712 20.8685 10.0828 20.858 11.6558V32.061H22.4821V10.369C22.4821 10.369 22.5839 9.61297 22.2272 9.1365C21.8705 8.66003 21.698 8.52716 21.0541 8.46288C20.4183 8.39861 19.6634 8.60349 19.2606 9.00523C18.8018 9.47124 18.7048 9.41045 18.7966 7.87634H16.9733Z" fill="#141721"/>
                        </svg>
                        <p>Toute l'équipe OandB vous souhaite un bon événement</p>
                    </div>
            </div>

            <div id="center_infos">
                <div class="name">
                    Alan <span>Smithee</span>
                </div>
                <div class="form_field" *ngIf="event.ticketing.badgeVisual.formField">
                    [[{{ event.ticketing.badgeVisual.formField }}]]
                </div>
                <div class="qrcode">
                    <img src="assets/images/ticket/qrcode.png" />
                </div>
            </div>
            <div id="bottom_infos" *ngIf="event.ticketing.badgeVisual.footerText">
                <div class="bottom_infos_content">
                    <p class="bottom_txt">{{ event.ticketing.badgeVisual.footerText }}</p>
                </div>
            </div>
        </div>
    </div>
    <div id="bottom_left" class="badge_section">
        <div class="inner">
            <div class="custom_text" *ngIf="getTicketCustomText()">
                <div class="custom_text_inner">
                    <p [innerHTML]="getTicketCustomText()"></p>
                </div>
            </div>
            <div *ngIf="!getTicketCustomText()" class="empty_custom_text"></div>
            <div class="oandb">
                <div class="logo">
                    <svg width="30" height="25" viewBox="0 0 50 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.068 4.35214H31.7626C32.6745 4.35214 33.4136 5.25919 33.4136 6.37686C33.4154 6.73311 33.337 7.08521 33.1842 7.40712H11.6464C11.4934 7.08524 11.4151 6.73311 11.4171 6.37686C11.4171 5.25919 12.1563 4.35214 13.068 4.35214Z" fill="#1AC694"/>
                        <path d="M40.5894 18.0083V35.2082H43.027C46.4221 35.2082 47.2968 35.1148 48.2378 34.6479C49.2668 34.1424 49.7895 33.2854 49.9436 31.8298C50.0208 31.1156 50.0152 15.9482 49.9382 15.3989C49.8556 14.7946 49.7071 14.4376 49.3881 14.0914C49.0817 13.7677 48.6911 13.5356 48.26 13.4212C48.0784 13.3882 47.9959 13.2895 48.1444 13.2895C48.2859 13.2497 48.4225 13.1943 48.5518 13.1246C49.1679 12.8169 49.6632 12.1412 49.8779 11.3283C49.9605 10.9931 49.9715 10.6031 49.9715 7.49382C49.9715 4.10983 49.9659 4.02196 49.8448 3.51107C49.4871 1.97285 48.6838 1.27524 46.9009 0.945644C46.5487 0.879677 45.8114 0.857769 43.5225 0.83574L40.5894 0.808304V18.0083ZM45.6631 3.00017C45.8219 3.05128 45.9687 3.13414 46.0945 3.2437C46.2202 3.35327 46.3224 3.48724 46.3946 3.63746L46.5322 3.91758V7.19168C46.5322 10.8997 46.5433 10.8119 46.1141 11.2348C45.8005 11.5535 45.4812 11.6414 44.6778 11.6414H44.0561V2.90676H44.7439C45.2556 2.90676 45.4861 2.92867 45.6631 3.00017ZM45.6575 14.02C45.9625 14.1615 46.2162 14.3937 46.384 14.6847L46.505 14.9098L46.5213 23.5237C46.527 29.495 46.5158 32.2089 46.4773 32.3626C46.3947 32.6592 46.0923 32.9723 45.751 33.1207C45.5199 33.2196 45.3219 33.2527 44.7605 33.2746L44.0562 33.302V13.8771L44.7605 13.9044C45.195 13.9266 45.5307 13.965 45.6573 14.02H45.6575Z" fill="#141721"/>
                        <path d="M0 22.646H3.25979V28.0337H0V22.646Z" fill="#141721"/>
                        <path d="M0 22.646H3.25979V28.0337H0V22.646Z" fill="#141721"/>
                        <path d="M3.3955 0.819246C1.45852 1.1214 0.501137 2.02232 0.132527 3.89013C0.0554772 4.27474 0.0443875 5.57665 0.0279865 14.7451L0.00592041 25.1552L0.600249 25.743L1.19446 26.3308H3.25793L3.26902 15.2725C3.27999 6.32372 3.29651 4.18675 3.35704 4.05505C3.59361 3.50564 3.97886 3.24224 4.52363 3.24224C5.13979 3.24224 5.60752 3.68163 5.72858 4.37947C5.77259 4.62107 5.78922 7.95017 5.78922 15.5257V26.3312H7.90768L8.46896 25.7708L9.03533 25.2054V14.8503C9.03533 8.04382 9.01338 4.37428 8.98023 4.13809C8.83179 3.22621 8.49055 2.53946 7.87982 1.92973C7.62616 1.66343 7.32595 1.44558 6.99394 1.2869C6.20149 0.907944 5.80539 0.830903 4.60587 0.814529C4.03373 0.802749 3.48906 0.808762 3.3955 0.819246Z" fill="#141721"/>
                        <path d="M0.594702 27.2535L0.000488281 27.8415L0.0279844 30.0389C0.0554768 32.3845 0.0829605 32.6372 0.358356 33.3734C1.01912 35.1148 2.82358 35.9719 5.3327 35.7301C6.58732 35.6092 7.29706 35.3296 7.91333 34.7137C8.32156 34.3229 8.61302 33.8265 8.75521 33.28C8.93681 32.6593 8.96971 32.1868 9.0083 29.9345L9.04688 27.7975L8.47462 27.2262L7.90825 26.6608H5.79524L5.77871 29.446L5.76232 32.2367L5.63572 32.4949C5.15195 33.4726 3.89142 33.5057 3.41827 32.5552L3.28623 32.2915L3.26959 29.4733L3.2532 26.6607H1.19446L0.594702 27.2535Z" fill="#141721"/>
                        <path d="M5.78485 22.646H9.0437V28.0337H5.78485V22.646Z" fill="#141721"/>
                        <path d="M1.56932 40.3934H8.04713C8.91355 40.3934 9.61644 39.6395 9.61644 38.7099C9.61694 38.4105 9.54148 38.1158 9.39709 37.8534H0.217827C0.0738411 38.116 -0.00109959 38.4107 1.21924e-05 38.71C1.21924e-05 39.6397 0.702306 40.3934 1.56932 40.3934Z" fill="#FFC941"/>
                        <path d="M41.9529 40.3934H48.4307C49.2971 40.3934 50 39.6395 50 38.7099C50.0005 38.4105 49.925 38.1158 49.7806 37.8534H40.6014C40.4574 38.116 40.3824 38.4107 40.3836 38.71C40.3836 39.6397 41.0859 40.3934 41.9529 40.3934Z" fill="#FFC941"/>
                        <path d="M35.7709 12.9016L35.3057 12.2451C35.0257 11.9571 34.6904 11.7284 34.3199 11.5726C33.9494 11.4167 33.5512 11.337 33.1492 11.338C32.3846 11.338 31.4251 11.4938 31.1182 12.9021C31.008 13.4498 31.0051 14.3638 31.0238 15.6305V25.4185C31.0238 25.4185 30.953 37.6715 31.0238 38.013C31.2753 39.178 31.8824 39.7495 33.2405 39.7495C34.3472 39.7495 35.0365 39.2685 35.5595 38.5353L35.8781 38.1093L35.922 40.3632H37.9327L38.0344 0.992172H35.7709V12.9016ZM35.2188 13.3234C35.6643 13.7314 35.7004 13.7676 35.7709 15.5152V37.1537C35.63 37.4246 35.392 38.0287 34.5201 38.1087C33.6483 38.1886 33.2871 37.271 33.2779 36.8739V25.418C33.2943 14.6012 33.2779 14.0309 33.2779 14.0309C33.3422 13.094 34.8994 13.0312 35.2189 13.3233L35.2188 13.3234Z" fill="#141721"/>
                        <path d="M13.7247 10.1206C11.2172 10.5648 11.4381 13.3638 11.4381 13.3638V24.112H13.7218C13.8224 20.1147 13.7247 12.5852 13.7247 12.5852C13.7247 12.5852 14.0787 11.8758 14.541 11.7307C15.0033 11.5855 16.2235 11.5669 16.161 13.3637C16.161 13.3637 16.1693 12.1049 16.1693 20.2401V27.6722L14.5596 27.7325C12.4169 27.8229 12.0215 28.0024 11.6392 29.0593C11.5482 29.3108 11.482 29.8408 11.4348 30.5553C11.4348 30.5553 11.3697 36.3667 11.5521 37.2603C11.7915 38.4328 12.4925 38.9919 13.7501 38.9919C14.7766 38.9819 15.4147 38.6304 15.9781 37.9273L16.3705 37.4251V40.5682L17.4067 40.538L18.4332 40.5079C18.4726 19.4573 18.4634 13.9757 18.4634 13.9757C18.4264 11.7642 18.3026 11.8041 17.8479 11.1494C17.3516 10.5437 16.2322 9.67638 13.7247 10.1206ZM16.1593 32.7444C16.1593 35.1347 16.1669 36.3061 16.0865 36.5471C15.835 37.2903 14.5408 37.6427 13.9948 36.8495C13.7389 36.4774 13.752 35.3458 13.7501 34.1683C13.7481 32.914 13.7501 31.6229 13.7501 31.6229C13.6947 30.0079 13.8661 29.7287 14.359 29.4877C14.7413 29.2969 14.8816 29.3295 16.1693 29.3095L16.1593 32.7444Z" fill="#141721"/>
                        <path d="M21.2167 10.2495V45H23.4623C23.5165 34.4416 23.4998 16.2796 23.4998 16.2796C23.4825 14.3248 23.5023 13.4433 23.9404 13.0401C24.3416 12.6691 25.1475 12.7103 25.3727 12.8516C25.598 12.993 26.0857 13.0075 26.0726 14.9738V40.4803H28.1026V13.3652C28.1026 13.3652 28.2299 12.4203 27.784 11.8247C27.3381 11.2291 27.1225 11.063 26.3176 10.9827C25.5228 10.9023 24.5792 11.1584 24.0758 11.6606C23.5023 12.2431 23.381 12.1671 23.4958 10.2495H21.2167Z" fill="#141721"/>
                    </svg>
                </div>
                <div class="promo">
                    <span class="title">Vous organisez des événements ?</span>
                    <span class="description">
                        Créez simplement sur <a href="https://www.oandb.fr">www.oandb.fr</a> votre module d'inscription et de billetterie en ligne et vendez en un clic !
                    </span>
                </div>
                <div class="clear"></div>
            </div>
        </div>
    </div>
    <div id="bottom_right" class="badge_section">
        <div class="inner">

            <div class="ticket_infos">
                <div class="header-left">
                    <h1>{{event.name}}</h1>
                    <div class="location"  *ngIf="event.place.length > 0">
                        {{event.place[0].name}}
                        {{event.place[0].address}}
                        <ng-container *ngIf="event.place[0].address2">
                            {{event.place[0].address2}}
                        </ng-container>
                        {{event.place[0].zipcode}} {{event.place[0].city}}
                        <ng-container *ngIf="event.place[0].country">({{event.place[0].country}})</ng-container>
                    </div>
                </div>
                <div id="date">
                    <div class="start">
                        <h3>{{ event.dates.startDate| date:'dd MMM yyyy'}}</h3>
                        <p>{{ event.dates.startDate| date:'HH:mm'}}</p>
                    </div>
                    <div class="sep">
                        <svg width="8" height="21" viewBox="0 0 8 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.25 20L6.75 10.5L1.25 1" stroke="#010025"/>
                        </svg>
                    </div>
                    <div class="start">
                        <h3>{{ event.dates.endDate| date:'dd MMM yyyy'}}</h3>
                        <p>{{ event.dates.endDate| date:'HH:mm'}}</p>
                    </div>
                </div>
                <div id="participant">
                    <div class="infos">
                        <div class="name">
                            Alan Smithee
                        </div>
                        <div class="ticket_number">
                            Billet n° <span>968768658799</span>
                        </div>
                        <div class="order_number">
                            Commande n°<span>20231021000021</span>
                        </div>
                    </div>
                </div>
                <div id="ticket_type">
                    <div class="inner">
                        <div class="tt_name">
                            <div class="tt_label">
                                <ng-container *ngIf="event.ticketTypeCategories && event.ticketTypeCategories.length > 0 && event.ticketTypeCategories[0].name !== 'Sans Catégorie'">
                                    {{ event.ticketTypeCategories[0].name}}
                                    <ng-container *ngIf="event.ticketTypeCategories[0].ticketTypes && event.ticketTypeCategories[0].ticketTypes.length > 0">-</ng-container>
                                </ng-container>
                                <ng-container *ngIf="!event.ticketTypeCategories|| event.ticketTypeCategories.length == 0">
                                    ZONE 1 & 8  - Tribunes  étudiantes réservées
                                </ng-container>
                                <ng-container *ngIf="event.ticketTypeCategories &&   event.ticketTypeCategories.length > 0 && event.ticketTypeCategories[0].ticketTypes && event.ticketTypeCategories[0].ticketTypes.length > 0">
                                    {{ event.ticketTypeCategories[0].ticketTypes[0].name}}
                                </ng-container>
                            </div>
                            <div class="tt_price">
                                <ng-container *ngIf="event.ticketTypeCategories && event.ticketTypeCategories.length > 0 && event.ticketTypeCategories[0].ticketTypes && event.ticketTypeCategories[0].ticketTypes.length > 0">
                                    {{ event.ticketTypeCategories[0].ticketTypes[0].price / 100 | currency: 'EUR'}}
                                </ng-container>
                                <ng-container *ngIf="!event.ticketTypeCategories || event.ticketTypeCategories.length == 0">{{ 0  | currency: 'EUR'}}</ng-container>
                            </div>
                        </div>
                        <div class="addons">
                            <div class="addon">
                                <div class="a_label">Addon 1</div>
                                <div class="a_price">3.00 €</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>

<section id="accounting_page" class="loading_container" [ngClass]="{ 'loaded' : data && me }">
    <span class="loader_global"></span>
    <div class="loading_content">
        <div *ngIf="data && me">
            <div id="page_header" class="container">
                <h1>Comptabilité</h1>
            </div>

            <section *ngIf="totalData">
                <div id="filters_wrap" class="container">
                    <app-search-input placeholder="Rechercher un événement" (sendInput)="searchByKeyword($event, 'search')">
                    </app-search-input>
                </div>
                <app-main-table *ngIf="data?.length; else noData" [tableClass]="(isAdmin && !adminOnOrganisationPage) ? 'with_organisation' : ''"
                    [ref]="this" idSection="accounting_content" idTable="table_accounting" tableType="accounting"
                    [columns]="tableOptions" [datum]="data"></app-main-table>

                <ng-template #noData>
                    <section id="block_first" class="container">
                        <h2>Aucun événement ne correspond à votre recherche !</h2>
                        <figure>
                            <img src="assets/images/visuel_evenement.svg" />
                        </figure>
                    </section>
                </ng-template>

            </section>
            <section id="block_first" class="container" *ngIf="!totalData">
                <p class="description">
                    {{isAdmin ? "Aucun évènement crée pour le moment " : "Vous n'avez pas encore créé d'événement" }}.
                </p>
                <app-button *ngIf="!isAdmin || adminOnOrganisationPage" (clickEvent)="createEvent()" class="button_create"
                    text="Créer mon premier événement" [style]="'plain'">
                </app-button>
                <figure>
                    <img src="assets/images/visuel_evenement.svg" />
                </figure>
            </section>

            <app-pager *ngIf="totalPages" [totalPages]="totalPages" [page]="page" (changePage)="changePage($event)">
            </app-pager>
        </div>
    </div>
</section>




<div class="popup-overlay" (click)="popup = false" *ngIf="partialRedditionOpenedFor">
    <div class="popup" [@popupAnimation]>
        <button class="button_close" (click)="popup = false">
            <svg role="img" title="close" class="close-icon">
                <use href="assets/images/sprite.svg#icon-close"></use>
            </svg>
        </button>
        <div class="popup_content" (click)="$event.stopPropagation()">
            <h2>Exporter la reddition sur une période donnée</h2>
            <div class="popup_inner">
                <p>Vous souhaitez exporter la reddition sur une période donnée ?</p>
                <p class="grey">Sélectionnez la période et appuyez sur exporter.</p>
                <form [formGroup]="dateForm">
                    <app-date-interval (newDates)="changePartialDates($event)" [allowBefore]="true" [allowAfter]="false"
                        [noHour]="true" startText="Du" endText="Au"></app-date-interval>
                </form>
                <app-button text="Exporter" [loadingOff]="loadingState$" (clickEvent)="getPartialRedition()"
                    [style]="'plain'"></app-button>
            </div>
        </div>
    </div>
</div>
<div class="full_loader" *ngIf="fullLoader">
    <div></div>
</div>
<section id="form_page" class="container loading_container" [ngClass]="{ 'loaded' : mainForm }">
	<div class="loading_content">
		<div id="page_header">
			<h1>Import billetterie</h1>
			<app-button (clickEvent)="goBack()" text="retour" [style]="'border blue'"></app-button>
		</div>
		<div id="form_wrapper">
			<h2>Importez vos billets "FNAC"</h2>
			<form *ngIf="mainForm" [formGroup]="mainForm" class="full">
				<div class="table-tarif">
					<div class="tarif header">
						<div class="name">Nom</div>
						<div class="qty">Quantité</div>
						<div class="tickettype">Tarif associé</div>
					</div>
					<div formArrayName="ticketTypes">
						<div *ngFor="let ticketType of data.ticketTypes; let i = index" >
							<div [formGroupName]="i" class="tarif">
								<div class="name">{{ ticketType.source }}</div>
								<div class="qty">{{ ticketType.total }} participants</div>
								<div class="tickettype">
									<div class="input_wrap select">
										<input type="hidden" formControlName="source">
										<select id="provider" formControlName="destination">
											<option selected="selected" [value]="null">Créer un nouveau tarif</option>
											<option *ngFor="let ticketTypeOption of ticketTypesOptions" [value]="ticketTypeOption._id">{{ ticketTypeOption.name }}</option>
										</select>
										<svg role="img" title="chevron" class="icon-chevron">
											<use href="assets/images/sprite.svg#icon-chevron"></use>
										</svg>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="form_actions right">
					<app-button [style]="'plain'" type="submit" [loadingOff]="loadingState$" [disabled]="mainForm.invalid"
						(clickEvent)="submitForm()" text="Importer">
					</app-button>
				</div>
			</form>
			<!-- END FORMULAIRE EN DUR -->
		</div>
	</div>
</section>
import { Component, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { IAccountingInfo } from 'src/app/models/accounting.model';
import { IChart } from 'src/app/models/chart.model';
import { TableOptions } from 'src/app/models/type.definition';
import { EventService, AuthService, OrganisationService, UserService } from 'src/app/providers';
import User from 'src/app/models/user.model';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-seating-plan',
  templateUrl: './seating-plan.component.html',
  styleUrls: ['./seating-plan.component.scss']
})
export class SeatingPlanComponent implements OnInit {

  public loadingState$ = new Subject<void>();
  public seatingPlanName = "";
  public totalData: number;
  private subscription = new Subscription();
  public data: [];
  public fullLoader: boolean;
  public tableOptions: TableOptions<IAccountingInfo>[];
  public me: User;
  public isAdmin: boolean;
  public adminOnOrganisationPage: boolean;
  private workspaceSecret: string;

  // Popup validation
  displayDuplicateSeatingPlan: IChart;
  displayPublishLastVersion: IChart;
  displayDiscardDraft: IChart;
  displayArchiveChart: IChart;
  displayCopyToWorkspace: IChart;

  organisationData = [];

  constructor(
    private eventService: EventService,
    private userService: UserService,
    private authService: AuthService,
    private organisationService: OrganisationService,
    private notification: NotificationService,
    private storage: StorageService,
    private router: Router
  ) { }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public async ngOnInit() {
    this.isAdmin = this.authService.isAdmin;
    this.adminOnOrganisationPage = this.organisationService.adminConnected$.value;
    this.subscription.add(this.userService.me$.subscribe(me => {
      this.me = me;
    }));

    this.tableOptions = [
      {
        title: "ID",
        class: "status",
        sort: false,
        key: 'key',
      },
      {
        title: "Plans",
        class: "name",
        sort: false,
        key: 'name',
      },
      {
        title: "Statut",
        class: "participants",
        sort: false,
        key: 'status'
      },
      {
        title: "Archivé",
        class: "participants",
        sort: false,
        key: 'archived'
      },
      {
        class: "more",
        toggle: [
          {
            title: 'Modifier le plan',
            method: this.editSeatingPlan,
          },
          {
            title: 'Dupliquer le plan',
            method: this.duplicateSeatingPlan
          },
          {
            title: 'Publier le plan',
            method: this.publishLastVersion,
            if: (data: IChart) => data.status == "Brouillon"
          },
          {
            title: 'Publier les modifications',
            method: this.publishLastVersion,
            if: (data: IChart) => data.status == "Publié, un brouillon en cours"
          },
          {
            title: 'Annuler les modifications',
            method: this.discardDraft,
            if: (data: IChart) => data.status == "Publié, un brouillon en cours"
          },
          {
            title: 'Archiver le plan',
            method: this.archiveChart
          },
          {
            title: "Copier le plan vers",
            method: this.copyToWorkspace,
            if: (data: IAccountingInfo) => this.isAdmin == true
          }
        ]
      }
    ];
    if (this.isAdmin && !this.adminOnOrganisationPage) {
      this.tableOptions.unshift(
        {
          title: "Espace",
          class: "organisation_name",
          key: 'organisation'
        })
      this.organisationService.getOrganisationWithWorkspace().subscribe(_result => {
        if (_result) {
          if (_result['data']) {
            this.organisationData = _result['data'];
          }
        }
      });
    }
    this.getData();
  }

  private getData(): void {
    let organisationId: string = undefined
    if (this.adminOnOrganisationPage) {
      organisationId = this.storage.getItem('organisationId');
    }
    this.subscription.add(this.eventService.getSeatingPlan(this.seatingPlanName).subscribe(results => {
      this.data = (results?.data || []).map(current => {
        return current;
      });
      if (results.workspaceSecretKey) {
        this.workspaceSecret = results.workspaceSecretKey;
      }
      if (this.totalData == undefined) {
        this.totalData = this.data.length;
      }
    }, error => {
      this.data = [];
      this.totalData = 0;
    }));
  }

  searchEvent(input: string) {
    const searchFilterValue = input?.toLowerCase().trim().replace(/\s+/g, '');
    this.seatingPlanName = searchFilterValue
    this.getData();
  }

  createSeatingPlanMaybeFirst() {
    // If workspace exist, go to create plan
    if (this.workspaceSecret) {
      this.router.navigate(['parametres/seating-plan/edit']);
    } else {
      // If workspace doesn't exist, create them and go to create plan
      this.subscription.add(this.eventService.createWorkspace().subscribe(_result => {
        if (_result) {
          if (_result['status'] == "success") {
            this.router.navigate(['parametres/seating-plan/edit']);
          }
        }
      }));
    }
  }

  createSeatingPlan() {
    if (this.isAdmin) {
      this.router.navigate(['seating-plan/edit']);
    } else {
      this.router.navigate(['parametres/seating-plan/edit']);
    }
  }

  public async editSeatingPlan(_data, ref = this): Promise<void> {

    if (_data.key) {
      if (ref.isAdmin) {
        ref.router.navigate(['seating-plan/edit/' + _data.key]);
      } else {
        ref.router.navigate(['parametres/seating-plan/edit/' + _data.key]);
      }
    }
  }

  public async duplicateSeatingPlan(_data: IChart, ref = this): Promise<void> {
    ref.displayDuplicateSeatingPlan = _data;
  }

  duplicateSeatingPlanOk(_event) {
    if (_event) {
        this.organisationService.duplicateChart({
          chartId: _event.key
        }).subscribe(_result => {
          if (_result) {
            this.displayDuplicateSeatingPlan = null;

            // Redirect to edit chart
            if (_result['key']) {
              if (this.isAdmin) {
                this.router.navigate(['seating-plan/edit/' + _result['key']]);
              } else {
                this.router.navigate(['parametres/seating-plan/edit/' + _result['key']]);
              }
            }
          }
        });
    } else {
      this.notification.newNotification({
        message: `Impossible de dupliquer ce plan de salle`,
        state: 'error'
      });
      this.displayDuplicateSeatingPlan = null;
    }
  }

  public async publishLastVersion(_data, ref = this): Promise<void> {
    ref.displayPublishLastVersion = _data;
  }

  displayPublishLastVersionOk(_event) {
    if (_event) {
        this.organisationService.publishChart({
          chartId: _event.key
        }).subscribe(_result => {
          if (_result) {
            // Todo : Display success message
            this.displayPublishLastVersion = null;
            this.getData();
            if (_result['success']) {
              this.notification.newNotification({
                message: `Le plan de salle est maintenant publié !`,
                state: 'success'
              });
            } else {
              this.notification.newNotification({
                message: `Des erreurs sont présentes dans votre plan, veuillez les corriger pour pouvoir publier cette version.`,
                state: 'error'
              });
            }
          }
        });
    } else {
      this.displayPublishLastVersion = null;
      this.notification.newNotification({
        message: `Des erreurs sont présentes dans votre plan, veuillez les corriger pour pouvoir publier cette version.`,
        state: 'error'
      });
    }
  }

  public async discardDraft(_data, ref = this): Promise<void> {
    ref.displayDiscardDraft = _data;
  }

  displayDiscardDraftOk(_event) {
    if (_event) {
        this.organisationService.discardDraftChart({
          chartId: _event.key
        }).subscribe(_result => {
          if (_result) {
            this.displayDiscardDraft = null;

            this.notification.newNotification({
              message: `Les modifications ont été annulé.`,
              state: 'success'
            });

            this.getData();
          }
        });
    } else {
      this.displayDiscardDraft = null;
      this.notification.newNotification({
        message: `Impossible d'annuler les modifications`,
        state: 'error'
      });
    }
  }

  public async archiveChart(_data, ref = this): Promise<void> {
    ref.displayArchiveChart = _data;
  }

  displayArchiveChartOk(_event) {
    if (_event) {
        this.organisationService.archiveChart({
          chartId: _event.key
        }).subscribe(_result => {
          if (_result) {
            this.displayArchiveChart = null;

            this.notification.newNotification({
              message: `Le plan de salle a été archivé !`,
              state: 'success'
            });

            this.getData();
          }
        });
    } else {

      this.displayArchiveChart = null;

      this.notification.newNotification({
        message: `Impossible d'archiver ce plan de salle`,
        state: 'error'
      });
    }
  }

  chartIdSelected: string = null;

  public async copyToWorkspace(_data, ref = this): Promise<void> {
    ref.chartIdSelected = _data.key;
    ref.displayCopyToWorkspace = _data;
  }

  displayCopyToWorkspaceOk(_organisationId) {
    if (_organisationId && _organisationId != 'null') {
      this.organisationService.copyChartToWorkspace({
        chartId: this.chartIdSelected,
        toOrganisationId: _organisationId
      }).subscribe(_result => {
        if (_result) {
          this.chartIdSelected = null;
          this.displayCopyToWorkspace = null;

          this.notification.newNotification({
            message: `Le plan de salle a été copié !`,
            state: 'success'
          });

        }
      });
    } else {
      this.displayCopyToWorkspace = null;

      this.notification.newNotification({
        message: `Impossible de copier le plan de salle.`,
        state: 'error'
      });

    }
  }
}

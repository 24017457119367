import { Component, OnInit } from '@angular/core';
import { ListFromEventComponent } from 'src/app/elements/list-component';
import { IWidget } from 'src/app/models/widget.model';
import { EventWidgetService } from 'src/app/providers';
import { TableOptions } from 'src/app/models/type.definition';
import { GetEvent } from '../getEvent';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-event-widget',
  templateUrl: './event-widget.component.html',
  styleUrls: ['./event-widget.component.scss'],
  providers: [GetEvent]
})
export class EventWidgetComponent extends ListFromEventComponent<IWidget> {
  protected dataName = 'Widget';
  public messageIFrame: string;
  public widgetToDelete: { _id: string, name: string };
  public tableOptions: TableOptions[] = [
    {
      title: "Nom",
      class: "name",
      sort: true,
      key: 'name',
    },
    {
      title: "Restrictions",
      class: "restrict",
      key: 'restricted'
    },
    {
      title: "Type",
      class: "type",
      key: 'type'
    },
    {
      title: "Inscription",
      class: "inscription",
      key: 'isRegistrationString'
    },
    {
      class: "more",
      toggle: [{
        title: "Récupérer le code intégré",
        method: this.getIFrame,
        class: "medium_drop_li"
      },
      ...this.DefaultToggle.toggle
      ]

    }

  ];

  constructor(protected provider: EventWidgetService,
    protected getEvent: GetEvent) {
    super();
  }

  protected setGetParams(): void {
    this.getParams.select = ['name', 'ticketTypeRestricted', 'ticketTypeIds', 'forProduct', 'isRegistration'];
  }

  protected async formatElement(element: IWidget): Promise<IWidget> {
    element.restricted = element.ticketTypeRestricted ? 'Oui' : 'Non';
    element.isRegistrationString = element.isRegistration ? 'Oui' : 'Non';
    element.type = element.forProduct ? "Produit" : "Ticket";
    return element;
  }

  public deleteData(widget: IWidget, ref = this): void {
    ref.widgetToDelete = {
      _id: widget._id,
      name: widget.name
    }
  }

  public validateDeletion(): void {
    super.deleteData(this.widgetToDelete, this, () => {
      this.widgetToDelete = null;
    });
  }

  public getIFrame(data: IWidget, ref = this) {
    ref.messageIFrame = `<iframe src='${environment.widgetBaseUrl}${data._id}' id="iframe_${data._id}" frameborder='0' style="width:100%;border:none;"></iframe>
<script>
  var iframeElement=document.getElementById("iframe_${data._id}");window.onmessage=(e=>{if(e.data.hasOwnProperty("changeStep")&&window.innerWidth<891){var t=iframeElement.getBoundingClientRect().top+window.scrollY-80;window.scrollTo(0,t)}e.data.hasOwnProperty("frameHeight")&&(iframeElement.style.height=e.data.frameHeight+30+"px")});
</script>`
  }

  public copyIFrame(): void {
    navigator.clipboard.writeText(this.messageIFrame)
      .then(() => {
        this.notificationService.newNotification({ state: "success", message: "Texte copié avec succès" })
        this.messageIFrame = null;
      })
      .catch(e => {
        this.notificationService.newNotification({ state: "error", message: "Une erreur s'est produite" })
        console.error(e);
        this.messageIFrame = null;
      })
  }

}

import { OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Subject, Subscription } from "rxjs";
import { AbstractComponent } from "src/app/etc/abstract-component";
import { injector } from "src/app/etc/injector";
import { AuthService, OrganisationService } from "src/app/providers";
import { ErrorHandlerService } from "src/app/services/error-handler.service";
import { StorageService } from "src/app/services/storage.service";

@AbstractComponent()
export abstract class AuthComponent implements OnInit, OnDestroy {
    protected _subscription = new Subscription();
    protected authService: AuthService;
    protected storageService: StorageService;
    protected organisationService: OrganisationService;
    protected errorHandler: ErrorHandlerService;
    protected router: Router;
    protected queryParams: Params;
    protected url = '';
    public loadingState$ = new Subject<void>();
    public error: string;

    constructor() {
        this.authService = injector.get(AuthService);
        this.storageService = injector.get(StorageService);
        this.organisationService = injector.get(OrganisationService);
        this.errorHandler = injector.get(ErrorHandlerService);
        this.router = injector.get(Router);
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    ngOnInit(): void { }

    logIn(formData: any): void {
        this._subscription.add(this.authService.auth(formData).subscribe((result: { token: string, roles: string[], isSuperAdmin: boolean }) => {
            if (result.token) {
                this.authService.setIfAdmin(result.isSuperAdmin);
                this.authService.saveToken(result.token);
                if (this.authService.isAdmin) {
                    this.router.navigate(['admin']);
                }
                else {
                    this.loadOrganisationsIfNeeded();
                    this.router.navigate([this.url]);
                }
            }
        },
            (err: any) => {
                this.loadingState$.next();
                if (err.status === 403) {
                    this.error = 'Adresse email ou mot de passe incorrect';
                }
                else {
                    this.error = this.errorHandler.getError(err)
                }
            }
        ))
    }

    loadOrganisationsIfNeeded() {
        if (this.storageService.getItem('organisationId')) {
            this.organisationService.setList();
            this.organisationService.getCurrent();
        }
    }
}
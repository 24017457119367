import { IAddon } from './addon.model';
import { EditableEventData } from './editable-data'
import { ISession } from './session.model';
import { ITicketType } from './ticket.model';
import User from './user.model';

export interface IControlList extends EditableEventData {
	name?: string;
	nbAccess?: number;
	timeBetweenScans?: number;
	startDate?: Date;
	endDate?: Date;
	isActive?: boolean;
	filterType?: 'ticket' | 'addon',
	eventSessionId?: ISession[],
	ticketTypeFiltered?: boolean,
	ticketTypeIds?: ITicketType[];
	addonIds?: IAddon[];
	purchaseDateMax?: Date | null,
	purchaseDateMin?: Date | null,
	operatorIds?: User[];
	participants?: string,
	rates?: string,
	ticketsTotal?: number,
	ticketsScanned?: number,
	ratesArray?: string[]
	operators?: string,
	isProduct?: boolean,
	translatedType?: string
}


export function getTranslatedType(list: IControlList): string {
	if (!list.ticketTypeFiltered) return "Ticket(s) & Addon(s)";
	switch (list.filterType) {
		case 'addon': return "Addon(s)";
		case 'ticket': return "Ticket(s)";
		case undefined: return list.addonIds.length ? "Addon(s)" : "Ticket(s)"
	}
}

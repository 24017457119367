import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService, OrganisationService, UserService } from 'src/app/providers';
import User from 'src/app/models/user.model';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { IOrganisation } from 'src/app/models/organisation.model';

@Component({
  selector: 'app-organisations',
  templateUrl: './organisations.component.html',
  styleUrls: ['./organisations.component.scss']
})
export class OrganisationsComponent implements OnInit, OnDestroy {

  private _subscription = new Subscription();
  public me: User;
  public organisations: IOrganisation[];

  constructor(
    private userService: UserService,
    private organisationService: OrganisationService,
    private authService: AuthService,
    private router: Router) { }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ngOnInit(): void {
    this._subscription.add(this.userService.me$.subscribe(value => {
      this.me = value;
    }))
    this._subscription.add(this.organisationService.data$.subscribe(data => {
      this.organisations = data;
    }));
    this.organisationService.setList();
  }


  async goToOrganisation(id: string) {
    const organisationToken = await this.authService.authOrganisation(id).toPromise();
    this.organisationService.goToOrganisation(id, organisationToken);
  }

  createOrganisation() {
    this.router.navigate(['organisations/creer']);
  }

}

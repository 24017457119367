import { Component, OnInit, Input, OnChanges, SimpleChanges, DoCheck } from '@angular/core';
import { IEvent } from 'src/app/models/event.model';
import { ITicketType } from 'src/app/models/ticket.model';
import { FilesHandler } from 'src/app/services/file-handler.service';

@Component({
  selector: 'app-ticket-preview',
  templateUrl: './ticket-preview.component.html',
  styleUrls: ['./ticket-preview.component.scss']
})
export class TicketPreviewComponent implements DoCheck {
  @Input() public event: IEvent;
  @Input() public ticketType: ITicketType;
  public base64Image: string;
  public blobImage: Blob;
  public isVideo = false;
  public videoLocalUrl: any;

  constructor(private filesHandler: FilesHandler) {

  }

  private async setBase64Image(): Promise<void> {
    this.blobImage = this.ticketType ? this.ticketType.ticketVisual.image: this.event?.ticketing?.ticketVisual?.image
    this.base64Image = await this.filesHandler.getBase64(this.blobImage);
  }

  ngDoCheck() {
    if (this.ticketType && this.blobImage !== this.ticketType.ticketVisual.image) {
      this.checkFormat(this.ticketType.ticketVisual.image);
    } else if (!this.ticketType && this.blobImage !== this.event?.ticketing?.ticketVisual?.image) {
      this.checkFormat(this.event.ticketing.ticketVisual.image);
    }
  }

  getTicketCustomText() {
    const text = this.event.ticketing.ticketVisual?.text || ""
    const strippedText = text.replace(/(<([^>]+)>)/gi, "");
    if (strippedText === '') {
      return ''
    }
    return text
  }

  checkFormat(_visual) {
    if (_visual) {
      if (_visual.type === 'video/mp4') {
        this.isVideo = true;
        let file = new File([_visual], 'file', { type: _visual.type });
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          this.videoLocalUrl = (<FileReader>event.target).result;
        }
      } else {
        this.isVideo = false;
        this.setBase64Image();
      }
    } else {
      this.isVideo = false;
      this.base64Image = null;
    }
  }
}

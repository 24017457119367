import { RouteType } from 'src/app/models/type.definition';

export const EVENTROUTES: RouteType[] = [
  {
    route: 'general',
    title: 'Général',
    icon: 'chart',
    active: false,
    displaySubmenu: false,
    submenu: []
  },
  {
    route: 'parametres',
    title: 'Paramètres',
    icon: 'gear',
    active: false,
    displaySubmenu: false,
    submenu: []
  },
  {
    route: 'billetterie',
    title: 'Billetterie',
    icon: 'ticket',
    active: false,
    displaySubmenu: true,
    submenu: [
      {
        route: 'ticket',
        title: 'Tickets',
        icon: '',
        active: false,
        displaySubmenu: false,
        submenu: []
      },
      {
        route: 'e-tickets',
        title: 'E-tickets',
        icon: '',
        active: false,
        displaySubmenu: false,
        submenu: []
      },
      {
        route: 'badges',
        title: 'Badges',
        icon: '',
        active: false,
        displaySubmenu: false,
        submenu: []
      },
      {
        route: 'parametres',
        title: 'Paramètres',
        icon: '',
        active: false,
        displaySubmenu: false,
        submenu: []
      },
      {
        route: 'seating-plan',
        title: 'Plan de salle',
        icon: '',
        active: false,
        displaySubmenu: false,
        submenu: []
      },
      {
        route: 'codes-promos',
        title: 'Codes promos',
        icon: '',
        active: false,
        displaySubmenu: false,
        submenu: []
      },
      {
        route: 'invitations',
        title: 'Invitations',
        icon: '',
        active: false,
        displaySubmenu: false,
        submenu: []
      },
      {
        route: 'sessions',
        title: 'Sessions',
        icon: '',
        active: false,
        displaySubmenu: false,
        submenu: []
      },
      {
        route: 'widgets',
        title: 'Widgets',
        icon: '',
        active: false,
        displaySubmenu: false,
        submenu: []
      },
      {
        route: 'addons',
        title: 'Addons',
        icon: '',
        active: false,
        displaySubmenu: false,
        submenu: []
      },
      {
        route: 'produits',
        title: 'Produits',
        icon: '',
        active: false,
        displaySubmenu: false,
        submenu: []
      },
      {
        route: 'securite',
        title: 'Sécurité',
        icon: '',
        active: false,
        displaySubmenu: false,
        submenu: []
      }
    ]
  },
  {
    route: 'formulaires',
    title: 'Formulaires',
    icon: 'form',
    active: false,
    displaySubmenu: false,
    submenu: []
  },
  /** @Temp - Supprimer pour V1 **/
  /** {
    route: 'campagnes',
    title: 'Campagnes',
    icon: 'speaker',
    active: false,
    displaySubmenu: false,
    submenu: []
  },**/
  /** @EndTemp **/
  {
    route: 'exclusive-content',
    title: 'Contenus exclusifs',
    icon: 'eye',
    active: false,
    displaySubmenu: false,
    submenu: []
  },
  {
    route: 'mini-sites',
    title: 'Mini-sites',
    icon: 'laptop',
    active: false,
    displaySubmenu: false,
    submenu: []
  },
  {
    route: 'commandes',
    title: 'Commandes',
    icon: 'creditcard',
    active: false,
    displaySubmenu: true,
    submenu: [
      {
        route: '',
        title: "Commandes billets",
        icon: '',
        active: false,
        displaySubmenu: false,
        submenu: []
      },
      {
        route: 'produits',
        title: "Commandes produits",
        icon: '',
        active: false,
        displaySubmenu: false,
        submenu: []
      },
      {
        route: 'creer',
        title: "Ajout de commandes",
        icon: '',
        active: false,
        displaySubmenu: false,
        submenu: []
      },
      {
        route: 'import',
        title: "Import commandes",
        icon: '',
        active: false,
        displaySubmenu: false,
        submenu: []
      },
    ]
  },
  {
    route: 'participants',
    title: 'Participants',
    icon: 'user',
    active: false,
    displaySubmenu: true,
    submenu: [
      {
        route: 'tous',
        title: "Tous",
        icon: '',
        active: false,
        displaySubmenu: false,
        submenu: []
      }, {
        route: 'liste-d-attente',
        title: "Liste d'attente",
        icon: '',
        active: false,
        displaySubmenu: false,
        submenu: []
      },
    ]
  },
  {
    route: 'guichet',
    title: 'Guichet',
    icon: 'shop',
    active: false,
    displaySubmenu: true,
    submenu: [
      {
        route: '',
        title: "Configuration",
        icon: '',
        active: false,
        displaySubmenu: false,
        submenu: []
      },
      {
        route: 'outs',
        title: "Sorties de caisse",
        icon: '',
        active: false,
        displaySubmenu: false,
        submenu: []
      },
    ]
  },
  {
    route: 'controle-d-acces',
    title: 'Contrôle d\'accès',
    icon: 'control',
    active: false,
    displaySubmenu: true,
    submenu: [
      // {
      //   route: 'listes',
      //   title: "Contrôle d'accès",
      //   icon: '',
      //   active: false,
      //   displaySubmenu: false,
      //   submenu: []
      // },  {
      //   route: 'operateurs',
      //   title: 'Opérateurs',
      //   icon: '',
      //   active: false,
      //   displaySubmenu: false,
      //   submenu: []
      // },
    ]
  },
];

<section id="form_page" class="container loading_container" [ngClass]="{ 'loaded' : mainForm }">
  <div class="loading_content">
    <div id="page_header">
      <div id="page_header_left">
        <h1>Widget</h1>
      </div>
    </div>
    <div *ngIf="loaded" id="form_wrapper">
      <h2>{{data ? 'Éditer' : 'Créer'}} un widget</h2>
      <form *ngIf="mainForm" [formGroup]="mainForm">
        <div class="form_item">
          <label for="name">Nom du widget</label>
          <input id="name" type="text" formControlName="name">
        </div>
        <div class="form_item">
          <input #dateType id="forProduct" type="checkbox" formControlName="forProduct" class="toggle">
          <label for="forProduct">
            Pour l'achat de produit
            <i class="check">
              <svg role="img" title="check" class="check-icon">
                <use href="assets/images/sprite.svg#icon-check"></use>
              </svg>
            </i>
          </label>
        </div>

        <div class="form_item" *ngIf="forProduct">
          <input #dateType id="askZone" type="checkbox" formControlName="askZone" class="toggle">
          <label for="askZone">
            Demander l'emplacement pour la livraison
            <i class="check">
              <svg role="img" title="check" class="check-icon">
                <use href="assets/images/sprite.svg#icon-check"></use>
              </svg>
            </i>
          </label>
        </div>


        <div formGroupName="customStyles">
          <div class="form_cols">
            <h3>Couleurs du widget</h3>
            <div class="form_item quarter color">
              <label for="description">Arrière plan</label>
              <input [(colorPicker)]="colors.background" [style.background]="colors.background" name="color"
                (colorPickerChange)="changeColor('background', $event)" autocomplete="off" />
            </div>
            <div class="form_item quarter color">
              <label for="description">Titres</label>
              <input [(colorPicker)]="colors.title" [style.background]="colors.title" name="color"
                (colorPickerChange)="changeColor('title', $event)" autocomplete="off" />
            </div>
            <div class="form_item quarter color">
              <label for="description">Textes</label>
              <input [(colorPicker)]="colors.text" [style.background]="colors.text" name="color"
                (colorPickerChange)="changeColor('text', $event)" autocomplete="off" />
            </div>
            <div class="form_item quarter color">
              <label for="description">Liens</label>
              <input [(colorPicker)]="colors.link" [style.background]="colors.link" name="color"
                (colorPickerChange)="changeColor('link', $event)" autocomplete="off" />
            </div>
          </div>
          <!-- <div formGroupName="size" class="form_cols">
            <h3>Taille du widget</h3>
            <div class="form_item">
              <label for="height">Hauteur</label>
              <input id="height" type="number" formControlName="height">
            </div>
            <div class="form_item">
              <input id="auto_height" type="checkbox" formControlName="autoHeight">
              <label for="auto_height">Hauteur automatique</label>
            </div>
          </div> -->


        </div>

        <div class="form_cols">
            <h3>Taille du widget</h3>
            <div class="form_item no_margin">
              <label for="height">Hauteur</label>
              <div class="form_cols">
                <div class="form_item half">
                  <input id="height" type="number" formControlName="height" [attr.disabled]="heightDisabled()" />
                </div>
                <div class="form_item half">
                  <input id="auto_height" class="boxed" type="checkbox" formControlName="autoHeight" />
                  <label for="auto_height">Automatique</label>
                </div>
              </div>
            </div>
          </div>

        <div>
          <h3>{{forProduct ? 'Produits' : 'Tickets'}} concernés</h3>
          <div class="form_cols">
            <div class="form_item half">
              <input id="restrict" class="boxed" type="checkbox" formControlName="ticketTypeRestricted" />
              <label for="restrict">Restreindre</label>
            </div>
          </div>
          <ng-container *ngIf="ticketRestricted()">
            <p *ngIf="!ticketTypes?.length" class="form_item error">Il semble que vous n’ayez pas encore créé de
              tarifs,
              <a routerLink="../../ticket/creer-ticket">créez-en un !</a>
            </p>
            <div class="form_item" *ngIf="ticketTypes?.length">
              <div class="input_wrap select multiple">
                <span class="select_multiple_toggle" (click)="dropDown = !dropDown; $event.stopPropagation()">
                  {{getTicketsName()}}
                </span>
                <svg role="img" title="chevron" class="icon-chevron">
                  <use href="assets/images/sprite.svg#icon-chevron"></use>
                </svg>
                <div class="dropdown multiple" *ngIf="dropDown" (click)="$event.stopPropagation()">
                  <ul>
                    <li *ngFor="let ticket of ticketTypes; let i = index">
                      <input id="ticket_{{i}}" type="checkbox" [attr.checked]="ticketChecked(ticket._id)"
                        (change)="checkTicket(ticket._id, $event.target.checked)" />
                      <label for="ticket_{{i}}">
                        {{ticket.name}} <span class="ticket_category"> - {{ticket.ticketTypeCategoryId?.name || 'Sans
                          catégorie'}}</span>
                        <svg role="img" title="check" class="check-icon">
                          <use href="assets/images/sprite.svg#icon-check"></use>
                        </svg>
                      </label>
                    </li>
                  </ul>
                  <div class="dropdown_action">
                    <app-button (clickEvent)="dropDown = false" text="Sélectionner" [style]="'plain full'"></app-button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <div id="tracking_script_input">
          <h3 for="tracking_script">Script de tracking</h3>
          <div class="form_cols">
            <div class="form_item half">
              <input id="display_tracking_script" class="boxed" type="checkbox"
                [attr.checked]="displayTrackingScript ? 'checked' : null"
                (change)="displayTrackingScript = !displayTrackingScript" />
              <label for="display_tracking_script">Ajouter un script de tracking</label>
            </div>
          </div>
          <div *ngIf="displayTrackingScript">
            <textarea id="tracking_script" formControlName="trackingScript"></textarea>
            <p class="description_medium">
              Vous êtes responsable du code injecté sur votre widget. OandB ne pourrait être tenu responsable d’une
              injection malveillante. Pour toute intégration vous demandant la base URL, vous pouvez indiquer
              <a (click)="copyLink()" target="_blank" (mouseenter)="hoverLink = true"
                (mouseleave)="leaveLink()">{{widgetUrl}}</a>
              <span id="copy_info" [ngClass]="{'visible': hoverLink, 'green': linkCopied}">
                <ng-container *ngIf="!linkCopied">Copier le lien</ng-container>
                <ng-container *ngIf="linkCopied"><span>✓</span> Lien copié</ng-container>
              </span>
            </p>
          </div>
        </div>
        <div *ngIf="isAdmin" class="admin_only">
          <div class="form_item">
            <input #dateType id="isRegistration" type="checkbox" formControlName="isRegistration" class="toggle">
            <label for="isRegistration">
              Mode "Inscription"
              <i class="check">
                <svg role="img" title="check" class="check-icon">
                  <use href="assets/images/sprite.svg#icon-check"></use>
                </svg>
              </i>
            </label>
            <div class="description">
              <strong> Attention !</strong> Cette option passe le widget en mode "inscription". <br />
              Les informations de facturation et l'étape de paiement seront désactivées.<br />
              Vérifier à ne pas inclure de billet ou d'addon payant dans le widget.
            </div>
          </div>
        </div>
        <div class="form_actions right">
          <app-button [loadingOff]="loadingState$" [style]="'plain'" [disabled]="mainForm.invalid"
            (clickEvent)="submitForm()" text="Enregistrer" type="submit">
          </app-button>
          <app-popup *ngIf="error != null" title="Une erreur est survenue" message="{{error}}" popupOpened="true"
            (backEvent)="error = null"></app-popup>
        </div>
      </form>
    </div>
  </div>
</section>
<div id="page_header" class="container">
    <div id="page_header_left">
        <h1>
        	Guichets
        	<span class="black">
        		Accessibles par les opérateurs à l’adresse 
        		<a href="guichet.oandb.fr">guichet.oandb.fr</a>
        	</span>
        </h1>
    </div>
    <div id="page_header_right">
        <app-button class="button" text="Créer un guichet" [style]="'plain'"></app-button>
    </div>
</div>

<section id="block_first" class="container">
    <p class="description grey">Vous n’avez pas encore créé de guichet pour cet événement !</p>
    <figure>
        <img src="assets/images/visuel_guichet.svg" />
    </figure>
    <app-button class="button_create no_margin" text="Créer un guichet" [style]="'plain full'"></app-button>
</section>

<form *ngIf="mainForm" id="field_form" class="center_form" [formGroup]="mainForm">
  <div id="field_form_fields">
    <ng-container *ngIf="!data">
      <h2 for="type">Ajouter un champ ...</h2>
      <div class="form_radios form_field_choice_list">
        <div>
          <input name="custom_value_radio" id="predefined" type="radio" [value]="false" [checked]="!customField"
            (change)="customField = false">
          <label for="predefined">Prédéfinie</label>
        </div>
        <div>
          <input name="custom_value_radio" id="custom" type="radio" [value]="true" [checked]="customField"
            (change)="customField = true">
          <label for="custom">Personnalisée</label>
        </div>
      </div>
    </ng-container>
    <div *ngIf="!customField" class="select_preset">
      <h2 for="type">Sélectionner le/les champ(s)</h2>
      <span class="info_required">Les champs ajouter seront obligatoires </span>
      <div class="form_item form_field_choice_list">
        <div *ngFor="let field of predefinedField; let i = index">
          <input id="predefined_{{i}}" type="checkbox" (change)="selectPredefinedField(field, $event.target.checked)">
          <label for="predefined_{{i}}">
            {{field.label}}
            <svg role="img" title="check" class="check-icon">
              <use href="assets/images/sprite.svg#icon-check"></use>
            </svg>
          </label>
        </div>
      </div>

    </div>

    <div *ngIf="customField">
      <h2>{{data ? 'Modifiez' : "Créez"}} votre champ</h2>

      <div class="form_item">
        <div class="input_wrap select">
          <select id="select_type" formControlName="type">
            <option value="" hidden selected>Type du champ</option>
            <option *ngFor="let fieldType of fieldTypes" value="{{fieldType.name}}">{{fieldType.translateName}}</option>
          </select>
          <svg role="img" title="chevron" class="icon-chevron">
            <use href="assets/images/sprite.svg#icon-chevron"></use>
          </svg>
        </div>
      </div>
      <div class="form_item">
        <textarea id="label" placeholder="Nom du champ" formControlName="label"></textarea>
      </div>
      <ng-container *ngIf="currentField && currentField.options">
        <h2>Réponses possibles</h2>
        <div class="form_item field_answers" formArrayName="options">
          <div class="answer" *ngFor="let option of mainForm.get('options').controls;  index as i">
            <svg role="img" title="menu">
              <use href="assets/images/sprite.svg#icon-menu"></use>
            </svg>
            <input type="text" [formControlName]="i">
          </div>
          <span class="add_answer" (click)="addAnswer()">
            <i>
              <svg role="img" title="menu">
                <use href="assets/images/sprite.svg#icon-add"></use>
              </svg>
            </i>
            Ajoutez une réponse
          </span>
        </div>
      </ng-container>
      <div class="form_item">
        <input id="description" type="checkbox" class="toggle" [checked]="optionsToShow.description"
          (change)="optionsToShow.description = !optionsToShow.description" />
        <label for="description">
          Description
          <i class="check">
            <svg role="img" title="check" class="check-icon">
              <use href="assets/images/sprite.svg#icon-check"></use>
            </svg>
          </i>
        </label>
      </div>
      <div class="form_item" *ngIf="optionsToShow.description">
        <textarea id="description" placeholder="Description de la question" formControlName="description"></textarea>
      </div>

      <!-- CONTRAINTES ------------------------------------------------------------------------------------------>
      <div *ngIf="currentField" formGroupName="constraints" class="constraints">
        <ng-container *ngIf="currentField.length">
          <div class="form_item">
            <input id="length" type="checkbox" class="toggle" [checked]="optionsToShow.length"
              (change)="optionsToShow.length = !optionsToShow.length">
            <label for="length">
              Limite de caractères
              <i class="check">
                <svg role="img" title="check" class="check-icon">
                  <use href="assets/images/sprite.svg#icon-check"></use>
                </svg>
              </i>
            </label>
          </div>
          <div class="form_cols" *ngIf="optionsToShow.length">
            <div class="form_item half">
              <input id="limitMin" type="text" placeholder="min" formControlName="minLength">
            </div>
            <div class="form_item half">
              <input id="limitMax" type="text" placeholder="max" formControlName="maxLength">
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="currentField.extension">
          <div class="form_item">
            <input id="extensions" type="checkbox" class="toggle" [checked]="optionsToShow.extensions"
              (change)="optionsToShow.extensions = !optionsToShow.extensions">
            <label for="extensions">
              Fichiers autorisés
              <i class="check">
                <svg role="img" title="check" class="check-icon">
                  <use href="assets/images/sprite.svg#icon-check"></use>
                </svg>
              </i>
            </label>
          </div>
          <div class="form_cols" *ngIf="optionsToShow.extensions">
            <div class="input_wrap select multiple">
              <span class="select_multiple_toggle"
                (click)="optionsToShow.extensionDropDown = !optionsToShow.extensionDropDown; $event.stopPropagation()">
                {{getExtensionNames()}}
              </span>
              <svg role="img" title="chevron" class="icon-chevron">
                <use href="assets/images/sprite.svg#icon-chevron"></use>
              </svg>
              <div class="dropdown multiple" *ngIf="optionsToShow.extensionDropDown" (click)="$event.stopPropagation()">
                <ul>
                  <li *ngFor="let extension of extensions; let i = index">
                    <input id="extension_{{extension}}" type="checkbox" [attr.checked]="extensionChecked(extension)"
                      (change)="checkExtension(extension, $event.target.checked)" />
                    <label for="extension_{{extension}}">
                      {{extension}}
                      <svg role="img" title="check" class="check-icon">
                        <use href="assets/images/sprite.svg#icon-check"></use>
                      </svg>
                    </label>
                  </li>
                </ul>
                <div class="dropdown_action">
                  <app-button (clickEvent)="optionsToShow.extensionDropDown = false" text="Sélectionner"
                    [style]="'plain full'"></app-button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="currentField.nbLength">
          <div class="form_item">
            <input id="size" type="checkbox" class="toggle" [checked]="optionsToShow.size"
              (change)="optionsToShow.size = !optionsToShow.size">
            <label for="size">
              Valeurs min et max
              <i class="check">
                <svg role="img" title="check" class="check-icon">
                  <use href="assets/images/sprite.svg#icon-check"></use>
                </svg>
              </i>
            </label>
          </div>
          <div class="form_cols" *ngIf="optionsToShow.size">
            <div class="form_item half">
              <input id="limitMin" type="text" placeholder="min" formControlName="min">
            </div>
            <div class="form_item half">
              <input id="limitMax" type="text" placeholder="max" formControlName="max">
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="currentField.date">
          <div class="form_item">
            <input id="date" type="checkbox" class="toggle" [checked]="optionsToShow.date"
              (change)="optionsToShow.date = !optionsToShow.date">
            <label for="date">
              Dates min et max
              <i class="check">
                <svg role="img" title="check" class="check-icon">
                  <use href="assets/images/sprite.svg#icon-check"></use>
                </svg>
              </i>
            </label>
          </div>
          <div class="form_cols" *ngIf="optionsToShow.date">
            <app-date-interval (newDates)="changeDates($event, 'constraints')" startText="Date minimum"
              endText="Date maximum" [row]="false" [preData]="mainForm.value.constraints"></app-date-interval>
          </div>
        </ng-container>
      </div>

      <!-- FIN CONTRAINTES ------------------------------------------------------------------------------------------>


      <div class="form_item">
        <input id="mandatory" type="checkbox" class="toggle" formControlName="isRequired"
          [checked]="optionsToShow.mandatory" (change)="optionsToShow.mandatory = !optionsToShow.mandatory">
        <label for="mandatory">
          Rendre obligatoire
          <i class="check">
            <svg role="img" title="check" class="check-icon">
              <use href="assets/images/sprite.svg#icon-check"></use>
            </svg>
          </i>
        </label>
        <p class="form_item description" *ngIf="optionsToShow.mandatory">Attention, pour que votre formulaire soit
          conforme à la
          législation, évitez de rendre obligatoire les questions sensibles.</p>
      </div>

      <!-- <div class="form_item">
          <input id="counter" type="checkbox" class="toggle" formControlName="">
          <label for="counter">
            Activer sur le guichet
            <i class="check">
              <svg role="img" title="check" class="check-icon">
                <use href="assets/images/sprite.svg#icon-check"></use>
              </svg>
            </i>
          </label>
        </div> -->
    </div>
  </div>
  <div id="field_form_button">
    <app-button [style]="'plain full'" [loadingOff]="loadingState$"
      [disabled]="(customField && mainForm.invalid) || (!customField && !selectedPredefinedField.length)"
      (clickEvent)="submitForm()" text="Valider">
    </app-button>
  </div>

</form>
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { IOrganisation } from 'src/app/models/organisation.model';
import { IUserOrganisation } from 'src/app/models/user-organisation.model';
import { APIListResponse, GetParams, OrganisationToken } from 'src/app/models/type.definition';
import { ReactiveProvider } from '../reactive-provider';

@Injectable()
export class OrganisationService extends ReactiveProvider<IOrganisation> {
  protected url = 'users/organisations';
  public adminConnected$ = new BehaviorSubject(false);

  constructor(protected router: Router) {
    super();
  }

  public getById(id: string, getParams: GetParams<IOrganisation> = null): Observable<IOrganisation> {
    getParams = getParams || { select: ["paymentGateways", 'name', 'place', 'type'] };
    return super.getById(id, getParams);
  }

  public setList() {
    const body: GetParams<IOrganisation> = {
      populate: ['imageId']
    };
    this.httpClient
      .get<APIListResponse<IOrganisation>>(this.getUrl('list/mine', body))
      .toPromise()
      .then(result => {
        return this.data$.next(result?.data)
      })
      .catch(err => console.error(err));
  }

  public getCurrent(): void {
    const organisationId = this.storageService.getItem("organisationId");
    if (organisationId) {
      this.getById(organisationId)
        .toPromise()
        .then(result => this.selected$.next(result))
        .catch(err => console.error(err))
    }
  }

  public getOrganisationWithWorkspace() {
    return this.httpClient.get(this.getUrl(`list/organisation-with-workspace`));
  }

  public duplicateChart(_body) {
    return this.httpClient.post(this.getUrl(`edit/duplicate-chart`), _body);
  }

  public publishChart(_body) {
    return this.httpClient.post(this.getUrl(`edit/publish-chart`), _body);
  }

  public discardDraftChart(_body) {
    return this.httpClient.post(this.getUrl(`edit/discard-chart`), _body);
  }

  public archiveChart(_body) {
    return this.httpClient.post(this.getUrl(`edit/archive-chart`), _body);
  }

  public copyChartToWorkspace(_body) {
    return this.httpClient.post(this.getUrl(`edit/push-chart-to-workspace`), _body);
  }

  async goToOrganisation(id: string, organisationToken: OrganisationToken): Promise<void> {
    const data = await this.getById(id).toPromise();
    this.storeToken(organisationToken);
    this.selected$.next(data);
    this.router.navigate(['tableau-de-bord']);
  }

  public storeToken(organisationToken: OrganisationToken): void {
    this.storageService.setItem('organisationId', organisationToken.organisationId);
    this.storageService.setItem('token', organisationToken.token);
  }

  reset(): void {
    this.selected$.next(null);
    this.router.navigate(['organisations']);
    this.storageService.removeItem('organisationId');
  }
}

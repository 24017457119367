<ng-container *ngIf="data">
	<div id="page_header" class="container">
		<h1>{{name}}</h1>
		<div id="page_header_right" (click)="$event.stopPropagation()">
			<app-button *ngIf="actionToggle.links.length > 0" (clickEvent)="actionToggleOpen = true" [loadingFunction]="false" class="button_create"
				text="Actions" [style]="'plain'" [icon]="'chevron'"></app-button>
			<nav class="drop drop_more" *ngIf="actionToggleOpen" [@moreAnimation]>
				<ul>
					<li *ngFor="let link of actionToggle.links" (click)="actionToggleOpen = false; link.method(actionToggle.ref)"
						(click)="$event.stopPropagation()" class="{{link.class}}">{{link.title}}</li>
				</ul>
			</nav>
		</div>
	</div>

	<div id="participant_content" class="container">
		<div class="inner">
			<p class="h2">Informations sur le participant {{name}}</p>
			<ul class="participant_details">
				<li>
					<span class="label">Nom</span>
					<span class="value doselect" *ngIf="!editName">{{ name }}</span>
					<div class="aligned_input" *ngIf="editName">
						<input placeholder="Nom" (change)="newLastName = $event.target.value" id="lastname" type="text"
							value="{{data.lastName}}" />
						<input placeholder="Prénom" (change)="newFirstName = $event.target.value" id="firstname"
						type="text" value="{{data.firstName}}" />
					</div>
					<span class="edit" *ngIf="!editName">
						<app-button (clickEvent)="editName = true" text="Modifier" [style]="'link'"></app-button>
					</span>
					<span class="save" *ngIf="editName">
						<app-button (clickEvent)="saveNewName()" text="Valider" [style]="'plain full'">
						</app-button>
					</span>
				</li>
				<li *ngIf="data.email">
					<span class="label">E-mail</span>
					<span class="value doselect" *ngIf="!editEmail">{{data.email}}</span>
					<div class="aligned_input" *ngIf="editEmail">
						<input placeholder="Email" (change)="newEmail = $event.target.value" id="email"
							type="text" value="{{data.email}}" />
					</div>
					<span class="edit" *ngIf="!editEmail">
						<app-button (clickEvent)="editEmail = true" text="Modifier" [style]="'link'"></app-button>
					</span>
					<span class="save" *ngIf="editEmail">
						<app-button (clickEvent)="saveNewEmail()" text="Valider" [style]="'plain full'">
						</app-button>
					</span>
				</li>
				<li>
					<span class="label">N° de commande</span>
					<span class="value doselect">{{data.orderId.orderNumber}}</span>
				</li>
				<li>
					<span class="label">Date</span>
					<span class="value doselect">{{data.createdAt | date: 'dd/MM/yyyy'}}</span>
				</li>
				<li>
					<span class="label">Ticket</span>
					<span class="value doselect">{{data.ticketTypeId.name}}{{data.categoryName ? ' - ' + data.categoryName : ''}}</span>
				</li>
				<li *ngIf="event.bookType !=='nft'">
					<span class="label">N° du ticket</span>
					<span class="value doselect">
						{{data.ticketNumber}}
						<span *ngIf="data.externalTicketNumber"><br>N°import: {{data.externalTicketNumber}}</span>
					</span>
				</li>
				<li *ngIf="data.seat">
					<span class="label">Numéro de place</span>
					<span class="value doselect">{{data.seat}}</span>
				</li>
				<li>
					<span class="label">Addons</span>
					<span class="value doselect">{{addons || 'Aucun'}}</span>
				</li>
			</ul>
			<p class="h2">Scans</p>
			<p class="grey" *ngIf="!data.scans.length">L’événement n’a pas encore démarré. Il n’y a donc pas encore de
				scans.</p>
			<ul class="participant_scans" *ngIf="data.scans.length">
				<li class="scan" *ngFor="let scan of data.scans">
					<span class="label">{{scan.dateScan | date: 'dd/MM/yyyy - hh:mm'}}</span>
					<span class="value">
						{{scan.controlListId?.name || 'Aucune liste de contrôle utilisée'}}
					</span>
					<span class="operator">
						{{scan.operatorId?.email || 'Opérateur inconnu'}}
					</span>
				</li>
			</ul>
			<p class="h2" *ngIf="data.form?.length">Questions</p>
			<ul class="questions" *ngIf="data.form?.length">
				<ng-container *ngFor="let form of data.form">
					<li *ngFor="let field of form.fields">
						<p class="label">{{field.label}}</p>
						<p class="value" *ngIf="!field.values.url">{{field.values}}</p>
						<a class="value" *ngIf="field.values.url" href="{{field.values.url}}"
							[attr.download]="field.values.name">{{field.values.name}}</a>
					</li>
				</ng-container>
			</ul>
		</div>
	</div>
</ng-container>

<app-popup *ngIf="resendConfirmOfOrder" (backEvent)="resendConfirmOfOrder = false"
	[title]="'Renvoyer la confirmation de la commande N°' + data.orderId.orderNumber"
	message="L'e-mail de confirmation ainsi que les tickets concernées seront envoyés à l'adresse e-mail ayant été utilisée pour cette commande"
	buttonText="Renvoyer" (clickEvent)="resendConfirmOk()"></app-popup>

<app-delete-popup *ngIf="deleteTicket" deleteWord="Annuler" buttonText="Annuler le ticket"
	[customText]="'Désirez-vous annuler le ticket ' + data.ticketNumber + ' des participants de l\événement ?'"
	type='participant' (clickEvent)="deleteOk()" (close)="deleteTicket = false"></app-delete-popup>

	<div class="full_loader" *ngIf="fullLoader">
		<div></div>
	</div>
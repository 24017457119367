import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
// import { AuthService } from 'src/app/providers';
import { environment } from '../../../environments/environment';
import { Observable, throwError } from "rxjs";
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { HTTPTools } from 'src/app/etc/http-tools'

@Injectable()
export class Interceptor implements HttpInterceptor {
  message: number = 0;

  constructor(
    private storageService: StorageService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this.storageService.getItem('token') || '';
    const requestObject = {
      setHeaders: {
        'Authorization': authToken
      }
    }
    const contentType = HTTPTools.getNextContentType();
    if (contentType) {
      requestObject.setHeaders['Content-Type'] = contentType;
    }
    if (request.url.includes(environment.apiUrl)) {
      request = request.clone({ ...requestObject });
    } else {
      request = request.clone({ ...requestObject });
    }
    return next.handle(request).pipe(
      catchError((error) => {
        if (error.status == 404 && !error.message.includes("/reset/") && !error.url.includes("unsplash")) {
          this.router.navigate(['/404']);
        }
        if ((error.status == 401 || error.status == 403) && !error.message.includes("/auth:")) {
          this.router.navigate(['/403']);
        }
        console.error(error);
        return throwError(error);
      })
    )
  }


}

<div id="toggle_notifications" (click)="$event.stopPropagation()">
    <div id="notifications_button" (click)="openMenu()">
        <svg role="img" title="bell" class="icon-bell">
            <use href="assets/images/sprite.svg#icon-bell"></use>
        </svg>
        <div *ngIf="count" id="notification_count">
            <span>{{count > 99 ? '99+' : count}}</span>
        </div>
    </div>
    <div *ngIf="menuOpened" id="notifications_menu" (scroll)="onScroll($event.target)">
        <ul *ngIf="notifications?.length">
            <li *ngFor="let notification of notifications" (click)="goTo(notification.link); closeMenu()">
                <a [style.fontWeight]="!notification.readed || notification.tempBold ? 'bold' : 'default'">
                    <span class="notification_date">{{notification.date | date: 'dd/MM/yyyy HH:mm' }}</span><br />
                    <span>{{notification.message}}</span>
                </a>
            </li>
        </ul>
        <div id="notification_loading_zone" *ngIf="!maxReached">
            <div *ngIf="loading" class="loader" ></div>
        </div>
        <p *ngIf="notifications?.length == 0" id="no_notification">Aucune notification pour le moment</p>
    </div>
</div>
<section id="{{idSection}}" class="container" [ngClass]="{'loading' : loading}">
  <div *ngIf="!noHeader" class="table_header {{tableType}} {{tableClass}}">
    <span *ngFor="let column of columns; index as i" class="{{column.class}}">
      <ng-container *ngIf="!columns[i].hide">
        {{column.title}}
        <input *ngIf="column.checkbox" id="checkbox_table_header" type="checkbox" />
        <label *ngIf="column.checkbox" for="checkbox_table_header">
          <svg role="img" title="check" class="check-icon">
            <use href="assets/images/sprite.svg#icon-check"></use>
          </svg>
        </label>
        <span *ngIf="column.sort" (click)="sortTable(column)" class="sort">
          <svg role="img" title="chevron" class="chevron-icon up">
            <use href="assets/images/sprite.svg#icon-chevron"></use>
          </svg>
          <svg role="img" title="chevron" class="chevron-icon down">
            <use href="assets/images/sprite.svg#icon-chevron"></use>
          </svg>
        </span>
      </ng-container>
    </span>
  </div>
  <ul id="{{idTable}}" class="table_common {{tableClass}}">
    <ng-container *ngFor="let data of datum; let j = index">
      <li *ngIf="data.name || data.label || data.firstName || data.fullName" [attr.data-parent-id]="data._id || 1"
        (click)="!draggable && onRowClick(data)" [attr.data-order]="data.order"
        (mousedown)="(data._id && draggable && dragging($event)) || $event"
        [ngClass]="{'row-pointer': gotClickEvent || (data._id && draggable), 'parent_li': subTableKey, 'draggable': data._id && draggable, 'selected': checkRowIsSelected(data._id)}">
        <ng-container *ngFor="let i of arrayLoop" >
          <div *ngIf="!columns[i].if || columns[i].if(data)" [ngxTippy]="columns[i].tooltip === true ? data[columns[i].tooltipKey] : null" class="main_table_li {{columns[i].class}} {{data[columns[i].classKey]}}"
             title="{{columns[i].title}}">
            <figure *ngIf="columns[i].image && data[columns[i].imageKey]">
              <img [src]="data[columns[i].imageKey]" />
            </figure>

            <span *ngIf="columns[i].image && !data[columns[i].imageKey]" class="initial">{{data.name[0]}}</span>
            <ng-container *ngIf="!columns[i].checkbox && !columns[i].toggleBox">
              <span *ngIf="!columns[i].isArray && !columns[i].link"
                class="{{columns[i].subClass}}">{{data[columns[i].key]}}</span>
              <ng-container *ngIf="columns[i].isArray">
                <p *ngFor="let element of data[columns[i].key]">{{element}}</p>
              </ng-container>
              <a *ngIf="columns[i].link" [href]="data[columns[i].key]"
                [target]="columns[i].target || '_self'">{{data[columns[i].key]}}</a>
            </ng-container>
            <span *ngIf="data[columns[i].subKey]" class="{{columns[i].subClass2}}">{{data[columns[i].subKey]}}</span>
            <!-- {{data[columns[i].subKey2]}} -->
            <span *ngIf="data[columns[i].subKey2]" class="{{columns[i].subClass3}}">{{data[columns[i].subKey2]}}</span>
            <ul *ngIf="columns[i].hover && data[columns[i].hoverKey] && data[columns[i].hoverKey].length"
              class="elements_hover">
              <li *ngFor="let element of data[columns[i].hoverKey]">{{element}}</li>
            </ul>
            <div *ngIf="columns[i].toggle && data._id" class="icon_wrap"
              (click)="openToggle(data); $event.stopPropagation()" (mousedown)="$event.stopPropagation()">
              <svg class="down" role="img" title="dots" class="dots-icon">
                <use href="assets/images/sprite.svg#icon-dots"></use>
              </svg>
            </div>
            <nav class="drop drop_more" *ngIf="data.toggleOpen" [@moreAnimation] (mousedown)="$event.stopPropagation()">
              <ul>
                <ng-container *ngFor="let link of columns[i].toggle">
                  <li *ngIf="!link.if || link.if(data)"
                    (click)="$event.stopPropagation(); closeAllToggle(); link.method(data, ref)" class="{{link.class}}">
                    {{link.title}}</li>
                </ng-container>
              </ul>
            </nav>
            <input *ngIf="columns[i].checkbox" id="checkbox_table_{{j}}" name="checkbox_table_{{j}}" type="checkbox"
              (mousedown)="$event.stopPropagation()" (change)="checkBoxTableChange(data._id)" />
            <label *ngIf="columns[i].checkbox" for="checkbox_table_{{j}}" (mousedown)="$event.stopPropagation()">
              <svg role="img" title="check" class="check-icon">
                <use href="assets/images/sprite.svg#icon-check"></use>
              </svg>
            </label>
            <input
              *ngIf="columns[i].toggleBox && (data[columns[i].key] !== undefined || data[columns[i].otherKey] !== undefined )"
              id="toggleBox_table_{{j}}" (mousedown)="$event.stopPropagation()" (click)="$event.stopPropagation()"
              type=checkbox [checked]="data[columns[i].key] || data[columns[i].otherKey]"
              (change)="columns[i].callback(data, ref)" />
            <label
              *ngIf="columns[i].toggleBox && (data[columns[i].key] !== undefined || data[columns[i].otherKey] !== undefined ) "
              for="toggleBox_table_{{j}}" (mousedown)="$event.stopPropagation()" (click)="$event.stopPropagation()">
              <i class="check">
                <svg role="img" title="check" class="check-icon">
                  <use href="assets/images/sprite.svg#icon-check"></use>
                </svg>
              </i>
            </label>
          </div>
        </ng-container>
      </li>
      <ng-container *ngIf="subTableKey">
        <li *ngFor="let subData of data[subTableKey]; let k = index " #liSubElement id="{{subData._id}}"
          [attr.data-order]="subData.order" (mousedown)="draggable && dragging($event)"
          [ngClass]="{'row-pointer': gotClickEvent || draggable, 'subElement': data.name, 'draggable': draggable}">
          <div *ngFor="let i of arrayLoop" class="main_table_li {{columns[i].class}} {{subData[columns[i].classKey]}}"
            title="{{columns[i].title}}">
            <figure *ngIf="columns[i].image && subData[columns[i].imageKey]">
              <img [src]="subData[columns[i].imageKey]" />
            </figure>
            <span *ngIf="columns[i].image && !subData[columns[i].imageKey]" class="initial">{{subData.name[0]}}</span>
            <span *ngIf="!columns[i].checkbox" class="{{columns[i].subClass}}">{{subData[columns[i].key]}}</span>
            <span *ngIf="subData[columns[i].subKey]"
              class="{{columns[i].subClass2}}">{{subData[columns[i].subKey]}}</span>
            <span *ngIf="subData[columns[i].subKey2]"
              class="{{columns[i].subClass3}}">{{subData[columns[i].subKey2]}}</span>
            <ul *ngIf="columns[i].hover" class="elements_hover">
              <li *ngFor="let element of subData[columns[i].hoverKey]">{{element}}</li>
            </ul>
            <div *ngIf="columns[i].toggle" class="icon_wrap" (click)="openToggle(subData);"
              (mousedown)="$event.stopPropagation()">
              <svg class="down" role="img" title="dots" class="dots-icon">
                <use href="assets/images/sprite.svg#icon-dots"></use>
              </svg>
            </div>
            <nav class="drop drop_more" *ngIf="subData.toggleOpen" [@moreAnimation]
              (mousedown)="$event.stopPropagation()">
              <ul>
                <li *ngFor="let link of columns[i].toggle" (click)="closeAllToggle(); link.method(subData, ref);"
                  class="{{link.class}}">
                  {{link.title}}</li>
              </ul>
            </nav>
            <input *ngIf="columns[i].checkbox" id="checkbox_table_{{j}}_{{k}}" type=checkbox
              (mousedown)="$event.stopPropagation()" />
            <label *ngIf="columns[i].checkbox" for="checkbox_table_{{j}}_{{k}}" (mousedown)="$event.stopPropagation()">
              <svg role="img" title="check" class="check-icon">
                <use href="assets/images/sprite.svg#icon-check"></use>
              </svg>
            </label>
            <input
              *ngIf="columns[i].toggleBox && (subData[columns[i].key] !== undefined || subData[columns[i].otherKey] !== undefined )"
              id="toggleBox_table_{{j}}_{{k}}" (mousedown)="$event.stopPropagation()" type=checkbox
              [checked]="subData[columns[i].key]" (change)="columns[i].callback(subData, ref)" />
            <label
              *ngIf="columns[i].toggleBox && (subData[columns[i].key] !== undefined || subData[columns[i].otherKey] !== undefined ) "
              for="toggleBox_table_{{j}}_{{k}}" (mousedown)="$event.stopPropagation()">
              <i class="check">
                <svg role="img" title="check" class="check-icon">
                  <use href="assets/images/sprite.svg#icon-check"></use>
                </svg>
              </i>
            </label>
          </div>
        </li>
      </ng-container>
    </ng-container>
  </ul>
</section>
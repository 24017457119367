import { Component, OnInit } from '@angular/core';
import { MemberService, UserService } from 'src/app/providers';
import { Subscription } from 'rxjs';
import User from 'src/app/models/user.model';
import { RoutesService } from 'src/app/services/routes.service';
import { Router } from '@angular/router';

// Animations
import { moreAnimation } from 'src/app/animations/animations';
import { ListComponent } from 'src/app/elements/list-component';
import { TableOptions } from 'src/app/models/type.definition';
import { StorageService } from 'src/app/services/storage.service';
import { first } from 'rxjs/operators';
import { IMember, translateRoles, translateStatus } from 'src/app/models/member.model';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss'],
  animations: [
    moreAnimation
  ]
})


export class TeamComponent extends ListComponent<IMember> implements OnInit {
  dataName = "Membre";
  me: User;

  public tableOptions: TableOptions[] = [
    {
      title: "Nom",
      class: "member",
      subClass: "name",
      subClass2: "email doselect",
      sort: true,
      key: 'name',
      subKey: 'email'
    },
    {
      title: "Statut",
      class: "phone",
      subClass: "doselect",
      sort: true,
      key: 'translatedStatus'
    },
    {
      title: "Rôle",
      class: "role",
      sort: true,
      key: 'currentRole'
    },
    {
      title: "Événements",
      class: "events",
      sort: true,
      key: 'eventsNames',
      hover: true,
      hoverKey: 'eventsNamesArray'
    },
    {
      class: "more",
      toggle: [
        {
          title: 'Modifier',
          method: this.editData
        },
        // {
        //   title: 'Désactiver',
        //   class: 'yellow',
        //   if: (data: IMember) => data.status == 'active',
        //   method: this.changeOrganisationStatus
        // },
        // {
        //   title: 'Réactiver',
        //   class: 'green',
        //   if: (data: IMember) => data.status != 'active',
        //   method: this.changeOrganisationStatus
        // },
        {
          title: 'Supprimer le membre',
          class: 'red',
          method: this.deleteData
        }
      ]
    }
  ]

  constructor(
    protected provider: MemberService,
    protected userService: UserService
  ) {
    super()
  }


  async beforeInit(): Promise<void> {
    this.me = await new Promise(res => {
      this.subscribeTo(this.userService.me$, user => user && res(user))
    });
  }

  setGetParams(): void {
    this.getParams.populate = ['organisationId', 'userId', 'restrictEventIds'];
    super.setGetParams();
  }

  formatElement(member: IMember): IMember {
    member.name = `${member?.userId.firstName} ${member?.userId.lastName}`;
    member.email = member?.userId.email;
    member.currentRole = translateRoles(member.roles).join(', ');
    member.translatedStatus = translateStatus(member.status);
    if (member.restrictEventIds.length) {
      member.eventsNamesArray = member.restrictEventIds.map(_ => _.name);
      member.eventsNames = member.eventsNamesArray.join(', ')
    }
    else {
      member.eventsNames = 'Tous'
    }
    return member
  }

  createMember() {
    this.router.navigate(['equipe/ajouter-un-membre']);
  }

  afterInit() {
    this.totalData = this.data.length;
  }

  public deleteData(member: IMember, ref: ListComponent<IMember>): void {
    ref.elementToDelete = member;
  }

  public deleteOk(): void {
    super.deleteData(this.elementToDelete, this, () => {
      this.elementToDelete = null;
    });
  }

  // public changeOrganisationStatus(member: IMember, ref = this): void {
  //   const status = member.status == 'active' ? 'blocked' : 'active'
  //   ref.provider.update(member.id, {status}).toPromise();
  // }

  public editData(data: IMember, ref = this) {
    ref.router.navigate([`${ref.currentUrl}/ajouter-un-membre/${data._id}`]);
  }

}

import { Component, HostListener, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { INotification } from "src/app/models/notification.model";
import { OrganisationNotificationService, OrganisationService } from "src/app/providers";
import { SocketService } from "src/app/services/socket.service";

@Component({
  selector: 'app-organisation-notifications-menu',
  templateUrl: './organisation-notifications-menu.component.html',
  styleUrls: ['./organisation-notifications-menu.component.scss']
})
export class OrganisationNotificationsMenuComponent implements OnInit {
  private page = 1;
  private maxReached = false;
  public count: number;
  public menuOpened = false;
  public notifications: INotification[] = null;
  public loading = false;
  public total: number;

  constructor(
    private notificationService: OrganisationNotificationService,
    private organisationService: OrganisationService,
    private router: Router,
    private socket: SocketService,
  ) { }

  ngOnInit(): void {
    this.getNotificationsCount();
    this.socket.socketEvent$.subscribe(event => {
      if (event.type == 'notification') {
        if (this.notifications) {
          this.total++;
          this.notifications.unshift(event.item);
          if (this.notifications.length > this.page * 5) {
            this.notifications.pop();
          }
          this.setMaxReached();
        }
        if (this.menuOpened) {
          this.updateReadedNotifications();
        }
        this.count++
      }
      if (event.type == 'connection') {
        this.notifications = null;
        this.maxReached = false;
      }
    })
  }


  async getNotificationsCount(): Promise<void> {
    const result = await this.notificationService.getList({ filter: { readed: false }, limit: 1 }).toPromise()
    this.count = result?.total || 0;
  }

  openMenu(): void {
    if (this.menuOpened) {
      this.menuOpened = false;
    }
    else {
      this.menuOpened = true;
      this.getNotifications();
    }
  }

  async getNotifications(fromScroll = false): Promise<void> {
    if (!this.maxReached && (fromScroll || !this.notifications)) {
      this.loading = true;
      setTimeout(async () => {
        const notifications = await this.notificationService.getList({ page: this.page, limit: 5 }).toPromise();
        this.loading = false;
        if (!this.notifications) {
          this.notifications = [];
          this.total = notifications?.total || 0;
        }
        if (notifications) {
          this.notifications.push(...notifications.data);
        }
        this.setMaxReached();
        this.updateReadedNotifications();
      }, 400)
    }
    else {
      this.updateReadedNotifications();
    }
  }

  private setMaxReached(): void {
    this.maxReached = this.notifications.length == this.total;
  }

  private updateReadedNotifications(): void {
    this.notifications.forEach(notification => {
      if (!notification.readed) {
        notification.readed = true;
        notification.tempBold = true;
        this.notificationService.update(notification._id, { readed: true }).subscribe(res => {
          this.count--
        });
      }
    })
  }

  public goTo(link: string): void {
    this.router.navigateByUrl(link);
  }

  public onScroll(target: HTMLDivElement): void {
    /** Margin of error of 5 */
    if (!this.loading && !this.maxReached && target.scrollTop + target.clientHeight >= target.scrollHeight - 5) {
      this.page++;
      this.getNotifications(true);
    }
  }

  @HostListener('document:click') closeMenu(): void {
    this.menuOpened = false;
    this.notifications?.forEach( notification => notification.tempBold = false)
  }
}
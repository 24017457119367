import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService, EventService, EventCategoryService, CurrencyService, OrganisationService, TaxService, CountryService } from 'src/app/providers';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, Validators, FormArray, AbstractControl, ValidationErrors } from '@angular/forms';
import { StorageService } from 'src/app/services/storage.service';
import { Router } from '@angular/router';
import { FormComponent } from 'src/app/elements/form-component';
import { IOrganisation } from 'src/app/models/organisation.model';
import { ICountry } from 'src/app/models/country.model';
import { ICurrency } from 'src/app/models/currency.model';
import { ITax } from 'src/app/models/tax.model';
import { IEventCategory } from 'src/app/models/event-category.model';
import { IEvent } from 'src/app/models/event.model';
import { IChart } from 'src/app/models/chart.model';
import { APICreateResponse } from 'src/app/models/type.definition';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.scss']
})
export class CreateEventComponent extends FormComponent<IEvent> {
  private _organisation: IOrganisation;

  protected dataName = 'Événement';

  public addressEntered: boolean;
  public eventsCount: number;
  public categories: IEventCategory[];
  public parentCategories: IEventCategory[];
  public childCategories: IEventCategory[];
  public selectedCategory: string;
  public tags: string[] = [];
  public countries: ICountry[];
  public currencies: ICurrency[];
  public taxes: ITax[];
  public dataLoading = { value: false };
  public session = false;
  public currentType: 'presential' | 'remote' = 'presential';
  public charts: IChart[] = [];

  public isAdmin: boolean;

  constructor(
    private authService: AuthService,
    protected formProvider: EventService,
    private categoriesService: EventCategoryService,
    private storageService: StorageService,
    private organisationService: OrganisationService,
    private currencyService: CurrencyService,
    private countryService: CountryService,
    private taxService: TaxService,
    private notification: NotificationService
  ) {
    super();
  }

  async onInit(): Promise<void> {
    this.isAdmin = this.authService.isAdmin;
    //this.subscribeTo(this.organisationService.selected$, data => this.handleData(data));
    this.countries = await this.getListOf(this.countryService, 'data');
    this.eventsCount = await this.getListOf(this.formProvider, 'total');
    this.currencies = await this.getListOf(this.currencyService, 'data');
    this.taxes = await this.getListOf(this.taxService, 'data')
    this.categories = await this.getListOf(this.categoriesService, 'data', { perPage: 500 });
    this.getCharts();
  }

  getCharts() {
    this.subscription.add(this.formProvider.getSeatingPlan().subscribe(results => {
      this.charts = [];
      for (let i = 0; i < results.data.length; ++i) {
        if (results.data[i].valid) {
          this.charts.push(results.data[i]);
        }
      }
    }));
  }

  setCategories(): void {
    this.parentCategories = this.categories.filter(_ => !_.parentId);
    this.mainForm.patchValue({ categoryId: this.parentCategories[0]?._id })
    this.selectCategory(this.parentCategories[0]?._id)
  }

  async handleData(data: any) {
    if (data) {
      if (!this.dataLoading.value) {
        this.dataLoading.value = true;
        const keys = ['bankAccount', 'name']
        const nextStep = await this.growthService.checkCurrent(data, keys, this.dataLoading, this.organisationService, 'selected$')
        if (nextStep) {
          this._organisation = data;
          if (this.mainForm) {
            this.mainForm.patchValue({ currencyId: data.bankAccount?.currencyId })
          }
        }
      }
    }
  }

  protected initForm(): void {
    this.addressEntered = false;
    this.mainForm = this.formBuilder.group({
      image: new FormControl(''),
      name: new FormControl('', Validators.required),
      categoryId: new FormControl(null),
      categoryId2: new FormControl(null),
      bookType: new FormControl('ticketing'),
      tags: new FormControl([]),
      dates: this.formBuilder.group({
        type: new FormControl('interval', Validators.required),
        startDate: new FormControl('', this.setOrMultiple),
        endDate: new FormControl(''),
        timeZone: "Europe/Paris"
      }),
      place: this.formBuilder.group({
        name: new FormControl(''),
        address: new FormControl(''),
        address2: new FormControl(''),
        zipcode: new FormControl(''),
        city: new FormControl(''),
        countryId: new FormControl(null)
      }),
      description: new FormControl(null),
      ticketing: this.formBuilder.group({
        defaultCurrencyId: new FormControl(this._organisation?.bankAccount?.currencyId._id || this.currencies[0]._id, Validators.required),
        taxId: new FormControl(null, Validators.required),

      }),
      commissionIncreased: new FormControl(false),
      langCode: new FormControl("FR"),
      isInSearch: new FormControl(false),
      agreedTerms: new FormControl(false, Validators.requiredTrue),
      eventLink: new FormControl(''),
      eventType: new FormControl('presential'),
      useRoomPlacement: new FormControl(false),
      seatsIoId: new FormControl(null, this.requiredIfValidator(() => this.mainForm.get('useRoomPlacement').value))
    });
    this.setCategories();
  }

  requiredIfValidator(predicate) {
    return (formControl => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return Validators.required(formControl);
      }
      return null;
    })
  }

  setOrMultiple(control: AbstractControl): ValidationErrors | null {
    const sessions = control.parent?.value.type == "sessions"
    return control.value || sessions ? null : { error: "Date needed" };
  }

  goBack(): void {
    this.router.navigate(['evenements'])
  }

  setDescriptionValue(value) {
    this.mainForm.patchValue({ description: value });
  }

  selectCategory(parentCategory: string): void {
    this.selectedCategory = parentCategory;
    this.childCategories = this.categories.filter(_ => {
      if (!_.parentId) return false;
      return _.parentId._id === parentCategory;
    });
    console.log(this.childCategories, this.selectedCategory);

    this.mainForm.patchValue({ categoryId2: this.childCategories[0]?._id })
  }

  uploadFile(file) {
    this.mainForm.patchValue({ file });
  }

  checkTypeDate(value: boolean): void {
    const type = value ? "sessions" : "interval";
    const dates = this.mainForm.get('dates') as FormGroup;
    this.session = value;
    dates.patchValue({ type });
    if (value) {
      dates.patchValue({ startDate: null , endDate: null });
    }
  }

  addTag(tag: any) {
    this.tags.push(tag.value);
    tag.value = "";
  }

  deleteTag(tag: string) {
    this.tags = this.tags.filter(_ => _ !== tag);
  }

  agreeTerms(value) {
    this.mainForm.patchValue({ agreedTerms: value })
  }

  changeIsInSearch(value) {
    this.mainForm.patchValue({ isInSearch: value })
  }

  changeSeatingPlan(value) {
    this.mainForm.patchValue({ useRoomPlacement: value })
  }

  submitForm(): void {
    const formData = this.mainForm.value;

    let pass = true;

    if (formData.useRoomPlacement) {
      if (formData.seatsIoId == null || formData.seatsIoId == '' || formData.seatsIoId == 'null') {
        pass = false;
        this.notification.newNotification({
          message: `Vous n'avez pas choisi de plan de salle`,
          state: 'error'
        });
      }
    }

    if (pass) {
      if (!Array.isArray(formData.place)) {
        formData.place = [formData.place];
      }
      formData.categoriesIds = [formData.categoryId];
      if (formData.categoryId2) {
        formData.categoriesIds.push(formData.categoryId2)
      }
      formData.organisationId = this.storageService.getItem('organisationId');
      if (this.currentType == 'presential') {
        formData.eventLink = null
      }
      else if(this.currentType == 'remote') {
        formData.place  = []
      }
      this.createOrUpdate(formData, async (res: APICreateResponse) => {
        const event = await this.formProvider.getById(res._id, { select: ['dates', 'name', 'langCode'] }).toPromise();
        this.formProvider.selectEvent(event);
        this.router.navigateByUrl(`evenements/${res._id}/general`);
      }, null, false );
    }
  }

  selectType(type: 'presential' | 'remote'): void {
    this.currentType = type;
  }

  setEnteredAddress(e) {
    this.addressEntered = e !== null;
    const place = this.mainForm.get('place');
    const country = this.countries.find(_ => _.name == e?.country);
    place.patchValue({
      address: e?.name,
      zipcode: e?.postcode,
      city: e?.city,
      countryId: country?._id
    });
  }

  selectTimeZone(timeZone: string) {
    const dates = this.mainForm.get('dates') as FormGroup;
    dates.patchValue({ timeZone });
  }

  checkType(_event) {
    if (_event === 'nft') {
      this.checkTypeDate(false);
      this.mainForm.get('description').setValidators(Validators.required);
    } else {
      this.mainForm.get('description').clearValidators();
    }
  }
}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormComponent, FormFromEventComponent } from 'src/app/elements/form-component';
import { IEvent } from 'src/app/models/event.model';
import { Invitations } from 'src/app/models/invitation.model';
import { ITicketType } from 'src/app/models/ticket.model';
import { GetParams } from 'src/app/models/type.definition';
import { InvitationService, TicketTypeService } from 'src/app/providers';
import { GetEvent } from '../../../getEvent';
import jspreadsheet from 'jspreadsheet-ce'

@Component({
  selector: 'app-event-ticketing-invitation-create',
  templateUrl: './event-ticketing-invitation-create.component.html',
  styleUrls: [
    './event-ticketing-invitation-create.component.scss',
  ],
  providers: [GetEvent]
})
export class EventTicketingInvitationCreateComponent extends FormFromEventComponent<Invitations> implements OnInit {
  @ViewChild("spreadsheet") spreadsheet: ElementRef<HTMLDivElement>;
  public event: IEvent;
  public dataName = 'Invitation'
  public displayEmail: boolean = false;
  public ticketTypes: {name: string, tickets: ITicketType[]}[];
  public sendEmail = false;
  public stock: number;
  public qtyOk: boolean = true;
  public spreadsheetOk: boolean = false;
  public table = null;
  protected getEventParams: GetParams<IEvent> = {select: ['status', 'ticketing']};

  constructor(
    protected formProvider: InvitationService,
    protected getEvent: GetEvent,
    protected ticketTypeService: TicketTypeService,
    protected route: ActivatedRoute
  ) {
    super()
  }

  ngAfterViewChecked() {
    if (this.table === null && this.mainForm) {
      if (this.mainForm.get('ticketTypeId').value) {
        this.table = jspreadsheet(this.spreadsheet.nativeElement, {
          data: [[]],
          columns: [
            { title: "Prénom", type: "text", width:190 },
            { title: "Nom", type: "text", width:190 },
            { title: "Quantité", type: "numeric", mask: '0', width:90 },
            { title: "Email", type: "text", width:190 }
          ],
          minDimensions: [4, 1 ],
          allowManualInsertColumn: false,
          allowManualInsertRow: false,
          columnResize: false,
          rowResize: false,
          ngInstance: this,
          contextMenu: () => false ,
          onchange: (instance, cell, x, y, value) => {
            this.beforeChange(instance, cell, x, y, value)
          }
        });
      }
    }
  }

  async onInit(): Promise<void> {

    if (this.event.status == 'draft') {
      this.router.navigate(['../'], {relativeTo: this.route})
    }
    const params: GetParams<ITicketType> = {
      select: ['name', 'stock', 'ticketTypeCategoryId'],
      populate: ['ticketTypeCategoryId']
    }
    const ticketTypes = await this.getListFromEventOf(this.ticketTypeService, params);
    const categories = [...new Set(ticketTypes.map(_ => _.ticketTypeCategoryId))];
    this.ticketTypes = categories.map(cat => {
      const tickets = ticketTypes.filter(_ => _.ticketTypeCategoryId == cat);
      return {
        name: tickets[0].ticketTypeCategoryId?.name || 'Sans Catégorie',
        tickets: tickets
      }
    });
  }

  initForm(): void {
    this.mainForm = this.formBuilder.group({
      ticketTypeId: new FormControl("", Validators.required),
      orderType: new FormControl('stockImpression'),
      amount: new FormControl(1),
      isPaid: new FormControl(false),
      sendEmail: new FormControl(false),
      emailObject: new FormControl(null, this.requiredIfSendByEmail),
      emailContent: new FormControl(null, this.requiredIfSendByEmail)
    });

    this.subscribeTo(this.mainForm.get('amount').valueChanges, res => {
      if (res !== null) {
        if (res > 100) {
          this.mainForm.patchValue({ amount: 100 })
        }
        else if(res < 1) {
          this.mainForm.patchValue({ amount: 1 })
        }
        else {
          const size = this.table.getData().length;
          if (res < size) {
            for (let i = size - 1; i >= res; i--) {
              this.table.deleteRow()
            }
          }
          else if (res > size) {
            [...new Array(res - size)].forEach(() => this.table.insertRow())
          }
        }
      }
    });
    this.subscribeTo(this.mainForm.get('sendEmail').valueChanges, res => this.sendEmail = res)
    this.subscribeTo(this.mainForm.get('ticketTypeId').valueChanges, res => this.calcStock(res))
  }

  public setEmailContentValue(emailContent: string): void {
    this.mainForm.patchValue({ emailContent })
  }

  verifyQuantity() {
    if (this.table !== null) {
      const qties = this.table.getData().reduce((sum, el) => {
        if (el[2] !== '') {
          const num = parseInt(el[2])
          return sum + num
        }
        return sum
      }, 0)

      this.qtyOk = this.stock == undefined || qties <= this.stock;
    }
  }

  requiredIfSendByEmail(control: AbstractControl): ValidationErrors | null {
    if (control.parent) {
      const formValue = control.parent.value;
      if (formValue.sendByEmail && (!formValue.emailObject || !formValue.emailContent)) {
        return { msg: "Email content is missing" }
      }
    }
    return null
  }

  submitForm(): void {
    const form = this.mainForm.value;
    const plurial = form.amount > 1 ? 's' : '';

    form.tickets = this.table.getData().filter(line => {
      return !(line[0] == '' && line[1] == '' && line[3] == '')
    }).map(line => {
      return {
        firstName: line[0],
        lastName: line[1],
        qty: Number(line[2]),
        email: line[3]
      }
    })
    delete form.amount;
    this.subscribeTo(this.formProvider.generate(form, this.event._id), res => {
      this.notificationService.newNotification({
        state: 'success',
        message: `Invitation${plurial} envoyée${plurial} avec succès`
      })
      this.goBack();
    })

  }

  goBack(): void {
    this.router.navigate([`evenements/${this.event._id}/billetterie/invitations/`]);
  }

  toggleEmail(): void {
    this.displayEmail = !this.displayEmail;
  }

  private calcStock(ticketId: string): void {
    this.stock = undefined;
    const event = this.event.ticketing
    const category = this.ticketTypes.find(cat => cat.tickets.find(ticket => ticket._id == ticketId));
    const ticket = category.tickets.find(ticket => ticket._id == ticketId);
    const cat = ticket.ticketTypeCategoryId
    if (ticket.quota) {
      this.stock = ticket.stock;
    }
    if (cat?.quota && (this.stock == undefined || this.stock > cat.stock)) {
      this.stock = cat.stock;
    }
    if (event.quota && (this.stock == undefined || this.stock > event.stock)) {
      this.stock = event.stock;
    }
    this.verifyQuantity();
  }

  beforeChange(instance, cell, x, y, value): void {
    this.spreadsheetOk = true;
    if (x == 2) {
      this.verifyQuantity()
    }

    const data = this.table.getData().filter(line => {
      return !(line[0] == '' && line[1] == '' && line[3] == '')
    })
    if (data.length == 0) {
      this.spreadsheetOk = false
    }

    let numLine = 1
    data.forEach(line => {
      this.table.setStyle(`D${numLine}`, 'color', 'black')
      if (line[0] == '' || line[1] == '' || line[2] == '' || line[3] == '') {
        this.spreadsheetOk = false
      }
      else if (!this.validateEmail(line[3])) {
        this.table.setStyle(`D${numLine}`, 'color', 'red')
        this.spreadsheetOk = false
      }
      numLine += 1
    })
  }

  validateEmail(mail): boolean {
    return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
  }

}

<div class="loading_container" [ngClass]="{ 'loaded' : data }">
  <span class="loader_global"></span>
  <div class="loading_content">
      <ng-container *ngIf="data">
          <div id="page_header" class="container">
              <h1>
                  Demandes de remboursements
                  <span>({{totalData}} demande{{totalData > 1 ? 's' : ''}})</span>
              </h1>
          </div>

          <div id="filters_wrap" class="container">
              <form id="filters_form">
                  <div class="fields" >
                      <div class="input_wrap select">
                          <select id="filter_status" (change)="changeStatus($event.target.value)">
                              <option value="todo">
                                  Non traitées
                              </option>
                              <option value="done">
                                  Remboursées
                              </option>
                              <option value="canceled">
                                  Non remboursées
                              </option>
                          </select>
                          <svg role="img" title="chevron" class="icon-chevron">
                              <use href="assets/images/sprite.svg#icon-chevron"></use>
                          </svg>
                      </div>
                  </div>
              </form>
          </div>

          <div *ngIf="data.length else noData">
              <app-main-table idSection="refund_content" idTable="table_refund" tableType="refund" [loading]="loading"
                  [columns]="tableOptions" [datum]="data" [ref]="this">
              </app-main-table>
              <app-pager *ngIf="totalPages > 1" [totalPages]="totalPages" [page]="page" (changePage)="changePage($event)">

              </app-pager>
          </div>
          <ng-template #noData>
              <section id="block_first" class="container">
                  <h2 *ngIf="totalData">Aucune demande de remboursement ne correspond à votre recherche !</h2>
                  <h2 *ngIf="!totalData">Aucune demande de remboursement n'a été faite pour le moment !</h2>
                  <figure>
                      <img src="assets/images/visuel_guichet.svg" />
                  </figure>
              </section>
          </ng-template>
      </ng-container>
  </div>
</div>


import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { io, Socket } from "socket.io-client";
import { OrganisationService, UserService } from '../providers';
import { INotification } from '../models/notification.model';
import { Subject } from 'rxjs';

export type SocketEvent<T = {}> = {
    type: string,
    typeId: string,
    action: string,
    item: T
}

@Injectable({
    providedIn: 'root'
})
export class SocketService {
    public readonly socketEvent$ = new Subject<SocketEvent>();
    private socket: Socket;
    private userId: string;
    private organisationId: string;

    constructor(
        private userService: UserService,
        private organisationService: OrganisationService
    ) {
        this.initSocket();
        this.getIds();
    }

    initSocket(): void {
        this.socket = io(environment.socketUrl, { transports: ['websocket', 'polling', 'flashsocket'] });
        this.socket.connect();
        this.receive();
    }

    getIds(): void {
        this.userService.me$.subscribe(user => {
            this.userId = user?.id
            this.connect();
        });
        this.organisationService.selected$.subscribe(organisation => {
            if (this.organisationId) {
                this.socket.emit('leaveOrganisation');
            }
            this.organisationId = organisation?._id
            this.connect();
        });
    }

    connect(): void {
        if (this.organisationId && this.userId) {
            this.send('organisation', this.organisationId, 'connect', {
                organisationId: this.organisationId,
                userId: this.userId
            });
            this.sendEvent('main', {
                type: 'connection',
                typeId: 'connection',
                action: 'connection',
                item: {}})
        }
    }

    send(type: string, typeId: string, action: string, item: { organisationId: string, userId: string }): void {
        this.socket.emit(type, {
            userId: this.userId,
            organisationId: this.organisationId,
            type, typeId, action, item
        });
    }

    receive(): void {

        // {
        // 	type: "notification",
        // 	typeId: "123456789",
        // 	action: "connect", // connect ; disconnect ; add ; update ; delete
        // 	item: {}
        // }

        this.socket.on('restartConnection', (_data) => {
            this.sendEvent('main', {
                type: 'restartConnection',
                typeId: 'restartConnection',
                action: 'restartConnection',
                item: {}
            });
        });

        this.socket.on('organisation', (_data) => {
            this.sendEvent('main', _data);
        });

        this.socket.on('notification', (data: INotification) => {
            this.sendEvent('main', {
                type: 'notification',
                typeId: 'notification',
                action: 'notification',
                item: data
            });
        });
    }

    sendEvent<T = {}>(type: 'main' , data: SocketEvent<T>): void {
        this.socketEvent$.next(data);
    }
}
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from '../user';
import { StorageService } from 'src/app/services/storage.service';
import { Requester } from '../requester';
import { OrganisationToken } from 'src/app/models/type.definition';


@Injectable()
export class AuthService extends Requester {
  protected url = "auth";
  public isAdmin: boolean;
  public readonly isAuth$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  /** `adminSimpleToken` will always be a token without organisationId.
   * It will be used to reinit the token when no event is selected  */
  public adminSimpleToken: string;

  constructor(
    private userService: UserService,
    private storageService: StorageService
  ) {
    super();
  }

  public auth(body: { email: string, id: string }): Observable<{ token: string, roles: string[] }> {
    return this.httpClient.post<{ token: string, roles: string[], isSuperAdmin: boolean }>(this.getUrl(), body);
  }

  public authOrganisation(id: string): Observable<OrganisationToken> {
    return this.httpClient.get<OrganisationToken>(this.getUrl(id));
  }

  public renewToken(): Observable<{ token: string, roles: string[] }> {
    return this.httpClient.get<{ token: string, roles: string[] }>(this.getUrl());
  }

  public saveToken(token: string): void {
    this.storageService.init(token);
    this.isAuth$.next(true);
    this.userService.getMe({ populate: ["organisation"]});
  }

  public logOut(): void {
    this.isAdmin = false;
    this.isAuth$.next(false);
    this.storageService.clear();
  }

  public setIfAdmin(isSuperAdmin: boolean): void {
    this.isAdmin = isSuperAdmin
  }

  public getIfAdmin(): boolean {
    return this.isAdmin;
  }
}

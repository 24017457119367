import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EditableData } from '../models/editable-data';
import { GetParams } from '../models/type.definition';
import { OrganisationService } from '../providers';

@Injectable({
  providedIn: 'root'
})
export class GrowthService {

  constructor() { }

  async checkCurrent(object, keys, inUse, service, observable, populate = []): Promise<boolean> {
    const wantedKeys = [];
    const objectKeys = Object.keys(object)
    for (const key of keys) {
      if (!objectKeys.includes(key)) {
        wantedKeys.push(key);
      }
    }
    if (wantedKeys.length > 0) {
      const getOptions = {
        select: wantedKeys,
        populate
      }
      return await new Promise(res => {
        service.getById(object._id, getOptions)
          .toPromise()
          .then(data => {
            const newKeys = Object.keys(data);
            for (const key of newKeys) {
              object[key] = data[key];
            }
            inUse.value = false
            service[observable].next(object)
            res(false);
          });
      });
    }
    else {
      return true
    }
  }

  async addElementsOnObject<T extends EditableData>(
    options: GetParams<T>,
    data: T,
    service: { getById: (id: string, getOptions: GetParams<T>) => Observable<T> },
    getSelectKey: (key: string) => string,
    toPromise: <T>(obs: Observable<T>) => Promise<T>) {
    let reloadNecessary = false;
    const objectKeys = Object.keys(data);
    let getOptions = { select: [], populate: [] };
    options.populate?.forEach(element => {
      if (!objectKeys.includes(element as string)) {
        reloadNecessary = true;
        getOptions.populate.push(element);
        getOptions.select.push(getSelectKey(element as string));
      }
    });
    options.select?.forEach(element => {
      if (!objectKeys.includes(element as string) && !getOptions.select.includes(element)) {
        reloadNecessary = true;
        getOptions.select.push(element);
      }
    });
    if (reloadNecessary) {
      const newObject = await toPromise(service.getById(data._id, { ...getOptions }));
      const newKeys = Object.keys(newObject);
      for (const key of newKeys) {
        data[key] = newObject[key];
      }
    }
    return data;
  }

  updateObject(obj1, obj2) {
    for (const key in obj2) {
      if (typeof obj2[key] === 'object') {
        obj1[key] = this.updateObject(obj1[key], obj2[key]);
      }
      else {
        obj1[key] = obj2[key];
      }
    }
    return obj1;
  }

}

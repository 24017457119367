import { Injectable } from '@angular/core';
import { Provider } from '../provider';
import { IOrderImport } from '../../models/order-import.model';
import { APICreateResponse, APIResponse } from 'src/app/models/type.definition';
import { Observable } from 'rxjs';
import { HTTPTools } from 'src/app/etc/http-tools';

@Injectable()
export class ImportOrderService extends Provider<IOrderImport> {
  protected url = "events/orders/import";

  public create(body: FormData): Observable<APICreateResponse> {
    HTTPTools.setNextContentType(null);
    return this.httpClient.post<APICreateResponse>(this.getUrl('create'), body);
  }

  public start(id: string, body: FormData): Observable<APICreateResponse> {
    HTTPTools.setNextContentType(null);
    return this.httpClient.post<APICreateResponse>(this.getUrl(`start/${id}`), body);
  }

  public deleteTickets(id: string): Observable<APICreateResponse> {
    HTTPTools.setNextContentType(null);
    return this.httpClient.delete<APIResponse>(this.getUrl(`delete/tickets/${id}`));
  }

  public streamFile(id: string): Observable<Blob> {
    HTTPTools.setNextContentType(null);
    return this.httpClient.get(this.getUrl(`download-file/${id}`), { responseType: 'blob' });
  }
}

import { Component } from '@angular/core';
import { ListFromEventComponent } from 'src/app/elements/list-component';
import { EventSiteService, EventWidgetService, MediaService } from 'src/app/providers';
import { TableOptions } from 'src/app/models/type.definition';
import { GetEvent } from '../getEvent';
import { IMedia } from 'src/app/models/media.model';
import * as config from 'src/config.json';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-event-exclusive-content',
  templateUrl: './event-exclusive-content.component.html',
  styleUrls: ['./event-exclusive-content.component.scss'],
  providers: [GetEvent]
})
export class EventExclusiveContentComponent extends ListFromEventComponent<IMedia> {
  protected dataName = 'Media';
  public mediaToDelete: { id: string, name: string };
  public tableOptions: TableOptions[] = [
    {
      title: "Nom",
      class: "name",
      sort: true,
      key: 'name',
    },
    {
      class: 'url',
      subClass: "doselect",
      title: "Liens",
      link: true,
      target: "_blank",
      key: "url"
    },
    this.DefaultToggle
  ];

  constructor(
    protected provider: MediaService,
    protected getEvent: GetEvent
  ) {
    super();
  }

  async afterInit(): Promise<void> {

  }

  protected setGetParams(): void {
    this.getParams.filter.type = 'eventId';
    this.getParams.filter.typeId = this.event._id;
    this.getParams.filter.category = 'exclusiveContent';
    this.getParams.select = ['name', 'mimetype', 'url', 'ticketTypeIds'];
    this.getParams.populate = ['ticketType'];
    this.getParams.page = 0;
  }

  public createData(): void {
    super.createData();
  }

  public deleteData(media: IMedia): void {
    this.mediaToDelete = {
      id: media._id,
      name: media.name
    }
  }

  validateDeletion(): void {
    super.deleteData(this.mediaToDelete, this, () => {
      this.mediaToDelete = null;
    });
  }
}

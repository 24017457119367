import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { IEvent } from 'src/app/models/event.model';
import { GetEvent } from '../../getEvent';
import { createFormData } from 'src/app/etc/utilities'
import { FormComponent } from 'src/app/elements/form-component';
import { IMedia } from 'src/app/models/media.model';
import { EventService, TicketTypeService } from 'src/app/providers';
import { WysiwygComponent } from 'src/app/elements/wysiwyg/wysiwyg.component';
import { FileInputComponent } from 'src/app/elements/file-input/file-input.component';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { GetParams, FormOf } from 'src/app/models/type.definition';
import { ITicketType, ITicketTypeCategory } from 'src/app/models/ticket.model';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-event-ticketing-eticket-unique',
  templateUrl: './event-ticketing-eticket-unique.component.html',
  styleUrls: ['./event-ticketing-eticket-unique.component.scss'],
  providers: [GetEvent]
})
export class EventTicketingEticketUniqueComponent extends FormComponent<IEvent> {
  @ViewChild(WysiwygComponent) wysiwyg: WysiwygComponent;
  @ViewChild(FileInputComponent) fileInput: FileInputComponent;
  public dataName = 'E-Ticket';
  public error: string;
  public loadingState$: Subject<void> = new Subject<void>();
  public previousImage: Blob;
  public previousText: string;
  public dataPreview: any;
  public ticketType: ITicketType;
  // public isDefaultCustom: boolean;
  constructor(
    private getEvent: GetEvent,
    protected formProvider: EventService,
    public router: Router,
    private route: ActivatedRoute,
    private rateService: TicketTypeService,
    private notification: NotificationService,
  ) {
    super();
  }

  async onInit() {
    this.data = await this.getEvent.get({ select: ['ticketing', 'place', 'status'] });
    const id = this.route.snapshot.paramMap.get('ticketId');
    if (id) {
      const getParams: GetParams<ITicketType> = {
        template: 'full'
      }
      this.ticketType = await this.rateService.getById(id, getParams).toPromise();
      // if (this.ticketType && this.ticketType.ticketVisual && (this.ticketType.ticketVisual.text != null || this.ticketType.ticketVisual.imageId != null)) {
      //   this.isDefaultCustom = false;
      //   // this.data.ticketing.ticketVisual = this.ticketType.ticketVisual;
      // } else {
      //   this.isDefaultCustom = true;
      // }
    }

    this.dataPreview = this.data;
    this.previousText = this.ticketType.ticketVisual?.text || '';
    this.getImage();
    this.loaded = true;
    this.createImagesChangeState(['imageId']);
  }

  private async getImage(): Promise<void> {
    const id = this.ticketType.ticketVisual.imageId._id;
    if (id) {
      this.loadingImage.push("imageId");
      this.previousImage = await this.mediaService.getStream(id).toPromise();
      // if (this.isDefaultCustom) {
      //   this.data.ticketing.ticketVisual.image = this.previousImage;
      //   this.dataPreview.ticketing.ticketVisual.image = this.previousImage;
      // } else {
      this.ticketType.ticketVisual.image = this.previousImage;
      // }

      this.loadingImage.splice(this.loadingImage.indexOf("imageId"));
    }
  }

  initForm() {
    this.mainForm = this.formBuilder.group({
      image: new FormControl(null),
      imageId: new FormControl(this.data.ticketing.ticketVisual.imageId),
      text: new FormControl(this.previousText)
    });
  }

  setDescriptionValue(value: string) {
    this.mainForm.patchValue({ text: value });
    this.ticketType.ticketVisual.text = value;
  }

  async submitForm() {
    const formData: any = {
        ...this.ticketType,
        ticketVisual: this.mainForm.value
    }
    this.getFileToCreate(this.mainForm.value.image, 'imageId', '', 'ticketVisual.imageId');
    if (this.filesToCreate[0]) {
      await this.createOrDeleteMedia(formData, 0, 'eventId');
    }
    this.rateService.update(this.ticketType._id, formData).subscribe(result => {
      this.notification.newNotification({
        message: `Apparence modifiée avec succès`,
        state: 'success'
      });
      this.loadingState$.next();
    }, err => {
      this.loadingState$.next();
    });
  }

  public changeImage(file: File): void {
    const image = this.ticketType.ticketVisual.imageId;
    if (this.imageChanged.imageId.load || !image) {
      this.imageChanged.imageId.changed = true;
    }
    else {
      this.imageChanged.imageId.load = true;
    }
    this.setValue('image', file);
    if (!file) {
      this.setValue('imageId', null);
    }
    this.ticketType.ticketVisual.image = file;
  }

  public async cancel(): Promise<void> {
    this.mainForm.patchValue({ text: this.previousText, image: this.previousImage });
    this.ticketType.ticketVisual.image = this.previousImage;
    this.ticketType.ticketVisual.text = this.previousText;
    this.wysiwyg.reset();
    this.fileInput.reset();
    this.loadingState$.next();
  }
}

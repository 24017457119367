import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { CustomValidators } from 'src/app/etc/form-validators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {
  @Output() sendFilter: EventEmitter<any> = new EventEmitter();
  @Input() public statusArray: { name: string, trad: string, checked: boolean }[];
  @Input() public dates: { start?: string, end?: string } = {};
  public filtersForm: FormGroup;
  public loadingState$: Subject<void> = new Subject<void>();
  constructor(
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.filtersForm = this.formBuilder.group({
      startDate: new FormControl(this.dates.start, CustomValidators.dateNotAfterEnd),
      endDate: new FormControl(this.dates.end, CustomValidators.dateNotBeforeStart)
    });
  }

  addFilter() {
    const formData = this.filtersForm.value;
    formData.status = this.statusArray.filter(_ => _.checked);
    this.sendFilter.emit(formData);
    this.loadingState$.next();
  }

  stopLoading() {
    this.loadingState$.next();
  }

  statusChecked(status) {
    status.checked = !status.checked
  }
}

import { Component, OnInit } from '@angular/core';
import { ListFromEventComponent } from 'src/app/elements/list-component';
import { IAddon } from 'src/app/models/addon.model';
import { TableOptions } from 'src/app/models/type.definition';
import { AddonService } from 'src/app/providers';
import { GetEvent } from '../../getEvent';

@Component({
  selector: 'app-event-ticketing-addon',
  templateUrl: './event-ticketing-addon.component.html',
  styleUrls: ['./event-ticketing-addon.component.scss'],
  providers: [GetEvent]
})


export class EventTicketingAddonComponent extends ListFromEventComponent<IAddon> {
  public dataName = "Addon"
  public addonToDelete: { _id: string, name: string };
  public tableOptions: TableOptions<IAddon>[] = [{
    title: "Nom",
    class: "name",
    sort: true,
    key: 'name',
    sortMethod: (order) => this.sort("name", order),
  },
  {
    title: "Prix",
    class: "price",
    sort: true,
    key: 'priceToDisplay',
    sortMethod: (order) => this.sort("price", order)
  },
  {
    title: "Quota",
    class: "quota",
    sort: false,
    key: 'formattedQuota'
  },
  {
    title: "Tarifs",
    class: "rates",
    sort: false,
    key: 'ticketTypesNames',
    hover: true,
    hoverKey: 'ticketTypesNamesArray'
  },
  {
    class: "more",
    toggle: [
      {
        title: 'Modifier',
        method: this.editData
      },
      {
        title: 'Supprimer',
        class: "red",
        method: this.deleteData
      }
    ]
  }]

  constructor(protected provider: AddonService, protected getEvent: GetEvent) {
    super()
  }

  protected setGetParams(): void {
    this.getParams.select = ['ticketTypeIds', 'name', 'price', 'quota', 'stocks'];
    this.getParams.populate = ['ticketTypeIds'];
    super.setGetParams();
  }

  protected formatElement(addon: IAddon): IAddon {
    let sold = 0;
    if (addon.quota) {
      sold = addon.stocks.sold || 0;
    }

    addon.formattedQuota = `${sold}/${addon.stocks.quota || 'ꝏ'} (${addon.stocks.inDraft})`

    addon.ticketTypesNamesArray = addon.ticketTypeIds.map( _ => _.name);
    if (addon.ticketTypesNamesArray.length) {
      addon.ticketTypesNames = addon.ticketTypesNamesArray.join(', ');
    }
    else {
      addon.ticketTypesNames = 'Aucun'
    }
    addon.priceToDisplay = (addon.price / 100).toFixed(2) + '€';
    return addon;
  }

  public deleteData(addon: IAddon, ref = this): void {
    ref.addonToDelete = {
      _id: addon._id,
      name: addon.name
    }
  }

  public validateDeletion(): void {
    super.deleteData(this.addonToDelete, this, () => {
      this.addonToDelete = null;
    });
  }

}

import { Component, HostListener } from '@angular/core';
import { EventService, MemberService } from 'src/app/providers';
import { FormControl, ValidationErrors, Validators } from '@angular/forms';
import { FormComponent } from 'src/app/elements/form-component';
import { IEvent } from 'src/app/models/event.model';
import { StorageService } from 'src/app/services/storage.service';
import { IMember } from 'src/app/models/member.model';

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.scss']
})
export class AddMemberComponent extends FormComponent<IMember> {
  public dataName = "Membre"
  public organisationId: string;
  public members: number;
  public passwordsOk: boolean;
  public events: IEvent[];
  public checkedEvents: string[] = [];
  public dropDown = false;
  public showPwd: boolean = false;
  public showPwd2: boolean = false;
  public role: string;


  constructor(
    private eventService: EventService,
    protected formProvider: MemberService,
    protected storage: StorageService
  ) {
    super()
  }

  async onInit(): Promise<void> {
    this.organisationId = this.storage.getItem('organisationId');
    this.events = await this.getListOf(this.eventService, 'data', {
      filter: { organisationId: this.organisationId },
      perPage: 500
    });
    this.members = (await this.getListOf(this.formProvider)).data.length;
    await this.getDataToEdit('id', { populate: ['userId', 'organisationId', 'restrictEventIds'] })
    if (this.data) {
      this.checkedEvents = this.data.restrictEventIds.map(_ => _._id);
      //this.data._id = this.data._id;
      this.role = this.data.roles[0];
    }
  }

  initForm(): void {
    this.mainForm = this.formBuilder.group({
      lastName: new FormControl(this.data?.userId.lastName, Validators.required),
      firstName: new FormControl(this.data?.userId.firstName, Validators.required),
      email: new FormControl(this.data?.userId.email, [Validators.required, Validators.email]),
      role: new FormControl(this.role, Validators.required),
      password: new FormControl('', this.getValidators()),
      restrictEventIds: new FormControl(this.checkedEvents)
    })
  }

  getValidators(): ValidationErrors {
    return this.data ? [] : [Validators.required, Validators.minLength(8)];
  }

  checkPassword(pwd1: string, pwd2: string): void {
    this.passwordsOk = pwd1 === pwd2;
  }

  submitForm(): void {
    const form = this.mainForm.value;
    form.roles = [form.role];
    form.organisationId = this.organisationId;
    form.restrictEventIds = this.checkedEvents;
    delete form.role;
    this.createOrUpdate(form);
  }

  @HostListener('document:click') clickDoc(): void {
    this.dropDown = false;
  }

  public checkEvent(id: string, value: boolean): void {
    const inArray = this.checkedEvents.includes(id);
    if (value && !inArray) {
      this.checkedEvents.push(id);
    }
    else if (!value && inArray) {
      this.checkedEvents = this.checkedEvents.filter(_ => _ !== id);
    }
  }

  public eventChecked(id: string): boolean {
    return this.checkedEvents.includes(id) || null;
  }

  public getEventsName(): string {
    const names = this.events.filter(_ => this.checkedEvents.includes(_._id));
    if (!names.length) {
      return 'Tous';
    }
    else {
      const fullString = names.map(_ => _.name).join(', ');
      return fullString.length < 33 ? fullString : `${fullString.substring(0, 30)} ...`
    }
  }

  public goBack(): void {
    this.router.navigate([this.router.url.split('ajouter-un-membre')[0]]);
  }


}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIListResponse } from 'src/app/models/type.definition';
import { ICurrency } from 'src/app/models/currency.model';
import { ReactiveProvider } from '../reactive-provider';

@Injectable()
export class CurrencyService extends ReactiveProvider<ICurrency> {
  protected url = "vocabularies/currencies";

  public getAll(): Observable<APIListResponse<ICurrency> | ICurrency[]> {
    if (!this.data$.value) {
      return this.getList();
    }
    else {
      return this.data$;
    }
  }

  public getCurrencySymbol(currency: ICurrency): string {
    switch (currency.isoCode) {
      case 'EUR': return '€';
      case 'GBP': return '£';
      case 'USD': return '$';
    }
  }
}

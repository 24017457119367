import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClickOutService {

  closeToggle$: Subject<void> = new Subject();
  
  constructor() { }

  closeAll(){
    this.closeToggle$.next();
  }

}

import { Injectable } from '@angular/core';
import { Requester } from '../requester';
import { Observable } from 'rxjs';

@Injectable()
export class ResetService extends Requester {
  protected url = "users/reset";

	public recoverPassword(body: { email: string }): Observable<{ id: string, msg: string }> {
		return this.httpClient.post<{ id: string, msg: string }>(this.getUrl('send'), body);
	}

	public checkCodeReset(body: { id: string, code: string }): Observable<{ msg: string }> {
		return this.httpClient.post<{ msg: string }>(this.getUrl('check'), body);
	}

	public setNewPassword(body: { id: string, code: string, data: { password: string } }): Observable<{ token: string }> {
		return this.httpClient.post<{ token: string }>(this.getUrl('setNewPassword'), body);
	}

}

<section id="form_page" class="container loading_container" [ngClass]="{ 'loaded' : mainForm }">
	<div class="loading_content">
		<div id="page_header">
			<h1>Invitations</h1>
			<app-button (clickEvent)="goBack()" text="retour" [style]="'border blue'"></app-button>
		</div>
		<div id="form_wrapper">
			<h2>Créer une invitation</h2>
			<form *ngIf="mainForm" [formGroup]="mainForm" class="large">

				<div class="form_item">
					<label for="ticketTypeId">Tarif</label>
					<div class="input_wrap select">
						<select id="ticketTypeId" formControlName="ticketTypeId">
							<ng-container *ngFor="let category of ticketTypes">
								<optgroup label="{{category.name}}">
									<option *ngFor="let ticket of category.tickets" [value]="ticket._id">
										{{ticket.name}}
									</option>
								</optgroup>
							</ng-container>
						</select>
						<svg role="img" title="chevron" class="icon-chevron">
							<use href="assets/images/sprite.svg#icon-chevron"></use>
						</svg>
					</div>
					<div *ngIf="stock != undefined" class="stock_info">
						<p class="description_medium">{{stock}} place(s) restante(s) pour
							ce tarif</p>
					</div>
				</div>
				<div class="form_item">
					<label for="orderType">Type de commande</label>
					<div class="input_wrap select">
						<select id="orderType" formControlName="orderType">
							<option value="stockImpression">Impression de stock</option>
							<option value="invitation">Invitation</option>
						</select>
						<svg role="img" title="chevron" class="icon-chevron">
							<use href="assets/images/sprite.svg#icon-chevron"></use>
						</svg>
					</div>
				</div>
				<div *ngIf="mainForm.get('orderType').value == 'stockImpression'" class="form_item full">
					<input id="isPaid" type="checkbox" class="toggle" formControlName="isPaid">
					<label for="isPaid">
						Marquer les commandes comme "payées"
						<i class="check">
							<svg role="img" title="check" class="check-icon">
								<use href="assets/images/sprite.svg#icon-check"></use>
							</svg>
						</i>
					</label>
				</div>
				<div *ngIf="mainForm.get('ticketTypeId').value" class="form_item">
					<label for="amount">Nombre de commandes</label>
					<input type="number" formControlName="amount" />
					<div class="stock_info">
						<p class="description_medium">Maximum 100 commandes par création</p>
					</div>
				</div>
				<div *ngIf="mainForm.get('ticketTypeId').value" class="form_item full">
					<label for="contactDetails">Coordonnées</label>
					<div id="spreadsheet" #spreadsheet></div>
				</div>
				<div class="form_item full">
					<input id="sendByEmail" type="checkbox" class="toggle" formControlName="sendEmail">
					<label for="sendByEmail">
						Envoyer les commandes par E-mail
						<i class="check">
							<svg role="img" title="check" class="check-icon">
								<use href="assets/images/sprite.svg#icon-check"></use>
							</svg>
						</i>
					</label>
				</div>
				<div class="form_item full no_margin" *ngIf="sendEmail">
					<div class="form_item">
						<label for="amount">Objet de l'e-mail</label>
						<input type="text" formControlName="emailObject" />
					</div>
					<div (keydown.enter)="$event.stopPropagation()" class="form_item no_margin">
						<label for="description">Contenu de l'e-mail</label>
						<app-wysiwyg (changeText)="setEmailContentValue($event)"></app-wysiwyg>
					</div>
				</div>

				<div class="stock_info">
					<p *ngIf="!qtyOk" class="error">Les quantités séléctionnées dépassent le stock disponible pour ce
						ticket</p>
				</div>

				<div class="form_actions right">
					<app-button (clickEvent)="goBack()" text="Annuler" [style]="'border blue'"></app-button>
					<app-button [style]="'plain'" type="submit" [disabled]="mainForm.invalid || !qtyOk || !spreadsheetOk"
						(clickEvent)="submitForm()" text="Valider">
					</app-button>
				</div>
			</form>
			<!-- END FORMULAIRE EN DUR -->
		</div>
	</div>
</section>
<div class="loading_container" [ngClass]="{ 'loaded' : codes }">
    <div class="loading_content">
        <div id="page_header" class="container">
            <div id="page_header_left">
                <h1>Codes promos</h1>
            </div>
            <div id="page_header_right">
                <app-button (clickEvent)="displayCreateToggle()" [loadingFunction]="false" class="button"
                    text="Nouveau code promo" [style]="'plain'"></app-button>
                <nav class="drop drop_more" *ngIf="createToggleOpen" [@moreAnimation]>
                    <ul>
                        <li *ngFor="let link of createToggle.links" (click)="link.method(createToggle.ref)"
                            (click)="$event.stopPropagation()" class="{{link.class}}">{{link.title}}</li>
                    </ul>
                </nav>
            </div>
        </div>

        <section *ngIf="codes && !codes.length" id="block_first" class="container">
            <p class="description grey">Vous n’avez pas encore de codes promos pour cet événement.</p>
            <figure>
                <img src="assets/images/visuel_promo.svg" />
            </figure>
            <app-button (click)="createPromoCode()" class="button_create no_margin" text="Créer un code promo"
                [style]="'plain full'"></app-button>
            <app-button (click)="generatePromoCode()" class="button_create" text="Générer un code promo"
                [style]="'border blue full'"></app-button>
        </section>

        <app-main-table *ngIf="codes && codes.length" [ref]="this" idSection="codes_content" idTable="table_codes"
        tableType="codes" [columns]="tableOptions" [datum]="codes"></app-main-table>

        <app-pager *ngIf="totalPages" [totalPages]="totalPages" [page]="page" (changePage)="changePage($event)">
        </app-pager>

        <app-delete-popup *ngIf="codeToDelete" [name]="codeToDelete.name" type='code promo'
        (clickEvent)="validateDeletion()" (close)="codeToDelete = null"></app-delete-popup>
    </div>
</div>

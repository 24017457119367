import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { moreAnimation } from 'src/app/animations/animations';
import { ListComponent } from 'src/app/elements/list-component';
import { IEvent } from 'src/app/models/event.model';
import { IOrder, translateStatus, translatePaymentMethod, translatedOrigin } from 'src/app/models/order.model';
import { IOrganisation } from 'src/app/models/organisation.model';
import { GetParams, TableOptions } from 'src/app/models/type.definition';
import { EventService, OrganisationService, OrderService, CurrencyService, TicketService, RefundService } from 'src/app/providers';
import { DateService } from 'src/app/services/date.service';
import { FilesHandler } from 'src/app/services/file-handler.service';

@Component({
  selector: 'app-adminOrders',
  templateUrl: './adminOrders.component.html',
  styleUrls: ['./adminOrders.component.scss'],
  animations: [
    moreAnimation
  ]
})
export class AdminOrdersComponent extends ListComponent<IOrder>  {
  dataName = "Billetterie";
  public filtersForm: FormGroup;
  public noEmail = false;
  public moreFilters: boolean = false;
  public selectedStatus: string = 'completed';
  public selectedOrigin: string = null;
  public organisationToEdit: IOrganisation;
  public elementToDelete: IOrder;
  public firstDate: Date;
  public dateSelected = [null, null];
  public orderToPay: IOrder
  public resendConfirmOfOrder: IOrder;
  public orderToCancel: IOrder
  public orderToRefund: IOrder
  public fullLoader: boolean = false;
  public actionToggleOpen: boolean = false;
  public tableOptions: TableOptions<IOrder>[] = [
    {
      title: "Origine",
      class: "origin",
      classKey: "origin",
      tooltip: true,
      tooltipKey: 'translatedOrigin',
      sort: true,
      hover: true,
      sortMethod: order => this.sort("origin", order),
    },
    {
      title: "Nom (acheteur)",
      class: "name",
      tooltip: true,
      tooltipKey: 'eventName',
      sort: true,
      key: 'name',
      sortMethod: order => this.sort("name", order),
    },
    {
      title: "N° de commande",
      class: "number",
      subClass: "doselect",
      sort: true,
      tooltip: false,
      key: 'orderNumber',
      sortMethod: order => this.sort("orderNumber", order),

    },
    {
      title: "Date",
      class: "date",
      sort: true,
      key: 'date',
      sortMethod: order => this.sort("createdAt", order),
    },
    {
      title: "Montant",
      class: "amount",
      sort: true,
      key: 'amount',
      sortMethod: order => this.sort("totalPrice.sellingPrice", order),
    },
    {
      title: "Statut",
      class: "status",
      classKey: "status",
      sort: true,
      key: 'translatedStatus',
      sortMethod: order => this.sort("status", order),

    },
    {
      class: "more",
      toggle: [
        {
          title: 'Voir le détail',
          method: this.view
        },
        {
          title: 'Télécharger la facture',
          if: data => ['completed'].includes(data.status),
          method: this.download
        },
        {
          title: "Télécharger les tickets",
          if: data => ['completed'].includes(data.status),
          method: this.downloadTickets
        },
        {
          title: 'Marquée comme payée',
          if: data => ['notPaid'].includes(data.paymentStatus),
          method: this.payOrder
        },
        {
          title: 'Annuler et rembourser',
          if: data => ['paid', 'partiallyPaid'].includes(data.paymentStatus) && !!data.totalPrice.sellingPrice,
          method: this.askRefund
        },
        {
          title: 'Annuler sans rembourser',
          if: data => ['completed'].includes(data.status) && !!data.totalPrice.sellingPrice,
          class: "red",
          method: this.cancelOrder
        },
        {
          title: 'Annuler la commande',
          if: data => ['completed'].includes(data.status) && !data.totalPrice.sellingPrice,
          class: "red",
          method: this.cancelOrder
        },
      ]
    }
  ]

  constructor(
    protected provider: OrderService,
    protected dateService: DateService,
    private currencyService: CurrencyService,
    protected ticketService: TicketService,
    private filesHandler: FilesHandler,
    private refundService: RefundService,
    protected organisationService: OrganisationService) {
    super()
  }

  protected async afterInit(): Promise<void> {
    this.initForm();
  }

  initForm(): void {
    this.filtersForm = this.formBuilder.group({
      search: new FormControl(),
      createdAt__$lte: new FormControl(),
      createdAt__$gte: new FormControl(),
      status: new FormControl(),
    });
  }

  public setSearch(search: string): void {
    this.filtersForm.patchValue({ search })
  }

  setGetParams(): void {
    this.getParams.filter.status = this.selectedStatus || 'draft,pending,completed,canceled';
    this.getParams.filter.origin = this.selectedOrigin;
    this.getParams.select = ['origin', 'name', 'createdAt', 'status', 'orderNumber', 'totalPrice',
      'token', 'payment', 'eventWidgetId', 'invoiceData', 'isProduct', 'eventId', 'organisationId.name'];
    this.getParams.populate = ['eventId', 'organisationId']
    super.setGetParams();
  }

  protected async getData(): Promise<void> {
    this.loading = true;
    this.setGetParams();
    this.getParams.page = this.page;

    let results = await this.provider.getList(this.getParams).toPromise();
    if (results) {
      this.data = await Promise.all(results?.data.map(async element => this.formatElement(element)));
      this.currentTotalData = results.total;
      this.page = results?.pager?.page;
      this.totalPages = results?.pager?.pages;
      this.loadingState$.next();
      this.loading = false;
    }
    else {
      this.data = [];
      this.loadingState$.next();
      this.loading = false;
      this.currentTotalData = 0;
      this.totalPages = 0;
    }
    if (this.totalData == undefined) {
      this.totalData = this.currentTotalData || 0
    }
  }

  protected formatElement(order: IOrder): IOrder {

    if (order.payment === undefined) {
      order.payment = { "method" : ''}
    }

    order.eventName = order.eventId?.name;
    order.translatedStatus = translateStatus(order.status);
    console.log("====", order.status, order.translatedStatus);

    order.date = this.dateService.formatDateAndTime(order.createdAt);
    order.amount = order.totalPrice.sellingPrice / 100 + "" + this.currencyService.getCurrencySymbol(order.totalPrice.currency);
    order.totalPrice.sellingPrice /= 100;
    order.translatedMethod = translatePaymentMethod(order.payment.method);
    order.translatedOrigin = translatedOrigin(order.origin)
    if (order.invoiceData?.firstName && order.invoiceData?.lastName) {
      order.name = `${order.name} (${order.invoiceData.firstName} ${order.invoiceData.lastName})`
    }
    else {
      order.name = `${order.name} (aucun acheteur)`
    }

    if (order.origin == 'invitation') {
      order.amount = 'Gratuit'
    }
    order.type = order.isProduct ? "Produit(s)" : "Ticket(s)";
    return order;
  }

  public filter(): void {
    this.page = 1;
    this.addFilterOnNextRequest = true;
    this.setGetParams()
    this.getData();
  }

  public view(order: IOrder, ref = this): void {
    ref.router.navigate(['/evenements/' + order.eventId._id + '/commandes/' + order._id]);
  }

  public resendConfirm(order: IOrder, ref = this): void {
    ref.resendConfirmOfOrder = order;
  }

  public resendConfirmOk(): void {
    this.provider.resendAnOrderConfirmation(this.resendConfirmOfOrder._id).subscribe(_result => {
      this.resendConfirmOfOrder = null
      if (_result) {
        this.notificationService.newNotification({
          message: 'La confirmation a bien été renvoyée',
          state: 'success'
        })
      }
      else {
        this.notificationService.newNotification({
          message: 'Une erreur s\'est produite, veuillez réessayer plus tard',
          state: 'error'
        })
      }
    });
  }

  public downloadTickets(order: IOrder, ref = this): void {
    ref.fullLoader = true;
    ref.actionToggleOpen = false;
    ref.ticketService.streamOrder(order._id, order.token, 'orderIdTickets').subscribe(result => {
      ref.fullLoader = false;
      ref.filesHandler.downloadFile(result, 'Commande n°' + order.orderNumber)
    }, err => {
      ref.notificationService.newNotification({ state: 'error', message: ref.errorHandler.getError(err) });
      ref.fullLoader = false;
    });
  }

  public download(order: IOrder, ref = this): void {
    ref.fullLoader = true;
    ref.actionToggleOpen = false;
    ref.provider.streamOrder(order._id, order.token).subscribe(result => {
      ref.fullLoader = false;
      ref.filesHandler.downloadFile(result, 'Facture commande n°' + order.orderNumber)
    }, err => {
      ref.notificationService.newNotification({ state: 'error', message: ref.errorHandler.getError(err) });
      ref.fullLoader = false;
    });
  }

  public askRefund(order: IOrder, ref = this): void {
    ref.orderToRefund = order;
  }

  public async askRefundOk(): Promise<void> {
    await this.abandonOrder(this.orderToRefund);
    await this.refundService.create({
      orderId: this.orderToRefund,
      eventId: this.orderToRefund.eventId,
      status: 'todo'
    }).toPromise();
    this.orderToRefund = null;
  }

  public payOrder(order: IOrder, ref = this): void {
    ref.orderToPay = order;
  }

  public async payOrderOk(): Promise<void> {
    const result = await this.provider.update(this.orderToPay._id, { paymentStatus: 'paid' }).toPromise()
    if (result) {
      this.getData();
      this.notificationService.newNotification({
        message: 'La commande a bien été marquée comme "payée"',
        state: 'success'
      })
    }
    else {
      this.notificationService.newNotification({
        message: "Une erreur est survenue, la commande n'a pas pu être marquée comme payée",
        state: 'error'
      })
    }
    this.orderToPay = null
  }


  public cancelOrder(order: IOrder, ref = this): void {
    ref.orderToCancel = order;
  }


  public async cancelOrderOk(): Promise<void> {
    await this.abandonOrder(this.orderToCancel);
    this.orderToCancel = null;
  }

  public async abandonOrder(order: IOrder): Promise<void> {
    const result = await this.provider.update(order._id, { status: 'canceled' }).toPromise()
    if (result) {
      this.getData();
      this.notificationService.newNotification({
        message: 'La commande a bien été annulée',
        state: 'success'
      })
    }
    else {
      this.notificationService.newNotification({
        message: "Une erreur est survenue, l'annulation de la commande n'a pas pu aboutir",
        state: 'error'
      })
    }
  }


}

<div class="date_picker_container">
  <div class="input_wrap" (click)="open = true; $event.stopPropagation()">
    <input [value]="inputValue">
    <svg class="down" role="img" title="chevron" class="chevron-icon">
      <use href="assets/images/sprite.svg#icon-chevron"></use>
    </svg>
  </div>

  <ng-container *ngIf="open" >
    <div class="picker_popup" (click)="$event.stopPropagation()">
      <header>
        <button class="previous" (click)="previousMonth()">
        </button>
        <h3>{{currentMonth}}</h3>
        <button class="next" (click)="nextMonth()"></button>

      </header>
      <ul class="header_week">
        <li>{{"Lun"}}</li>
        <li>{{"Mar"}}</li>
        <li>{{"Mer"}}</li>
        <li>{{"Jeu"}}</li>
        <li>{{"Ven"}}</li>
        <li>{{"Sam"}}</li>
        <li>{{"Dim"}}</li>
      </ul>

      <div class="grid-days">
        <div class="grid-day" [ngClass]="{
            'selected' : isSelected(day),  
            'selected_before' : isSelected(day) && currentHover && isBefore(currentHover),  
            'selected_after' : isSelected(day) && currentHover && isAfter(currentHover),  
          'second_selection': selectedDay, 
          'in_range': selectedDay && isInRange(day), 
          'before': selectedDay && isBefore(day),
          'after': selectedDay && isAfter(day),
          'not_selectable': isNotSelectable(day)
        }" (mouseover)="currentHover = day" (mouseleave)="currentHover = null" (click)="clickOnDay(day)"
          *ngFor="let day of days; let index = index;">
          <div class="grid_day_num_container">
            <strong [ngClass]="{'is-today': isToday(day), 'in_month': isInMonth(day)}">
              {{ displayDayNum(day) }}
            </strong>
          </div>
        </div>
      </div>
    </div>

  </ng-container>
</div>
import { Component, OnInit } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
const slugify = require('slugify')
import { loadStripeTerminal } from '@stripe/terminal-js';

import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { IEvent } from 'src/app/models/event.model';
import { GetParams } from 'src/app/models/type.definition';
import { AuthService, EventService } from 'src/app/providers';
import { StripeTpeService } from 'src/app/providers';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UpdateService } from 'src/app/services/update.service';
import { GetEvent } from '../getEvent';
import * as config from 'src/config.json';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-event-guichet-settings',
  templateUrl: './event-guichet.component.html',
  styleUrls: ['./event-guichet.component.scss'],
  providers: [GetEvent],
})
export class EventGuichetComponent implements OnInit {
  private _subscription = new Subscription();
  public event: IEvent;
  public error: string;
  public isAdmin: boolean;
  public hasAddonLimit: boolean = false;
  public hasAddonMinimum: boolean = false;
  public loadingState$: Subject<void> = new Subject<void>();
  public ticketingSettingForm: FormGroup;
  public guichetSettingForm: FormGroup;
  public currentUrl;
  public bookingTimeOptions: [number, string, number][] = [
    [5, 'minutes', 300],
    [15, 'minutes', 900],
    [30, 'minutes', 1800],
    [45, 'minutes', 2700],
    [1, 'heure', 3600],
  ];
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  extraPaymentMethodsList: string[] = [];

  stripeTerminal = null;
  stripeConnectionToken = null
  terminal = null
  stripeReaders = []
  showReadersList: boolean = false

  constructor(
    private errorHandler: ErrorHandlerService,
    private getEvent: GetEvent,
    private update: UpdateService,
    private formBuilder: FormBuilder,
    private eventService: EventService,
    private notification: NotificationService,
    private authService: AuthService,
    private stripeTpeService: StripeTpeService,
    private router: Router
  ) {}

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  async ngOnInit() {
    this.currentUrl = this.router.url;
    await this.getCurrentEvent();
    this.isAdmin = this.authService.isAdmin
    await this.initStripeTerminal()
    await this.discoverReaders()
    this.initForm();
  }

  async initStripeTerminal() {
    this.stripeTerminal = await loadStripeTerminal()
    this.terminal = this.stripeTerminal.create({
      onFetchConnectionToken: async () => {
        const connectionToken = await this.stripeTpeService.getToken().toPromise()
        return connectionToken.token
      },
      onUnexpectedReaderDisconnect: this.unexpectedDisconnect
    })
  }

  async discoverReaders() {
    const config = { simulated: false }
    const discoverResult = await this.terminal.discoverReaders(config);
    if (!discoverResult.error) {
        this.stripeReaders = discoverResult.discoveredReaders
        console.log(this.stripeReaders);
    }
  }

  unexpectedDisconnect() {
    this.terminal = null
  }

  async getCurrentEvent(reset = false): Promise<void> {
    const eventOptions: GetParams<IEvent> = {
      select: ['name', 'ticketing', 'emailAlerts', 'commission', 'mailingList', 'ticketOffice'],
    };
    this.event = await this.getEvent.get(eventOptions, reset);

    this.hasAddonLimit = (this.event.ticketing?.limitAddonAmount) ? true : false
    this.hasAddonMinimum = (this.event.ticketing?.minimumAddonAmount) ? true : false
  }


  initForm() {
    const paymentMethods = (this.event?.ticketOffice?.paymentMethods) ? this.event?.ticketOffice?.paymentMethods : []
    this.showReadersList = this.isAdmin && paymentMethods.includes('creditCardTerminal')

    const extraPaymentMethods = []
    if (this.event?.ticketOffice?.extraPaymentMethods) {
      this.event?.ticketOffice?.extraPaymentMethods.forEach(el => {
        const method = this.formBuilder.group({
          label: el.label,
          slug: el.slug
        });
        extraPaymentMethods.push(method)
      })
    }

    const readers = []
    if (this.stripeReaders.length) {
      this.stripeReaders.forEach(reader => {
        const isChecked = (this.event?.ticketOffice?.stripeReaders && this.event.ticketOffice.stripeReaders.findIndex(el => el == reader.id) > -1)
        readers.push(new FormGroup({
          name: new FormControl(reader.label),
          id: new FormControl(reader.id),
          checked: new FormControl(isChecked)
        }))
      })
    }

    this.guichetSettingForm = this.formBuilder.group({
      ticketOffice: this.formBuilder.group({
        includeTicketCommission: new FormControl(this.event?.ticketOffice?.includeTicketCommission || false),
        includeProductCommission: new FormControl(this.event?.ticketOffice?.includeProductCommission || false),
        skipOrderInfo: new FormControl(this.event?.ticketOffice?.skipOrderInfo || false),
        skipTicketInfo: new FormControl(this.event?.ticketOffice?.skipTicketInfo || false),
        skipAddonInfo: new FormControl(this.event?.ticketOffice?.skipAddonInfo || false),
        paymentMethods: this.formBuilder.group({
          creditCardTerminal: new FormControl(paymentMethods.includes('creditCardTerminal')),
          creditCardExternal: new FormControl(paymentMethods.includes('creditCardExternal')),
          cash: new FormControl(paymentMethods.includes('cash')),
          check: new FormControl(paymentMethods.includes('check')),
        }),
        extraPaymentMethods: this.formBuilder.array(extraPaymentMethods),
        stripeReaders: this.formBuilder.array(readers)
      }),
    })
  }

  changeCreditCardTerminal(value: boolean) {
    this.showReadersList = (this.isAdmin && value == true)
  }

  get extraPaymentMethodForm() {
    return <FormArray>this.guichetSettingForm.get('ticketOffice').get('extraPaymentMethods');
  }

  removePaymentMethod(paymentMethodIndex: number) {
    this.extraPaymentMethodsList.splice(paymentMethodIndex, 1);
    this.extraPaymentMethodForm.removeAt(paymentMethodIndex);
  }

  goBack(): void {
    this.router.navigate([`evenements/${this.event._id}/billetterie`]);
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (value.trim()) {
      if (!this.extraPaymentMethodsList.includes(value.trim())) {
        this.extraPaymentMethodsList.push(value.trim());
        let paymentMethodFormGroup = this.formBuilder.group({
          label: value.trim(),
          slug: slugify(value.trim(), {remove: /[*+~.()'"!:@]/g})
        });
        this.extraPaymentMethodForm.push(paymentMethodFormGroup);
        if (input) {
          input.value = '';
        }
      }
    }
  }

  updateEvent() {
    const formData = {
      ...this.guichetSettingForm.value,
    };

    const paymentMethods = []
    for (const [key, value] of Object.entries(formData.ticketOffice.paymentMethods)) {
      if (value == true) {
        paymentMethods.push(key)
      }
    }
    formData.ticketOffice.paymentMethods = paymentMethods

    console.log(formData.ticketOffice.stripeReaders);

    formData.ticketOffice.stripeReaders = formData.ticketOffice.stripeReaders.filter(el => el.checked == true).map(el => el.id)

    this._subscription.add(
      this.eventService.update(this.event._id, formData).subscribe(
        async (result) => {
          this.loadingState$.next();
          await this.getCurrentEvent(true);
          this.eventService.selected$.next(this.event);
          this.notification.newNotification({
            message: 'Guichet mis à jour avec succès',
            state: 'success',
          });
        },
        (err: any) => {
          this.error = this.errorHandler.getError(err);
          this.notification.newNotification({
            message: this.error,
            state: 'error',
          });
          this.loadingState$.next();
        }
      )
    );
  }

}

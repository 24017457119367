import { Component } from '@angular/core';
import { ListComponent } from 'src/app/elements/list-component';
import { IEvent, translateStatus } from 'src/app/models/event.model';
import { IOrganisation } from 'src/app/models/organisation.model';
import { GetParams, TableOptions } from 'src/app/models/type.definition';
import { EventService, OrganisationService } from 'src/app/providers';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'app-adminDashboard',
  templateUrl: './adminDashboard.component.html',
  styleUrls: ['./adminDashboard.component.scss']
})
export class AdminDashboardComponent extends ListComponent<IEvent>  {
  dataName = "Événement";
  public noEmail = false;
  public organisationToEdit: IOrganisation;
  public commissionToEdit: IOrganisation;
  public elementToDelete: IEvent;
  tableOptions: TableOptions[] = [
    {
      title: "Organisation",
      class: "orgaName",
      // sort: true,
      key: 'organisationName',
      sortMethod: order => this.sort("name", order),
    },
    {
      title: "Événement",
      class: "name",
      sort: true,
      key: 'name',
      sortMethod: order => this.sort("name", order),
    },
    {
      title: "Date de l'événement",
      class: "date",
      sort: true,
      key: 'date',
      sortMethod: order => this.sort("dates.startDate", order)
    },
    {
      title: "Billetterie",
      class: "status",
      sort: true,
      key: 'translateStatus',
      classKey: 'status',
      sortMethod: order => this.sort("status", order)
    },
    {
      class: "more",
      toggle: [
        {
          title: 'Voir la billetterie',
          method: this.selectEvent
        },
        {
          title: 'Administrer l\'organisation',
          method: this.editOrganisation
        },
        {
          title: 'Contacter l\'organisateur',
          method: this.mailTo
        },
        {
          title: 'Modifier la commission',
          method: this.editCommission
        },
        {
          title: 'Supprimer l\'événement',
          class: "red",
          if: (data: IEvent) => data.status == 'draft',
          method: (data) => this.elementToDelete = data
        }
      ]
    }
  ];

  constructor(
    protected provider: EventService,
    protected dateService: DateService,
    protected organisationService: OrganisationService) {
    super()
  }

  setGetParams(): void {
    this.getParams.select = ['organisationId', 'name', 'dates', 'status'];
    this.getParams.populate = ['organisationId'];
  }

  formatElement(event: IEvent): IEvent {
    event.organisationName = event.organisation?.name;
    event.translateStatus = translateStatus(event.status);
    if (event.dates.type === 'sessions') event.date = 'Session';
    else event.date = this.dateService.formatDate(event.dates.startDate);
    if (event.organisation) {
      event.organisation.commission.flat *= 100;
    }
    return event;
  }

  selectEvent(event: IEvent, ref = this) {
    ref.provider.selectEvent(event);
    ref.router.navigate(['evenements/' + event._id + '/general'])
  }

  mailTo(event: IEvent, ref = this) {
    const email = event.organisation?.contactInfo.email;
    if (email) {
      window.location.href = `mailto:${email}`;
    }
    else {
      ref.noEmail = true;
    }
  }

  async editOrganisation(event: IEvent, ref = this): Promise<void> {
    const getParams: GetParams<IOrganisation> = {
      select: ['commission', 'name', 'paymentGateways']
    }
    const organisation = await ref.organisationService.getById(event.organisationId._id, getParams).toPromise();
    ref.organisationToEdit = organisation;
    ref.organisationToEdit._id = event.organisationId._id;
  }

  async editCommission(event: IEvent, ref = this): Promise<void> {
    const getParams: GetParams<IOrganisation> = {
      select: ['commission', 'name']
    }
    const organisation = await ref.organisationService.getById(event.organisationId._id, getParams).toPromise();
    ref.commissionToEdit = organisation;
    ref.commissionToEdit._id = event.organisationId._id;
  }

  editStripeOk(values: [[string]]): void {
    const updatedObject: { paymentGateways?: any[] } = {};

    if (values[0][0]) {
      const gateways = this.organisationToEdit.paymentGateways
      let stripegateway = gateways.find(gateway => gateway.type === "stripe")
      const gatewayData = {
        accountId: values[0][0],
        chargesEnabled: true,
        detailsSubmitted: true,
        metadata: null
      }
      if (stripegateway) {
        stripegateway = { ...stripegateway, ...gatewayData }
      }
      else {
        gateways.push({ type: "stripe", ...gatewayData })
      }
      updatedObject.paymentGateways = gateways
    }

    this.subscribeTo(this.organisationService.update(this.organisationToEdit._id, updatedObject), res => {
      this.organisationToEdit = null;
      this.notificationService.newNotification({ message: "Organisation mise à jour avec succès", state: "success" });
    })
  }

  editCommissionOk(values: [number, number]): void {
    const updatedObject: { commission?: { flat: number, percentage: number }} = {};
    updatedObject.commission = { flat: values[0][1] * 100, percentage: values[0][0] };
    this.subscribeTo(this.organisationService.update(this.commissionToEdit._id, updatedObject), res => {
      this.data.filter(_ => _.organisationId == this.commissionToEdit._id).forEach(_ => {
        _.organisation.commission = {
          flat: updatedObject.commission.flat / 100,
          percentage: updatedObject.commission.percentage
        }
      })
      this.commissionToEdit = null;
      this.notificationService.newNotification({ message: "Organisation mise à jour avec succès", state: "success" });
    });

  }


  searchEvent(input: string) {
    this.page = 1;
    const searchFilterValue = input?.toLowerCase().trim().replace(/\s+/g, '');
    this.getParams.filter = this.getParams.filter || {};
    this.getParams.filter.name = searchFilterValue
    this.updateFilterStorage();
    this.getData();
  }

  deleteOk() {
    this.deleteData(this.elementToDelete, this, () => this.elementToDelete = null);
  }

}

<section id="form_page" class="container loading_container" [ngClass]="{ 'loaded' : mainForm }">
  <div class="loading_content">
    <div id="page_header">
      <h1>Formulaire</h1>

      <!-- @Temp Désactiver pour la v1 -->
      <!-- <app-button text="Prévisualiser" [style]="'border blue'"></app-button> -->
    </div>
    <div id="form_wrapper">
      <form *ngIf="mainForm" class="small" [formGroup]="mainForm">
        <div class="form_item">
          <label for="name">Nom</label>
          <input id="name" type="text" formControlName="name">
        </div>
        <div formGroupName="ticketTypes">
          <label>Tarifs concernés</label>
          <div class="form_item">
            <div *ngIf="ticketTypes?.length" class="input_wrap select multiple">
              <span class="select_multiple_toggle" (click)="dropDown = !dropDown; $event.stopPropagation()">
                {{getTicketsName()}}
              </span>
              <svg role="img" title="chevron" class="icon-chevron">
                <use href="assets/images/sprite.svg#icon-chevron"></use>
              </svg>
              <div class="dropdown multiple" *ngIf="dropDown" (click)="$event.stopPropagation()">
                <ul>
                  <li *ngFor="let ticket of ticketTypes; let i = index">
                    <input id="ticket_{{i}}" type="checkbox" [attr.checked]="ticketChecked(ticket._id)"
                      (change)="checkTicket(ticket._id, $event.target.checked)" />
                    <label for="ticket_{{i}}">
                      {{ticket.name}} <span class="ticket_category"> - {{ticket.ticketTypeCategory?.name || 'Sans catégorie'}}</span>
                      <svg role="img" title="check" class="check-icon">
                        <use href="assets/images/sprite.svg#icon-check"></use>
                      </svg>
                    </label>
                  </li>
                </ul>
                <div class="dropdown_action">
                  <app-button (clickEvent)="dropDown = false" text="Sélectionner" [style]="'plain full'"></app-button>
                </div>
              </div>
            </div>
            <input *ngIf="!ticketTypes?.length" type="text" value="Aucun ticket crée" disabled>
          </div>
        </div>
      </form>
    </div>

    <app-event-form-fields *ngIf="mainForm" [fieldsToDelete]='fieldsToDelete' [fieldsToCreate]='fieldsToCreate'
      [fieldsToUpdate]="fieldsToUpdate" [formId]="data?._id"></app-event-form-fields>

    <form *ngIf="mainForm" class="save_form" [formGroup]="mainForm">
      <app-button [loadingOff]="loadingState$" [style]="'plain'" [disabled]="mainForm.invalid" (clickEvent)="submitForm()"
        text="Enregistrer le formulaire" type="submit">
      </app-button>
    </form>
  </div>
</section>
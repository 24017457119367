<form id="filters_form" [formGroup]="filtersForm">
  <div class="filters_section">
    <label>Date</label>
    <div class="form_cols">
      <div class="form_item half">
        <div class="input_wrap date">
          <div class="input_date">
            <input id="start-date" type="date" formControlName="startDate">
            <svg role="img" title="calendar" class="calendar-icon">
              <use href="assets/images/sprite.svg#icon-calendar"></use>
            </svg>
          </div>
        </div>
      </div>
      <div class="form_item half">
        <div class="input_wrap date">
          <div class="input_date">
            <input id="end-date" type="date" formControlName="endDate">
            <svg role="img" title="calendar" class="calendar-icon">
              <use href="assets/images/sprite.svg#icon-calendar"></use>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="filters_section">
    <label>Statut</label>
    <div class="form_item">
      <ng-container *ngFor="let status of statusArray" (click)="statusChecked($status)">
        <input (click)="statusChecked(status)" [attr.checked]="status.checked || null" id="status_{{status.name}}"
          name="filter_status" type="checkbox" />
        <label for="status_{{status.name}}">{{status.trad}}
          <svg role="img" title="check" class="check-icon">
            <use href="assets/images/sprite.svg#icon-check"></use>
          </svg>
        </label>
      </ng-container>
    </div>
  </div>
  <div class="form_actions">
    <app-button [loadingOff]="loadingState$" type="submit" (clickEvent)="addFilter()" [style]="'plain full'"
      text="Valider"></app-button>
  </div>
</form>

<div class="loading_container" [ngClass]="{ 'loaded' : data }">
  <div class="loading_content">
    <div id="page_header" class="container">
      <div id="page_header_left">
        <h1>Formulaires</h1>
      </div>
      <div id="page_header_right">
        <app-button (click)="createData()" class="button" text="Créer un formulaire" [style]="'plain'"></app-button>
      </div>
    </div>

    <ng-container *ngIf="data">

      <section *ngIf="!data.length" id="block_first" class="container">
        <p class="description grey">Vous n’avez pas encore créé de formulaire pour cet événement !</p>
        <figure>
          <img src="assets/images/visuel_forms.svg" />
        </figure>
        <app-button class="button_create no_margin" text="Créer un formulaire" (click)="createData()" [style]="'plain full'"></app-button>
      </section>

      <section *ngIf="data.length">
        <app-main-table [ref]="this" idSection="forms_content" idTable="table_forms" tableType="forms" [id]="forms"
          [columns]="tableOptions" [datum]="data"></app-main-table>
      </section>
    </ng-container>
  </div>
</div>
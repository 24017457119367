<div *ngIf="data">
    <div id="page_header" class="container">
        <h1>Équipe <span>({{totalData}} membre{{totalData > 1 ? 's' : ''}})</span></h1>
        <!-- En dur : nombre de membres -->
        <app-button (clickEvent)="createMember()" class="button_create" text="Ajouter un membre" [style]="'plain'">
        </app-button>
    </div>
    <section id="block_first" class="container" *ngIf="!data.length">
        <h1>Créez votre équipe</h1>
        <p class="description">
            {{me.firstName}}, vous n'avez pas encore d'équipiers.<br />
            Invitez des collaborateurs ou amis pour qu’ils vous aident à gérer votre événement
        </p>
        <app-button (clickEvent)="createMember()" class="button_create" text="Ajouter un membre" [style]="'plain'">
        </app-button>
        <figure>
            <img src="assets/images/visuel_team.svg" />
        </figure>
    </section>

    <app-main-table *ngIf="data.length" idSection="team_content" idTable="table_team" tableType="team" [ref]="this"
        [columns]="tableOptions" [datum]="data"></app-main-table>

</div>



<app-delete-popup *ngIf="elementToDelete" [name]="elementToDelete.name" type='membre' (clickEvent)="deleteOk()"
    (close)="elementToDelete = null"></app-delete-popup>
<section id="form_page" class="container loading_container" [ngClass]="{ 'loaded' : categoryForm }">
    <div class="loading_content">
        <div id="page_header">
            <h1>Produits</h1>
            <app-button (clickEvent)="goBack()" text="retour" [style]="'border blue'"></app-button>
        </div>
        <div id="form_wrapper">
            <h2>{{!ticketTypeCategory ? "Crée" : "Éditer"}} une catégorie</h2>

            <form *ngIf="categoryForm" [formGroup]="categoryForm">
                <div class="form_item">
                    <label for="name">Nom de la catégorie</label>
                    <input id="name" type="text" formControlName="name">
                </div>
                <div class="form_cols">
                    <div class="form_item quarter">
                        <label for="quota">Quota</label>
                        <input id="quota" type="text" formControlName="quota">
                    </div>
                </div>

                <div class="form_item full smallest_margin">
                    <input id="rate_active" type="checkbox" class="toggle" formControlName="isOpened">
                    <label for="rate_active">
                      Cette catégorie est active
                      <i class="check">
                        <svg role="img" title="check" class="check-icon">
                          <use href="assets/images/sprite.svg#icon-check"></use>
                        </svg>
                      </i>
                    </label>
                  </div>

                <div class="form_actions right">
                    <app-button (clickEvent)="goBack()" text="Annuler" [style]="'border blue'"></app-button>
                    <app-button [loadingOff]="loadingState$" [style]="'plain'" type="submit" [disabled]="categoryForm.invalid"
                        (clickEvent)="createCategory()" text="{{ticketTypeCategory ? 'Éditer' : 'Créer'}}">
                    </app-button>
                    <app-popup *ngIf="error != null" title="Une erreur est survenue" message="{{error}}"
                        popupOpened="true" (backEvent)="error = null"></app-popup>
                </div>
            </form>
        </div>
    </div>
</section>

<section id="form_page" class="container loading_container" [ngClass]="{ 'loaded' : eventForm }">
    <div class="loading_content">
        <div id="page_header">
            <div id="page_header_left">
                <h1>Paramètres</h1>
            </div>
            <div id="page_header_right">
                <app-button *ngIf="manageToggle" (clickEvent)="displayManageToggle()" [loadingFunction]="false" class="button" text="Gérer"
                    [style]="'plain'"></app-button>
                <nav class="drop drop_more" *ngIf="manageToggleOpen" [@moreAnimation]>
                    <ul>
                        <li *ngFor="let link of manageToggle.links" (click)="link.method(manageToggle.ref)"
                            (click)="$event.stopPropagation()" class="{{link.class}}">{{link.title}}</li>
                    </ul>
                </nav>
            </div>
        </div>
        <div id="form_wrapper">
            <form *ngIf="eventForm" [formGroup]="eventForm">
                <h3>Votre événement</h3>
                <!-- <app-file-input class="form_item" text="Ajoutez le logo de votre événement"
                    (imageChange)="uploadFile($event)"></app-file-input> -->
                <div class="form_cols">
                    <div class="form_item full required">
                        <label for="name">Nom</label>
                        <input id="name" type="text" formControlName="name" [disabled]="event.status=='published'">
                        <p *ngIf="isPublishedNftEvent()" class="description_medium">Cette information n’est plus modifiable lorsque l'événement est publié.
</p>
                    </div>

                    <div class="form_item half">
                        <label for="category">Catégorie</label>
                        <div class="input_wrap select">
                            <select #parentCategory id="category" (change)="selectCategory(parentCategory.value)"
                                formControlName="categoryId">
                                <option value="" hidden>Choisir une catégorie</option>
                                <option *ngFor="let category of parentCategories" value="{{category._id}}">
                                    {{category.name}}
                                </option>
                            </select>
                            <svg role="img" title="chevron" class="icon-chevron">
                                <use href="assets/images/sprite.svg#icon-chevron"></use>
                            </svg>
                        </div>
                    </div>
                    <div class="form_item half" *ngIf="childCategories && childCategories.length > 0">
                        <label for="child-category">Sous-categorie</label>
                        <div class="input_wrap select">
                            <select #childCategory id="child-category" formControlName="categoryId2">
                                <option *ngFor="let category of childCategories" value="{{category._id}}">
                                    {{category.name}}
                                </option>
                            </select>
                            <svg role="img" title="chevron" class="icon-chevron">
                                <use href="assets/images/sprite.svg#icon-chevron"></use>
                            </svg>
                        </div>
                    </div>
                    <div class="form_item half">
                        <label for="category">Type d'événement</label>
                        <div class="input_wrap select">
                            <select #typeSelector id="eventType" (change)="selectType(typeSelector.value)"
                                formControlName="eventType">
                                <option value="presential">Présentiel</option>
                                <option value="remote">Distanciel</option>
                            </select>
                            <svg role="img" title="chevron" class="icon-chevron">
                                <use href="assets/images/sprite.svg#icon-chevron"></use>
                            </svg>
                        </div>
                    </div>
                </div>
                <div *ngIf="event.bookType != 'nft'" class="form_item half">
                    <label for="booking-type">Type de réservation</label>
                    <div class="input_wrap select">
                        <select #childCategory id="booking-type" formControlName="bookType">
                            <option value="ticketing">Avec billetterie</option>
                            <option value="registration">Avec inscription</option>
                        </select>
                        <svg role="img" title="chevron" class="icon-chevron">
                            <use href="assets/images/sprite.svg#icon-chevron"></use>
                        </svg>
                    </div>
                </div>
                <!-- <div class="form_item tags">
                    <label for="tags">Tags</label>
                    <ul>
                        <li *ngFor="let tag of tags" (click)="deleteTag(tag)">
                            {{tag}}
                            <svg role="img" title="close" class="icon-close">
                                <use href="assets/images/sprite.svg#icon-close"></use>
                            </svg>
                        </li>
                    </ul>
                    <input #tagInput type="text" (keydown.enter)="addTag(tagInput)"
                        (keydown.enter)="$event.preventDefault()">
                </div> -->


                <div formGroupName="dates" class="form_item">
                    <div class="form_item" *ngIf="event.bookType !== 'nft'">
                        <input [checked]="multipleDate" id="date-type" type="checkbox" (change)="checkTypeDate()"
                            class="toggle">
                        <label for="date-type">
                            Mon événement compte plusieurs dates
                            <i class="check">
                                <svg role="img" title="check" class="check-icon">
                                    <use href="assets/images/sprite.svg#icon-check"></use>
                                </svg>
                            </i>
                        </label>
                        <p class="session_info" *ngIf="multipleDate">Vous pourrez déterminer les sessions de votre
                            événement après la création
                            dans l'onglet "Billetterie > Sessions"</p>
                    </div>

                    <app-date-interval *ngIf="!multipleDate" (changeTimeZone)="selectTimeZone($event)"
                        [timeZone]="event.dates && event.dates.timeZone" (newDates)="changeDates($event)"
                        startText="Début de l'événement" endText="Fin de l'événement" [preData]="event.dates">
                    </app-date-interval>


                </div>
                <div (keydown.enter)="$event.stopPropagation()" class="form_item required" *ngIf="event.bookType === 'nft'">
                    <label for="description">Description (Affiché sur la page du ticket NFT)</label>
                    <app-wysiwyg [previousValue]="event.description" (changeText)="setDescriptionValue($event)">
                    </app-wysiwyg>
                </div>
                <ng-container *ngIf="currentType == 'presential'">
                    <h3>Votre lieu</h3>
                    <div formGroupName="place">
                        <div class="form_item">
                            <label for="place-name">Nom de l'adresse</label>
                            <input id="place-name" type="text" formControlName="name"
                                placeholder="Par exemple, Zenith de Paris">
                        </div>

                        <app-places (onChange)="setEnteredAddress($event)" label="Adresse de votre événement">
                        </app-places>

                        <ng-container *ngIf="addressEntered">
                            <div class="form_item">
                                <label for="address">Adresse</label>
                                <input id="address" type="text" formControlName="address">
                            </div>
                            <div class="form_item">
                                <label for="address2">Adresse bis</label>
                                <input id="address2" type="text" formControlName="address2">
                            </div>
                            <div class="form_cols">
                                <div class="form_item half">
                                    <label for="zipcode">Code Postal</label>
                                    <input id="zipcode" type="text" formControlName="zipcode">
                                </div>
                                <div class="form_item half">
                                    <label for="city">Ville</label>
                                    <input id="city" type="text" formControlName="city">
                                </div>
                                <div class="form_item three_cols">
                                    <label for="countries{{i}}">Pays</label>
                                    <div class="input_wrap select">
                                        <select id="countries{{i}}" formControlName="countryId">
                                            <option *ngFor="let country of countries" value="{{country._id}}">
                                                {{country.name}}</option>
                                        </select>
                                        <svg role="img" title="chevron" class="icon-chevron">
                                            <use href="assets/images/sprite.svg#icon-chevron"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>

                        </ng-container>
                    </div>
                </ng-container>
                <ng-container *ngIf="currentType == 'remote'">
                    <h3>Accès à votre événement</h3>
                    <div class="form_item">
                        <label for="eventLink">URL</label>
                        <input id="eventLink" type="text" formControlName="eventLink">
                    </div>
                </ng-container>


                <h3>Informations Financières</h3>
                <div formGroupName="ticketing">
                    <div class="form_cols">
                        <div class="form_item half">
                            <label for="currency">Devise</label>
                            <div class="input_wrap select">
                                <select id="currency" formControlName="defaultCurrencyId">
                                    <option value="null" selected hidden>Choisir une devise</option>
                                    <option *ngFor="let currency of currencies" value="{{currency._id}}">
                                        {{currency.isoCode}}</option>
                                </select>
                                <svg role="img" title="chevron" class="icon-chevron">
                                    <use href="assets/images/sprite.svg#icon-chevron"></use>
                                </svg>
                            </div>
                        </div>
                        <div class="form_item half required">
                            <label for="tax">TVA</label>
                            <div class="input_wrap select">
                                <select id="tax" formControlName="taxId">
                                    <option value="null" selected hidden>Choisir une TVA</option>
                                    <option *ngFor="let tax of taxes" value="{{tax._id}}">
                                        {{tax.value / 100 + "%"}}</option>
                                </select>
                                <svg role="img" title="chevron" class="icon-chevron">
                                    <use href="assets/images/sprite.svg#icon-chevron"></use>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form_cols commission">
                    <div class="form_item half">
                        <label for="commissionType">Type de commission</label>
                        <div *ngIf="canEditCommissionType" class="input_wrap select">
                            <select id="commissionType" formControlName="commissionIncreased">
                                <option [ngValue]="false">Incluse</option>
                                <option [ngValue]="true">Majorée</option>
                            </select>
                            <svg role="img" title="chevron" class="icon-chevron">
                                <use href="assets/images/sprite.svg#icon-chevron"></use>
                            </svg>
                        </div>
                        <div *ngIf="!canEditCommissionType" class="input_wrap select"
                        (mouseover)="!canEditCommissionType && displayCommissionDisabledText = true"
                        (mouseleave)="!canEditCommissionType && displayCommissionDisabledText = false">
                            <input id="name" type="text" disabled [value]="this.event.commissionIncreased ? 'Majorée' : 'Incluse' ">
                        </div>
                        <div *ngIf="displayCommissionDisabledText" class="disabled_text">
                            La commission ne peut pas être modifié si au moins un ticket a été vendu
                        </div>
                    </div>
                    <p class="commission_info">La commission incluse sera déduite du prix du billet pour l'organisateur
                        alors que la commission majorée
                        augmentera le prix du billet</p>
                </div>
                <div class="form_item">
                    <input [checked]="isInSearch" id="is-in-search" type="checkbox" formGroupName="isInSearch"
                        class="toggle" (change)="changeIsInSearch()">
                    <label for="is-in-search">
                        Référencer l'événement sur les moteurs de recherches
                        <i class="check">
                            <svg role="img" title="check" class="check-icon">
                                <use href="assets/images/sprite.svg#icon-check"></use>
                            </svg>
                        </i>
                    </label>
                </div>

                <ng-container *ngIf="isAdmin == true">
                    <h3>Auteur</h3>
                    <div class="input_wrap select">
                        <select id="authorId" formControlName="authorId">
                            <option value="null" selected hidden>Choisir un auteur</option>
                            <option *ngFor="let user of usersOrganisation" value="{{user._id}}">
                                {{user.firstName}} {{user.lastName}} ({{ user.email }})</option>
                        </select>
                        <svg role="img" title="chevron" class="icon-chevron">
                            <use href="assets/images/sprite.svg#icon-chevron"></use>
                        </svg>
                    </div>
                </ng-container>

                <div class="form_actions right">
                    <app-button [loadingOff]="loadingState$" [style]="'plain'" type="submit"
                        [disabled]="eventForm.invalid" (clickEvent)="updateEvent()" text="Modifier mon événement">
                    </app-button>
                    <app-popup *ngIf="error != null" title="Une erreur est survenue" message="{{error}}"
                        popupOpened="true" (backEvent)="error = null"></app-popup>
                </div>

            </form>

        </div>
    </div>
</section>

<div class="full_loader" *ngIf="fullLoader">
    <div></div>
</div>


<app-delete-popup *ngIf="deletePanelOpen" [name]="event.name" type='événement' [startWithVowel]="true"
(clickEvent)="deleteOk()" (close)="deletePanelOpen = false"></app-delete-popup>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ListFromEventComponent } from 'src/app/elements/list-component';
import { IEvent } from 'src/app/models/event.model';
import { ITicket } from 'src/app/models/ticket.model';
import { GetParams, TableOptions } from 'src/app/models/type.definition';
import { TicketService } from 'src/app/providers';
import { DateService } from 'src/app/services/date.service';
import { GetEvent } from '../../getEvent';
import { FilesHandler } from 'src/app/services/file-handler.service';

@Component({
  selector: 'app-event-ticketing-invitation',
  templateUrl: './event-ticketing-invitation.component.html',
  styleUrls: ['./event-ticketing-invitation.component.scss'],
  providers: [GetEvent]
})
export class EventTicketingInvitationComponent extends ListFromEventComponent<ITicket> {
  public currentUrl: string;
  public dataName = 'Invitation';
  public ticketToDelete: ITicket;
  public fullLoader: boolean;
  public actionToggleOpen = false;
  protected getEventParams: GetParams<IEvent> = { select: ['status'] };
  public tableOptions: TableOptions[] = [
    {
      title: "Nom",
      class: "name",
      sort: true,
      key: 'lastName',
    },
    {
      title: "Prénom",
      class: "first_name",
      sort: true,
      key: 'firstName',
    },
    {
      title: "Tarif",
      class: "rate",
      sort: true,
      key: 'ticketName',
    },
    {
      title: "Commande",
      class: "order",
      sort: true,
      key: 'orderNumber',
    },
    {
      title: "Date d'invitation",
      class: "date",
      sort: true,
      key: 'orderDate',
    },
    {
      class: "more",
      toggle: [
        {
          title: 'Supprimer',
          class: "red",
          method: this.delete
        },
        {
          title: "Télécharger l'invitation",
          class: "",
          method: this.download
        },
        {
          title: 'Télécharger les invitations',
          class: "",
          method: this.downloadOrder
        }
      ],
    }
  ];

  constructor(
    protected provider: TicketService,
    protected getEvent: GetEvent,
    protected dateService: DateService,
    private filesHandler: FilesHandler,) {
    super()
  }

  setGetParams(): void {
    this.getParams.filter.status = 'invitation';
    this.getParams.select = ['firstName', 'lastName', 'ticketTypeId', 'status', 'createdAt', 'ticketNumber', 'orderId']
    this.getParams.populate = ['ticketType', 'order'];
    super.setGetParams();
  }

  formatElement(ticket: ITicket): ITicket {
    ticket.firstName = ticket.firstName || 'Non renseigné';
    ticket.lastName = ticket.lastName || 'Non renseigné';
    ticket.ticketName = ticket.ticketTypeId.name;
    ticket.orderNumber = ticket.orderId.orderNumber;
    ticket.orderDate = this.dateService.formatDate(ticket.createdAt)
    return ticket;
  }

  public delete(ticket: ITicket, ref = this): void {
    ref.ticketToDelete = ticket;
  }

  public download(ticket: ITicket, ref = this): void {
    ref.fullLoader = true;
    ref.actionToggleOpen = false;
    ref.provider.streamOrder(ticket._id, ticket.orderId.token, "ticketId").subscribe(result => {
      ref.fullLoader = false;
      ref.filesHandler.downloadFile(result, 'Ticket n°' + ticket.ticketNumber)
    }, err => {
      ref.notificationService.newNotification({ state: 'error', message: ref.errorHandler.getError(err) });
      ref.fullLoader = false;
    });
  }

  public downloadOrder(ticket: ITicket, ref = this): void {
    ref.fullLoader = true;
    ref.actionToggleOpen = false;
    ref.provider.streamOrder(ticket.orderId._id, ticket.orderId.token, 'orderIdTickets').subscribe(result => {
      ref.fullLoader = false;
      ref.filesHandler.downloadFile(result, 'Commande n°' + ticket.orderId.orderNumber)
    }, err => {
      ref.notificationService.newNotification({ state: 'error', message: ref.errorHandler.getError(err) });
      ref.fullLoader = false;
    });
  }

  public deleteOk(): void {
    this.provider.update(this.ticketToDelete._id, { status: 'canceled' }).subscribe(result => {
      const id = this.ticketToDelete._id
      this.ticketToDelete = null;
      if (result) {
        this.data = this.data.filter(ticket => ticket._id !== id);

        this.notificationService.newNotification({
          message: 'Ticket supprimé avec succès',
          state: 'success'
        })
      }
      else {
        this.notificationService.newNotification({
          message: 'Une erreur s\'est produite, veuillez réessayer plus tard',
          state: 'error'
        })
      }
    })
  }

  getParticipantName(participant: ITicket): string {
    if (participant.lastName == 'Non renseigné') {
      return participant.firstName !== 'Non renseigné' ? participant.firstName : 'Non renseigné'
    }
    else return participant.lastName + (participant.firstName !== 'Non renseigné' ? ' ' + participant.firstName : '')
  }

  public createInvitation(): void {
    this.router.navigate([`${this.currentUrl}/creer`]);
  }

}

<div class="loading_container" [ngClass]="{ 'loaded' : me }">
  <span class="loader_global"></span>
  <div class="loading_content">
    <div id="page_header" class="container">
      <div id="page_header_left">
        <h1>Événements {{totalEvents > 0 ? "("+totalEvents+")" : ''}}</h1>
      </div>
      <div id="page_header_right">
        <app-button (click)="createEvent()" class="button" text="Créer un nouvel événement" [style]="'plain'"
          *ngIf="totalEvents"></app-button>
      </div>
    </div>
    <section id="block_first" class="container" *ngIf="!totalEvents">
      <h2>Bonjour {{me?.firstName}} !</h2>
      <p class="description">
        Envie d’organiser votre premier événement ?? N’hésitez pas à vous lancer !<br />
        C’est simple, cela prend moins de 3 minutes.
      </p>
      <app-button (clickEvent)="createEvent()" class="button_create" text="Créer mon premier événement" [style]="'plain'">
      </app-button>
      <figure>
        <img src="assets/images/visuel_evenement.svg" />
      </figure>
    </section>
    <section>
      <div id="filters_wrap" class="container">
        <app-search-input placeholder="Rechercher un événement"
          (sendInput)="searchEvent($event)">
        </app-search-input>

        <div id="filters">
          <span id="toggle_filters" (click)="displayFilters(true); $event.stopPropagation()">
            <svg class="down" role="img" title="filter" class="filter-icon">
              <use href="assets/images/sprite.svg#icon-filter"></use>
            </svg>
            <span class="label">Filtres</span>
            <span *ngIf="filters && filters.length" class="active">{{filters.length}}</span>
          </span>



          <div *ngIf="filtersDisplay" id="box_filters" (click)="$event.stopPropagation()" [@menuAnimation]>
            <span class="title">Filtrer</span>
            <button class="close" (click)="displayFilters(false); $event.stopPropagation()">
              <svg role="img" title="close" class="icon-close">
                <use href="assets/images/sprite.svg#icon-close"></use>
              </svg>
            </button>
            <app-filters (sendFilter)="setFilter($event)" [dates]="dateFilter" [statusArray]="statusArray"></app-filters>
          </div>
        </div>

        <div *ngIf="filterLoading" class="loader"></div>

        <div id="active_filters" *ngIf="filters">
          <span *ngFor="let filter of filters; index as i" (click)="deleteFilter(i)" class="item">
            {{filter.name}}
            <svg class="down" role="img" title="close" class="close-icon">
              <use href="assets/images/sprite.svg#icon-close"></use>
            </svg>
          </span>
          <span *ngIf="filters.length" (click)="resetFilter()" class="reset_filters">Supprimer les filtres</span>
        </div>
      </div>

      <app-main-table *ngIf="events && events.length; else noData" [ref]="this" (clickEvent)="selectEvent($event)"
        idSection="events_content" idTable="table_events" tableType="events" [id]="table_event" [columns]="tableOptions"
        [datum]="events"></app-main-table>
      <ng-template #noData>
        <section  id="block_first" class="container">
          <h2>Aucun événement ne correspond à votre recherche !</h2>
          <p class="description">
            Vous pouvez modifier vos filtres ou bien créer un nouvel événement<br />
          </p>
          <app-button (clickEvent)="createEvent()" class="button_create" text="Créer un événement" [style]="'plain'">
          </app-button>
          <figure>
            <img src="assets/images/visuel_evenement.svg" />
          </figure>
        </section>
      </ng-template>

      <app-pager *ngIf="totalPages" [totalPages]="totalPages" [page]="page" (changePage)="changePage($event)">
      </app-pager>
    </section>
  </div>
</div>


<app-popup *ngIf="eventToDuplicate" (backEvent)="eventToDuplicate = null"
	[title]="'Dupliquer l\'événement ' + eventToDuplicate.name"
	message="Veuillez choisir un nom pour l'événement dupliqué"
  input="text"
	buttonText="Renvoyer" (clickEvent)="duplicateOk($event)"></app-popup>

  <app-delete-popup *ngIf="eventToDelete" [name]="eventToDelete.name" type='événement' [startWithVowel]="true"
  (clickEvent)="deleteOk()" (close)="eventToDelete = null"></app-delete-popup>
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IAccountingInfo } from "src/app/models/accounting.model";
import { IOrganisation } from "src/app/models/organisation.model";
import { APIListResponse, GetParams } from "src/app/models/type.definition";
import { Requester } from "../requester";
import { IEvent } from "src/app/models/event.model";

@Injectable()
export class AccountingService extends Requester {
    protected url = "users/organisations";

    public getList(page: number, search = "", organisationId?: string): Observable<APIListResponse<IEvent>> {
        const filter: GetParams<IOrganisation>['filter'] = { };
        organisationId && (filter.organisationId = organisationId)
        return this.httpClient.get<APIListResponse<IEvent>>(this.getUrl('list/accounting', {
            page,
            filter,
            search,
            populate: ['organisationId']
        }));
    }

    public getReddition(eventId: string, startDate: string = null, endDate: string = null): Observable<Blob> {
        return this.httpClient.get(this.getUrl(`accounting/reissue/${eventId}`), {responseType: 'blob'});
    }
}
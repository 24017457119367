<section id="form_page" class="container">
    <div id="page_header">
        <h1>Campagnes</h1>
        <app-button text="Lancer ma campagne" [style]="'plain'"></app-button>
    </div>
    <div id="form_wrapper">
        <ul id="block_steps">
            <li [ngClass]="{'active' : activeStep == 1, 'completed' : activeStep > 1}">
                <span class="step">1</span>
                <span class="label">Paramètres</span>
            </li>
            <li [ngClass]="{'active' : activeStep == 2, 'completed' : activeStep > 2}">
                <span class="step">2</span>
                <span class="label">Contenu</span>
            </li>
            <li [ngClass]="{'active' : activeStep == 3, 'completed' : activeStep > 3}">
                <span class="step">3</span>
                <span class="label">Liste d'envoi</span>
            </li>
            <li [ngClass]="{'active' : activeStep == 4, 'completed' : activeStep > 4}">
                <span class="step">4</span>
                <span class="label">Récapitulatif</span>
            </li>
        </ul>
        <form [formGroup]="campaignForm">
            <div class="step" *ngIf="activeStep == 1">
                <div class="form_item">
                    <label for="name">Nom de la campagne</label>
                    <input id="name" type="text" formControlName="name">
                </div>
                <div class="form_item">
                    <label for="type">Type de campagne</label>
                    <div class="input_wrap select">
                        <select id="type" formControlName="type">
                            <option></option>
                            <option>Type 1</option>
                            <option>Type 2</option>
                        </select>
                        <svg role="img" title="chevron" class="icon-chevron">
                            <use href="assets/images/sprite.svg#icon-chevron"></use>
                        </svg>
                    </div>
                </div>
                <div class="form_item">
                    <label for="event">Événement</label>
                    <div class="input_wrap select">
                        <select id="event" formControlName="event">
                            <option></option>
                            <option>Événement 1</option>
                            <option>Événement 2</option>
                        </select>
                        <svg role="img" title="chevron" class="icon-chevron">
                            <use href="assets/images/sprite.svg#icon-chevron"></use>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="step" *ngIf="activeStep == 2">
                <h3>Informations générale</h3>
                <div class="form_item">
                    <label for="email_object">Objet de l'e-mail</label>
                    <input id="email_object" type="text">
                </div>
                <div class="form_item">
                    <label for="email_sender">Expéditeur</label>
                    <input id="email_sender" type="email">
                </div>
                <div class="form_item">
                    <label for="email_answer">E-mail de réponse</label>
                    <input id="email_answer" type="email">
                </div>
                <h3>Contenu</h3>
                <div (keydown.enter)="$event.stopPropagation()" class="form_item">
                    <label for="description">Message de votre e-mail</label>
                    <app-wysiwyg></app-wysiwyg>
                </div>
            </div>
            <div class="step" *ngIf="activeStep == 3">
                <h3>Choisissez vos contacts</h3>
                <div class="form_item">
                    <input id="all_contacts" type="checkbox">
                    <label for="all_contacts">
                        Envoyer aux 327 abonnés à la newsletter
                        <i class="check">
                            <svg role="img" title="check" class="check-icon">
                                <use href="assets/images/sprite.svg#icon-check"></use>
                            </svg>
                        </i>
                    </label>
                </div>
                <h3>Rajouter des contacts</h3>
                <div class="form_item">
                    <input id="new_contacts" type="checkbox">
                    <label for="new_contacts">
                        Ajouter de nouveaux contacts à cet envoi
                        <i class="check">
                            <svg role="img" title="check" class="check-icon">
                                <use href="assets/images/sprite.svg#icon-check"></use>
                            </svg>
                        </i>
                    </label>
                </div>
                <div class="form_cols">
                    <div class="form_item half">
                        <label for="contact_last_name">Nom</label>
                        <input id="contact_last_name" type="text">
                    </div>
                    <div class="form_item half">
                        <label for="contact_first_name">Prénom</label>
                        <input id="contact_first_name" type="text">
                    </div>
                    <div class="form_item full">
                        <label for="contact_first_name_again_dol">Prénom (encore mdr ?)</label>
                        <input id="contact_first_name_again_dol" type="text">
                    </div>
                </div>
                <app-button class="add_group" [style]="'link add_plus'" text="Ajouter un contact" [icon]="'add'"></app-button>
            </div>
            <div class="step border" *ngIf="activeStep == 4">
                <h3>Récapitulatif</h3>
                <div class="form_item preview_field">
                    <label>E-mail de réponse</label>
                    <p>chaussette@colorpeoplerun.fr</p>
                </div>
                <div class="form_item preview_field">
                    <label>Objet de la campagne</label>
                    <p>Prêt à partir ? J-10 avant le départ !</p>
                </div>
                <div class="form_item preview_field">
                    <label>Contenu</label>
                    <app-button class="add_group" [style]="'link'" text="Aperçu du mail"></app-button>
                </div>
                <div class="form_item preview_field">
                    <label>Contacts sélectionnés</label>
                    <p>328</p>
                </div>
                <h3>Tester ma campagne</h3>
                <div class="form_item">
                    <label>Testez votre campagne avant de l’envoyer à l’ensemble des contacts que vous avez sélectionné en la prévisualisant d’abord sur votre adresse e-mail.</label>
                </div>
                <div class="form_item has_button">
                    <div>
                        <label for="email_test">E-mail de test</label>                       
                        <input id="email_test" type="email">
                   </div>
                   <app-button text="Envoyer" [style]="'border blue mw'"></app-button>
                </div>
                <h3>Programmer ma campagne</h3>
                <div class="form_item">
                    <input id="defer" type="checkbox">
                    <label for="defer">
                        Je souhaite différer l'envoi de ma campagne
                        <i class="check">
                            <svg role="img" title="check" class="check-icon">
                                <use href="assets/images/sprite.svg#icon-check"></use>
                            </svg>
                        </i>
                    </label>
                </div>                
                <div class="form_item">
                    <label for="send_date">Date de l'envoi</label>
                    <div class="input_wrap date small">
                        <div class="input_date">
                            <input id="send_date" type="date">
                            <svg role="img" title="calendar" class="calendar-icon">
                                <use href="assets/images/sprite.svg#icon-calendar"></use>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form_actions right">
                <app-button *ngIf="activeStep > 1" (click)="previousStep()" text="Étape précédente" [style]="'border blue mw'"></app-button>
                <app-button *ngIf="activeStep < 4" (click)="nextStep()" text="Valider" [style]="'plain mw'"></app-button>
                <app-button *ngIf="activeStep == 4" text="Envoyer" [style]="'plain mw'"></app-button>
            </div>
        </form>
    </div>
</section>
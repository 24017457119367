<section id="form_page" class="container">
    <div id="page_header">
        <h1>Mon compte</h1>
    </div>
    <div id="form_wrapper">
        <h2>Modifier mon profil</h2>
        <form *ngIf="mainForm" [formGroup]="mainForm" class="large">
            <h3>Vos informations générales</h3>
            <!-- <app-file-input class="form_item" text="Ajoutez une photo de profil" (imageChange)="uploadFile($event)"></app-file-input> -->
            <app-file-input *ngIf="loaded" [loading]="loadingImage.includes('avatarId')"
            class="form_item" text="Ajoutez une photo de profil"
            [preFile]="data && !loadingImage.includes('avatarId') && data.avatar"
            (imageChange)="changeImage('avatar', 'avatarId', $event)"
            [cropperStaticHeight]="370" [cropperStaticWidth]="370"></app-file-input>

            <div class="form_item required">
                <label for="last-name">Nom</label>
                <input id="last-name" type="text" formControlName="lastName">
            </div>
            <div class="form_item required">
                <label for="first-name">Prénom</label>
                <input id="first-name" type="text" formControlName="firstName">
            </div>
            <h3>Vos informations utilisateur</h3>
            <div class="form_cols">
                <div class="form_item half" [class.required]="currentPasswordRequired">
                    <label for="name">Mot de passe actuel</label>
                    <div class="input_wrap password">
                        <input id="currentPassword" [attr.type]="showCrtPswd ? 'text' : 'password'" formControlName="currentPassword">
                        <svg role="img" title="eye" class="eye-icon" [ngClass]="{'active': showCrtPswd}" (click)="showCrtPswd = !showCrtPswd">
                            <use href="assets/images/sprite.svg#icon-eye"></use>
                        </svg>
                    </div>
                    <div class="description">
                        Veuillez renseigner votre mot de passe actuel pour modifier votre mot de passe ou votre email.
                    </div>
                </div>
            </div>
            <div class="form_cols">
                <div class="form_item half">
                    <label for="name">Nouveau mot de passe</label>
                    <div class="input_wrap password">
                        <input id="newPassword"  [attr.type]="showNewPwd ? 'text' : 'password'" formControlName="newPassword">
                        <svg role="img" title="eye" class="eye-icon" [ngClass]="{'active': showNewPwd}" (click)="showNewPwd = !showNewPwd">
                            <use href="assets/images/sprite.svg#icon-eye"></use>
                        </svg>
                    </div>
                    <span class="description">Votre mot de passe doit contenir au moins 8 caractères dont un
                        chiffre et un caractère spécial</span>
                </div>
                <div class="form_item half">
                    <label for="name">Confirmer le mot de passe</label>
                    <div class="input_wrap password">
                        <input id="newPassword2" [attr.type]="showNewPwd2 ? 'text' : 'password'" formControlName="newPassword2">
                        <svg role="img" title="eye" class="eye-icon" [ngClass]="{'active': showNewPwd2}" (click)="showNewPwd2 = !showNewPwd2">
                            <use href="assets/images/sprite.svg#icon-eye"></use>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="form_item">
                <label for="email">E-mail</label>
                <input id="email" type="email" formControlName="email">
            </div>
            <h3>Vos informations de contact</h3>

            <div class="form_item">
                <label for="phone">Numéro de téléphone</label>
                <input id="phone" type="tel" formControlName="phone">
            </div>
            <div class="form_actions right">
                <app-button (clickEvent)="goBack()" text="Annuler" [style]="'border blue'"></app-button>
                <app-button [disabled]="mainForm.invalid" [loadingOff]="loadingState$" type="submit" text="Enregistrer" (clickEvent)="submitForm()" [style]="'plain'"></app-button>
            </div>
        </form>
    </div>
</section>
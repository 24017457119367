import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { moreAnimation } from 'src/app/animations/animations';
import { IEvent } from 'src/app/models/event.model';
import { GetParams } from 'src/app/models/type.definition';
import { EventService, PromoCodeService, TicketTypeService } from 'src/app/providers';
import { DateService } from 'src/app/services/date.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { GetEvent } from '../../getEvent';
import { IPromoCode } from 'src/app/models/promo-code.model';
import { NotificationService } from 'src/app/services/notification.service';
import { ITicketType } from 'src/app/models/ticket.model';

@Component({
  selector: 'app-event-ticketing-promo-code',
  templateUrl: './event-ticketing-promo-code.component.html',
  styleUrls: ['./event-ticketing-promo-code.component.scss'],
  providers: [GetEvent],
  animations: [
    moreAnimation
  ]
})
export class EventTicketingPromoCodeComponent implements OnInit {
  private _subscription = new Subscription();
  private typeTranslate = {
    percentage: "Pourcentage",
    flat: "Fixe",
    free: "Gratuit"
  }
  public codeToDelete: { id: string, name: string };
  public event: IEvent;
  public error: string;
  public currentUrl: string;
  public getOptions: any;
  public page = 1;
  public totalPages: number;
  public tickets: ITicketType[];
  public createToggleOpen: boolean;
  public createToggle = {
    ref: this,
    links: [
      {
        title: 'Créer un code promo',
        method: this.createPromoCode
      },
      {
        title: 'Générer plusieurs codes promos',
        method: this.generatePromoCode
      }
    ]
  }
  public codes: IPromoCode[];
  public tableOptions = [
    {
      title: "Libellé",
      class: "name",
      sort: true,
      key: 'name',
      sortMethod: (order) => this.sort("name", order),
    },
    {
      title: "Code",
      class: "code",
      subClass: "doselect",
      sort: false,
      key: 'code',
      //sortMethod: (order) => this.sort("dates.startDate", order)
    },
    {
      title: "Type",
      class: "type",
      sort: false,
      key: 'translatedType',
      //sortMethod: (order) => this.sort("ticketing.quota", order)
    },
    {
      title: "Montant",
      class: "amount",
      sort: false,
      key: 'amount',
      //sortMethod: (order) => this.sort("ticketing.totalPrice.sellingPrice", order),
    },
    {
      title: "Disponibilité",
      class: "availability",
      subClass: "availabilityStart",
      subClass2: "availabilityEnd",
      sort: false,
      key: 'availabilityStart',
      subKey: 'availabilityEnd',
      //sortMethod: (order) => this.sort("ticketing.totalPrice.sellingPrice", order),
    },
    {
      title: "Quota",
      class: "usage",
      sort: false,
      key: 'usages',
      //sortMethod: (order) => this.sort("status", order)
    },
    {
      title: "Tarifs",
      class: "rates",
      sort: false,
      key: 'rates',
      hover: true,
      hoverKey: 'ratesArray'
      //sortMethod: (order) => this.sort("status", order)
    },
    {
      class: "more",
      toggle: [
        {
          title: 'Modifier ce code promo',
          method: this.updatePromoCode
        },
        {
          title: 'Supprimer ce code promo',
          class: "red",
          method: this.deletePromoCode
        }
      ]
    }
  ]

  constructor(
    private getEvent: GetEvent,
    private router: Router,
    private promoCodeService: PromoCodeService,
    private dateService: DateService,
    private notifications: NotificationService,
    private ticketService: TicketTypeService
  ) { }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  async ngOnInit() {
    this.currentUrl = this.router.url;
    const eventBody: GetParams<IEvent> = {
      select: ['ticketing']
    }
    this.event = await this.getEvent.get(eventBody);
    this.getOptions = {
      filter: {
        eventId: this.event._id
      }
    }
    const tickets = await this.ticketService.getList({ filter: { eventId: this.event._id } }).toPromise();
    this.tickets = tickets?.data || [];
    this.getData();
  }

  getData(): void {
    this.getOptions.page = this.page;
    this._subscription.add(this.promoCodeService.getList(this.getOptions).subscribe((result: any) => {
      this.totalPages = result?.pager?.pages;
      this.codes = result?.data?.map((code: any) => {
        code.translatedType = this.typeTranslate[code.reduction.type];
        if (code.reduction.type !== 'free') {
          code.amount = code.reduction.amount + this.addSymbol(code.reduction.type);
        }
        if (code.constraints.beginDate && code.constraints.endDate) {
          const start = this.dateService.formatDateAndTime(code.constraints.beginDate);
          const end = this.dateService.formatDateAndTime(code.constraints.endDate);
          code.availabilityStart = `du ${start}`
          code.availabilityEnd = `au ${end}`
        }
        else {
          code.availabilityStart = "Durée de l'événement"
        }
        code.ratesArray = this.tickets.filter(_ => code.constraints.ticketTypesIds.includes(_._id)).map( _ => _.name);
        if (code.ratesArray.length) {
          code.rates = code.ratesArray.join(' ');
        }
        else {
          code.rates = 'Tous'
        }

        code.usages = code.counterUsed
        if (code.constraintQuantity) {
          code.usages += '/' + code.constraintQuantity + '('+ code.inDraft +')'
        }
        else {
          code.usages += '/∞'
        }

        return code
      }) || [];

    }));
  }

  addSymbol(type) {
    switch (type) {
      case "percentage": return '%';
      case "flat": return this.event.ticketing.defaultCurrencyId.symbol;
    }
  }

  displayCreateToggle() {
    this.createToggleOpen = !this.createToggleOpen
  }

  createPromoCode(ref = this) {
    ref.router.navigate([`${ref.currentUrl}/creer`]);
  }

  generatePromoCode(ref = this) {
    ref.router.navigate([`${ref.currentUrl}/generer`]);
  }

  sort(key, order) {
    this.getOptions.sort = [[key, order]]
    this.getData();
  }

  updatePromoCode(promoCode: IPromoCode, ref = this) {
    ref.router.navigate([ref.currentUrl + '/editer/' + promoCode._id])
  }

  deletePromoCode(promoCode: IPromoCode, ref = this) {
    ref.codeToDelete = {
      id: promoCode._id,
      name: `"${promoCode.name}" ${promoCode.code}`
    }
  }

  validateDeletion(): void {
    this.promoCodeService.delete(this.codeToDelete.id).subscribe(res => {
      this.codes = this.codes.filter(_ => _._id !== this.codeToDelete.id);
      this.notifications.newNotification({ state: "success", message: "Code supprimé avec succès" })
      this.codeToDelete = null;
    })
  }

  public changePage(page: number) {
    this.page = page;
    this.getData();
  }
}

<section id="accounting_page" class="loading_container" [ngClass]="{ 'loaded' : data && me }">
    <span class="loader_global"></span>
    <div class="loading_content">
        <div *ngIf="data && me">
            <div id="page_header" class="container">
                <h1>Plan de salle</h1>
            </div>

            <section *ngIf="totalData">
                <div id="filters_wrap" class="container">
                    <app-search-input placeholder="Rechercher un plan" (sendInput)="searchEvent($event)">
                    </app-search-input>

                    <app-button [loadingOff]="loadingState$" [style]="'plain'" type="submit" (clickEvent)="createSeatingPlan()" text="Ajouter un plan de salle"></app-button>

                </div>
                <app-main-table *ngIf="data?.length; else noData" [tableClass]="(isAdmin && !adminOnOrganisationPage) ? 'with_organisation' : ''"
                    [ref]="this" idSection="accounting_content" idTable="table_accounting" tableType="accounting"
                    [columns]="tableOptions" [datum]="data"></app-main-table>

                <ng-template #noData>
                    <section id="block_first" class="container">
                        <h2>Aucun plan de salle ne correspond à votre recherche !</h2>
                        <figure>
                            <img src="assets/images/visuel_evenement.svg" />
                        </figure>
                    </section>
                </ng-template>

            </section>
            <section id="block_first" class="container" *ngIf="!totalData">
                <p class="description">
                    {{isAdmin ? "Aucun plan de salle crée pour le moment " : "Vous n'avez pas encore créé de plan de salle" }}.
                </p>
                <app-button *ngIf="!isAdmin || adminOnOrganisationPage" (clickEvent)="createSeatingPlanMaybeFirst()" class="button_create"
                    text="Créer mon premier plan de salle" [style]="'plain'">
                </app-button>
                <figure>
                    <img src="assets/images/visuel_evenement.svg" />
                </figure>
            </section>
        </div>
    </div>
</section>

<div class="full_loader" *ngIf="fullLoader">
    <div></div>
</div>

<app-popup *ngIf="displayDuplicateSeatingPlan" (backEvent)="displayDuplicateSeatingPlan = null"
  [returnInitialValue]="true"
  [data]="displayDuplicateSeatingPlan"
  message="Vous souhaitez vraiment dupliquer le plan de salle {{ displayDuplicateSeatingPlan.name }} ?" buttonText="Je duplique ce plan de salle" (clickEvent)="duplicateSeatingPlanOk($event)"></app-popup>

<app-popup *ngIf="displayPublishLastVersion" (backEvent)="displayPublishLastVersion = null"
  [returnInitialValue]="true"
  [data]="displayPublishLastVersion"
  message="Vous souhaitez vraiment publier la dernière version du plan de salle {{ displayPublishLastVersion.name }} ?" buttonText="Je publie la dernière version" (clickEvent)="displayPublishLastVersionOk($event)"></app-popup>

<app-popup *ngIf="displayDiscardDraft" (backEvent)="displayDiscardDraft = null"
  [returnInitialValue]="true"
  [data]="displayDiscardDraft"
  message="Vous souhaitez vraiment annuler les dernières modification en brouillon du plan de salle {{ displayDiscardDraft.name }} ? (action irréversible)" buttonText="Je supprime les modifications non publié" (clickEvent)="displayDiscardDraftOk($event)"></app-popup>

<app-popup *ngIf="displayArchiveChart" (backEvent)="displayArchiveChart = null"
  [returnInitialValue]="true"
  [data]="displayArchiveChart"
  message="Vous souhaitez vraiment archiver le plan de salle {{ displayArchiveChart.name }} ?" buttonText="J'archive ce plan de salle" (clickEvent)="displayArchiveChartOk($event)"></app-popup>

<app-popup *ngIf="displayCopyToWorkspace" (backEvent)="displayCopyToWorkspace = null"
  [title]="'Copier le plan de salle ' + displayCopyToWorkspace.name +' dans le workspace : '" [selects]="[[
    {
        label: 'Organisation',
        options: organisationData
    }
  ]]"
  [labels]="[['Workspace']]"
  buttonText="Copier le plan de salle" (clickEvent)="displayCopyToWorkspaceOk($event)"></app-popup>
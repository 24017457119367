<div class="loading_container" [ngClass]="{ 'loaded' : data }">
	<span class="loader_global"></span>
	<div class="loading_content">
		<ng-container *ngIf="data">
			<div id="page_header" class="container">
				<h1>Participants <span>({{totalData}} participant{{totalData > 1 ? 's' : ''}})</span></h1>
				<div *ngIf="totalData" class="export_button_container" (click)="$event.stopPropagation()">
					<app-button class="button_create" text="Exporter" [style]="'border blue light'" [icon]="'chevron'"
						(clickEvent)="actionToggleOpen = !actionToggleOpen; loadingState$.next();"
						[loadingOff]="loadingState$">
					</app-button>
					<nav class="drop drop_more" *ngIf="actionToggleOpen" [@moreAnimation]>
						<ul>
							<li (click)="export('search')" (click)="$event.stopPropagation()">
								Exporter la recherche
							</li>
							<li (click)="export('all')" (click)="$event.stopPropagation()">
								Tout exporter
							</li>
						</ul>
					</nav>
				</div>
			</div>

			<div id="filters_wrap" class="container" *ngIf='totalData && filtersForm'
			[ngClass]="{ 'more_filters': moreFilters }">>
				<form id="filters_form" [formGroup]="filtersForm">
					<app-search-input class="medium" placeholder="Rechercher (nom, prénom, numéro commande...)"
						(sendInput)="setSearch($event)">
					</app-search-input>




					<div class="fields">
						<div class="input_wrap date small">
							<div class="input_date">
								<input id="filtr_date" type="date" formControlName="createdAt__$gte">
								<svg role="img" title="calendar" class="calendar-icon">
									<use href="assets/images/sprite.svg#icon-calendar"></use>
								</svg>
							</div>
						</div>

						<div class="input_wrap date small">
							<div class="input_date">
								<input id="filtr_date" type="date" formControlName="createdAt__$lte">
								<svg role="img" title="calendar" class="calendar-icon">
									<use href="assets/images/sprite.svg#icon-calendar"></use>
								</svg>
							</div>
						</div>
						 <span id="more_filters" (click)="moreFilters = !moreFilters">
                            {{moreFilters ? "Moins" : "Plus"}} de filtres
                        	</span>
					</div>
					<div id="filters_more" *ngIf="moreFilters">
						<div class="input_wrap select">
                            <select id="filter_origin" (change)="selectedOrigin = $event.target.value">
                                <option [attr.selected]="selectedOrigin == null ? true : null" value="">
                                    Toutes origines
                                </option>
                                <option [attr.selected]="selectedOrigin == 'web' ? true : null" value="web">
                                    Web
                                </option>
                                <option [attr.selected]="selectedOrigin == 'ticketOffice' ? true : null" value="ticketOffice">
                                    Guichet
                                </option>
                                <option [attr.selected]="selectedOrigin == 'invitation' ? true : null" value="invitation">
                                    Invitation
                                </option>
                                <option [attr.selected]="selectedOrigin == 'stockImpression' ? true : null" value="stockImpression">
                                    Impression de stock
                                </option>
                            </select>
                            <svg role="img" title="chevron" class="icon-chevron">
                                <use href="assets/images/sprite.svg#icon-chevron"></use>
                            </svg>
                        </div>
						<div class="input_wrap select">
                            <select id="filter_widget" (change)="selectedWidget = $event.target.value">
                                <option [attr.selected]="selectedOrigin == null ? true : null" value="">
                                    Tous les widgets
                                </option>
								<option *ngFor="let widget of widgets" [value]="widget._id">
									{{ widget.name }}
								</option>
                            </select>
                            <svg role="img" title="chevron" class="icon-chevron">
                                <use href="assets/images/sprite.svg#icon-chevron"></use>
                            </svg>
                        </div>

						<div class="input_wrap select medium">
							<select id="filter_rates" formControlName="ticketTypeId">
								<option value="" selected>Tous les tickets</option>
								<option *ngFor="let ticketType of ticketTypes" [value]="ticketType._id">
									{{getTicketConcatName(ticketType, 35)}}
								</option>
							</select>
							<svg role="img" title="chevron" class="icon-chevron">
								<use href="assets/images/sprite.svg#icon-chevron"></use>
							</svg>
						</div>
                        <div class="input_wrap select">
                            <select id="filter_origin" formControlName="addons.addonId">
                                <option value="" > Tous les addons </option>
                                <option *ngFor=" let addon of addons" [value]="addon._id">{{addon.name}}</option>
                            </select>
                            <svg role="img" title="chevron" class="icon-chevron">
                                <use href="assets/images/sprite.svg#icon-chevron"></use>
                            </svg>
                        </div>
						<app-search-input *ngIf="event.useRoomPlacement" class="medium" placeholder="Rechercher une place"
							(sendInput)="setSeat($event)">
						</app-search-input>
                    </div>
					<div class="form_actions">
						<app-button [style]="'plain'" type="submit" text="Rechercher" [loadingOff]="loadingState$"
							(clickEvent)="filter()"></app-button>
					</div>
				</form>
			</div>

			 <div *ngIf="totalData" class="between_filter_and_list container">
                <h2>{{currentTotalData}} participant{{currentTotalData > 1 ? 's' : ''}} trouvé{{currentTotalData > 1 ? 's' : ''}}</h2>
            </div>
			<app-main-table *ngIf="data?.length; else noData" idSection="participants_content"
				idTable="table_participants" tableType="participants" [columns]="tableOptions" [datum]="data"
				[ref]="this"></app-main-table>
			<ng-template #noData>
				<section id="block_first" class="container">
					<h2 *ngIf="totalData">Aucun participant ne correspond à votre recherche !</h2>
					<h2 *ngIf="!totalData">Aucun participant pour le moment !</h2>
					<figure>
						<img src="assets/images/visuel_evenement.svg" />
					</figure>
				</section>
			</ng-template>
			<app-pager *ngIf="totalPages" [totalPages]="totalPages" [page]="page" (changePage)="changePage($event)">
			</app-pager>
		</ng-container>
	</div>
</div>

<app-popup *ngIf="ticketToResend" (backEvent)="ticketToResend = null"
	[title]="'Renvoyer le ticket N°' + ticketToResend.ticketNumber"
	message="Le ticket sera envoyé à l'adresse e-mail utilisé lors de la commande" buttonText="Renvoyer"
	(clickEvent)="resendConfirmOk()"></app-popup>

<app-delete-popup *ngIf="ticketToDelete"
	[customText]="'Désirez-vous supprimer ' + getParticipantName(ticketToDelete) + ' des participants de l\événement ?'"
	type='participant' (clickEvent)="deleteOk()" (close)="ticketToDelete = null"></app-delete-popup>

<div class="full_loader" *ngIf="fullLoader">
	<div></div>
</div>
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { base64ToFile, ImageCroppedEvent } from 'ngx-image-cropper';
import { FilesHandler } from 'src/app/services/file-handler.service';

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss']
})
export class FileInputComponent implements OnInit, OnChanges {
  @Output() imageChange: EventEmitter<File> = new EventEmitter();
  @Input() preFile: Blob;
  @Input() text: string;
  @Input() imageWidth: number = 150;
  @Input() fullWidth: boolean = false;
  @Input() cropperStaticHeight: number = 120;
  @Input() cropperStaticWidth: number = 120;
  @Input() loading: boolean;
  @Input() disabled: boolean
  @Input() disabledText: string;
  @Input() alt: string;
  @Input() addFormat: string[];
  @Input() removeFormat: string[];
  public dimensions: { [key: string]: string };
  file: File;
  base64: string;
  acceptedFormat: string[] = ['png', 'jpeg', 'jpg'];
  filteredAcceptedFormat: string[];
  acceptFile: string;
  error: string;
  displayDisabledText = false;
  @Input() limitSizeMo = 3;
  limitSize: number;
  loaded = false;
  preFileSaved: Blob;
  isVideo = false;
  isFile = false;
  videoLocalUrl: any;

  constructor(private fileHandler: FilesHandler) {
  }

  async ngOnInit(): Promise<void> {
    if (this.addFormat) {
      for (let i = 0; i < this.addFormat.length; ++i) {
        this.acceptedFormat.push(this.addFormat[i]);
        if (this.addFormat[i] === "txt") {
          this.acceptedFormat.push("plain");
        }
      }
    }
    if (this.removeFormat) {
      for (let i = 0; i < this.removeFormat.length; ++i) {
        const index = this.acceptedFormat.indexOf(this.removeFormat[i]);
        if (index > -1) {
          this.acceptedFormat.splice(index, 1);
        }
      }
    }

    this.filteredAcceptedFormat = this.acceptedFormat.filter(_ => _ != "plain")
    this.acceptFile = this.acceptedFormat.map(format => `.${format}`).join(',');
    this.limitSize = this.limitSizeMo * 1000000
    this.dimensions = {
      width: `${this.imageWidth}px`,
    }
    if (this.fullWidth) {
      this.dimensions = {
        width: `100%`,
      }
    }
    if (this.preFile && !this.loading) {
      this.setPreFile();
    }
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.preFile) {
      this.preFileSaved = change.preFile.currentValue;
    }
    if (this.preFile && !this.loading && !this.loaded) {
      this.setPreFile();
    }
  }

  async setPreFile(): Promise<void> {
    if (this.preFile) {


      if (this.preFile.type === 'video/mp4') {
        this.file = new File([this.preFile], 'file', { type: this.preFile.type });
        this.isVideo = true;
        var reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload = (event) => {
          this.videoLocalUrl = (<FileReader>event.target).result;
        }
        this.preFileSaved = this.preFile;
        this.preFile = null;
        this.loaded = true;
      } else {
        this.file = new File([this.preFile], 'file', { type: this.preFile.type });
        this.base64 = await this.fileHandler.getBase64(this.preFile);
        this.preFileSaved = this.preFile;
        this.preFile = null;
        this.loaded = true;
      }
    }
    else {
      this.file = null
      this.base64 = null
      this.preFileSaved = null
      this.preFile = null
    }
  }

  uploadFile(event) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      console.log("===> ", file.type, this.acceptedFormat);

      if (file.size > this.limitSize) {
        this.error = "Veuillez choisir un fichier plus petit que " + this.limitSizeMo + "mo";
        return
      }
      else if (!this.acceptedFormat.includes(file.type.split('/')[1])) {
        this.error = "Veuillez choisir un fichier au format ";
        const size = this.acceptedFormat.length;
        for (let i = 0; i < size; i++) {
          this.error += this.acceptedFormat[i];
          this.error += i + 2 < size ? ', ' : (i + 1 === size ? '.' : ' ou ')
        }
        return
      }
      if (file.type.split('/')[0] !== 'image') {
        this.isFile = true
        this.dimensions = {
          width: `100%`,
        }
        this.imageChange.emit(file);
      }
      if (file.type === 'video/mp4') {
        this.isVideo = true;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          this.videoLocalUrl = (<FileReader>event.target).result;
          this.imageChange.emit(file);
        }
      } else {
        this.isVideo = false;
        this.videoLocalUrl = null;
      }
      this.file = file;
      this.fileChangeEvent(event)
    }
  }

  deleteFile() {
    this.base64 = null;
    this.file = null;
    this.imageChangedEvent = null;
    this.imageChange.emit(null);
  }

  closeError() {
    this.error = null;
  }

  imageChangedEvent: any = '';
  croppedImage: any = '';

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    const blob = base64ToFile(event.base64);
    const file = new File([blob], this.file?.name || 'fileName', { type: this.file.type });
    this.imageChange.emit(file);
  }

  loadImageFailed() {
    this.error = "Erreur lors du chargement de l'image";
  }

  public async reset(): Promise<void> {
    this.file = null;
    this.loading = true;
    this.loaded = false;
    this.preFile = this.preFileSaved;
    this.imageChangedEvent = '';
    await setTimeout(async () => {
      await this.setPreFile();
      this.loading = false, 500
    })
  }
}

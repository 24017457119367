import { Component, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { GetParams, TableOptions } from 'src/app/models/type.definition';
import {
  AddonService,
  EventWidgetService,
  ImportOrderService,
  OrderService,
  TicketService,
  TicketTypeService,
} from 'src/app/providers';
import { GetEvent } from '../getEvent';
import { FormControl } from '@angular/forms';
import { moreAnimation } from 'src/app/animations/animations';
import { ListFromEventComponent } from 'src/app/elements/list-component';
import { ITicket, ITicketType, translatedOrigin, translateStatus } from 'src/app/models/ticket.model';
import { DateService } from 'src/app/services/date.service';
import { FilesHandler } from 'src/app/services/file-handler.service';
import { ActivatedRoute } from '@angular/router';
import { IAddon } from 'src/app/models/addon.model';
import { IWidget } from 'src/app/models/widget.model';
import { IImportOrder } from 'src/app/models/import-order';

@Component({
  selector: 'app-event-participants',
  templateUrl: './event-participants.component.html',
  styleUrls: ['./event-participants.component.scss'],
  providers: [GetEvent],
  animations: [
    moreAnimation
  ]
})
export class EventParticipantsComponent extends ListFromEventComponent<ITicket> {
  public dataName = "Participants";
  public isNft = false;
  public ticketToResend: ITicket;
  public ticketToDelete: ITicket;
  public loadingState$ = new Subject<void>();
  public ticketTypes: ITicketType[];
  public imports: IImportOrder[];
  public widgets: IWidget[];
  public actionToggleOpen: boolean;
  public exportToggleOpen = false;
  public addons: IAddon[];
  public moreFilters = false;
  public fullLoader: boolean;
  public selectedOrigin: string;
  public selectedWidget: string;
  public tableOptions: TableOptions[] = [
    {
      title: "Origine",
      class: "origin",
      classKey: 'classKeyOrigin',
      sort: true,
      tooltip: true,
      tooltipKey: 'translatedOrigin',
      key: 'origin',
      sortMethod: order => this.sort("origin", order),
    },
    {
      title: "Participant",
      class: "name",
      sort: true,
      key: 'fullNameTicketId',
      sortMethod: order => this.sort("lastName", order),
    },
    {
      title: "Tarif",
      class: "rate",
      key: 'ticketName',

    },
    {
      title: "N° de commande",
      class: "serial",
      subClass: "doselect",
      sort: false,
      key: 'orderNumber',
    },
    {
      title: "Date",
      class: "date",
      sort: true,
      key: 'orderDate',
      sortMethod: order => this.sort("createdAt", order),
    },
    {
      title: "Statut",
      class: "status",
      classKey: "status",
      sort: false,
      key: 'translatedStatus',
    },
    { // Last index
      class: "more",
      toggle: [
        {
          title: 'Télécharger le ticket',
          if: (data: ITicket) => this.event.bookType !== 'nft' || data.origin == 'invitation',
          method: this.download
        },
        {
          title: 'Télécharger le badge',
          if: (data: ITicket) => this.event.ticketing?.hasBadge,
          method: this.downloadBadge
        },
        {
          title: 'Télécharger les badges',
          if: (data: ITicket) => this.event.ticketing?.hasBadge,
          method: this.downloadOrderBadges
        },
        {
          title: 'Télécharger les tickets',
          if: (data: ITicket) => this.event.bookType !== 'nft' || data.origin == 'invitation',
          method: this.downloadOrderTickets
        },
        {
          title: 'Renvoyer le ticket',
          if: data => this.event.bookType !== 'nft',
          method: this.resendConfirm
        },
        {
          title: 'Détails',
          method: this.detail
        }
      ]
    }
  ];

  constructor(
    protected getEvent: GetEvent,
    protected ticketTypesService: TicketTypeService,
    protected importOrdersService: ImportOrderService,
    protected widgetService: EventWidgetService,
    private route: ActivatedRoute,
    private dateService: DateService,
    private orderService: OrderService,
    private addonService: AddonService,
    private filesHandler: FilesHandler,
    protected provider: TicketService) {
    super();
  }


  async afterInit() {
    const orderNumber = this.route.snapshot.queryParams.order;
    const importId = this.route.snapshot.queryParams.importId;
    this.initForm(orderNumber, importId);
    this.ticketTypes = await this.getListOfDataOfEvent(this.ticketTypesService, {
      select: ['name', 'ticketTypeCategoryId'],
      populate: ['ticketTypeCategoryId'],
      filter: { isProduct: false },
      perPage: 500
    });
    this.imports = await this.getListOfDataOfEvent(this.importOrdersService, {perPage: 500, filter: { status: "COMPLETED" }, sort: [['createdAt', -1]]});

    this.widgets = await this.getListOfDataOfEvent(this.widgetService, {
      select: ['name'],
      perPage: 500
    })
    this.addons = await this.getListOfDataOfEvent(this.addonService, {perPage: 500});
    if (orderNumber || importId) {
      this.filter();
    }
  }

  public setGetParams(): void {
    this.getParams.filter.status = 'pending,completed,canceled';
    this.getParams.filter.origin = this.selectedOrigin || null;
    this.getParams.filter.eventWidgetId = this.selectedWidget || null;
    this.getParams.populate = ['orderId', 'ticketTypeId', 'importId'];
    if (!this.getParams.sort) {
      this.getParams.sort = [['createdAt', -1]];
    }
    super.setGetParams();
  }

  async formatElement(ticket: ITicket): Promise<ITicket> {
    ticket.translatedStatus = translateStatus(ticket.status);
    ticket.fullNameTicketId = 'Anonyme';
    if (ticket.firstName || ticket.lastName) {
     ticket.fullNameTicketId = `${ticket.firstName} ${ticket.lastName}`
    }
    ticket.fullNameTicketId = `${ticket.fullNameTicketId} - ${ticket.ticketNumber}`
    ticket.translatedOrigin = translatedOrigin(ticket.origin)
    ticket.ticketName = this.getTicketConcatName(ticket, 45);
    ticket.classKeyOrigin = ticket.origin;
    if (ticket.origin == "import" && ticket.importId.provider) {
      ticket.classKeyOrigin = ticket.importId.provider
    }
    if (ticket.seat) {
      ticket.ticketName += ` - Place ${ticket.seat}`
    }
    ticket.orderNumber = ticket.orderId?.orderNumber || "-";
    ticket.orderDate = this.dateService.formatDateAndTime(ticket.createdAt)
    return ticket;
  }

  initForm(orderNumber?: string, importId?: string): void {
    this.filtersForm = this.formBuilder.group({
      search: new FormControl(orderNumber || null),
      orderNumber: new FormControl(),
      importId: new FormControl(importId || null),
      createdAt__$lte: new FormControl(),
      createdAt__$gte: new FormControl(),
      "addons.addonId": new FormControl(""),
      ticketTypeId: new FormControl(""),
      seat: new FormControl("")
    });
  }

  public setSearch(search: string): void {
    this.filtersForm.patchValue({ search })
  }

  public setSeat(seat: string): void {
    this.filtersForm.patchValue({ seat })
  }

  public searchOrderNumber(orderNumber: string): void {
    this.filtersForm.patchValue({ orderNumber })
  }

  public detail(data: ITicket, ref = this) {
    ref.router.navigate([`${ref.currentUrl}/details/${data._id}`]);
  }

  public download(ticket: ITicket, ref = this): void {
    if (ticket.orderId.createdAt) {
      ref.fullLoader = true;
      ref.orderService.streamOrder(ticket._id, ticket.orderId.token, 'ticketId').subscribe(result => {
        ref.fullLoader = false;
        if (ref.event.bookType === 'nft') {
          ref.filesHandler.downloadFile(result, 'Invitation n°' + ticket.invitationCode);
        } else {
          ref.filesHandler.downloadFile(result, 'Ticket n°' + ticket.ticketNumber);
        }
      }, err => {
        ref.notificationService.newNotification({ state: 'error', message: ref.errorHandler.getError(err) });
        ref.fullLoader = false;
      });
    }
    else {
      ref.notificationService.newNotification({ state: 'error', message: "Ce ticket n'est rattaché à aucune commande" });
    }
  }

  public downloadBadge(ticket: ITicket, ref = this): void {
    ref.fullLoader = true;
    ref.orderService.streamOrder(ticket._id, ticket.orderId.token, 'ticketId', 'badge').subscribe(result => {
      ref.fullLoader = false;
      ref.filesHandler.downloadFile(result, 'Badge ' + ticket.ticketNumber);
    }, err => {
      ref.notificationService.newNotification({ state: 'error', message: ref.errorHandler.getError(err) });
      ref.fullLoader = false;
    });
  }

  public downloadOrderBadges(ticket: ITicket, ref = this): void {
    ref.fullLoader = true;
    ref.actionToggleOpen = false;
    ref.provider.streamOrder(ticket.orderId._id, ticket.orderId.token, 'orderIdTickets', 'badge').subscribe(result => {
      ref.fullLoader = false;
      ref.filesHandler.downloadFile(result, 'Badges commande n°' + ticket.orderId.orderNumber)
    }, err => {
      ref.notificationService.newNotification({ state: 'error', message: ref.errorHandler.getError(err) });
      ref.fullLoader = false;
    });
  }

  public downloadOrderTickets(ticket: ITicket, ref = this): void {
    ref.fullLoader = true;
    ref.actionToggleOpen = false;
    ref.provider.streamOrder(ticket.orderId._id, ticket.orderId.token, 'orderIdTickets').subscribe(result => {
      ref.fullLoader = false;
      ref.filesHandler.downloadFile(result, 'Commande n°' + ticket.orderId.orderNumber)
    }, err => {
      ref.notificationService.newNotification({ state: 'error', message: ref.errorHandler.getError(err) });
      ref.fullLoader = false;
    });
  }

  public delete(ticket: ITicket, ref = this): void {
    ref.ticketToDelete = ticket;
  }

  public deleteOk(): void {
    this.provider.update(this.ticketToDelete._id, { status: 'canceled' }).subscribe(result => {
      const id = this.ticketToDelete._id
      this.ticketToDelete = null;
      if (result) {
        this.data = this.data.filter(ticket => ticket._id !== id);
        this.notificationService.newNotification({
          message: 'Ticket supprimé avec succès',
          state: 'success'
        })
      }
      else {
        this.notificationService.newNotification({
          message: 'Une erreur s\'est produite, veuillez réessayer plus tard',
          state: 'error'
        })
      }
    })
  }

  public resendConfirm(ticket: ITicket, ref = this): void {
    ref.ticketToResend = {
      ...ticket,
      _id: ticket._id
    }
  }

  public resendConfirmOk(): void {
    this.provider.resendTicket(this.ticketToResend._id).subscribe(_result => {
      this.ticketToResend = null
      if (_result) {
        this.notificationService.newNotification({
          message: 'Le ticket a bien été renvoyé',
          state: 'success'
        })
      }
      else {
        this.notificationService.newNotification({
          message: 'Une erreur s\'est produite, veuillez réessayer plus tard',
          state: 'error'
        })
      }
    });
  }

  getParticipantName(participant: ITicket): string {
    if (participant.lastName == 'Non renseigné') {
      return participant.firstName !== 'Non renseigné' ? participant.firstName : 'Non renseigné'
    }
    else return participant.lastName + (participant.firstName !== 'Non renseigné' ? ' ' + participant.firstName : '')
  }

  public export(type: string): void {
    let params: GetParams<ITicket> = {
      filter: {}
    };
    let total = this.totalData
    if (type == 'search') {
      this.addFilterOnOtherParams(params);
      params.filter = this.getParams.filter
      params.search = this.getParams.search
      params.sort = this.getParams.sort
      total = this.currentTotalData
    }
    this.actionToggleOpen = false;
    this.fullLoader = true;
    this.exportToggleOpen = false;
    this.provider.exportCSV(this.event._id, params).subscribe(result => {
      this.fullLoader = false;
      this.filesHandler.downloadFile(result, `${total} Participant${total > 1 ? 's' : ''}`);
    });
  }

  @HostListener('document:click') clickDoc(): void {
    this.exportToggleOpen = false;
    this.actionToggleOpen = false;
  }

  public getImportConcatName(importOrder: IImportOrder) {
    return `Import ${importOrder.provider} du ${this.dateService.formatDateAndTime(importOrder.createdAt)} (${importOrder.success})`;
  }

  public getTicketConcatName(ticket: ITicketType | ITicket, max: number) {
    let name = ticket.name || (ticket as ITicket).ticketTypeId.name;
    const categoryName = (ticket as ITicket).categoryName || ticket.ticketTypeCategoryId?.name;
    if (categoryName) name += ` - ${categoryName}`;
    if (name.length > max) name = `${name.substring(0, max - 3)}...`;
    return name;
  }
}

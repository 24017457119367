<section id="form_page" class="container loading_container" [ngClass]="{ 'loaded' : rateForm }">
  <div class="loading_content">
    <div id="page_header">
      <h1>Tickets</h1>
      <app-button (clickEvent)="goBack()" text="retour" [style]="'border blue'"></app-button>
    </div>
    <div id="form_wrapper" *ngIf="event">
      <h2 *ngIf="event.bookType!='nft'">{{!ticketType ? "Créer" : "Éditer"}} un tarif simple</h2>
      <h2 *ngIf="event.bookType=='nft'">{{!ticketType ? "Créer" : "Éditer"}} un ticket NFT</h2>
      <form *ngIf="rateForm" [formGroup]="rateForm">
        <div class="form_cols">
          <div class="form_item full">
            <label for="name">Nom du tarif</label>
            <input id="name" type="text" formControlName="name">
          </div>
          <div class="form_item full smaller_margin" *ngIf="event.bookType!='nft'">
            <input id="free" type="checkbox" [checked]="freeTicket" (change)="changeFreeTicket()">
            <label for="free">
              Ce tarif est gratuit
              <i class="check">
                <svg role="img" title="check" class="check-icon">
                  <use href="assets/images/sprite.svg#icon-check"></use>
                </svg>
              </i>
            </label>
          </div>
          <ng-container *ngIf="!freeTicket">
            <div class="form_item half">
              <label for="price">Prix</label>
              <input id="price" (keyup)="changePrice($event.target.value)" type="number" formControlName="price">
              <ng-container *ngIf="price.user">
                <p *ngIf="!stripeInvalid; else noStripe"
                  class="description_medium info_commission">{{price.user || 0 | currency: 'EUR'}}
                  pour {{isAdmin ? "l'organisation" : "vous"}},
                  {{(price.commission + flatCommission) || 0 | currency: 'EUR'}} pour OandB .</p>
                <ng-template #noStripe>
                  <p *ngIf="!isAdmin" class="form_item error">Votre compte de paiement n'est pas valide. Pour le valider, suivez
                    <a routerLink="/parametres/payment-account" target="_blank">ce lien</a>
                  </p>
                  <p *ngIf="isAdmin" class="form_item error">Le compte de paiement de cette organisation n'est pas valide
                  </p>
                </ng-template>
              </ng-container>
              <p class="description_medium" *ngIf="event.bookType=='nft'">Le prix d’un ticket NFT doit être d’un minimum de 5,00 €</p>
            </div>
            <div class="form_item full">
              <div class="form_cols">
                <div class="form_item half no_margin">
                  <label for="var">TVA</label>
                  <div class="input_wrap select">
                    <select id="vat" formControlName="taxId">
                      <option *ngFor="let tax of taxes" [value]="tax._id">{{tax.name}}</option>
                    </select>
                    <svg *ngIf="!ticketType" role="img" title="chevron" class="icon-chevron">
                      <use href="assets/images/sprite.svg#icon-chevron"></use>
                    </svg>
                  </div>
                </div>
              </div>
              <p class="description_medium">Une fois le tarif créé, vous ne pourrez plus modifier la TVA. <a
                  href="https://www.expert-comptable-tpe.fr/articles/billetterie-spectacle-quelle-tva-appliquer/"
                  target="blank">Quelle
                  TVA choisir ?</a></p>
            </div>
          </ng-container>

          <!-- <div class="form_item full bigger_margin" formGroupName="advancePayment">
            <input id="installments" type="checkbox" formControlName="enabled">
            <label for="installments">
              Le tarif est payable en plusieurs fois
              <i class="check">
                <svg role="img" title="check" class="check-icon">
                  <use href="assets/images/sprite.svg#icon-check"></use>
                </svg>
              </i>
            </label>
          </div> -->
          <div class="form_item full no_margin">
            <app-date-interval [preData]="rateForm.value.scheduled" (newDates)="changeDates($event)"
              startText="Début de la vente" endText="Fin de la vente"></app-date-interval>
          </div>

          <div class="form_item full smallest_margin" *ngIf="rateForm.value.scheduled.endDate">
            <input id="show_countdown" type="checkbox" class="toggle" formControlName="showCountDown">
            <label for="show_countdown">
              Afficher un compte à rebours jusqu'à la date de fin
              <i class="check">
                <svg role="img" title="check" class="check-icon">
                  <use href="assets/images/sprite.svg#icon-check"></use>
                </svg>
              </i>
            </label>
          </div>

        </div>
        <div formGroupName="tickets" class="form_cols">
          <div class="form_item half">
            <label for="tickets_min">Billets minimum par commande</label>
            <input id="tickets_min" type="number" formControlName="minimumSelectable">
          </div>
          <div class="form_item half">
            <label for="tickets_max">Billets maximum par commande</label>
            <input id="tickets_max" type="number" formControlName="maximumSelectable">
          </div>
        </div>
        <div class="form_cols">
          <div class="form_item half">
            <label for="category">Catégorie</label>
            <div class="input_wrap select">
              <ng-container>
                <select *ngIf="ticketTypeCategories.length" id="category" formControlName="ticketTypeCategoryId">
                  <option *ngFor="let category of ticketTypeCategories" [value]="category._id">{{category.name}}</option>
                </select>
                <select *ngIf="!ticketTypeCategories.length" id="category" disabled>
                  <option selected hidden disabled>Aucune catégorie à sélectionner</option>
                </select>
                <svg role="img" title="chevron" class="icon-chevron">
                  <use href="assets/images/sprite.svg#icon-chevron"></use>
                </svg>
              </ng-container>
            </div>
          </div>
          <div class="form_item full smaller_margin">
            <input id="ticket_limit" type="checkbox" (change)="limitStock = !limitStock" [attr.checked]="limitStock || null">
            <label for="ticket_limit">
              Fixer une limite de places totale
              <i class="check">
                <svg role="img" title="check" class="check-icon">
                  <use href="assets/images/sprite.svg#icon-check"></use>
                </svg>
              </i>
            </label>
          </div>

          <div formGroupName="stocks" *ngIf="limitStock" class="form_item half">
            <label for="tickets_stock">Quota</label>
            <input id="tickets_stock" type="number" formControlName="quota">
          </div>

          <div class="form_item full smallest_margin" *ngIf="limitStock">
            <input id="show_remaining_place" type="checkbox" class="toggle" formControlName="showRemainingPlace">
            <label for="show_remaining_place">
              Afficher le nombre de place disponible
              <i class="check">
                <svg role="img" title="check" class="check-icon">
                  <use href="assets/images/sprite.svg#icon-check"></use>
                </svg>
              </i>
            </label>
          </div>

          <div class="form_item full smallest_margin">
            <input id="rate_visible" type="checkbox" class="toggle" formControlName="isVisible">
            <label for="rate_visible">
              Ce tarif est visible
              <i class="check">
                <svg role="img" title="check" class="check-icon">
                  <use href="assets/images/sprite.svg#icon-check"></use>
                </svg>
              </i>
            </label>
          </div>
          <!-- <div class="form_item full smallest_margin">
            <input id="rate_active" type="checkbox" class="toggle" formControlName="disabled">
            <label for="rate_active">
              Ce tarif est actif
              <i class="check">
                <svg role="img" title="check" class="check-icon">
                  <use href="assets/images/sprite.svg#icon-check"></use>
                </svg>
              </i>
            </label>
          </div> -->
          <div class="form_item full smallest_margin" *ngIf="event.bookType !== 'nft'">
            <input id="rate_available" type="checkbox" class="toggle" formControlName="inTicketOffice">
            <label for="rate_available">
              Ce tarif est disponible au guichet
              <i class="check">
                <svg role="img" title="check" class="check-icon">
                  <use href="assets/images/sprite.svg#icon-check"></use>
                </svg>
              </i>
            </label>
          </div>

          <div (keydown.enter)="$event.stopPropagation()" class="form_item full">
            <label for="description">Description complémentaire</label>
            <app-wysiwyg (changeText)="changeDescriptionValue($event)" [previousValue]="rateForm.value.description">
            </app-wysiwyg>
          </div>
        </div>

        <div *ngIf="isNft" formGroupName="security" class="form_cols">
          <div class="form_item smallest_margin">
            <input id="ticket-resalable" type="checkbox" class="toggle" formControlName="isResalable">
            <label for="ticket-resalable">
              Ce ticket NFT peut-être revendu
              <i class="check">
                <svg role="img" title="check" class="check-icon">
                  <use href="assets/images/sprite.svg#icon-check"></use>
                </svg>
              </i>
            </label>
          </div>
          <div *ngIf="rateForm.value.security.isResalable" class="form_item full">
            <input id="ticket-has-price-limit" type="checkbox" class="toggle" formControlName="priceHasLimit">
            <label for="ticket-has-price-limit">
              Je contrôle les prix de revente
              <i class="check">
                <svg role="img" title="check" class="check-icon">
                  <use href="assets/images/sprite.svg#icon-check"></use>
                </svg>
              </i>
            </label>
          </div>
          <div *ngIf="rateForm.value.security.isResalable && rateForm.value.security.priceHasLimit" class="form_item full">
            <label for="tickets_min">Prix minimum en pourcentage
              <span *ngIf="!rateForm.value.security.minPricePercent" class="description_medium">Pas de minimum</span>
              <span *ngIf="rateForm.value.security.minPricePercent" class="description_medium">Minimum : {{ rateForm.value.price * (rateForm.value.security.minPricePercent / 100) || 0 | currency: 'EUR'}}</span>
            </label>
            <input id="tickets_min" type="number" formControlName="minPricePercent">
          </div>
          <div *ngIf="rateForm.value.security.isResalable && rateForm.value.security.priceHasLimit" class="form_item full">
            <label for="tickets_max">Prix maximum en pourcentage
              <span *ngIf="!rateForm.value.security.maxPricePercent" class="description_medium">Pas de maximum</span>
              <span *ngIf="rateForm.value.security.maxPricePercent" class="description_medium">Maximum : {{ rateForm.value.price * (rateForm.value.security.maxPricePercent / 100) || 0 | currency: 'EUR'}}</span>
            </label>
            <input id="tickets_max" type="number" formControlName="maxPricePercent">
          </div>
        </div>


        <p *ngIf="event.bookType == 'nft' && ticketType && event.status=='published'" class="description_medium">Les modifications suivantes ne seront pas prises en compte pour les tickets déjà achetés : "Nom du ticket", "Ce ticket NFT peut-être revendu", "Contrôle sur le prix de revente". Si ces informations sont modifiées, cela sera pris en compte seulement pour les prochains tickets NFT générés.</p>

        <div class="form_actions right">
          <app-button (clickEvent)="goBack()" text="Annuler" [style]="'border blue'"></app-button>
          <app-button [loadingOff]="loadingState$" [style]="'plain'" type="submit"
            [disabled]="rateForm.invalid || !priceOk" (clickEvent)="createRate()"
            text="{{ticketType ? 'Éditer' : 'Créer'}}">
          </app-button>
          <app-popup *ngIf="error != null" title="Une erreur est survenue" message="{{error}}" popupOpened="true"
            (backEvent)="error = null"></app-popup>
        </div>
      </form>
    </div>
  </div>
</section>
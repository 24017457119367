import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { moreAnimation } from 'src/app/animations/animations';
import { GetEvent } from '../getEvent';

@Component({
  selector: 'app-event-campaign',
  templateUrl: './event-campaign.component.html',
  styleUrls: ['./event-campaign.component.scss'],
  providers: [GetEvent],
  animations: [
    moreAnimation
  ]
})
export class EventCampaignComponent implements OnInit {
  private _subscription = new Subscription();
  public event: any;
  public error: string;
  public loadingState$: Subject<void> = new Subject<void>();
  public currentUrl;
  public campaigns: any[] = [
    {
      name: "Campagne échauffement",
      object: "Prêt-e à partir ? J-10 avant le départ !",
      openings: "489",
      clicks: "111",
      status: "Délivrée",
    },
    {
      name: "Campagne électorale",
      object: "Devenez président de le monde",
      openings: "1",
      clicks: "4563513",
      status: "Annulée",
    },
    {
      name: "Baguette de campagne",
      object: "Hon hon hon ! Baguette.",
      openings: "7851",
      clicks: "61",
      status: "Farinée",
    },
    {
      name: "Câlons-en-Campagne",
      object: "Il faut appeler un cat un cat",
      openings: "163",
      clicks: "64875",
      status: "Hachurée",
    },
    {
      name: "Marcel Campagnol",
      object: "Le célèbre rygbyman",
      openings: "14",
      clicks: "614",
      status: "Provencée",
    }
  ];
  public tableOptions = [
    {
      title: "Libellé",
      class: "name",
      sort: true,
      key: 'name',
      //sortMethod: (order) => this.sort("name", order),
    },
    {
      title: "Objet",
      class: "object",
      sort: false,
      key: 'object',
      //sortMethod: (order) => this.sort("dates.startDate", order)
    },
    {
      title: "Ouvertures",
      class: "openings",
      sort: true,
      key: 'openings',
      //sortMethod: (order) => this.sort("ticketing.quota", order)
    },
    {
      title: "Clics",
      class: "clicks",
      sort: true,
      key: 'clicks',
      //sortMethod: (order) => this.sort("ticketing.totalPrice.sellingPrice", order),
    },
    {
      title: "Statut",
      class: "status",
      sort: true,
      key: 'status',
      //sortMethod: (order) => this.sort("status", order)
    },
    {
      class: "more",
      toggle: [
        {
          title: 'Partager la campagne',
          //method: this.selectEvent
        },
        {
          title: 'Supprimer la campagne',
          class: "red",
          //method: this.selectEvent
        }
      ]
    }
  ]

  constructor(
    private getEvent: GetEvent,
    private router: Router
  ) { }

  async ngOnInit() {
    this.currentUrl = this.router.url;
    this.event = await this.getEvent.get();
  }

  createCampaign(ref = this) {
    ref.router.navigate([`${ref.currentUrl}/creer`])
  }

}

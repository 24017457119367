import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IMedia } from 'src/app/models/media.model';
import { APICreateResponse, APIListResponse, APIResponse, GetParams } from 'src/app/models/type.definition';
// import { Requester } from '../requester';
import { Provider } from '../provider';
import { HTTPTools } from 'src/app/etc/http-tools'
import { map } from 'rxjs/operators';


@Injectable()
export class MediaService extends Provider<IMedia> {
  protected url = "common/medias";

  // public getList(params: GetParams<IMedia>): Observable<IMedia[]> {
  //   return this.httpClient.get<IMedia[]>(this.getUrl('list', params));
  // }

  public getMedia(id: string, body?: GetParams<IMedia>): Observable<IMedia> {
    if (!body.filter) {;
      body.filter = {};
    }
    body.filter.id = id;
    return this.httpClient.get<APIListResponse<IMedia>>(this.getUrl('list', body))
      .pipe(map(medias => medias.data[0]));
  }

  public getStream(id: string): Observable<Blob> {
    return this.httpClient.get(this.getUrl(`view/stream/${id}`), { responseType: 'blob' })
  }

  public upload(body: FormData): Observable<APICreateResponse> {
    HTTPTools.setNextContentType(null);
    return this.httpClient.put<APICreateResponse>(this.getUrl('create/upload'), body);
  }

  public delete(id: string): Observable<APIResponse> {
    return this.httpClient.delete<APIResponse>(this.getUrl(`delete/${id}`));
  }

  public getUnsplash(keyword: string): Observable<{ author: string, url: string }[]> {
    const url = this.getUrl(`list/unsplash?keyword=${keyword}&orientation=landscape&perPage=10`);

    return this.httpClient.get<{ data: string[], author: string[] }>(url)
      .pipe(map(res => {
        const images: { author: string, url: string } [] = [];
        for (let i = 0; i < res.data.length; i++){
          images.push({ author: res.author[i], url: res.data[i] })
        }
        return images;
      }));
  }
}




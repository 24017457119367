import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ListFromEventComponent } from 'src/app/elements/list-component';
import { TableOptions } from 'src/app/models/type.definition';
import { IWaitingList } from 'src/app/models/waiting-list.model';
import { WaitingListService } from 'src/app/providers';
import { GetEvent } from '../../getEvent';

@Component({
  selector: 'app-waiting-list',
  templateUrl: './waiting-list.component.html',
  styleUrls: ['./waiting-list.component.scss'],
  providers: [GetEvent]
})


export class WaitingListComponent extends ListFromEventComponent<IWaitingList> {
  public dataName = "Liste d'attente";
  public tableOptions: TableOptions<IWaitingList>[] = [
    {
      title: "Nom",
      class: "name",
      subClass: 'doselect',
      sort: true,
      key: 'lastName',
      sortMethod: order => this.sort("lastName", order),
    },
    {
      title: "Prénom",
      class: "first_name",
      subClass: 'doselect',
      sort: true,
      key: 'firstName',
      sortMethod: order => this.sort("firstName", order),
    },
    {
      title: "Email",
      class: "email",
      subClass: 'doselect',
      sort: true,
      key: 'email',
      sortMethod: order => this.sort("email", order),
    },
    {
      title: "Tarif",
      class: "rate",
      sort: false,
      key: 'ticketName',
    }
  ]

  constructor(protected getEvent: GetEvent, protected provider: WaitingListService) {
    super();
  }

  protected setGetParams(): void {
    this.getParams.select = ['firstName', 'lastName', 'email', 'ticketTypeId'];
    this.getParams.populate = ['ticketTypeId'];
    super.setGetParams();
  }

  protected formatElement(element: IWaitingList): IWaitingList {
    element.ticketName = element.ticketType.name;
    return element;
  }

  protected afterInit(): void {
    this.initForm();
  }

  protected initForm(): void {
    this.filtersForm = this.formBuilder.group({
      keyword: new FormControl()
    });
  }

}
